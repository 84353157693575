import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { useCustomMutation } from "@/hooks";

import { api, handleScroll } from "@/helpers";

import { ScrollTypes } from "@/constants/global";

import { IBookingTransactions, TMutationsOptions } from "@/types";

import { setIsShowPaymentModal } from "@/store/booking/booking.slice";

interface ICreateBookingReservation {
  bookingId: number | undefined;
  data: IBookingTransactions;
}

const fetcher = async (data: ICreateBookingReservation) => {
  const { bookingId, ...restData } = data;
  
return api.post(`bookings/${bookingId}/transactions`, restData.data);
};

export const useCreateTransaction = (
  options?: TMutationsOptions<ICreateBookingReservation, ICreateBookingReservation, Promise<void>>
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useCustomMutation<
    ICreateBookingReservation,
    ICreateBookingReservation,
    Promise<void>
  >(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      dispatch(setIsShowPaymentModal(false));
      handleScroll(ScrollTypes.REMOVE);
      await queryClient.invalidateQueries(["get-booking"], { exact: false });
      await queryClient.invalidateQueries(["get-bookings"], { exact: false });
      await queryClient.invalidateQueries(["get-bookings-reservations"], { exact: false });
      await queryClient.invalidateQueries(["get-booking-transactions"], { exact: false });
    }
  });

  const handleMutate = (data: ICreateBookingReservation) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

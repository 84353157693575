
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useCustomMutation } from "@/hooks";

import { api } from "@/helpers";

import { ROUTES } from "@/constants";

import { TMutationsOptions } from "@/types";

import { setFooterContent, setHandleSubmitSeasonData } from "@/store/footer/footer.slice";

const fetcher = async (seasonId: string) => {
  return api.del(`seasons/${seasonId}`);
};

const { SETTINGS, PRICE_SETUP } = ROUTES;

export const useDeleteSeason = (options?: TMutationsOptions<string, string>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hotelId } = useParams();

  const { mutate, ...rest } = useCustomMutation<string, string>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      navigate(`/${hotelId}/${SETTINGS}/${PRICE_SETUP}`);
      await queryClient.invalidateQueries(["get-seasons"], { exact: false });
    },
    onSettled: () => {
      dispatch(setHandleSubmitSeasonData(false))
      dispatch(setFooterContent(null));
    }
  });

  const handleMutate = (seasonId: string) => {
    mutate(seasonId);
  };

  return { mutate: handleMutate, ...rest };
};

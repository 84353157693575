import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";

import { FallingOut, Icon } from "@/elements";

import { useChangeBookingStatus } from "@/react-queries/booking/useChangeBookingStatus";

import { handleScroll } from "@/helpers";

import { BookingStatus } from "@/constants/bookings/bookings.constant";
import { ScrollTypes } from "@/constants/global";

import { IBookingStatus } from "@/types";

import styles from "./BookingCreated.module.scss";

import { RootState } from "@/store";
import { clearBookingStates } from "@/store/booking/booking.slice";

export const BookingCreated = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const bookingCreatedId = useSelector(
    (state: RootState) => state.booking.creation.createdBookingId
  );

  const { mutate: handleStatusBooking } = useChangeBookingStatus();

  const handleSkipApprove = () => {
    dispatch(clearBookingStates());
    handleScroll(ScrollTypes.REMOVE);
  };

  const handleApprove = () => {
    handleStatusBooking({
      id: bookingCreatedId as IBookingStatus["status"],
      status: BookingStatus.APPROVED
    });
    dispatch(clearBookingStates());
  };

  return (
    <FallingOut
      content={
        <div className={styles["booking-created__wrapper"]}>
          <div className={styles["booking-created__container"]}>
            <h1 className={styles["booking-created__title"]}>
              {t("booking.create.success-create")}
            </h1>
            <div className={styles["booking-created__content"]}>
              <div className={styles["booking-created__item"]}>
                <Icon icon="info" className={styles["booking-created__icon"]} />
                <span className={styles["booking-created__text"]}>
                  {t("booking.create.guest-recieve-message")}
                </span>
              </div>
              <div className={styles["booking-created__item"]}>
                <Icon icon="info" className={styles["booking-created__icon"]} />
                <span className={styles["booking-created__text"]}>
                  {t("booking.create.booking-status")}
                </span>
              </div>
            </div>
          </div>
          <div className={styles["booking-created__btns"]}>
            <Button
              onClick={handleApprove}
              size="large"
              type="primary"
              className={styles["booking-created__btn"]}
            >
              {t("booking.create.approve")}
            </Button>
            <Button
              onClick={handleSkipApprove}
              size="large"
              ghost
              className={styles["booking-created__btn"]}
            >
              {t("booking.create.skip-approve")}
            </Button>
          </div>
        </div>
      }
    />
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { FallingOut } from "@/elements";
import { AppDrawer } from "@/elements/Drawer/Drawer";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { handleScroll } from "@/helpers";

import { DATE_MONTH_YEAR_FORMAT, ScrollTypes } from "@/constants/global";

import styles from "./BookingAction.module.scss";

import { BookingActionContent } from "./BookingActionContent";

import { RootState } from "@/store";
import {
  clearChoosenCalendarBooking,
  setHandleAllLocationId,
  setHandleBookingActionButtonContent
} from "@/store/booking/booking.slice";

export const BookingAction = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const chooseCalendarDay = useSelector((state: RootState) => state.booking.choosenCalendarBooking);
  const isMobile = useGetScreenStatus();
  const isShowActionButtonContent = useSelector((state: RootState) => state.booking.main.isShowActionButtonContent);

  const conditionTitle = chooseCalendarDay?.date ? (
    <span className={styles["modal-title"]}>
      {dayjs(dayjs(chooseCalendarDay.date)).format(DATE_MONTH_YEAR_FORMAT)}
    </span>
  ) : (
    t("booking.action-btn.title")
  );

  const closeFallingOut = () => {
    dispatch(clearChoosenCalendarBooking());
    dispatch(setHandleBookingActionButtonContent(false));
    dispatch(setHandleAllLocationId(null));
    handleScroll(ScrollTypes.REMOVE);
  };

  if (!isMobile) {
    return (
      <AppDrawer title={conditionTitle} onClose={closeFallingOut} open={isShowActionButtonContent}>
        <BookingActionContent />
      </AppDrawer>
    );
  }

  return (
    <FallingOut
      title={conditionTitle}
      handleClose={closeFallingOut}
      content={<BookingActionContent />}
    />
  );
};

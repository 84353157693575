import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";

import { Icon } from "@/elements";
import { Weekdays } from "@/elements/Weekdays/Weekdays";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { ROUTES } from "@/constants";

import styles from "./PriceSetupTariffItem.module.scss";

import { IPriceSetupTariffItem } from "./PriceSetupTariffItem.type";

const { SETTINGS, PRICE_SETUP, SEASON_TARIFF, EDIT } = ROUTES;

export const PriceSetupTariffItem: FC<IPriceSetupTariffItem> = ({ tariff }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { priceSetupId, hotelId } = useParams();
  const isMobile = useGetScreenStatus();

  return (
    <div key={tariff.id} className={styles.tariffs__item}>
      <div className={styles["tariffs__actions-wrapper"]}>
        <div className={styles.tariffs__actions}>
          <Weekdays isRead choosenDays={tariff.daysOfWeek} />
        </div>
        {!isMobile ? (
          <Button
            onClick={() =>
              navigate(
                `/${hotelId}/${SETTINGS}/${PRICE_SETUP}/${priceSetupId}/${SEASON_TARIFF}/${tariff.id}/${EDIT}`
              )
            }
            icon={<Icon icon="edit" />}
            className={styles["tariffs__edit-btn"]}
            ghost
            type="primary"
          >
            {t("price-setup.tariff.edit")}
          </Button>
        ) : null}
      </div>
      <div className={styles.tariffs__details}>
        <div className={styles["tariffs__details-item"]}>
          <span className={styles["tariffs__details-item-label"]}>
            {t("price-setup.tariff.standart")}
          </span>
          <span className={styles["tariffs__details-item-text"]}>
            {tariff.standardPrice} {t("price-setup.tariff.fiat")}
          </span>
        </div>
        <div className={styles["tariffs__details-item"]}>
          <span className={styles["tariffs__details-item-label"]}>
            {t("price-setup.tariff.additional")}
          </span>
          <span className={styles["tariffs__details-item-text"]}>
            {tariff.extraGuestPrice || 0} {t("price-setup.tariff.fiat")}
          </span>
        </div>
      </div>
      {isMobile ? (
        <Button
          onClick={() =>
            navigate(
              `/${hotelId}/${SETTINGS}/${PRICE_SETUP}/${priceSetupId}/${SEASON_TARIFF}/${tariff.id}/${EDIT}`
            )
          }
          icon={<Icon icon="edit" />}
          className={styles["tariffs__edit-btn"]}
          ghost
          type="primary"
        >
          {t("price-setup.tariff.edit")}
        </Button>
      ) : null}
    </div>
  );
};

import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

import { useGetCalendarDayPrice } from "@/react-queries/booking/useGetCalendarDayPrice";
import { userGetReservationBookings } from "@/react-queries/booking/useGetReservations";

import { useGetDaysInMonth } from "@/hooks/booking/useGetDaysInMonth";

import { CalendarListViewType, CalendarTypes } from "@/constants/calendar/calendar.constants";
import { LIST_CALENDAR_HEIGHT_STICKY, MAX_MOBILE_WIDTH } from "@/constants/global";

import { IMonthDays } from "@/types";

import styles from "./CalendarListView.module.scss";

import { CalendarListViewItem } from "../CalendarListViewItem/CalendarListViewItem";
import { getTotalPriceForDay } from "../CalendarMonthly/CalendarMonthly.helper";

import { ICalendarListView } from "./CalendarListView.type";

import { RootState } from "@/store";
import { setCalendarType } from "@/store/calendar/calendar.slice";

export const CalendarListView: FC<ICalendarListView> = ({
  calendarDate,
  handleNextMonth,
  handlePrevMonth,
  setSticky
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const [monthDays, setMonthDays] = React.useState<IMonthDays[]>([]);
  const {
    dates,
    handleDates,
    handleRangeDaysInMonthWithExpanded,
    handleExpandCollapseBookingsDays
  } = useGetDaysInMonth();
  const isUpdateReservation = useSelector((state: RootState) => state.calendar.isUpdateReservation);
  const calendarViewType = useSelector((state: RootState) => state.calendar.calendarType);

  const { data: bookingReservations, mutate: bookingReservationMutate } =
    userGetReservationBookings();

  const { data: calendarPricing, refetch: refetchCalendarPricing } = useGetCalendarDayPrice(dates, {
    enabled: !!dates
  });

  const handleExpandDates = (
    e: React.MouseEvent<HTMLDivElement>,
    type: CalendarListViewType,
    date: string
  ) => {
    e.stopPropagation();
    if (bookingReservations) {
      setMonthDays(
        handleExpandCollapseBookingsDays(type, date, monthDays, bookingReservations, dates)
      );
    }
  };

  const checkScrollTop = () => {
    if (window.innerWidth < MAX_MOBILE_WIDTH) {
      setSticky(window.scrollY > LIST_CALENDAR_HEIGHT_STICKY);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);

    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  });

  useEffect(() => {
    handleDates();
  }, [calendarDate]);

  useEffect(() => {
    if (isUpdateReservation || dates) {
      bookingReservationMutate(dates);
    }
  }, [dates, isUpdateReservation]);

  useEffect(() => {
    if (dates && bookingReservations) {
      setMonthDays(
        handleRangeDaysInMonthWithExpanded({
          monthDays: {
            fromDate: dates.fromDate,
            toDate: dates.toDate
          },
          reservationDates: bookingReservations
        })
      );

      refetchCalendarPricing();
    }
  }, [dates, bookingReservations]);

  const handlers = useSwipeable({
    onSwipedLeft: handleNextMonth,
    onSwipedRight: handlePrevMonth
  });

  useEffect(() => {
    if (!location.search) {
      navigate({
        search: `?type=${calendarViewType}`
      });
    } else if (queryParams.get("type")) {
      dispatch(setCalendarType(queryParams.get("type") as CalendarTypes));
    }
  }, [location]);

  return (
    <div {...handlers} className={styles["calendar-list-view__container"]}>
      {monthDays.map((day, index) => (
        <CalendarListViewItem
          dates={dates}
          handleExpandDates={handleExpandDates}
          key={index}
          day={day}
          dayTotalPrice={getTotalPriceForDay({
            calendarPricing: calendarPricing || [],
            date: day.date
          })}
          reservations={bookingReservations}
        />
      ))}
    </div>
  );
};

import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./auth/auth.slice";
import bookingReducer from "./booking/booking.slice";
import calendarReducer from "./calendar/calendar.slice";
import footerReducer from "./footer/footer.slice";
import guestReducer from "./guests/guests.slice";
import headerReducer from "./header/header.slice";
import hotelReducer from "./hotel/hotel.slice";
import priceSetupReducer from "./price-setup/price-setup.slice";
import userReducer from "./user/user.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    guests: guestReducer,
    header: headerReducer,
    booking: bookingReducer,
    user: userReducer,
    calendar: calendarReducer,
    footer: footerReducer,
    priceSetup: priceSetupReducer,
    hotel: hotelReducer
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

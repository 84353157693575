import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Divider, Form, Typography } from "antd";
import { useForm } from "antd/es/form/Form";

import { FormItem, Icon, Input, Loader, notification } from "@/elements";
import { Weekdays } from "@/elements/Weekdays/Weekdays";

import { useCreateTariff } from "@/react-queries/tariffs/useCreateTariff";
import { useDeleteTariff } from "@/react-queries/tariffs/useDeleteTariff";
import { useGetTariff } from "@/react-queries/tariffs/useGetTariff";
import { useGetTariffs } from "@/react-queries/tariffs/useGetTariffs";
import { useUpdateTariff } from "@/react-queries/tariffs/useUpdateTariff";

import { useYupSync } from "@/hooks";
import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { handleScroll } from "@/helpers";

import { ROUTES } from "@/constants";
import { FooterType, ScrollTypes } from "@/constants/global";

import { tariffSchema } from "./SeasonTariff.schema";

import styles from "./SeasonTariff.module.scss";

import { CancellationModal } from "../../Forms/CancellationModal/CancellationModal";

import { ISeasonTariffForm } from "./SeasonTariff.type";

import { RootState } from "@/store";
import { setFooterContent } from "@/store/footer/footer.slice";
import { setHeaderNavigation } from "@/store/header/header.slice";
import {
  setCreateTariff,
  setIsDeleteTariffModal,
  setIsUpdatedTariffData,
  setIsUpdateTariff
} from "@/store/price-setup/price-setup.slice";

const { SETTINGS, PRICE_SETUP, EDIT } = ROUTES;

const { Link } = Typography;

export const SeasonTariff = () => {
  const [form] = useForm<ISeasonTariffForm>();
  const yupSync = useYupSync(tariffSchema);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { priceSetupId, tariffId, hotelId } = useParams();
  const { pathname } = useLocation();
  const header = useSelector((state: RootState) => state.header);
  const [blockedDays, setBlockedDays] = React.useState<string[]>([]);
  const [choosenDays, setChoosenDays] = React.useState<string[]>([]);
  const isCreateTariff = useSelector((state: RootState) => state.priceSetup.tariff.isCreateTariff);
  const isUpdateTariff = useSelector((state: RootState) => state.priceSetup.tariff.isUpdateTariff);
  const isUpdateDataTariff = useSelector((state: RootState) => state.priceSetup.tariff.isUpdateDataTariff);
  const isDeleteTariff = useSelector(
    (state: RootState) => state.priceSetup.tariff.isDeleteTariffModal
  );
  const isEdit = !!tariffId;

  const isMobile = useGetScreenStatus();

  const { mutate: createTariff, isLoading: isCreationTariff } = useCreateTariff();
  const { mutate: updateTariff } = useUpdateTariff();
  const { mutate: deleteTariff } = useDeleteTariff();
  const { data: tariffsData, isLoading: isTariffsLoading } = useGetTariffs(priceSetupId || "", {
    enabled: !!priceSetupId
  });
  const {
    data: tariffData,
    isLoading: isTariffLoading,
    refetch,
    isRefetching
  } = useGetTariff(
    {
      seasonId: Number(priceSetupId),
      tariffId: Number(tariffId)
    },
    {
      enabled: !!tariffId,
      cacheTime: 0
    }
  );

  const { t } = useTranslation();

  const handleChooseWeekDay = (day: string) => {
    if (choosenDays.includes(day)) {
      setChoosenDays((prevValue) => prevValue.filter((choosenDay) => choosenDay !== day));
    } else {
      setChoosenDays((prevValue) => [...prevValue, day]);
    }
  };

  const handleSubmit = (values: ISeasonTariffForm) => {
    if (!choosenDays.length) {
      return notification.error(t("price-setup.tariff.error"));
    }

    if (isEdit) {
      return updateTariff({
        seasonId: priceSetupId as string,
        tariffId: tariffId as string,
        body: {
          standardPrice: Number(values.standardPrice),
          extraGuestPrice: Number(values.extraGuestPrice),
          daysOfWeek: choosenDays
        }
      });
    }

    return createTariff({
      standardPrice: Number(values.standardPrice),
      extraGuestPrice: Number(values.extraGuestPrice),
      daysOfWeek: choosenDays,
      id: Number(priceSetupId)
    });
  };

  const handleModalClose = () => {
    dispatch(setIsDeleteTariffModal(false));
    handleScroll(ScrollTypes.REMOVE);
  };

  const handleModalRemove = () => {
    if (priceSetupId) {
      deleteTariff({
        seasonId: Number(priceSetupId),
        tariffId: Number(tariffId)
      });
      navigate(`/${hotelId}/${SETTINGS}/${PRICE_SETUP}/${priceSetupId}/${EDIT}`);
      handleModalClose();
    }
  };

  const handleDelete = () => {
    dispatch(setIsDeleteTariffModal(true));
  };

  const handleClose = () => {
    navigate(`/${hotelId}/${SETTINGS}/${PRICE_SETUP}/${priceSetupId}/${EDIT}`)
  }

  const onValueChange = (_: ISeasonTariffForm, values: ISeasonTariffForm) => {
    if (tariffData && isEdit) {
      for (const key of Object.keys(values)) {
        if (
          Number(values[key as keyof ISeasonTariffForm]) !==
          tariffData[key as keyof ISeasonTariffForm]
        ) {
          dispatch(setIsUpdatedTariffData(true));
          break;
        }
        dispatch(setIsUpdatedTariffData(false));
      }
    }
  };

  useEffect(() => {
    const conditionTypeFooterContent = isEdit
      ? FooterType.PRICE_SETUP_EDIT_TARIFF
      : FooterType.PRICE_SETUP_TARIFF;

    dispatch(
      setFooterContent({
        type: conditionTypeFooterContent,
        submitButtonText: t("price-setup.tariff.save"),
        cancelButtonText: t("price-setup.tariff.cancel")
      })
    );
  }, [pathname]);

  useEffect(() => {
    dispatch(
      setHeaderNavigation({
        navigation: {
          title: t("price-setup.form.title"),
          link: `/${hotelId}/${SETTINGS}/${PRICE_SETUP}/${priceSetupId}/${EDIT}`
        }
      })
    );
  }, []);

  useEffect(() => {
    if (isCreateTariff || isUpdateTariff) {
      form.submit();
      dispatch(setCreateTariff(false));
      dispatch(setIsUpdateTariff(false));
    }
  }, [isCreateTariff, isUpdateTariff]);

  useEffect(() => {
    if (!isEdit && tariffsData?.length) {
      const weekdays = Array.from(
        new Set([...(tariffsData?.map((tariff) => tariff.daysOfWeek).flat() as string[])])
      );
      setBlockedDays(weekdays);
    }
    if (isEdit && tariffsData?.length) {
      const choosenDaysInAnotherTariffs = tariffsData
        .map((tariff) => (Number(tariff?.id) === Number(tariffId) ? [] : tariff.daysOfWeek))
        .flat();
      setBlockedDays(choosenDaysInAnotherTariffs);
    }
  }, [tariffsData]);

  useEffect(() => {
    if (tariffId && tariffData) {
      setChoosenDays(tariffData.daysOfWeek);

      form.setFieldsValue({
        standardPrice: tariffData.standardPrice,
        extraGuestPrice: tariffData.extraGuestPrice
      });
    }
  }, [tariffData, tariffId]);

  useEffect(() => {
    if (tariffId) {
      refetch();
    }
  }, [pathname]);

  useEffect(() => {
    if (tariffData && choosenDays) {
      if (tariffData.daysOfWeek.join("") === choosenDays.join("")) {
        dispatch(setIsUpdatedTariffData(false));
      } else {
        dispatch(setIsUpdatedTariffData(true));
      }
    }
  }, [choosenDays, tariffData]);

  if (isTariffLoading || isTariffsLoading || isRefetching) {
    return <Loader isFullScreen />;
  }

  return (
    <>
      {!isMobile ? (
        <div className={styles.tariff__header}>
          <div className={styles.tariff__back}>
            <Link className={styles["tariff__back-container"]} href={header.navigation.link}>
              <div className={styles["tariff__back-icon"]}>
                <Icon icon="arrowLeft" className={styles["logo-header"]} />
              </div>
              <span className={styles["tariff__back-title"]}>{header.navigation.title}</span>
            </Link>
          </div>
          <h1 className={styles.tariff__title}>{t("price-setup.tariff.title")}</h1>
        </div>
      ) : null}
      <Form
        onValuesChange={onValueChange}
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        className={styles.tariff__container}
      >
        {isMobile ? (
          <h1 className={styles.tariff__title}>{t("price-setup.tariff.title")}</h1>
        ) : null}
        <div className={styles.tariff__step}>{t("price-setup.tariff.step-1")}</div>
        <div className={styles["tariff__days-container"]}>
          <Weekdays
            blockedDays={blockedDays}
            choosenDays={choosenDays}
            handleChooseWeekDay={handleChooseWeekDay}
          />
        </div>
        <Divider className={styles.tariff__divider} />
        <div className={styles.tariff__step}>{t("price-setup.tariff.step-2")}</div>
        <div className={styles["tariff__form-items"]}>
          <FormItem
            rules={[yupSync]}
            required
            name="standardPrice"
            className={styles["tariff__form-item"]}
            label={t("price-setup.tariff.price-guests")}
          >
            <Input
              prefix={(<Icon icon="bankNote" />) as unknown as string}
              type="number"
              min={1}
              suffix={t("booking.create.fiat")}
            />
          </FormItem>

          <FormItem
            rules={[yupSync]}
            name="extraGuestPrice"
            className={styles["tariff__form-item"]}
            label={t("price-setup.tariff.additional-price")}
          >
            <Input
              prefix={(<Icon icon="bankNote" />) as unknown as string}
              type="number"
              min={1}
              suffix={t("booking.create.fiat")}
            />
          </FormItem>
        </div>
      </Form>
      {!isMobile ? (
        <>
          <div className={styles.tariff__buttons}>
            <div className={styles["tariff__buttons-cancellation"]}>
              <Button loading={isCreationTariff} ghost className={styles.tariff__button} onClick={handleClose}>
                {t("price-setup.tariff.cancel")}
              </Button>

              {isEdit ? (
                <div className={styles["tariff__delete-btn"]} onClick={handleDelete}>
                  <Icon icon="outlineTrash" />
                </div>
              ) : null}
            </div>

            <Button
              type="primary"
              className={styles.tariff__button}
              onClick={form.submit}
              loading={isCreationTariff}
              disabled={isEdit && !isUpdateDataTariff}
            >
              {t("price-setup.tariff.save")}
            </Button>
          </div>
        </>
      ) : null}
      {isDeleteTariff && (
        <CancellationModal
          title={t("price-setup.tariff.delete.title")}
          approveButtonText={t("price-setup.tariff.delete.confirm-btn")}
          declineButtonText={t("price-setup.tariff.delete.cancel-btn")}
          handleClose={handleModalClose}
          handleApprove={handleModalRemove}
        />
      )}
    </>
  );
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Typography } from "antd";

import { GuestHistory } from "@/components/Guest/GuestHistory/GuestHistory";
import { GuestViewCard } from "@/components/Guest/GuestViewCard/GuestViewCard";

import { Icon, Loader, Title } from "@/elements";

import { useGetGuest } from "@/react-queries/guests/useGetGuest";

import { ROUTES } from "@/constants";

import styles from "./GuestView.module.scss";

import { RootState } from "@/store";
import { setHeaderNavigation } from "@/store/header/header.slice";

const { GUESTS, EDIT } = ROUTES;

const { Link } = Typography;

export const GuestView = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const header = useSelector((state: RootState) => state.header);

  const dispatch = useDispatch();

  const { userId, hotelId } = useParams<{ userId: string; hotelId: string }>();

  const {
    data: guestData,
    isLoading,
    refetch,
    isRefetching
  } = useGetGuest(userId || "", {
    enabled: !userId
  });

  const handleEdit = () => {
    navigate(`/${hotelId}/${GUESTS}/${userId}/${EDIT}`);
  };

  useEffect(() => {
    dispatch(
      setHeaderNavigation({
        navigation: {
          title: t("guests.back-button"),
          link: `/${hotelId}/${GUESTS}`
        }
      })
    );
  }, []);

  useEffect(() => {
    refetch();
  }, [userId]);

  if (isLoading || isRefetching) {
    return <Loader isFullScreen />;
  }

  return (
    <>
      <div className={styles["guests-view__container"]}>
        <div className={styles.tariff__back}>
          <Link className={styles["guests-view__back-container"]} href={header.navigation.link}>
            <div className={styles["guests-view__back-icon"]}>
              <Icon icon="arrowLeft" className={styles["logo-header"]} />
            </div>
            <span className={styles["guests-view__back-title"]}>{header.navigation.title}</span>
          </Link>
        </div>
        <GuestViewCard guestData={guestData} handleEdit={handleEdit} />
        <div className={styles["guests-view__card"]}>
          <Title className={styles["guests-view-history__title"]}>
            {t("view-guests.history.title")}
          </Title>
          <GuestHistory />
        </div>
      </div>
    </>
  );
};

import { cookieStorageService } from "../storage/storage.helpers";

import { COOKIE_STORAGE_KEYS } from "@/constants";

const {
  getItem: getToken,
  setItem: setToken,
  removeItem: removeToken
} = cookieStorageService<string>(COOKIE_STORAGE_KEYS.JWT_TOKEN);

const {
  getItem: getRefreshToken,
  setItem: setRefreshToken,
  removeItem: removeRefreshToken
} = cookieStorageService<string>(COOKIE_STORAGE_KEYS.REFRESH_TOKEN);

export const getJWTToken = () => {
  return getToken();
};

export const getJWTRefreshToken = () => {
  return getRefreshToken();
};

export const setJWTToken = (token: string) => {
  return setToken(token);
};

export const setJWTRefreshToken = (token: string) => {
  return setRefreshToken(token);
};

export const removeJWTToken = () => {
  removeRefreshToken();
  
return removeToken();
};

export const removeJWTRefreshToken = () => {
  return removeRefreshToken();
};

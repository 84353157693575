import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { FallingOut } from "@/elements";
import { AppDrawer } from "@/elements/Drawer/Drawer";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { IBookingEdit } from "./BookingEdit.type";
import { BookingEditContent } from "./BookingEditContent";

import { RootState } from "@/store";

export const BookingEdit: FC<IBookingEdit> = ({ bookingData, closeModal }) => {
  const { t } = useTranslation();
  const isMobile = useGetScreenStatus();
  const isShowEditModal = useSelector((state: RootState) => state.booking.edit.isShowEditModal);

  if (!isMobile) {
    return (
      <AppDrawer title={t("booking.edit.title")} onClose={closeModal} open={isShowEditModal}>
        <BookingEditContent bookingData={bookingData} closeModal={closeModal} />
      </AppDrawer>
    );
  }

  return (
    <>
      <FallingOut
        handleClose={closeModal}
        title={t("booking.edit.title")}
        content={<BookingEditContent bookingData={bookingData} closeModal={closeModal} />}
      />
    </>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Calendar } from "@/containers";

import { Loader } from "@/elements";

import { allLocationsIdStorageService } from "@/helpers";

import { AllLocationType } from "@/constants/global";

import styles from "./Dashboard.module.scss";

import { RootState } from "@/store";

const { getItem: getIsAllLocation } = allLocationsIdStorageService(AllLocationType.ALL_LOCATIONS_ID);

export const Dashboard = () => {
  const { t } = useTranslation();
  const activeHotel = useSelector((state: RootState) => state.hotel.activeHotel);
  const isAllLocation = getIsAllLocation();
  const conditionTitle = isAllLocation ? t("locations.dashboard.title") : activeHotel?.publicLabel || <Loader />;

  return (
    <div className={styles.dashboard__wrapper}>
      <h1 className={styles.dashboard__title}>
        { conditionTitle }
      </h1>
      <div className={styles["dashboard__container-wrapper"]}>
        <div className={styles.dashboard__container}>
          <Calendar />
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";

import { ROUTES } from "@/constants";

import styles from "./PriceSetupTariffFooter.module.scss";

import { RootState } from "@/store";
import { setCreateTariff } from "@/store/price-setup/price-setup.slice";

const { PRICE_SETUP, SETTINGS, EDIT } = ROUTES;

export const PriceSetupTariffFooter = () => {
  const location = useLocation();
  const priceSetupId = location.pathname.split("/").filter(Boolean)[3]
  const footerContent = useSelector((state: RootState) => state.footer.footerContent);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hotelId } = useParams();
  const isCreateTariff = useSelector((state: RootState) => state.priceSetup.tariff.isCreateTariff);

  const handleSubmit = () => {
    dispatch(setCreateTariff(true))
  }

  const handleClose = () => {
    navigate(`/${hotelId}/${SETTINGS}/${PRICE_SETUP}/${priceSetupId}/${EDIT}`)
  }

  return (
    <div className={styles["footer__buttons-wrapper"]}>
      <div className={styles.footer__buttons}>
        <Button size="large" type="primary" onClick={handleSubmit} className={styles.footer__button}>
          {footerContent?.submitButtonText}
        </Button>
        <Button
          onClick={handleClose}
          size="large"
          ghost
          className={styles.footer__button}
          loading={isCreateTariff}
        >
          {footerContent?.cancelButtonText}
        </Button>
      </div>
    </div>
  );
};

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

import { GuestList } from "@/components";

import { Search, Title } from "@/elements";

import styles from "./Guests.module.scss";

export const Guests = () => {
  const { t } = useTranslation();

  const { pathname } = useLocation();
  const isViewPage = pathname.includes("view");
  const isEditPage = pathname.includes("edit");
  const isCreatePage = pathname.includes("create");
  const [search, setSearch] = useState("");

  const onSearch = (query: string) => {
    setSearch(query);
  };

  if (isCreatePage || isEditPage || isViewPage) {
    return (
      <div className={styles.guests__wrapper}>
        <Outlet />
      </div>
    );
  }

  return (
    <div className={styles.guests__wrapper}>
      <div className={styles.guests__container}>
        <div className={styles.guests__header}>
          <Title level={3} className={styles.guests__title}>
            {t("guests.title")}
          </Title>
          <div className={styles.guests__search}>
            <Search value={search} onSearch={onSearch} />
          </div>
        </div>
        <div className={styles.guests__content}>
          <GuestList search={search} />
        </div>
      </div>
    </div>
  );
};

import React, { FC, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useSwipeable } from "react-swipeable";
import { EventContentArg, ViewMountArg } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import FullCalendar from "@fullcalendar/react";
import { Popover } from "antd";
import dayjs from "dayjs";

import { Icon, ReservationDetails } from "@/elements";

import { useGetCalendarDayPrice } from "@/react-queries/booking/useGetCalendarDayPrice";
import { userGetReservationBookings } from "@/react-queries/booking/useGetReservations";

import { useGetDaysInMonth } from "@/hooks/booking/useGetDaysInMonth";
import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { handleScroll } from "@/helpers";

import { ROUTES } from "@/constants";
import { BookingStatus } from "@/constants/bookings/bookings.constant";
import { DATE_CALENDAR_EVENT_FORMAT, DATE_FORMAT, ScrollTypes } from "@/constants/global";

import { IBookingReservations, ICalendarEvent } from "@/types";

import styles from "./CalendarMonthly.module.scss";

import {
  getClassNameForEvents,
  getIconForEvents
} from "../../../containers/Calendar/Calendar.helper";
import { conditionGuests } from "../CalendarListViewItem/CalendarListViewItem.helper";

import { getEventDays, getTotalPriceForDay } from "./CalendarMonthly.helper";
import { ICalendarMonthly } from "./CalendarMonthly.type";

import { RootState } from "@/store";
import {
  setChoosenCalendarBooking,
  setHandleBookingActionButtonContent
} from "@/store/booking/booking.slice";

const { BOOKINGS, VIEW, BLOCKED, DASHBOARD } = ROUTES;

export const CalendarMonthly: FC<ICalendarMonthly> = ({
  calendarRef,
  handleNextMonth,
  handlePrevMonth,
  dates
}) => {
  const isUpdateReservation = useSelector((state: RootState) => state.calendar.isUpdateReservation);
  const calendarActiveType = useSelector((state: RootState) => state.calendar.calendarType);
  const activeHotel = useSelector((state: RootState) => state.hotel.activeHotel);
  const isMobileScreen = useGetScreenStatus();
  const conditionForCalendarHeight = isMobileScreen ? "60vh" : "75vh";
  const [calendarKey, setCalendarKey] = useState(0);

  const navigate = useNavigate();
  const { hotelId } = useParams();
  const dispatch = useDispatch();
  const { handleRangeDaysInMonth } = useGetDaysInMonth();
  const {
    t,
    i18n: { language }
  } = useTranslation();

  const [events, setEvents] = React.useState<ICalendarEvent[]>([]);
  const approvedDays = useMemo(() => {
    return getEventDays({
      events,
      handleRangeDaysInMonth,
      withoutStatus: BookingStatus.BLOCKED
    });
  }, [events]);

  const blockedDays = useMemo(() => {
    return getEventDays({
      events,
      handleRangeDaysInMonth,
      exactStatus: BookingStatus.BLOCKED
    });
  }, [events]);

  const { data: bookingReservations, mutate: bookingReservationMutate } =
    userGetReservationBookings();

  const { data: calendarPricing, refetch: refetchCalendarPricing } = useGetCalendarDayPrice(dates, {
    enabled: !!dates
  });
  const handlers = useSwipeable({
    onSwipedLeft: handleNextMonth,
    onSwipedRight: handlePrevMonth
  });

  const handleDayClick = ({ date, totalPrice }: { date: string; totalPrice: number | null }) => {
    if (approvedDays.bookingDates.includes(date)) {
      const findDay = approvedDays.data.find((day) => day.bookedDays.includes(date));

      return navigate(`/${hotelId}/${BOOKINGS}/${findDay?.bookingId}/${VIEW}`, {
        state: {
          route: `/${hotelId}/${DASHBOARD}/?type=${calendarActiveType}`
        }
      });
    }

    if (blockedDays.bookingDates.includes(date)) {
      const findDay = blockedDays.data.find((day) => day.bookedDays.includes(date));

      return navigate(`/${hotelId}/${BOOKINGS}/${findDay?.bookingId}/${BLOCKED}`, {
        state: {
          route: `/${hotelId}/${DASHBOARD}/?type=${calendarActiveType}`
        }
      });
    }

    dispatch(
      setChoosenCalendarBooking({
        date,
        totalPrice
      })
    );
    dispatch(setHandleBookingActionButtonContent(true));
    handleScroll(ScrollTypes.ADD);
  };

  useEffect(() => {
    if (isUpdateReservation || dates) {
      bookingReservationMutate(dates);
      refetchCalendarPricing();
    }
  }, [dates, isUpdateReservation, activeHotel]);

  useEffect(() => {
    if (bookingReservations) {
      setEvents(
        bookingReservations.map((data: IBookingReservations) => {
          const conditionGuestName =
            data.guestsCount > 1 ? `${data.guestName}+${data.guestsCount - 1}` : data.guestName;

          return {
            start: data.checkinDate,
            end: data.checkoutDate,
            title: conditionGuestName || "",
            id: data.id?.toString() || "",
            status: data.status || "",
            lastDay: data.checkoutDate,
            guestsCount: data.guestsCount || 1,
            details: data.notes || "",
            guestName: data.guestName || "",
            totalPrice: data.totalPrice || 0,
            checkinDate: data.checkinDate,
            checkoutDate: data.checkoutDate,
            bookingId: data.id,
            paidAmount: data.paidAmount || 0
          };
        })
      );
    }
  }, [bookingReservations]);

  const conditionOfEventClick = useMemo(() => {
    if (isMobileScreen) {
      return {
        eventClick: (eventInfo: any) => {
          if (eventInfo.event.extendedProps.status === "blocked") {
            return navigate(`/${hotelId}/${BOOKINGS}/${eventInfo.event.id}/${BLOCKED}`, {
              state: {
                route: `/${hotelId}/${DASHBOARD}/?type=${calendarActiveType}`
              }
            });
          }

          return navigate(`/${hotelId}/${BOOKINGS}/${eventInfo.event.id}/${VIEW}`, {
            state: {
              route: `/${hotelId}/${DASHBOARD}/?type=${calendarActiveType}`
            }
          });
        }
      };
    }

    return {};
  }, [isMobileScreen]);

  useEffect(() => {
    setCalendarKey(calendarKey + 1);
  }, [isMobileScreen]);

  return (
    <div {...handlers} className={styles.calendar__container}>
      <FullCalendar
        ref={calendarRef}
        key={calendarKey}
        plugins={[dayGridPlugin]}
        locale={language}
        initialView="dayGridMonth"
        themeSystem="Simplex"
        fixedWeekCount={false}
        firstDay={1}
        dayCellContent={({ date }) => {
          return (
            <div
              onClick={() =>
                handleDayClick({
                  date: dayjs(date).format(DATE_FORMAT),
                  totalPrice:
                    getTotalPriceForDay({
                      calendarPricing: calendarPricing || [],
                      date: dayjs(date).format(DATE_FORMAT)
                    }) || null
                })
              }
            >
              {date.getDate()}
              <div className="fc-daygrid-day-top">
                <div className={styles["calendar__day-price"]}>
                  {getTotalPriceForDay({
                    calendarPricing: calendarPricing || [],
                    date: dayjs(date).format(DATE_FORMAT)
                  })}
                </div>
              </div>
            </div>
          );
        }}
        viewDidMount={(view: ViewMountArg) => {
          return view;
        }}
        headerToolbar={false}
        events={events}
        {...conditionOfEventClick}
        eventContent={(eventInfo: EventContentArg) => {
          if (eventInfo.event.extendedProps.status === "blocked") {
            if (!isMobileScreen) {
              return (
                <Popover
                  content={
                    <ReservationDetails
                      reservation={eventInfo.event.extendedProps as IBookingReservations}
                    />
                  }
                  trigger="click"
                >
                  <div
                    className={`${styles["calendar__desktop-event-block"]} ${
                      styles[getClassNameForEvents(eventInfo.event._def.extendedProps)]
                    }`}
                  >
                    <Icon icon="lock" className={styles["calendar__block-icon"]} />
                    <div className={styles["calendar__desktop-event-content"]}>
                      <div className={styles["calendar__desktop-event-title"]}>
                        {eventInfo.event.extendedProps.details}
                      </div>
                      <div className={styles["calendar__desktop-event-details"]}>
                        <div className={styles["calendar__desktop-event-date"]}>
                          {dayjs(eventInfo.event.start).format(DATE_CALENDAR_EVENT_FORMAT)}
                        </div>
                        <span className={styles["calendar__desktop-event-divider"]}></span>
                        <div className={styles["calendar__desktop-event-date"]}>
                          {dayjs(eventInfo.event.end).format(DATE_CALENDAR_EVENT_FORMAT)}
                        </div>
                      </div>
                    </div>
                  </div>
                </Popover>
              );
            }

            return <Icon icon="lock" className={styles["calendar__block-icon"]} />;
          }

          if (!isMobileScreen) {
            return (
              <Popover
                content={
                  <ReservationDetails
                    reservation={eventInfo.event.extendedProps as IBookingReservations}
                  />
                }
                trigger="click"
              >
                <div
                  className={`${styles["calendar__desktop-event"]} ${
                    styles[getClassNameForEvents(eventInfo.event._def.extendedProps)]
                  }`}
                >
                  <div className={styles["calendar__desktop-event-icon"]}>
                    {getIconForEvents(eventInfo.event.extendedProps)}
                  </div>
                  <div className={styles["calendar__desktop-event-content"]}>
                    <div className={styles["calendar__desktop-event-title"]}>
                      {eventInfo.event.title}
                    </div>
                    <div className={styles["calendar__desktop-event-details"]}>
                      <div className={styles["calendar__desktop-event-date"]}>
                        {dayjs(eventInfo.event.start).format(DATE_CALENDAR_EVENT_FORMAT)}
                      </div>
                      <span className={styles["calendar__desktop-event-divider"]}></span>
                      <div className={styles["calendar__desktop-event-date"]}>
                        {dayjs(eventInfo.event.end).format(DATE_CALENDAR_EVENT_FORMAT)}
                      </div>
                      <span className={styles["calendar__desktop-event-point"]}></span>
                      <div className={styles["calendar__desktop-event-guests"]}>
                        {t(
                          conditionGuests(eventInfo.event.extendedProps.guestsCount || 1) as string,
                          { count: eventInfo.event.extendedProps.guestsCount }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Popover>
            );
          }

          return eventInfo.event.title;
        }}
        eventClassNames={(eventInfo) => {
          return getClassNameForEvents(eventInfo.event._def.extendedProps);
        }}
        dayCellDidMount={({ date, el }) => {
          const now = dayjs();

          if (dayjs(date).isBefore(now)) {
            el.classList.add("active-week");
          }
        }}
        height={conditionForCalendarHeight}
      />
    </div>
  );
};

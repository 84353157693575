// react-queries
import axios, { AxiosRequestHeaders } from "axios";

import { useCustomMutation } from "@/hooks";

interface IDirectUpload {
  url: string;
  data: Blob;
  headers: AxiosRequestHeaders;
}

const fetcher = ({ url, data, headers }: IDirectUpload) => {
  return axios.put(url, data, { headers });
};

export const useDirectUpload = () => {
  return useCustomMutation(fetcher, {
    preventShowDefaultErrorToast: true
  });
};

import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Layout, Typography } from "antd";

import { ProfileDropDown } from "@/components";

import { Icon } from "@/elements";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { allLocationsIdStorageService } from "@/helpers";

import { ROUTES } from "@/constants";
import { AllLocationType } from "@/constants/global";

import styles from "./MainLayoutHeader.module.scss";

import { RootState } from "@/store";

const { Link } = Typography;
const { Header } = Layout;

const { DASHBOARD, ALL_LOCATIONS, BOOKINGS, GUESTS, SETTINGS } = ROUTES;

const { getItem: getIsAllLocation } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

export const MainLayoutHeader: FC = () => {
  const header = useSelector((state: RootState) => state.header);
  const activeHotel = useSelector((state: RootState) => state.hotel.activeHotel);
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { hotelId } = useParams();
  const isAllLocation = getIsAllLocation();
  const isMobile = useGetScreenStatus();

  const conditionLink =
    activeHotel?.id && !isAllLocation
      ? `/${activeHotel.id}/${DASHBOARD}`
      : `/${ALL_LOCATIONS}/${DASHBOARD}`;

  const isNavigation = header.navigation.title;

  const handleNavigation = useMemo(() => {
    if (isNavigation && isMobile) {
      return (
        <Link className={styles["header__navigation-container"]} href={header.navigation.link}>
          <div className={styles["navigation-icon__container"]}>
            <Icon icon="arrowLeft" className={styles["logo-header"]} />
          </div>
          <span className={styles["navigation-icon__title"]}>{header.navigation.title}</span>
        </Link>
      );
    }

    return (
      <Link href={conditionLink}>
        <Icon icon="logo" className={styles["logo-header"]} />
      </Link>
    );
  }, [header, isMobile]);

  const handleIsActiveTab = (tabPathname: string) => {
    const currentPagePathName = pathname.split("/").filter((item) => item)[1];
    const isActive = currentPagePathName.includes(tabPathname);
    
    if (currentPagePathName === "guests") {
      return `${styles["header__routes-item"]} ${isActive ? styles["active-guests"] : ""}`;
    }

    return `${styles["header__routes-item"]} ${isActive ? styles.active : ""}`;
  };

  return (
    <div className={styles.header__wrapper}>
      <Header className={styles.header}>
        {handleNavigation}
        <div className={styles.header__routes}>
          <Link href={`/${hotelId}/${DASHBOARD}`} className={handleIsActiveTab(DASHBOARD)}>
            <Icon icon="calendar" className={styles["header__routes-item-icon"]} />
            <span className={styles["header__routes-item-text"]}>{t("navigation.calendar")}</span>
          </Link>
          <Link href={`/${hotelId}/${BOOKINGS}`} className={handleIsActiveTab(BOOKINGS)}>
            <Icon icon="receiptCheck" className={styles["header__routes-item-icon"]} />
            <span className={styles["header__routes-item-text"]}> {t("navigation.bookings")} </span>
          </Link>
          <Link href={`/${hotelId}/${GUESTS}`} className={handleIsActiveTab(GUESTS)}>
            <Icon icon="outlineUser" className={styles["header__routes-item-icon"]} />
            <span className={styles["header__routes-item-text"]}> {t("navigation.guests")} </span>
          </Link>
          <Link href={`/${hotelId}/${SETTINGS}`} className={handleIsActiveTab(SETTINGS)}>
            <Icon icon="settingsOutline" className={styles["header__routes-item-icon"]} />
            <span className={styles["header__routes-item-text"]}> {t("navigation.settings")} </span>
          </Link>
        </div>
        <ProfileDropDown />
      </Header>
    </div>
  );
};

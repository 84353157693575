import { array, number, object } from "yup";

export const tariffSchema = object().shape({
  standardPrice: number().required().min(1),
  extraGuestPrice: number()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .min(1),
  weeks: array().required()
});

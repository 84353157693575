import React, { FC, useMemo } from "react";

import { getWeekDays } from "@/components/Season/SeasonTariff/SeasonTariff.helper";

import styles from "./Weekdays.module.scss";

import { IWeekday, IWeekdayType } from "./Weekdays.type";

export const Weekdays: FC<IWeekday> = ({
  blockedDays,
  choosenDays,
  handleChooseWeekDay,
  isRead = false
}) => {
  const weekDays = useMemo(() => {
    return getWeekDays();
  }, []);

  const handleChooseDay = (weekDay: string) => {
    if (handleChooseWeekDay && !blockedDays?.includes(weekDay)) {
      handleChooseWeekDay(weekDay);
    }
  };

  return (
    <>
      {weekDays.map((weekDay: IWeekdayType, index) => {
        return (
          <div
            key={index}
            className={`${isRead ? styles["weekday__item-read"] : styles.weekday__item} ${
              choosenDays.includes(weekDay.fullName) ? styles.active : null
            } ${blockedDays?.includes(weekDay.fullName) ? styles.blocked : null}`}
            onClick={() => handleChooseDay(weekDay.fullName)}
          >
            {weekDay.shortName}
          </div>
        );
      })}
    </>
  );
};

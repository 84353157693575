import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { useCustomMutation } from "@/hooks";

import { api, removeJWTToken } from "@/helpers";

import { ROUTES } from "@/constants";

import { IRequestSetNewPassword, TMutationsOptions } from "@/types";

const fetcher = async (body: IRequestSetNewPassword) => {
  const { newPassword } = body;

  return api.patch("/host/user/set-new-password", { newPassword });
};

const { AUTH, SIGN_IN } = ROUTES

export const useUpdatePassword = (
  options?: TMutationsOptions<Promise<void>, IRequestSetNewPassword>
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useCustomMutation<Promise<void>, IRequestSetNewPassword>(fetcher, {
    ...options,
    onSuccess: (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      removeJWTToken();
      queryClient.clear();
      navigate(`${AUTH}${SIGN_IN}`);
    }
  });

  const handleMutate = (data: IRequestSetNewPassword) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

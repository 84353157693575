// src/SearchBar.js

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { debounce } from "lodash";

import styles from "./Search.module.scss";

import { Icon } from "../Icon/Icon";
import { Input } from "../Input/Input";

export const Search = ({
  onSearch,
  value,
  active,
  isSmall
}: {
  onSearch: (query: string) => void;
  value: string;
  active?: boolean;
  isSmall?: boolean;
}) => {
  const [query, setQuery] = useState(value);
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  const toggleSearchBar = () => {
    if (active) {
      return onSearch("");
    }
    if (isOpen) {
      onSearch("");
    }
    setIsOpen(!isOpen);
  };

  const handleSearchDebounce = debounce(async (value) => {
    onSearch(value);
  }, 300);

  function handleChange(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
    handleSearchDebounce(event.target.value);

    setQuery(event.target.value);
  }

  useEffect(() => {
    if (!value) {
      setQuery(value);
    }
  }, [value]);

  return (
    <div className={`${styles["search-bar-container"]} ${active ? styles.active : null}`}>
      {(isOpen || active) && (
        <Input
          prefix={(<Icon icon="search" />) as unknown as string} // Cast the Element to ReactNode
          type="default"
          value={query}
          onChange={handleChange}
          className={`${styles["search-input"]} ${isSmall ? styles.small : ""}`}
          placeholder={t("search")}
          suffix={<Icon icon="cross" onClick={toggleSearchBar} />}
        />
      )}
      {!active ? (
        <button onClick={toggleSearchBar} className={styles["toggle-button"]}>
          <Icon icon="search" />
        </button>
      ) : null}
    </div>
  );
};

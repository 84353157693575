import { useSelector } from "react-redux";

import { useCustomMutation } from "@/hooks";

import { allLocationsIdStorageService, api, handleHotelIdInApi } from "@/helpers";

import { AllLocationType } from "@/constants/global";

import { IDynamicPrice, IDynamicPriceResponse, TMutationsOptions } from "@/types";

import { RootState } from "@/store";

const { getItem: getAllLocationStatus } = allLocationsIdStorageService(AllLocationType.ALL_LOCATIONS_ID);

const fetcher = async (body: IDynamicPrice): Promise<IDynamicPriceResponse> => {
  const isAllLocation = getAllLocationStatus();
  const conditionData = isAllLocation ? body : handleHotelIdInApi(body);

  return api.get("host/bookings/prices/calculate", conditionData);
};

export const useGetDynamicPrice = (
  options?: TMutationsOptions<IDynamicPriceResponse, IDynamicPrice, IDynamicPriceResponse>
) => {
  const isAllLocation = getAllLocationStatus();
  const chosenFromCalendarLocationId = useSelector((state: RootState) => state.booking.choosenCalendarBooking.hotelId);
  const chosenLocationId = useSelector((state: RootState) => state.booking.allLocation.chosenLocationId);

  const { mutate, ...rest } = useCustomMutation<
    IDynamicPriceResponse,
    IDynamicPrice,
    IDynamicPriceResponse
  >(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      return options?.onSuccess?.(data, ...args);
    }
  });

  const handleMutate = (data: IDynamicPrice) => {
    if (isAllLocation) {
      data.hotelId = chosenLocationId || String(chosenFromCalendarLocationId);
    }

    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

import React from "react";

import { ISeason } from "@/types/season/season.type";

import styles from "./SeasonList.module.scss";

import { SeasonSetupListItem } from "../SeasonSetupListItem/SeasonSetupListItem";

export const SeasonList = ({
    seasonsData,
    }: {
    seasonsData: ISeason[];
}) => {
  return (
    <div className={styles["price-setup-list__container"]}>
      {seasonsData?.map((season: ISeason, index) => (
        <SeasonSetupListItem key={index} season={season} />
      ))}
    </div>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Divider } from "antd";

import { Icon } from "@/elements";

import { allLocationsIdStorageService, hotelIdStorageService } from "@/helpers";

import { ROUTES } from "@/constants";
import { AllLocationType, HotelType, SettingDetailType } from "@/constants/global";

import { ILocationSettingsResponse } from "@/types";

import styles from "./IntroduceMulti.module.scss";

import { Details } from "../Details/Details";

import { RootState } from "@/store";
import { setActiveHotel } from "@/store/hotel/hotel.slice";

const { DASHBOARD } = ROUTES;

const { setItem } = hotelIdStorageService(HotelType.HOTEL_ID);

const { setItem: setAllLocationItem } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

export const IntroduceMulti = () => {
  const hotels = useSelector((state: RootState) => state.user.main?.hotels);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNavigateToLocation = (hotel: ILocationSettingsResponse) => {
    setAllLocationItem(false);
    dispatch(setActiveHotel(hotel));
    setItem(hotel.id.toString());
    navigate(`/${hotel.id}/${DASHBOARD}`);
  };

  return (
    <div className={styles.introduce__wrapper}>
      <h1 className={styles.introduce__title}>
        { t("locations.settings.locations-title") }
      </h1>
      <div className={styles.introduce__location}>
        <div className={styles.introduce__helper}>
          <Icon className={styles["introduce__helper-icon"]} icon="infoOutline" />
          <span className={styles["introduce__helper-text"]}>
            {t("locations.settings.helper-text")}
          </span>
        </div>
        {hotels?.map((hotel, index) => (
          <>
            <div
              key={index}
              className={styles["introduce__location-item"]}
              onClick={() => handleNavigateToLocation(hotel)}
            >
              <div className={styles["introduce__image-container"]}>
                <img className={styles.introduce__image} src={hotel?.locationImage} alt="" />
              </div>
              <div className={styles["introduce__location-name"]}>{hotel?.publicLabel}</div>
            </div>
            <Divider className={styles["introduce__location-divider"]} />
          </>
        ))}
      </div>

      <div className={styles.introduce__details}>
        <div className={styles["introduce__column-contacts"]}>
          <div className={styles["introduce__column-contacts-item"]}>
            <Details type={SettingDetailType.FREE} />
          </div>
          <div className={styles["introduce__column-contacts-item"]}>
            <Details type={SettingDetailType.CONTACT} />
          </div>

          <div className={styles["introduce__column-contacts-item"]}>
            <Details type={SettingDetailType.SOCIALS} />
          </div>
        </div>
        <div className={`${styles.introduce__column} ${styles.second}`}>
          <div className={styles["introduce__column-contacts"]}>
            <div className={styles["introduce__column-contacts-item"]}></div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";

import { ROUTES } from "@/constants";

import styles from "./PriceSetupFooter.module.scss";

import { RootState } from "@/store";
import { setHandleSubmitSeasonData } from "@/store/footer/footer.slice";

const { PRICE_SETUP, SETTINGS } = ROUTES;

export const PriceSetupFooter = () => {
  const footerContent = useSelector((state: RootState) => state.footer.footerContent);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hotelId } = useParams();

  const handleSubmit = () => {
    dispatch(setHandleSubmitSeasonData(true))
  }

  const handleClose = () => {
    navigate(`/${hotelId}/${SETTINGS}/${PRICE_SETUP}`)
  }

  return (
    <div className={styles["footer__buttons-wrapper"]}>
      <div className={styles.footer__buttons}>
        <Button size="large" type="primary" onClick={handleSubmit} className={styles.footer__button}>
          {footerContent?.submitButtonText}
        </Button>
        <Button
          onClick={handleClose}
          size="large"
          ghost
          className={styles.footer__button}
        >
          {footerContent?.cancelButtonText}
        </Button>
      </div>
    </div>
  );
};

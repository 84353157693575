import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Form, Input as AntdInput } from "antd";
import { FormProps } from "antd/lib";

import { FallingOut, FormItem, Icon, Input, Title } from "@/elements";
import { LocationSelect } from "@/elements/LocationSelect/LocationSelect";

import { useCreateGuest } from "@/react-queries/guests/useCreateGuest";
import { useUpdateGuest } from "@/react-queries/guests/useUpdateGuest";

import { useYupSync } from "@/hooks";

import { allLocationsIdStorageService, handleScroll } from "@/helpers";

import { ROUTES } from "@/constants";
import { AllLocationType, ScrollTypes } from "@/constants/global";

import { guestSchema } from "./GuestForm.schema";

import styles from "./GuestForm.module.scss";

import { IGuestForm } from "./GuestForm.type";

import { RootState } from "@/store";
import { clearCreateStates } from "@/store/guests/guests.slice";

const { getItem: getAllLocationStatus } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

const { TextArea } = AntdInput;

const { GUESTS, VIEW } = ROUTES;

export const GuestForm = ({
  localizationName,
  userData,
  ...props
}: {
  localizationName: string;
  userData?: IGuestForm;
} & FormProps) => {
  const { userId, hotelId } = useParams<{ userId: string; hotelId: string }>();
  const [form] = Form.useForm();
  const guests = useSelector((state: RootState) => state.guests);
  const [decline, setDecline] = useState<boolean>(false);
  const navigate = useNavigate();
  const isAllLocaiton = getAllLocationStatus();

  const { mutate: createGuest } = useCreateGuest();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { mutate: updateGuest } = useUpdateGuest();

  const yupSync = useYupSync(guestSchema);
  const isCreate = pathname.includes("create");
  const isEdit = pathname.includes("edit");

  const handleSubmit = (values: IGuestForm) => {
    if (isCreate) {
      Object.keys(values).filter((valueKey: string) => {
        if (!values[valueKey as keyof IGuestForm]) {
          return (values[valueKey as keyof IGuestForm] = null);
        }

        return values[valueKey as keyof IGuestForm];
      });
      createGuest(values);
    }
    if (isEdit && userId) {
      Object.keys(values).filter((valueKey: string) => {
        if (!values[valueKey as keyof IGuestForm]) {
          return (values[valueKey as keyof IGuestForm] = null);
        }

        return values[valueKey as keyof IGuestForm];
      });
      updateGuest(userId, values);
    }
  };

  const isShowFallingOut = useMemo(() => {
    return guests.create.isSuccess || decline;
  }, [guests.create.isSuccess, decline]);

  const handleDeclineShow = () => {
    setDecline(true);
    handleScroll(ScrollTypes.ADD);
  };

  const handleDeclineHidden = () => {
    setDecline(false);
    handleScroll(ScrollTypes.REMOVE);
  };

  const handleRedirectToList = () => {
    navigate(`/${hotelId}/${GUESTS}`);
    handleScroll(ScrollTypes.REMOVE);
    dispatch(clearCreateStates());
  };

  const handleRedirectToView = () => {
    const conditionUserId = guests.create.createdUserId || userId;
    navigate(`/${hotelId}/${GUESTS}/${conditionUserId}/${VIEW}`);
    handleScroll(ScrollTypes.REMOVE);
    dispatch(clearCreateStates());
  };

  useEffect(() => {
    if (guests.create.isSuccess) {
      handleScroll(ScrollTypes.REMOVE);
    }
  }, [guests.create.isSuccess]);

  const conditionFallingOutContent = useMemo(() => {
    if (guests.create.isSuccess) {
      form.resetFields();

      return (
        <>
          <Icon icon="success" className={styles["guest-falling-out__icon"]} />
          <Title level={3} className={styles["guest-falling-out__title"]}>
            {t(`${localizationName}.success-create`)}
          </Title>
          <div className={styles["guest-falling-out__btns"]}>
            <Button
              onClick={handleRedirectToView}
              size="large"
              type="primary"
              className={styles["guest-falling-out__btn"]}
            >
              {t(`${localizationName}.check`)}
            </Button>
            <Button
              onClick={handleRedirectToList}
              size="large"
              ghost
              className={styles["guest-falling-out__btn"]}
            >
              {t(`${localizationName}.list`)}
            </Button>
          </div>
        </>
      );
    }
    if (decline) {
      return (
        <>
          <Title level={3} className={styles["guest-falling-out__title"]}>
            {t(`${localizationName}.decline-title`)}
          </Title>
          <div className={styles["guest-falling-out__btns"]}>
            <Button
              onClick={handleRedirectToList}
              size="large"
              danger
              type="primary"
              className={styles["guest-falling-out__btn"]}
            >
              {t(`${localizationName}.descline-btn`)}
            </Button>
            <Button
              onClick={handleDeclineHidden}
              size="large"
              ghost
              className={styles["guest-falling-out__btn"]}
            >
              {t(`${localizationName}.decline-cancel`)}
            </Button>
          </div>
        </>
      );
    }
  }, [decline, guests.create.isSuccess]);

  return (
    <>
      <Form
        name="guest"
        className={styles["guest-form"]}
        form={form}
        onFinish={handleSubmit}
        initialValues={userData}
        layout="vertical"
        {...props}
      >
        <Title level={3} className={styles["guest-form__title"]}>
          {t(`${localizationName}.title`)}
        </Title>

        <p className={styles["guest-form__subtitle"]}>{t(`${localizationName}.description`)}</p>

        <div className={styles["guest-form__inputs-wrapper"]}>
          {isAllLocaiton ? (
            <FormItem
              className={`${styles["guest-form__item"]} ${styles["guest-form__item-location"]}`}
              required
              label={t(`${localizationName}.location-label`)}
              name="hotelId"
              rules={[yupSync]}
            >
              <LocationSelect placeholder={t(`${localizationName}.location-placeholder`)} />
            </FormItem>
          ) : null}
          <FormItem
            className={styles["guest-form__item"]}
            name="fullName"
            label={t(`${localizationName}.name-label`)}
            required
            rules={[yupSync]}
          >
            <Input placeholder={t(`${localizationName}.name-placeholder`)} />
          </FormItem>

          <FormItem
            className={styles["guest-form__item"]}
            name="email"
            label={t(`${localizationName}.email-label`)}
            rules={[yupSync]}
          >
            <Input placeholder={t(`${localizationName}.email-placeholder`)} />
          </FormItem>

          <FormItem
            className={styles["guest-form__item"]}
            name="phoneNumber"
            label={t(`${localizationName}.phone-label`)}
            rules={[yupSync]}
          >
            <Input type="phone" placeholder={t(`${localizationName}.phone-placeholder`)} />
          </FormItem>

          <FormItem
            className={styles["guest-form__item-textarea"]}
            name="comment"
            label={t(`${localizationName}.comment-label`)}
            rules={[yupSync]}
          >
            <TextArea
              className={styles["guest-form__textarea"]}
              placeholder={t(`${localizationName}.comment-placeholder`)}
            />
          </FormItem>
        </div>
        <div className={styles["guest-form__buttons"]}>
          <Button
            size="large"
            htmlType="submit"
            type="primary"
            className={styles["guest-form-btn"]}
          >
            {t(`${localizationName}.create-btn`)}
          </Button>
          <Button
            onClick={handleDeclineShow}
            size="large"
            ghost
            className={styles["guest-form-btn__cancel"]}
          >
            {t(`${localizationName}.decline`)}
          </Button>
        </div>
      </Form>
      {isShowFallingOut && <FallingOut content={conditionFallingOutContent} />}
    </>
  );
};

import { IGuestHistoryBooking } from "@/components/Guest/GuestHistory/GuestHistory.type";

import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { api, getJWTToken } from "@/helpers";

import { TQueryOptions } from "@/types";

const fetcher = async (userId: string) => {
  return api.get(`guests/${userId}/bookings`);
};

export const useGetGuestHistotyBookings = <TQueryFnData = IGuestHistoryBooking[], TData = IGuestHistoryBooking[]>(
  userId: string,
  options?: TQueryOptions<TQueryFnData, TData>
) => {
  const token = getJWTToken();

  return useCustomQuery<TQueryFnData, TData>(["get-guest-history-booking"], () => fetcher(userId), {
    ...options,
    staleTime: Infinity,
    enabled: !!token
  });
};

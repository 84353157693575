import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Button, Divider, Form } from "antd";

import { FormItem, Input } from "@/elements";

import { useYupSync } from "@/hooks";
import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { ISetNewPassword } from "@/types";

import styles from "./AuthSetNewPasswordForm.module.scss";

import { IAuthSetNewPasswordFormProps } from "./AuthSetNewPasswordForm.type";
import { setNewPasswordSchema } from "./AuthSetNewPasswordSchema";

import { RootState } from "@/store";

export const AuthSetNewPasswordForm: FC<IAuthSetNewPasswordFormProps> = ({
  handleSubmit,
  navigateBackButton,
  isLoading
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm<ISetNewPassword>();
  const isMobile = useGetScreenStatus();
  const isUserAuth = useSelector((state: RootState) => !!state.user.main?.id);
  const [disableButton, setDisableButton] = React.useState<boolean>(true);

  const yupSync = useYupSync(setNewPasswordSchema, form.getFieldsValue);

  const handleOnValueChange = (_: ISetNewPassword, values: ISetNewPassword) => {
    if (values.password !== values.repeatPassword) {
      return setDisableButton(true);
    }
    if (!values.password || !values.repeatPassword) {
      return setDisableButton(true);
    }
    
return setDisableButton(false);
  };

  return (
    <Form
      form={form}
      onFinish={handleSubmit}
      onValuesChange={handleOnValueChange}
      className={`${styles["set-new-password-form"]} ${isUserAuth ? styles["auth-user"] : null}`}
      layout="vertical"
      name="set-new-password-form"
    >
      <div className={styles["set-new-password-form__container"]}>
        <FormItem label={t("set-password.new-password")} name="password" required rules={[yupSync]}>
          <Input type="password" />
        </FormItem>

        <FormItem
          label={t("set-password.confirm-password")}
          name="repeatPassword"
          required
          rules={[yupSync]}
        >
          <Input type="password" />
        </FormItem>
      </div>

      {isUserAuth && !isMobile ? <Divider className={styles.divider} /> : null}
      <div
        className={`${styles["set-new-password-form__buttons"]} ${
          isUserAuth ? styles["auth-user"] : null
        }`}
      >
        <Button
          size="large"
          htmlType="submit"
          type="primary"
          disabled={isLoading || disableButton}
          loading={isLoading}
          className={styles["set-new-password__btn"]}
        >
          {t("set-password.btn-text")}
        </Button>
        <Button className={styles["set-new-password__link-back"]} onClick={navigateBackButton}>
          {t("set-password.decline")}
        </Button>
      </div>
    </Form>
  );
};

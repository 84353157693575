import { UploadFile } from "antd";

// helpers
import { checkFileSize, checkFileType, compressImage } from "@/helpers";

const ALLOWED_FILE_SIZE_RANGE = "500..5242880";
const ALLOWED_IMAGE_FORMATS = ["image/png", "image/jpeg", "image/jpg", "image/webp"];

export const useCheckImage = () => {
  return async (file: UploadFile) => {
    try {
      const blob = (await compressImage(file, 800)) as UploadFile;

      checkFileType(blob, ALLOWED_IMAGE_FORMATS);

      checkFileSize(blob, ALLOWED_FILE_SIZE_RANGE);

      return blob as unknown as Blob;
    } catch (error) {
      console.error(error);

      return false;
    }
  };
};

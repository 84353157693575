import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Divider } from "antd";

import { Icon, Loader } from "@/elements";

import { useCurrentUser } from "@/react-queries";

import { allLocationsIdStorageService, hotelIdStorageService, removeHotelId } from "@/helpers";

import { ROUTES } from "@/constants";
import { AllLocationType, HotelType } from "@/constants/global";

import { ILocationSettingsResponse } from "@/types";

import styles from "./LocationChooser.module.scss";

import { setHandleShowLocationModal } from "@/store/header/header.slice";
import { setActiveHotel } from "@/store/hotel/hotel.slice";

const { CREATE, DASHBOARD, ALL_LOCATIONS, LOCATIONS } = ROUTES;

const { setItem } = hotelIdStorageService(HotelType.HOTEL_ID);

const { setItem: setAllLocationItem, getItem: getIsAllLocation } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

export const LocationChooser = ({ isPopoverVersion = false }: { isPopoverVersion?: boolean }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { hotelId } = useParams();
  const dispatch = useDispatch();

  const { data: userData, isLoading: isUserDataLoading } = useCurrentUser();

  const isSingleHotel = useMemo(() => {
    return userData?.hotels?.length === 1;
  }, [userData]);
  const isAllLocation = getIsAllLocation();

  const handleAddLocation = () => {
    navigate(CREATE);
  };

  const handleNavigationToLocations = () => {
    if (isAllLocation) {
      navigate(`/${ALL_LOCATIONS}/${LOCATIONS}`);
    } else {
      navigate(`/${hotelId}/${LOCATIONS}`);
    }
    dispatch(setHandleShowLocationModal(false));
  };

  const handleChangeLocation = (hotel: ILocationSettingsResponse) => {
    setAllLocationItem(false);
    dispatch(setActiveHotel(hotel));
    setItem(hotel.id.toString());
    navigate(`/${hotel.id}/${DASHBOARD}`);
    dispatch(setHandleShowLocationModal(false));
  };

  const handleNavigateToAllLocation = () => {
    setAllLocationItem(true);
    dispatch(setActiveHotel(null));
    removeHotelId();
    navigate(`/${ALL_LOCATIONS}/${DASHBOARD}`);
    dispatch(setHandleShowLocationModal(false));
  };

  if (isUserDataLoading) {
    return <Loader isFullScreen />;
  }

  return (
    <div className={`${styles.container} ${isPopoverVersion ? styles.popover : null}`}>
      {isSingleHotel ? null : (
        <>
          <div
            className={`${styles.item__locations} ${isAllLocation ? styles.active : null}`}
            onClick={handleNavigateToAllLocation}
          >
            <div className={styles.item__container}>
              <div
                className={`${styles["all-location__icon-wrapper"]} ${
                  isAllLocation ? styles.active : null
                }`}
              >
                <div className={styles["all-location__icon"]}>
                  <Icon icon="house" />
                </div>
              </div>

              <div className={styles.name}>{t("locations.all-location")}</div>
            </div>
          </div>
          <Divider className={styles.divider} />
        </>
      )}
      <div className={styles.list}>
        {userData?.hotels &&
          userData?.hotels.map((hotel: ILocationSettingsResponse, index: number) => (
            <div className={styles.item} key={index} onClick={() => handleChangeLocation(hotel)}>
              <div
                className={`${styles.item__container} ${
                  Number(hotelId) === hotel.id ? styles.active : null
                }`}
              >
                <div className={styles.image}>
                  <img src={hotel.locationImage} alt="hotel" />
                </div>
                <div className={styles.name}>{hotel.publicLabel}</div>
              </div>
              <Divider className={styles.item__divider} />
            </div>
          ))}
      </div>
      {isPopoverVersion ? (
        <Button
          onClick={handleNavigationToLocations}
          className={styles["submit-button"]}
        >
          {t("locations.page")}
        </Button>
      ) : (
        <Button
          className={styles["submit-button"]}
          icon={<Icon icon="roundedPlus" />}
          onClick={handleAddLocation}
        >
          {t("locations.add")}
        </Button>
      )}
    </div>
  );
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";

import { Icon } from "@/elements";

import { ROUTES } from "@/constants";

import styles from "./PriceSetupEditTariffFooter.module.scss";

import { RootState } from "@/store";
import { setIsDeleteTariffModal, setIsUpdateTariff } from "@/store/price-setup/price-setup.slice";

const { PRICE_SETUP, SETTINGS, EDIT } = ROUTES;

export const PriceSetupEditTariffFooter = () => {
  const footerContent = useSelector((state: RootState) => state.footer.footerContent);
  const priceSetupId = location.pathname.split("/").filter(Boolean)[3];
  const { hotelId } = useParams();
  const isUpdateDataTariff = useSelector((state: RootState) => state.priceSetup.tariff.isUpdateDataTariff);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = () => {
    dispatch(setIsUpdateTariff(true))
  }

  const handleClose = () => {
    navigate(`/${hotelId}/${SETTINGS}/${PRICE_SETUP}/${priceSetupId}/${EDIT}`)
  }

  const handleDelete = () => {
    dispatch(setIsDeleteTariffModal(true))
  }

  return (
    <div className={styles["footer__buttons-wrapper"]}>
      <div className={styles.footer__buttons}>
        <Button disabled={!isUpdateDataTariff} size="large" type="primary" onClick={handleSubmit} className={styles.footer__button}>
          {footerContent?.submitButtonText}
        </Button>
        <div className={styles["footer__button-container"]}>
          <Button
            onClick={handleClose}
            size="large"
            ghost
            className={styles.footer__button}
          >
            {footerContent?.cancelButtonText}
          </Button>
          <div className={styles["footer__delete-btn"]} onClick={handleDelete}>
            <Icon icon="outlineTrash" />
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";

import { Icon, Loader } from "@/elements";

import { useGetPaymentOptions } from "@/react-queries/paymentOptions/useGetPaymentOptions";

import { checkIsClearHeader } from "@/helpers";

import { ROUTES } from "@/constants";

import styles from "./PaymentOptions.module.scss";

import { setHeaderNavigation } from "@/store/header/header.slice";

const { SETTINGS, PAYMENT_OPTIONS, CREATE, EDIT } = ROUTES;

export const PaymentOptions = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { hotelId } = useParams();
  const isCreate = pathname.includes(CREATE);
  const isEdit = pathname.includes(EDIT);
  const dispatch = useDispatch();

  const { data: paymentOptions, isLoading } = useGetPaymentOptions();

  const handleNavigate = (id?: number) => {
    if (id) {
      return navigate(`/${hotelId}/${SETTINGS}/${PAYMENT_OPTIONS}/${id}/${EDIT}`);
    }

    return navigate(`/${hotelId}/${SETTINGS}/${PAYMENT_OPTIONS}/${CREATE}`);
  };

  useEffect(() => {
    if (checkIsClearHeader(pathname)) {
      dispatch(
        setHeaderNavigation({
          navigation: {
            title: t("settings.title"),
            link: `/${hotelId}/${SETTINGS}`
          }
        })
      );
    }
  }, [pathname]);

  if (isCreate || isEdit) {
    return <Outlet />;
  }

  if (isLoading) {
    return <Loader isFullScreen />;
  }

  return (
    <div className={styles["payment-options__wrapper"]}>
      <h1 className={styles["payment-options__title"]}>{t("payment-options.title")}</h1>
      <div className={styles["payment-options__content"]}>
        {paymentOptions?.length ? (
          <div className={styles["payment-options__list"]}>
            {paymentOptions?.map((paymentOption) => (
              <div
                key={paymentOption.id}
                onClick={() => handleNavigate(paymentOption.id)}
                className={`${styles["payment-options__item"]} ${
                  !paymentOption.isTurnedOn && styles["no-active"]
                }`}
              >
                <div className={styles["payment-options__item-name"]}>{paymentOption.name}</div>
                <Icon icon="arrowRight" />
              </div>
            ))}
          </div>
        ) : null}

        <Button
          type="primary"
          ghost
          className={styles["payment-options__add-button"]}
          icon={<Icon icon="roundedPlus" />}
          onClick={() => handleNavigate()}
        >
          {t("payment-options.add-payment")}
        </Button>
      </div>
    </div>
  );
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";

import { SeasonList } from "@/components/Season/SeasonList/SeasonList";

import { Icon, Loader } from "@/elements";

import { useGetSeasons } from "@/react-queries/sessions/useGetSessions";

import { checkIsClearHeader } from "@/helpers";

import { ROUTES } from "@/constants";

import styles from "./SeasonSetup.module.scss";

import { setHeaderNavigation } from "@/store/header/header.slice";

const { SETTINGS, CREATE, EDIT } = ROUTES;

export const PriceSetup = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { hotelId } = useParams();
  const isCreate = location.pathname.includes(CREATE);
  const isEdit = location.pathname.includes(EDIT);
  const dispatch = useDispatch();

  const { data: seasonsData, isLoading: isSeasonDataLoading } = useGetSeasons({
    enabled: !isCreate && !isEdit
  });

  const handleNavigateToCreate = () => {
    navigate(CREATE);
  };

  const handleRenderContent = () => {
    if (!seasonsData?.length) {
      return (
        <div className={styles["price-setup__content-empty"]}>
          <Icon icon="infoOutline" />
          <p className={styles["price-setup__content-empty-text"]}>
            {t("price-setup.empty-content")}
          </p>
        </div>
      );
    }

    return <SeasonList seasonsData={seasonsData} />;
  };

  useEffect(() => {
    if (checkIsClearHeader(location.pathname)) {
      dispatch(
        setHeaderNavigation({
          navigation: {
            title: t("settings.title"),
            link: `/${hotelId}/${SETTINGS}`
          }
        })
      );
    }
  }, [location.pathname]);

  if (isCreate || isEdit) {
    return (
      <div className={styles.wrapper}>
        <Outlet />
      </div>
    );
  }

  if (isSeasonDataLoading) {
    return <Loader isFullScreen />;
  }

  return (
    <div className={styles["price-setup__wrapper"]}>
      <h1 className={styles["price-setup__title"]}>{t("price-setup.title")}</h1>
      <div className={styles["price-setup__container"]}>
        <div className={styles["price-setup__content"]}>
          <h2 className={styles["price-setup__content-title"]}>{t("price-setup.content-title")}</h2>
          {
            seasonsData?.length ?
            <div className={styles["price-setup__content-empty"]}>
              <Icon icon="infoOutline" />
              <p className={styles["price-setup__content-empty-text"]}>
                {t("price-setup.attention-content")}
              </p>
            </div>
            : null
          }
          {handleRenderContent()}
          <Button
            onClick={handleNavigateToCreate}
            type="primary"
            className={styles["price-setup__button"]}
          >
            {t("price-setup.add-season")}
          </Button>
        </div>
      </div>
    </div>
  );
};

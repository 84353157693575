import React, { FC } from "react";
import { useTranslation } from "react-i18next";

import { Icon } from "@/elements";

import { SettingDetailType } from "@/constants/global";

import styles from "./Details.module.scss";

export const Details: FC<{ type: SettingDetailType }> = ({ type }) => {
  const { t } = useTranslation();

  switch (type) {
    case SettingDetailType.FOCUS:
      return (
        <div className={styles.focus__wrapper}>
          <h1 className={styles.focus__title}>{t("settings.details.focus.title")}</h1>

          <div className={styles.focus__content}>
            <div className={styles.focus__item}>{t("settings.details.focus.chapter-one")}</div>

            <div className={styles.focus__item}>{t("settings.details.focus.chapter-two")}</div>

            <div className={styles.focus__item}>{t("settings.details.focus.chapter-three")}</div>
          </div>
        </div>
      );
    case SettingDetailType.SLOGAN:
      return (
        <div className={styles.slogan__wrapper}>
          <h1 className={styles.slogan__title}>{t("settings.details.slogan.title")}</h1>
        </div>
      );
    case SettingDetailType.FREE:
      return (
        <div className={styles.free__wrapper}>
          <div className={styles.free__label}>
            <div className={styles["free__label-content"]}>{t("settings.details.free.label")}</div>
          </div>
          <h1 className={styles.free__title}>{t("settings.details.free.title")}</h1>
          <div className={styles.free__desc}>{t("settings.details.free.desc")}</div>
        </div>
      );
    case SettingDetailType.CONTACT:
      return (
        <div className={styles.contact__wrapper}>
          <h1 className={styles.contact__title}>{t("settings.details.contact.title")}</h1>
          <div className={styles.contact__desc}>
            <a href={`mailto:${t("settings.details.contact.email")}`}>
              {t("settings.details.contact.email")}
            </a>
          </div>
        </div>
      );
    case SettingDetailType.SOCIALS:
      return (
        <div className={styles.socials__wrapper}>
          <h1 className={styles.socials__title}>
            {t("settings.details.socials.title")}
          </h1>
          <div className={styles.socials__desc}>
            <a
              href={t("settings.details.socials.facebook")}
              target="_blank"
              rel="noreferrer"
            >
              <Icon icon="facebook" className={styles.icon} />
            </a>
            <a
              href={t("settings.details.socials.instagram")}
              target="_blank"
              rel="noreferrer"
            >
              <Icon icon="inst" className={styles.icon} />
            </a>
            <a
              href={t("settings.details.socials.linkedin")}
              target="_blank"
              rel="noreferrer"
            >
              <Icon icon="linkedin" className={styles.icon} />
            </a>
          </div>
        </div>
      );
    default:
      return <></>;
  }
};

import React from "react";

import { Title } from "@/elements";

import styles from "./AlphabeticList.module.scss";

import { IGuest } from "../Guest/GuestList/GuestList.type";

export const AlphabeticList = ({
  data,
  handleAction
}: {
  data: IGuest[] | undefined;
  handleAction: (item: IGuest) => void;
}) => {
  const sortedData = data?.sort((a, b) => {
    return a.fullName.localeCompare(b.fullName);
  });

  const groupedData = sortedData?.reduce((acc: Record<string, IGuest[]>, currentValue) => {
    const firstLetter = currentValue.fullName[0].toUpperCase();
    if (!acc[firstLetter]) {
      acc[firstLetter] = [];
    }
    acc[firstLetter].push(currentValue);

    return acc;
  }, {});

  return (
    <div className={styles.alphabetic__container}>
      {Object.keys(groupedData || {}).map((letter) => (
        <div className={styles.alphabetic__section} key={letter}>
          <Title level={6} className={styles["alphabetic__section-title"]}>
            {letter}
          </Title>
          <ul className={styles.alphabetic__items}>
            {groupedData && groupedData[letter].map((item, index) => (
              <div
                onClick={() => handleAction(item)}
                className={styles.alphabetic__item}
                key={index}
              >
                <span className={styles["alphabetic__user-name"]}>{item.fullName}</span>
                <span className={styles["alphabetic__phone-number"]}>{item.phoneNumber}</span>
              </div>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

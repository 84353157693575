import { BookingPaymentStatus } from "@/constants/bookings/bookings.constant";

const MIN_VALUE = 0;

export const getTypeOfPayment = ({
  amountValue,
  totalValue
}: {
  amountValue: number;
  totalValue: number;
}) => {
  if (amountValue === MIN_VALUE) {
    return BookingPaymentStatus.NOT_PAID;
  }
  if (amountValue < totalValue && amountValue > MIN_VALUE) {
    return BookingPaymentStatus.PART_PAY;
  }
  
  if (amountValue > totalValue) {
    return BookingPaymentStatus.OVERPAY;
  }

  return BookingPaymentStatus.FULL_PAY;
};

import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { useCustomMutation } from "@/hooks";

import { allLocationsIdStorageService, api, handleHotelIdInApi } from "@/helpers";

import { BookingCreateSteps, SegmentTypes } from "@/constants/bookings/bookings.constant";
import { AllLocationType } from "@/constants/global";

import { IBookingForm, TMutationsOptions } from "@/types";

import { RootState } from "@/store";
import { setBookingCreatedData, setHandleBookingCreation, setHandleBookingCreationStep, setHandleBookingFormCreate, setHandleSwitchType } from "@/store/booking/booking.slice";
import { setHandleUpdateReservation } from "@/store/calendar/calendar.slice";

const { getItem: getAllLocationStatus } = allLocationsIdStorageService(AllLocationType.ALL_LOCATIONS_ID);

const fetcher = async (body: IBookingForm) => {
  const isAllLocation = getAllLocationStatus();
  const conditionData = isAllLocation ? body : handleHotelIdInApi(body);

  return api.post("host/bookings", conditionData);
};

export const useCreateBooking = (options?: TMutationsOptions<IBookingForm, IBookingForm>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const isAllLocation = getAllLocationStatus();
  const chosenFromCalendarLocationId = useSelector((state: RootState) => state.booking.choosenCalendarBooking.hotelId);
  const chosenLocationId = useSelector((state: RootState) => state.booking.allLocation.chosenLocationId);

  const { mutate, ...rest } = useCustomMutation<IBookingForm, IBookingForm>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      dispatch(setHandleBookingCreation(true));
      dispatch(setHandleUpdateReservation(true));
      dispatch(setBookingCreatedData(data?.id || ""));
      dispatch(setHandleBookingFormCreate(false));
      dispatch(setHandleSwitchType(SegmentTypes.LIST));
      dispatch(setHandleBookingCreationStep(BookingCreateSteps.CREATE));
      await queryClient.invalidateQueries(["get-booking"], { exact: false });
      await queryClient.invalidateQueries(["get-bookings"], { exact: false });
      await queryClient.invalidateQueries(["get-bookings-reservations"], { exact: false });
      await queryClient.invalidateQueries(["get-bookings-count"], { exact: false });
      await queryClient.invalidateQueries(["get-all-locations-calendar-booking-prices"], { exact: false });
      await queryClient.invalidateQueries(["get-all-locations-calendar-booking-prices"], { exact: false });
      options?.onSuccess?.(data, ...args);
    }
  });

  const handleMutate = (data: IBookingForm) => {
    if (isAllLocation) {
      data.hotelId = chosenLocationId || String(chosenFromCalendarLocationId);
    }
    
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

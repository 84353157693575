import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { ROUTES } from "@/constants";

import styles from "./TermsOfCondition.module.scss";

import { RootState } from "@/store";
import { setHeaderNavigation } from "@/store/header/header.slice";

const { SETTINGS } = ROUTES;

export const TermsOfCondition = () => {
  const { hotelId } = useParams();
  const { t } = useTranslation();

  const user = useSelector((state: RootState) => state.user.main);
  const isUserLoggedIn = !!user;
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(
        setHeaderNavigation({
          navigation: {
            title: t("settings.title"),
            link: `/${hotelId}/${SETTINGS}`
          }
        })
      )
    }
  }, [isUserLoggedIn, pathname])

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t("terms-and-conditions.title")}</h1>
      <div className={isUserLoggedIn ? styles.container : styles.auth__container}>
        <p className={styles.term__title}>
          {t("terms-and-conditions.last-update")}
        </p>
        <p>
          {t("terms-and-conditions.introduce")}
        </p>
        <h2 className={styles.text__title}>
          {t("terms-and-conditions.title-1")}
        </h2>
        <p>
          {t("terms-and-conditions.desc-1")}
        </p>
        <h2 className={styles.text__title}>
          {t("terms-and-conditions.title-2")}
        </h2>
        <p>
          {t("terms-and-conditions.desc-2")}
        </p>
        <h2 className={styles.text__title}>
          {t("terms-and-conditions.title-3")}
        </h2>
        <p>
          <Trans
            i18nKey="terms-and-conditions.desc-3"
            components={{
              list: <ul />,
              item: <li />
            }}
          />
        </p>
        <h2 className={styles.text__title}>
          {t("terms-and-conditions.title-4")}
        </h2>
        <p>
          {t("terms-and-conditions.desc-4")}
        </p>
        <h2 className={styles.text__title}>
          {t("terms-and-conditions.title-5")}
        </h2>
        <p>
          {t("terms-and-conditions.desc-5")}
        </p>
        <h2 className={styles.text__title}>
          {t("terms-and-conditions.title-6")}
        </h2>
        <p>
          {t("terms-and-conditions.desc-6")}
        </p>
        <h2 className={styles.text__title}>
          {t("terms-and-conditions.title-7")}
        </h2>
        <p>
          {t("terms-and-conditions.desc-7")}
        </p>
        <h2 className={styles.text__title}>
          {t("terms-and-conditions.title-8")}
        </h2>
        <p>
          {t("terms-and-conditions.desc-8")}
        </p>
        <h2 className={styles.text__title}>
          {t("terms-and-conditions.title-9")}
        </h2>
        <p>
          {t("terms-and-conditions.desc-9")}
        </p>
        <h2 className={styles.text__title}>
          {t("terms-and-conditions.title-10")}
        </h2>
        <p>
          <Trans
            i18nKey="terms-and-conditions.desc-10"
            components={{
              a: <a />,
            }}
          />
        </p>
        <p>
          {t("terms-and-conditions.desc-11")}
        </p>
        <p>
          {t("terms-and-conditions.desc-12")}
        </p>
        <p>
          {t("terms-and-conditions.desc-13")}
        </p>
      </div>
    </div>
  );
};

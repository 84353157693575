import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface GuestSlice {
  create: {
    isSuccess: boolean;
    createdUserId: string | null;
  };
}

const initialState: GuestSlice = {
  create: {
    isSuccess: false,
    createdUserId: null
  }
};

export const guestSlice = createSlice({
  name: "guests",
  initialState,
  reducers: {
    setCreateGuestStatus: (state, action: PayloadAction<boolean>) => {
      state.create.isSuccess = action.payload;
    },
    setCreatedUserId: (state, action: PayloadAction<string>) => {
      state.create.createdUserId = action.payload;
    },
    clearCreateStates: (state) => {
      state.create = {
        isSuccess: false,
        createdUserId: null
      };
    }
  }
});

export const { setCreateGuestStatus, setCreatedUserId, clearCreateStates } =
  guestSlice.actions;

export default guestSlice.reducer;

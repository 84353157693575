import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { api } from "@/helpers";

import { ILocationSettingsResponse, TQueryOptions } from "@/types";

const fetcher = async () => {
  return api.get("host/hotel");
};

export const useGetAllLocations = <TQueryFnData = ILocationSettingsResponse[], TData = ILocationSettingsResponse[]>(
  options?: TQueryOptions<TQueryFnData, TData>
) => {

  return useCustomQuery<TQueryFnData, TData>(["get-all-locations"], fetcher, {
    ...options,
    staleTime: Infinity,
  });
};

import i18next from "i18next";

import { FilterPaymentStatus } from "@/constants/bookings/bookings.constant";

export const bookingFilterSort = [
  {
    label: i18next.t("booking.filter.date-asc"),
    value: {
      sortField: "checkinDate",
      sortOrder: "ASC"
    }
  },
  {
    label: i18next.t("booking.filter.date-desc"),
    value: {
      sortField: "checkinDate",
      sortOrder: "DESC"
    }
  },
  {
    label: i18next.t("booking.filter.created-at-asc"),
    value: {
      sortField: "createdAt",
      sortOrder: "ASC"
    }
  },
  {
    label: i18next.t("booking.filter.created-at-desc"),
    value: {
      sortField: "createdAt",
      sortOrder: "DESC"
    }
  },
  {
    label: i18next.t("booking.filter.total-price-asc"),
    value: {
      sortField: "totalPrice",
      sortOrder: "ASC"
    }
  },
  {
    label: i18next.t("booking.filter.total-price-desc"),
    value: {
      sortField: "totalPrice",
      sortOrder: "DESC"
    }
  }
];

export const filterSortCount = [
  {
    value: "All",
    label: i18next.t("booking.filter.all")
  },
  {
    value: "1",
    label: "1"
  },
  {
    value: "2",
    label: "2"
  },
  {
    value: "3",
    label: "3"
  },
  {
    value: "4",
    label: "4"
  },
  {
    value: "5+",
    label: "5+"
  }
];

export const paymentFilter = [
  {
    value: FilterPaymentStatus.ALL,
    label: i18next.t("booking.filter.all")
  },
  {
    value: FilterPaymentStatus.FULL_PAY,
    label: i18next.t("booking.filter.payment-paid")
  },
  {
    value: FilterPaymentStatus.NOT_PAID,
    label: i18next.t("booking.filter.payment-not-paid")
  },
  {
    value: FilterPaymentStatus.PART_PAY,
    label: i18next.t("booking.filter.payment-partial-paid")
  }
];

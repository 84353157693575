import { BookingStatus } from "@/constants/bookings/bookings.constant";

import { ICalendarEvent, IGetBookingCalendarDayPriceResponse } from "@/types";

export const getTotalPriceForDay = ({
  calendarPricing,
  date
}: {
  calendarPricing: IGetBookingCalendarDayPriceResponse[];
  date: string;
}) => {
  if (!calendarPricing) return "";
  const findDay = calendarPricing?.find((day) => day.date === date);

  return findDay?.totalPrice || "";
};

export const getReservation = ({
  calendarPricing,
  date
}: {
  calendarPricing: IGetBookingCalendarDayPriceResponse[];
  date: string;
}) => {
  if (!calendarPricing) return "";
  const findDay = calendarPricing?.find((day) => day.date === date);

  return findDay?.totalPrice || "";
};

export const getEventDays = ({
  events,
  handleRangeDaysInMonth,
  exactStatus,
  withoutStatus
}: {
  events: ICalendarEvent[];
  handleRangeDaysInMonth: (start: string, end: string) => { date: string }[];
  exactStatus?: BookingStatus;
  withoutStatus?: BookingStatus;
}) => {
  const bookings: ICalendarEvent[] = [];
  if (exactStatus) {
    bookings.push(...events.filter((event: ICalendarEvent) => event.status === exactStatus));
  }
  if (withoutStatus) {
    bookings.push(...events.filter((event: ICalendarEvent) => event.status !== withoutStatus));
  }
  const bookingDays = bookings.length && bookings
    .map((booking: ICalendarEvent) => ({
      bookedDays: handleRangeDaysInMonth(booking.start, booking.end)
        .filter((day) => day.date !== booking.end)
        .map((day) => day.date),
      bookingId: booking.id
    }))
    .flat();

  return {
    bookingDates: bookingDays ? bookingDays.map((day) => day.bookedDays).flat() : [],
    data: bookingDays || []
  };
};

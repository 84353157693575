import React, { FC } from "react";
import { Button } from "antd";

import { FallingOut } from "@/elements";

import { handleScroll } from "@/helpers";

import { ScrollTypes } from "@/constants/global";

import styles from "./CancellationModal.module.scss";

import { ICancellationModal } from "./CancellationModal.type";

export const CancellationModal: FC<ICancellationModal> = ({
  titleHeader,
  title,
  handleApprove,
  handleClose,
  approveButtonText,
  declineButtonText
}) => {
  const conditionShow = titleHeader ? {
    title: titleHeader,
    handleClose
  } : {};

  const handleActionApprove = () => {
    handleScroll(ScrollTypes.REMOVE);
    handleApprove()
  }

  const handleActionClose = () => {
    handleScroll(ScrollTypes.REMOVE);
    handleClose()
  }

  return (
    <FallingOut
      {...conditionShow}
      content={
        <div className={styles.cancellation__container}>
          <h1 className={styles.cancellation__title}>{title}</h1>
          <div className={styles.cancellation__btns}>
            <Button
              onClick={handleActionApprove}
              size="large"
              danger
              type="primary"
              className={styles.cancellation__btn}
            >
              {approveButtonText}
            </Button>
            <Button
              onClick={handleActionClose}
              size="large"
              ghost
              className={styles.cancellation__btn}
            >
              {declineButtonText}
            </Button>
          </div>
        </div>
      }
    />
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { FallingOut } from "@/elements";
import { CalendarMonthChooser } from "@/elements/CalendarMonthChooser/CalendarMonthChooser";

import { handleScroll } from "@/helpers";

import { ScrollTypes } from "@/constants/global";

import { setHandleSelectingCalendarModal } from "@/store/calendar/calendar.slice";

export const CalendarSelectionModal = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const handleClose = () => {
    dispatch(setHandleSelectingCalendarModal(false));
    handleScroll(ScrollTypes.REMOVE);
  };

  return (
    <FallingOut
      handleClose={handleClose}
      title={t("calendar.selection-modal.title")}
      content={<CalendarMonthChooser handleClose={handleClose} />}
    />
  );
};

import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";

import { IBookingEditForm } from "@/components/Forms/BookingEdit/BookingEdit.type";

import { useCustomMutation } from "@/hooks";

import { allLocationsIdStorageService, api, handleHotelIdInApi, handleScroll } from "@/helpers";

import { AllLocationType, ScrollTypes } from "@/constants/global";

import { TMutationsOptions } from "@/types";

import { RootState } from "@/store";
import { setEditModalStatus } from "@/store/booking/booking.slice";

const { getItem: getAllLocationStatus } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

const fetcher = async (body: IBookingEditForm) => {
  const { id, ...rest } = body;

  const isAllLocation = getAllLocationStatus();
  const conditionData = isAllLocation ? rest : handleHotelIdInApi(rest);

  return api.patch(`host/bookings/${id}`, conditionData);
};

export const useUpdateBooking = (
  options?: TMutationsOptions<IBookingEditForm, IBookingEditForm>
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const isAllLocation = getAllLocationStatus();
  const chosenFromCalendarLocationId = useSelector(
    (state: RootState) => state.booking.choosenCalendarBooking.hotelId
  );
  const chosenLocationId = useSelector(
    (state: RootState) => state.booking.allLocation.chosenLocationId
  );

  const { mutate, ...rest } = useCustomMutation<IBookingEditForm, IBookingEditForm>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      dispatch(setEditModalStatus(false));
      handleScroll(ScrollTypes.REMOVE);
      await queryClient.invalidateQueries(["get-booking"], { exact: false });
      await queryClient.invalidateQueries(["get-bookings"], { exact: false });
      await queryClient.invalidateQueries(["get-bookings-reservations"], { exact: false });
      await queryClient.invalidateQueries(["get-bookings-count"], { exact: false });
    }
  });

  const handleMutate = (data: IBookingEditForm) => {
    if (isAllLocation) {
      data.hotelId = chosenLocationId || String(chosenFromCalendarLocationId);
    }

    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

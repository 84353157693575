import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Divider } from "antd";
import dayjs from "dayjs";

import { conditionStatus } from "@/containers/BookingViewCard/BookingViewCard.helper";

import { conditionGuests } from "@/components/Calendar/CalendarListViewItem/CalendarListViewItem.helper";

import { ROUTES } from "@/constants";
import { BookingStatus } from "@/constants/bookings/bookings.constant";
import { DATE_CALENDAR_EVENT_FORMAT } from "@/constants/global";

import { IBookingReservations } from "@/types";

import styles from "./ReservationDetails.module.scss";

import { RootState } from "@/store";

const { BOOKINGS, BLOCKED, DASHBOARD, VIEW } = ROUTES;

export const ReservationDetails = ({ reservation }: { reservation?: IBookingReservations }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const calendarActiveType = useSelector((state: RootState) => state.calendar.calendarType);
  const { hotelId } = useParams();

  const isBlocked = reservation?.status === BookingStatus.BLOCKED;

  const handleDayClick = () => {
    if (isBlocked) {
      return navigate(
        `/${hotelId}/${BOOKINGS}/${reservation?.bookingId || reservation.id}/${BLOCKED}`,
        {
          state: {
            route: `/${hotelId}/${DASHBOARD}/?type=${calendarActiveType}`
          }
        }
      );
    }

    return navigate(
      `/${hotelId}/${BOOKINGS}/${reservation?.bookingId || reservation?.id}/${VIEW}`,
      {
        state: {
          route: `/${hotelId}/${DASHBOARD}/?type=${calendarActiveType}`
        }
      }
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{reservation?.guestName}</div>
      <div className={styles.status__container}>
        {conditionStatus(reservation?.status || "")}
        <div className={styles.status__id}>{reservation?.bookingId || reservation?.id}</div>
      </div>
      <div className={styles.details}>
        <div className={styles.details__reservations}>
          <div className={styles.details__dates}>
            <div className={styles.details__date}>
              {dayjs(reservation?.checkinDate).format(DATE_CALENDAR_EVENT_FORMAT)}
            </div>
            <span className={styles.details__divider}></span>
            <div className={styles.details__date}>
              {dayjs(reservation?.checkoutDate).format(DATE_CALENDAR_EVENT_FORMAT)}
            </div>
          </div>
          {!isBlocked ? (
            <>
              <span className={styles["details__reservations-point"]}></span>
              <div className={styles["details__reservations-guests"]}>
                {t(conditionGuests(reservation?.guestsCount || 1) as string, {
                  count: reservation?.guestsCount
                })}
              </div>
            </>
          ) : null}
        </div>
        {!isBlocked ? (
          <div className={styles.details__price}>
            <div className={styles["details__price-title"]}>{t("calendar.reservation-pay")}</div>
            <div className={styles["details__price-value"]}>
              <span>{reservation?.paidAmount}</span> / <span>{reservation?.totalPrice}</span> {t("calendar.reservation-currency")}
            </div>
          </div>
        ) : null}
      </div>
      <Divider className={styles.divider} />
      <Button onClick={handleDayClick} className={styles.button} ghost>
        {t(isBlocked ? "calendar.blocking-details" : "calendar.reservation-details")}
      </Button>
    </div>
  );
};

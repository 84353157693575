
import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { api, getJWTToken, handleHotelIdInApi } from "@/helpers";

import { IBookingFilterParams, IBookingTabCounts, TQueryOptions } from "@/types";

const fetcher = async (filters: IBookingFilterParams | NonNullable<unknown>) => {
  return api.get("host/bookings/tabs/counts", handleHotelIdInApi(handleHotelIdInApi(filters)));
};

export const useGetBookingsCount = <TQueryFnData = IBookingTabCounts, TData = IBookingTabCounts>(
  filters?: IBookingFilterParams | NonNullable<unknown>,
  options?: TQueryOptions<TQueryFnData, TData>
) => {
  const token = getJWTToken();

  return useCustomQuery<TQueryFnData, TData>(["get-bookings-count"], () => fetcher(filters || {}), {
    ...options,
    staleTime: Infinity,
    enabled: !!token
  });
};

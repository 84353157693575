import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Divider, Form } from "antd";

import { FormItem, Input, Loader } from "@/elements";

import { useCurrentUser } from "@/react-queries";
import { useUpdateCurrentUser } from "@/react-queries/user/useUpdateCurrentUser";

import { useYupSync } from "@/hooks";
import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { ROUTES } from "@/constants";

import { userInfoUpdateSchema } from "./SettingsUserProfile.schema";

import { IUserWithHotel } from "@/types";
import { IUserUpdateInfo } from "@/types/userInfo/userInfo.type";

import styles from "./SettingsUserProfile.module.scss";

import { setHeaderNavigation } from "@/store/header/header.slice";

const { SETTINGS } = ROUTES;

export const SettingsUserProfile = () => {
  const [form] = Form.useForm<IUserUpdateInfo>();
  const [saveButtonDisable, setSaveButtonDisable] = useState<boolean>(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hotelId } = useParams();
  const isMobile = useGetScreenStatus();

  const { data: currentUser, isLoading: isCurrentUserLoading } = useCurrentUser();
  const { mutate: updateUser, isLoading: isUpdatingCurrentUser } = useUpdateCurrentUser();

  const yupSync = useYupSync(userInfoUpdateSchema);
  const { t } = useTranslation();

  const getUpdatedValues = (
    initialData: IUserWithHotel,
    updatedData: IUserUpdateInfo
  ): Partial<IUserUpdateInfo> => {
    const changes: Partial<IUserUpdateInfo> = {};

    for (const key in initialData) {
      if (initialData[key as keyof IUserUpdateInfo] !== updatedData[key as keyof IUserUpdateInfo]) {
        if (updatedData[key as keyof IUserUpdateInfo]) {
          changes[key as keyof IUserUpdateInfo] = updatedData[key as keyof IUserUpdateInfo];
        }
      }
    }

    return changes;
  };

  const onFinish = (values: IUserUpdateInfo) => {
    if (currentUser) {
      const updatedValues = getUpdatedValues(currentUser, values);
      updateUser(updatedValues as IUserUpdateInfo);
    }
  };

  const handleValueChange = (_: IUserUpdateInfo, values: IUserUpdateInfo) => {
    if (currentUser) {
      for (const key of Object.keys(values)) {
        if (key === "phoneNumber") {
          if (
            values[key as keyof IUserUpdateInfo].replace(/\D/g, "") !==
            currentUser[key as keyof IUserUpdateInfo].replace(/\D/g, "")
          ) {
            setSaveButtonDisable(false);
            break;
          }
          setSaveButtonDisable(true);
          continue;
        }
        if (values[key as keyof IUserUpdateInfo] !== currentUser[key as keyof IUserUpdateInfo]) {
          setSaveButtonDisable(false);
          break;
        }
        setSaveButtonDisable(true);
      }
    }
  };

  const navigateBack = () => {
    navigate(`/${hotelId}/${SETTINGS}`);
  };

  useEffect(() => {
    if (currentUser) {
      dispatch(
        setHeaderNavigation({
          navigation: {
            title: t("settings.title"),
            link: `/${hotelId}/${SETTINGS}`
          }
        })
      );

      form.setFieldsValue({
        fullName: currentUser.fullName,
        email: currentUser.email,
        phoneNumber: currentUser.phoneNumber
      });
    }
  }, [currentUser]);

  if (isCurrentUserLoading) {
    return <Loader isFullScreen />;
  }

  return (
    <div className={styles["user-info__wrapper"]}>
      <h1 className={styles["user-info__title"]}>{t("settings.user-info.title")}</h1>
      <Form
        onValuesChange={handleValueChange}
        className={styles["user-info__container"]}
        layout="vertical"
        onFinish={onFinish}
        form={form}
      >
        <FormItem name="fullName" label={t("auth-form.name")} required rules={[yupSync]}>
          <Input placeholder={t("auth-form.fullName-placeholder")} />
        </FormItem>

        <FormItem name="email" label="Email" required rules={[yupSync]}>
          <Input placeholder={t("auth-form.email-placeholder")} />
        </FormItem>

        <FormItem name="phoneNumber" label={t("auth-form.phone")} required rules={[yupSync]}>
          <Input type="phone" placeholder={t("auth-form.phoneNumber-placeholder")} />
        </FormItem>

        {!isMobile ? <Divider className={styles["user-info__divider"]} /> : null}

        <div className={styles["user-info__btns"]}>
          <Button
            disabled={saveButtonDisable}
            className={styles["user-info__submit-btn"]}
            type="primary"
            htmlType="submit"
            loading={isUpdatingCurrentUser}
          >
            {t("settings.user-info.save")}
          </Button>
          <Button
            loading={isUpdatingCurrentUser}
            onClick={navigateBack}
            className={styles["user-info__btn"]}
            ghost
          >
            {t("settings.user-info.decline")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

import { useCustomMutation } from "@/hooks";

import { api } from "@/helpers";

const fetcher = async (signedId: string) => {
  await api.del(`/direct-upload/${signedId}`);
};

export const useDeleteUploadedFile = () => {
  return useCustomMutation(fetcher, { preventShowDefaultErrorToast: true });
};

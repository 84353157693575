import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import { FallingOut, Icon } from "@/elements";

import styles from "../modal.module.scss";

import { IBookingModal } from "./DeclineModal.type";

export const DeclineModal = ({ title, buttonTitle, handleDecline, handleSkipDecline }: IBookingModal) => {
  const { t } = useTranslation();
  
  return (
    <FallingOut
      content={
        <div className={styles["booking-modal__wrapper"]}>
          <div className={styles["booking-modal__container"]}>
            <h1 className={styles["booking-modal__title"]}>{title}</h1>
            <div className={styles["booking-modal__content"]}>
              <div className={styles["booking-modal__item"]}>
                <Icon icon="info" className={styles["booking-modal__icon"]} />
                <span className={styles["booking-modal__text"]}>
                  {t("booking.decline-modal.status-message")}
                </span>
              </div>
            </div>
          </div>
          <div className={styles["booking-modal__btns"]}>
            <Button
              onClick={handleDecline}
              size="large"
              type="primary"
              danger
              className={styles["booking-modal__btn"]}
            >
              {buttonTitle}
            </Button>
            <Button
              onClick={handleSkipDecline}
              size="large"
              ghost
              className={styles["booking-modal__btn"]}
            >
              {t("booking.decline-modal.decline")}
            </Button>
          </div>
        </div>
      }
    />
  );
};

import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { useCustomMutation } from "@/hooks";

import { api } from "@/helpers";

import { IBookingDelete, TMutationsOptions } from "@/types";

import { setIsShowBlockedCancelModal, setIsShowBlockedModal } from "@/store/booking/booking.slice";
import { setHandleUpdateReservation } from "@/store/calendar/calendar.slice";

const fetcher = async (body: IBookingDelete) => {
  const { id } = body;

  return api.del(`host/bookings/blocked-dates/${id}`);
};

export const useDeleteBlocking = (options?: TMutationsOptions<IBookingDelete, IBookingDelete>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useCustomMutation<IBookingDelete, IBookingDelete>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      dispatch(setHandleUpdateReservation(true));
      dispatch(setIsShowBlockedCancelModal(false));
      dispatch(setIsShowBlockedModal(false));
      await queryClient.invalidateQueries(["get-booking"], { exact: false });
      await queryClient.invalidateQueries(["get-bookings"], { exact: false });
      await queryClient.invalidateQueries(["get-bookings-reservations"], { exact: false });
      await queryClient.invalidateQueries(["get-bookings-count"], { exact: false });
      options?.onSuccess?.(data, ...args);
    }
  });

  const handleMutate = (data: IBookingDelete) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

import React, { FC, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Popover, Table } from "antd";
import dayjs from "dayjs";

import { getClassNameForEvents, getIconForEvents } from "@/containers/Calendar/Calendar.helper";

import { Icon, Loader, ReservationDetails } from "@/elements";

import { useGetAllLocationsPriceCalendar } from "@/react-queries/booking/useGetAllLocationsPriceCalendar";
import { useGetAllReservationBookings } from "@/react-queries/booking/useGetAllReservations";
import { useGetAllLocations } from "@/react-queries/hotel/useGetAllLocation";

import { useGetDaysInMonth } from "@/hooks/booking/useGetDaysInMonth";
import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { allLocationsIdStorageService, handleScroll } from "@/helpers";

import { ROUTES } from "@/constants";
import { BookingStatus } from "@/constants/bookings/bookings.constant";
import { CalendarTypes } from "@/constants/calendar/calendar.constants";
import {
  AllLocationType,
  CELL_TABLE_WIDTH,
  DATE_FORMAT,
  FULL_DATE_FORMAT,
  ScrollTypes,
  WeekendsType
} from "@/constants/global";

import {
  IBookingAllLocationsPriceCalendar,
  IBookingAllReservations,
  IBookingReservations,
  IGetBookingCalendarDayPriceResponse,
  ILocationSettingsResponse,
  IMonthDays
} from "@/types";

import styles from "./AllLocationCalendar.module.scss";

import { IAllLocationCalendar } from "./AllLocationCalendar.type";

import { RootState } from "@/store";
import {
  setChoosenCalendarBooking,
  setHandleBookingActionButtonContent
} from "@/store/booking/booking.slice";

interface IAllReservations extends IBookingReservations {
  countNights: number;
}

const { BOOKINGS, VIEW, DASHBOARD, BLOCKED } = ROUTES;

const { getItem: getIsAllLocation } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

export const AllLocationCalendar: FC<IAllLocationCalendar> = ({
  calendarDate,
  checkIsContentActive
}) => {
  const { dates, handleDates, handleRangeDaysInMonth } = useGetDaysInMonth();
  const [monthDays, setMonthDays] = React.useState<IMonthDays[]>([]);
  const isUpdateReservation = useSelector((state: RootState) => state.calendar.isUpdateReservation);
  const [visibleTooltip, setVisibleTooltip] = useState<string | null>(null);
  const isAllLocation = getIsAllLocation();
  const isMobile = useGetScreenStatus();
  const containerRef = useRef<HTMLDivElement>(null);
  const calendarDates = useMemo(() => {
    if (dates.fromDate && dates.toDate) {
      const monthNames = [];
      const start = dayjs(dates.fromDate);
      const end = dayjs(dates.toDate);
      let current = start;

      while (current.isBefore(end) || current.isSame(end, "month")) {
        monthNames.push({
          monthName: current.format(FULL_DATE_FORMAT),
          start: current.startOf("month").format(DATE_FORMAT),
          end: current.endOf("month").format(DATE_FORMAT),
          countOfDays: current.daysInMonth()
        });
        current = current.add(1, "month");
      }

      return monthNames;
    }

    return [];
  }, [dates]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hotelId } = useParams();

  const { data: allLocations, isLoading: isAllLocationLoading } = useGetAllLocations();
  const {
    data: allLocationsPrices,
    isLoading: isAllLocationPriceLoading,
    refetch: refetchAllLocationPrices,
    isRefetching: isRefetchingAllLocationPrices
  } = useGetAllLocationsPriceCalendar(dates || {}, {
    enabled: !!dates || !isAllLocation
  });

  const {
    mutate: handleReservationsOfLocations,
    data: allReservations,
    isLoading: isReservationsLoading
  } = useGetAllReservationBookings();

  const isContentLoading = useMemo(() => {
    return (
      isAllLocationPriceLoading ||
      isAllLocationLoading ||
      isReservationsLoading ||
      isRefetchingAllLocationPrices
    );
  }, [
    isAllLocationPriceLoading,
    isAllLocationLoading,
    isReservationsLoading,
    isRefetchingAllLocationPrices
  ]);

  const handleAllLocationsReservations = useMemo(() => {
    if (allReservations) {
      const rangeBookedDays = (reservations: IBookingReservations[]) => {
        const activeMonth = dayjs(dates?.fromDate).month();
        const getBookingDate = ({
          reservationCheckinDate,
          rangeBookingsDates
        }: {
          reservationCheckinDate: string;
          rangeBookingsDates: IMonthDays[];
        }) => {
          const conditionRenderContentInCell = isMobile
            ? dayjs(reservationCheckinDate).month() !== activeMonth
            : true;

          if (conditionRenderContentInCell) {
            return {
              bookingDay: rangeBookingsDates[0]?.date
            };
          }

          return {
            bookingDay: reservationCheckinDate
          };
        };

        return reservations.reduce((acc, item: IBookingReservations) => {
          const activeMonthes = calendarDates.map((item) => dayjs(item.start).month());
          const rangeBookingsDates = handleRangeDaysInMonth(
            item.checkinDate,
            item.checkoutDate,
            dates
          ).filter((day) => {
            return (
              day.date !== item.checkoutDate && activeMonthes.includes(dayjs(day.date).month())
            );
          });
          const { bookingDay } = getBookingDate({
            reservationCheckinDate: item.checkinDate,
            rangeBookingsDates
          });

          acc[bookingDay] = {
            countNights: rangeBookingsDates.length,
            ...item
          };

          return acc;
        }, {} as { [key: string]: IAllReservations });
      };

      return allReservations.reduce((acc, item: IBookingAllReservations) => {
        acc[item.hotelId] = rangeBookedDays(item.reservations);

        return acc;
      }, {} as { [key: number]: any });
    }

    return {};
  }, [allReservations]);

  const handleLocationHotelPrices = useMemo(() => {
    if (allLocationsPrices) {
      const convertedPrices = (prices: IGetBookingCalendarDayPriceResponse[]) => {
        return prices.reduce((acc, item: IGetBookingCalendarDayPriceResponse) => {
          acc[item.date] = item.totalPrice;

          return acc;
        }, {} as { [key: string]: number });
      };

      return allLocationsPrices.reduce((acc, item: IBookingAllLocationsPriceCalendar) => {
        acc[item.hotelId as number] = convertedPrices(item.prices);

        return acc;
      }, {} as { [key: number]: { [key: string]: number } });
    }
  }, [allLocationsPrices]);

  const handleClickDate = (value: IMonthDays, render: ILocationSettingsResponse) => {
    if (
      handleAllLocationsReservations[render.id] &&
      handleAllLocationsReservations[render.id][value?.date]
    ) {
      if (!isMobile) {
        return;
      }
      const reservation = handleAllLocationsReservations[render.id][value.date];
      const isBlockedEvent = reservation.status === BookingStatus.BLOCKED;

      if (isBlockedEvent) {
        return navigate(`/${hotelId}/${BOOKINGS}/${reservation.id}/${BLOCKED}`, {
          state: {
            route: `/${hotelId}/${DASHBOARD}/?type=${CalendarTypes.ALL}`
          }
        });
      }

      return navigate(`/${hotelId}/${BOOKINGS}/${reservation?.id}/${VIEW}`, {
        state: {
          route: `/${hotelId}/${DASHBOARD}/?type=${CalendarTypes.ALL}`
        }
      });
    }
    const totalPrice = handleLocationHotelPrices
      ? handleLocationHotelPrices[render.id][value.date]
      : null;
    dispatch(
      setChoosenCalendarBooking({
        date: value.date,
        totalPrice: totalPrice ?? null,
        hotelId: render.id
      })
    );
    dispatch(setHandleBookingActionButtonContent(true));
    handleScroll(ScrollTypes.ADD);
  };

  const handleVisibleChange = (tooltipId: string) => {
    setVisibleTooltip((prev: string | null) => (prev === tooltipId ? null : tooltipId));
  };

  const conditionRenderContentInCell = (day: IMonthDays, hotelId: number) => {
    if (
      handleAllLocationsReservations &&
      day?.date &&
      handleAllLocationsReservations[hotelId] &&
      handleAllLocationsReservations[hotelId][day?.date]
    ) {
      const reservation = handleAllLocationsReservations[hotelId][day.date];
      const eventWidth = reservation.countNights * CELL_TABLE_WIDTH;
      const eventWidthCalculate = eventWidth + (reservation.countNights - 1);
      const isBlockedEvent = reservation.status === BookingStatus.BLOCKED;

      if (isBlockedEvent) {
        if (!isMobile) {
          return (
            <Popover
              trigger={"click"}
              title={<ReservationDetails reservation={reservation} />}
              key={`popover-${hotelId}-${day.date}`}
              open={visibleTooltip === `${hotelId}-${day.date}`}
              onOpenChange={() => handleVisibleChange(`${hotelId}-${day.date}`)}
            >
              <div
                className={`${styles["all-location__table-blocked"]} ${styles["all-location__table-reservation"]}`}
                style={{ width: eventWidthCalculate }}
              >
                <div
                  className={`${styles["all-location__table-reservation-wrapper"]} ${
                    styles[getClassNameForEvents(reservation)]
                  }`}
                >
                  <div className={styles["all-location__table-blocked-icon"]}>
                    <Icon icon="lock" />
                  </div>
                  <div className={styles["all-location__table-blocked-description"]}>
                    {reservation?.notes}
                  </div>
                </div>
              </div>
            </Popover>
          );
        }

        return (
          <div
            className={`${styles["all-location__table-blocked"]} ${styles["all-location__table-reservation"]}`}
            style={{ width: eventWidthCalculate }}
          >
            <div
              className={`${styles["all-location__table-reservation-wrapper"]} ${
                styles[getClassNameForEvents(reservation)]
              }`}
            >
              <div className={styles["all-location__table-blocked-icon"]}>
                <Icon icon="lock" />
              </div>
              <div className={styles["all-location__table-blocked-description"]}>
                {reservation?.notes}
              </div>
            </div>
          </div>
        );
      }

      if (!isMobile) {
        return (
          <Popover
            trigger={"click"}
            content={<ReservationDetails reservation={reservation} />}
            key={`popover-${hotelId}-${day.date}`}
            open={visibleTooltip === `${hotelId}-${day.date}`}
            onOpenChange={() => handleVisibleChange(`${hotelId}-${day.date}`)}
          >
            <div
              key={`reservation-${reservation.id}`}
              className={`${styles["all-location__table-reservation"]}`}
              style={{ width: eventWidthCalculate }}
            >
              <div
                className={`${styles["all-location__table-reservation-wrapper"]} ${
                  styles[getClassNameForEvents(reservation)]
                }`}
              >
                <div className={styles["all-location__table-reservation-icon"]}>
                  {getIconForEvents(reservation)}
                </div>
                <span className={styles["all-location__table-reservation-text"]}>
                  {reservation?.guestName || reservation?.notes}
                  {reservation.guestsCount > 1 ? `+${reservation.guestsCount - 1}` : ""}
                </span>
              </div>
            </div>
          </Popover>
        );
      }

      return (
        <div
          key={`reservation-${reservation.id}`}
          className={`${styles["all-location__table-reservation"]}`}
          style={{ width: eventWidthCalculate }}
        >
          <div
            className={`${styles["all-location__table-reservation-wrapper"]} ${
              styles[getClassNameForEvents(reservation)]
            }`}
          >
            <div className={styles["all-location__table-reservation-icon"]}>
              {getIconForEvents(reservation)}
            </div>
            <span className={styles["all-location__table-reservation-text"]}>
              {reservation?.guestName || reservation?.notes}
              {reservation.guestsCount > 1 ? `+${reservation.guestsCount - 1}` : ""}
            </span>
          </div>
        </div>
      );
    }

    if (handleLocationHotelPrices && handleLocationHotelPrices[hotelId][day.date]) {
      return handleLocationHotelPrices[hotelId][day.date];
    }

    return null;
  };

  const tableColumns = useMemo(() => {
    return [
      {
        title: "",
        width: 100,
        dataIndex: "publicLabel",
        fixed: "left" as const,
        render: (text: string, render: ILocationSettingsResponse) => {
          return (
            <div
              key={`location-${render.id}`}
              className={styles["all-location__table-public-label"]}
            >
              {isMobile ? text : null}
            </div>
          );
        }
      },
      {
        title: () => {
          return (
            <div className={styles["all-location__table-monthes"]}>
              {calendarDates.map((item, index) => (
                <div
                  style={{ width: item.countOfDays * (CELL_TABLE_WIDTH + 1) }}
                  key={index}
                  className={styles["all-location__table-month"]}
                >
                  <span className={styles["all-location__table-month-name"]}>{item.monthName}</span>
                </div>
              ))}
            </div>
          );
        },
        children: [
          ...monthDays.map((day: IMonthDays, index: number) => ({
            title: () => {
              const isActiveDay = day.date === dayjs().format(DATE_FORMAT);
              const classForActiveDay = isActiveDay ? styles["active-day"] : "";
              const conditionRefForActiveDay = isActiveDay
                ? { "data-active-day": "active-day" }
                : {};

              const weekendClass = WeekendsType.includes(day.dayOfWeek) ? styles.weekend : "";

              return (
                <div
                  {...conditionRefForActiveDay}
                  key={`day-${index}-${day.day}`}
                  className={`${styles["all-location__table-title"]} ${weekendClass} ${classForActiveDay}`}
                >
                  <div className={`${styles["all-location__table-day"]} ${classForActiveDay}`}>
                    {day.day}
                  </div>
                  <div className={styles["all-location__table-day-name"]}>{day.dayOfWeek}</div>
                </div>
              );
            },
            key: `table-day-${index}-${day.day}`,
            render: (render: ILocationSettingsResponse) => {
              const classForPassedDay =
                day.date < dayjs().format(DATE_FORMAT) ? styles["passed-day"] : "";

              const classForActiveDay =
                day.date === dayjs().format(DATE_FORMAT) ? styles["active-day"] : "";

              return (
                <div
                  key={`price-${render.id}-${day.date}`}
                  className={`${styles["all-location__table-price"]} ${classForPassedDay} ${classForActiveDay}`}
                  onClick={() => handleClickDate(day, render)}
                >
                  <div className={styles["all-location__table-price-value"]}>
                    {conditionRenderContentInCell(day, render?.id)}
                  </div>
                </div>
              );
            }
          }))
        ]
      }
    ];
  }, [handleLocationHotelPrices, handleAllLocationsReservations, isMobile, visibleTooltip]);

  useEffect(() => {
    if (calendarDate) {
      handleDates();
    }
  }, [calendarDate, isMobile]);

  useEffect(() => {
    if (dates) {
      setMonthDays(handleRangeDaysInMonth(dates.fromDate, dates.toDate));
    }
  }, [dates]);

  useEffect(() => {
    if (checkIsContentActive) {
      checkIsContentActive(!isContentLoading);
    }
  }, [isContentLoading]);

  useEffect(() => {
    if ((dates.fromDate && dates.toDate) || isUpdateReservation) {
      handleReservationsOfLocations(dates);
      refetchAllLocationPrices();
    }
  }, [dates, isUpdateReservation]);

  useEffect(() => {
    const item = containerRef.current?.querySelector("[data-active-day='active-day']");
    const itemRect = (item as HTMLElement)?.getBoundingClientRect();
    const startMonth = dayjs(calendarDates[0].start).month();
    const isStartedMonth = dayjs().month() === startMonth;

    if (containerRef && item && isStartedMonth) {
      if (containerRef.current) {
        containerRef.current.scrollLeft = itemRect.left - 400;
      }
    }
  }, [isContentLoading]);

  useEffect(() => {
    const handleScroll = () => {
      setVisibleTooltip(null);
    };

    containerRef?.current?.addEventListener("scroll", handleScroll);

    return () => {
      containerRef?.current?.removeEventListener("scroll", handleScroll);
    };
  }, [containerRef?.current]);

  if (isContentLoading) {
    return <Loader isFullScreen />;
  }

  return (
    <div ref={containerRef} className={styles.wrapper}>
      <Table
        showHeader
        rowKey={(render) => {
          return render.id;
        }}
        pagination={false}
        columns={tableColumns}
        dataSource={allLocations || []}
      />
    </div>
  );
};

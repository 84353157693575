import { BookingPaymentStatus } from "@/constants/bookings/bookings.constant";

export const getPaymentStatus = ({
    amountValue,
    totalValue
}:{
    amountValue: number;
    totalValue: number;
}) => {
    if (amountValue < totalValue && amountValue > 0) {
        return BookingPaymentStatus.PART_PAY;
    }

    if (amountValue > totalValue) {
        return BookingPaymentStatus.OVERPAY;
    }
    
    return null;
}

import { Tag } from "antd";
import i18next from "i18next";

import { Icon } from "@/elements";

import {
  BookingStatus,
  LESS_MULTIPE_NIGHTS,
  ONE_NIGHT
} from "@/constants/bookings/bookings.constant";
import { SINGLE_GUEST } from "@/constants/calendar/calendar.constants";

import styles from "./BookingViewCard.module.scss";

export const conditionStatus = (status: string) => {
  switch (status) {
    case BookingStatus.PENDING:
      return (
        <Tag
          icon={<Icon icon="info" />}
          className={`${styles["booking-view__card-status-btn"]} ${styles.warning}`}
        >
          {i18next.t("booking.view.pending")}
        </Tag>
      );
    case BookingStatus.APPROVED:
      return (
        <Tag
          icon={<Icon icon="success" />}
          className={`${styles["booking-view__card-status-btn"]} ${styles.approved}`}
        >
          {i18next.t("booking.view.approved")}
        </Tag>
      );
    case BookingStatus.REJECTED:
      return (
        <Tag
          icon={<Icon icon="decline" />}
          className={`${styles["booking-view__card-status-btn"]} ${styles.rejected}`}
        >
          {i18next.t("booking.view.rejected")}
        </Tag>
      );
    case BookingStatus.CANCELED:
      return (
        <Tag
          icon={<Icon icon="decline" />}
          className={`${styles["booking-view__card-status-btn"]} ${styles.rejected}`}
        >
          {i18next.t("booking.view.canceled")}
        </Tag>
      );
    case BookingStatus.BLOCKED:
      return (
        <Tag
          icon={<Icon icon="fullLock" />}
          className={`${styles["booking-view__card-status-btn"]} ${styles.approved}`}
        >
          {i18next.t("booking.view.blocked")}
        </Tag>
      );
    default:
      return (
        <Tag
          icon={<Icon icon="info" />}
          className={`${styles["booking-view__card-status-btn"]} ${styles.warning}`}
        >
          {i18next.t("booking.view.pending")}
        </Tag>
      );
  }
};

export const conditionNights = (nights: number) => {
  if (nights === ONE_NIGHT) {
    return "booking.view.single-night";
  } else if (nights < LESS_MULTIPE_NIGHTS && nights > ONE_NIGHT) {
    return "booking.view.multiple-less-nights";
  } else if (nights >= LESS_MULTIPE_NIGHTS) {
    return "booking.view.multiple-nights";
  }
};

export const conditionGuests = (guests: number) => {
  if (guests === SINGLE_GUEST) {
    return "booking.view.single-guest";
  } else if (guests > SINGLE_GUEST) {
    return "booking.view.multiple-guests";
  }
};

export const conditionCardStyle = (status: string) => {
  switch (status) {
    case BookingStatus.PENDING:
      return styles.pending;
    case BookingStatus.APPROVED:
      return styles.approved;
    case BookingStatus.REJECTED:
      return styles.rejected;
    case BookingStatus.CANCELED:
      return styles.rejected;
    case BookingStatus.BLOCKED:
      return styles.approved;
    default:
      return styles.pending;
  }
};

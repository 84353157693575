import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Typography } from "antd";

import { queryClient } from "@/providers";

import { Icon, Title } from "@/elements";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import {
  checkSettingsPathname,
  removeAllLocationsId,
  removeHotelId,
  removeJWTToken,
  removeLangKey
} from "@/helpers";

import { ROUTES } from "@/constants";

import styles from "./SettingsAllLocaiton.module.scss";

const { Link } = Typography;

const {
  USER_PROFILE,
  UPDATE_PASSWORD,
  TERMS_OF_CONDITION,
  PRIVACY_POLICY,
  APP,
  SETTINGS,
  AUTH,
  SIGN_IN,
  ALL_LOCATIONS,
  LOCATIONS,
  PAYMENT_OPTIONS
} = ROUTES;

export const SettingsAllLocaiton = () => {
  const { t } = useTranslation();
  const isMobile = useGetScreenStatus();

  const getIsActivePage = (type: string) => {
    return location.pathname.includes(type);
  };

  const logout = () => {
    removeJWTToken();
    removeLangKey();
    removeHotelId();
    removeAllLocationsId();
    queryClient.clear();

    window.location.href = `${AUTH}${SIGN_IN}`;
  };

  return (
    <div className={styles.settings__wrapper}>
      {isMobile ? (
        <Title className={styles.settings__title} level={3}>
          {t("settings.title")}
        </Title>
      ) : null}
      {isMobile || checkSettingsPathname(location.pathname) ? (
        <div className={styles.settings__content}>
          <div className={styles.settings__helper}>
            <Icon className={styles["settings__helper-icon"]} icon="infoOutline" />
            <span className={styles["settings__helper-text"]}>
              {t("locations.settings.helper-text")}
            </span>
          </div>
        </div>
      ) : null}
      {isMobile ? (
        <div className={styles.settings__content}>
          <div className={styles["settings__item-container"]}>
            <Link className={styles.settings__item} href={`/${ALL_LOCATIONS}/${LOCATIONS}`}>
              <span className={styles["settings__item-text"]}>
                {t("locations.settings.locations")}
              </span>
              <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
            </Link>
          </div>
        </div>
      ) : null}

      <div className={styles.settings__content}>
        <div
          className={`${styles["settings__item-container"]} ${
            getIsActivePage(USER_PROFILE) ? styles.active : null
          }`}
        >
          <Link
            className={styles.settings__item}
            href={`/${ALL_LOCATIONS}/${SETTINGS}/${USER_PROFILE}`}
          >
            <span className={styles["settings__item-text"]}>
              {t("locations.settings.user-profile")}
            </span>
            <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
          </Link>
          <Divider />
        </div>
        <div
          className={`${styles["settings__item-container"]} ${
            getIsActivePage(UPDATE_PASSWORD) ? styles.active : null
          }`}
        >
          <Link
            className={styles.settings__item}
            href={`/${ALL_LOCATIONS}/${SETTINGS}/${UPDATE_PASSWORD}`}
          >
            <span className={styles["settings__item-text"]}>
              {t("locations.settings.change-password")}
            </span>
            <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
          </Link>
          <Divider />
        </div>
        <div className={`${styles["settings__item-container"]} ${
              getIsActivePage(PAYMENT_OPTIONS) ? styles.active : null
            }`}>
            <Link
              className={styles.settings__item}
              href={`/${ALL_LOCATIONS}/${SETTINGS}/${PAYMENT_OPTIONS}`}
            >
              <span className={styles["settings__item-text"]}>{t("settings.payment-options")}</span>
              <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
            </Link>
            <Divider />
          </div>
        <div
          className={`${styles["settings__item-container"]} ${
            getIsActivePage(TERMS_OF_CONDITION) ? styles.active : null
          }`}
        >
          <Link
            className={styles.settings__item}
            href={`/${ALL_LOCATIONS}/${SETTINGS}/${TERMS_OF_CONDITION}`}
          >
            <span className={styles["settings__item-text"]}>
              {t("locations.settings.terms-and-conditions")}
            </span>
            <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
          </Link>
          <Divider />
        </div>
        <div
          className={`${styles["settings__item-container"]} ${
            getIsActivePage(PRIVACY_POLICY) ? styles.active : null
          }`}
        >
          <Link
            className={styles.settings__item}
            href={`/${ALL_LOCATIONS}/${SETTINGS}/${PRIVACY_POLICY}`}
          >
            <span className={styles["settings__item-text"]}>
              {t("locations.settings.privacy-policy")}
            </span>
            <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
          </Link>
          <Divider />
        </div>
        <div
          className={`${styles["settings__item-container"]} ${
            getIsActivePage(APP) ? styles.active : null
          }`}
        >
          <Link className={styles.settings__item} href={`/${ALL_LOCATIONS}/${SETTINGS}/${APP}`}>
            <span className={styles["settings__item-text"]}>
              {t("locations.settings.lang-settings")}
            </span>
            <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
          </Link>
        </div>
      </div>
      <div className={styles.settings__content}>
        <div className={styles["settings__item-container"]}>
          <Link className={styles.settings__item} onClick={logout}>
            <span className={styles["settings__item-text"]}>{t("locations.settings.logout")}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

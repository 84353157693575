import { initReactI18next } from "react-i18next";
import i18n from "i18next";

import { langStorageService } from "../../helpers/storage/storage.helpers";

import { LanguageType } from "@/constants/global";

import en from "@/assets/locale/en.json";
import uk from "@/assets/locale/uk.json";

const { setItem, getItem } = langStorageService<string>(LanguageType.LANG);

const navigatorLanguage = navigator.language.split("-")[0];

const language = navigatorLanguage === LanguageType.UA ? LanguageType.UA : LanguageType.EN;


const choosenLanguage = getItem();

setItem(choosenLanguage || language);

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    uk: { translation: uk }
  },
  lng: choosenLanguage || language,
  fallbackLng: choosenLanguage || language,
  interpolation: { escapeValue: false }
});

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { FallingOut } from "@/elements";
import { AppDrawer } from "@/elements/Drawer/Drawer";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { allLocationsIdStorageService, handleScroll } from "@/helpers";

import { AllLocationType, ScrollTypes } from "@/constants/global";

import { BookingBlockForm } from "./BookingBlockForm";

import { RootState } from "@/store";
import {
  clearChoosenCalendarBooking,
  setHandleAllLocationId,
  setHandleBookingBlockedSection
} from "@/store/booking/booking.slice";

const { getItem: getAllLocation } = allLocationsIdStorageService(AllLocationType.ALL_LOCATIONS_ID);

export const BookingBlock = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isAllLocation = getAllLocation();
  const isMobile = useGetScreenStatus();
  const isShowBlocked = useSelector((state: RootState) => state.booking.blocked.isShowBlocked);
  const chosenLocationId = useSelector(
    (state: RootState) => state.booking.allLocation.chosenLocationId
  );
  const bookingLocationId = useSelector(
    (state: RootState) => state.booking.choosenCalendarBooking.hotelId
  );
  const chosenLocation = useSelector((state: RootState) =>
    state.user.main?.hotels?.find((hotel) => hotel.id === (chosenLocationId || bookingLocationId))
  );
  const conditionTitle = isAllLocation
    ? t("booking.blocked.title-with-hotel", { hotelName: chosenLocation?.publicLabel })
    : t("booking.blocked.title");

  const handleCloseBookingBlock = () => {
    dispatch(setHandleBookingBlockedSection(false));
    dispatch(clearChoosenCalendarBooking());
    dispatch(setHandleAllLocationId(null));
    handleScroll(ScrollTypes.REMOVE);
  };

  if (!isMobile) {
    return (
      <AppDrawer maskClosable={false} title={conditionTitle} onClose={handleCloseBookingBlock} open={isShowBlocked}>
        <BookingBlockForm />
      </AppDrawer>
    );
  }

  return (
    <FallingOut
      title={conditionTitle}
      handleClose={handleCloseBookingBlock}
      content={<BookingBlockForm />}
    />
  );
};

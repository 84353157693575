import Cookies from "js-cookie";

import { COOKIE_STORAGE_KEYS } from "@/constants";
import { AllLocationType, LanguageType } from "../../constants/global";
import { HotelType } from "./../../constants/global/index";

export const cookieStorageService = <TData>(key: COOKIE_STORAGE_KEYS) => {
  const getItem = (): TData => {
    const data = Cookies.get(key);

    return data ? JSON.parse(data) : null;
  };

  const removeItem = () => {
    return Cookies.remove(key);
  };

  const setItem = (data?: TData) => {
    if (data) {
      return Cookies.set(key, JSON.stringify(data));
    }

    removeItem();
  };

  return { setItem, getItem, removeItem };
};

export const langStorageService = <TData>(key: LanguageType) => {
  const getItem = (): TData | null => {
    const data = localStorage.getItem(key);

    return data ? JSON.parse(data) : null;
  };

  const removeItem = () => {
    return localStorage.removeItem(key);
  };

  const setItem = (data?: TData) => {
    if (data) {
      return localStorage.setItem(key, JSON.stringify(data));
    }

    removeItem();
  };

  return { setItem, getItem, removeItem };
};

export const hotelIdStorageService = <TData>(key: HotelType) => {
  const getItem = (): TData | null => {
    const data = localStorage.getItem(key);

    return data ? JSON.parse(data) : null;
  };

  const removeItem = () => {
    return localStorage.removeItem(key);
  };

  const setItem = (data?: TData) => {
    if (data) {
      return localStorage.setItem(key, JSON.stringify(data));
    }

    removeItem();
  };

  return { setItem, getItem, removeItem };
};

export const allLocationsIdStorageService = <TData>(key: AllLocationType) => {
  const getItem = (): TData | null => {
    const data = localStorage.getItem(key);

    return data ? JSON.parse(data) : null;
  };

  const removeItem = () => {
    return localStorage.removeItem(key);
  };

  const setItem = (data?: TData) => {
    if (data) {
      return localStorage.setItem(key, JSON.stringify(data));
    }

    removeItem();
  };

  return { setItem, getItem, removeItem };
};

export const removeLangKey = () => {
  const { removeItem } = langStorageService(LanguageType.LANG);

  return removeItem();
}

export const removeHotelId = () => {
  const { removeItem } = hotelIdStorageService(HotelType.HOTEL_ID);
  
  return removeItem();
}

export const removeAllLocationsId = () => {
  const { removeItem } = allLocationsIdStorageService(AllLocationType.ALL_LOCATIONS_ID);

  return removeItem();
}

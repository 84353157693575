import i18next from "i18next";


export const yearMonths = [
    {
        title: i18next.t("calendar.months.january"),
        value: 0
    },
    {
        title: i18next.t("calendar.months.february"),
        value: 1
    },
    {
        title: i18next.t("calendar.months.march"),
        value: 2
    },
    {
        title: i18next.t("calendar.months.april"),
        value: 3
    },
    {
        title: i18next.t("calendar.months.may"),
        value: 4
    },
    {
        title: i18next.t("calendar.months.june"),
        value: 5
    },
    {
        title: i18next.t("calendar.months.july"),
        value: 6
    },
    {
        title: i18next.t("calendar.months.august"),
        value: 7
    },
    {
        title: i18next.t("calendar.months.september"),
        value: 8
    },
    {
        title: i18next.t("calendar.months.october"),
        value: 9
    },
    {
        title: i18next.t("calendar.months.november"),
        value: 10
    },
    {
        title: i18next.t("calendar.months.december"),
        value: 11
    }
]
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Image } from "antd";
import { RcFile } from "antd/es/upload";
import { UploadFile } from "antd/lib";

import { DirectUpload } from "@/components";

import { Modal, Paragraph } from "@/elements";

import { useCheckImage, useUploadControls } from "@/hooks";

import { TDirectHandleUploadProps } from "./DirectHandleUpload.types";

import styles from "./DirectHandleUpload.module.scss";

export const DirectHandleUpload: FC<TDirectHandleUploadProps> = ({
  onChange,
  getFileLists,
  fileList,
  beforeUpload,
  maxCount,
  disabled,
  ...props
}) => {
  const { t } = useTranslation();

  const [previewFile, setPreviewFile] = useState<UploadFile<RcFile> | null>(null);

  const { onRemove, customRequest } = useUploadControls({ onChange, getFileLists });
  const checkImage = useCheckImage();

  const handlePreview = (file: UploadFile<RcFile>) => {
    if (file) {
      setPreviewFile(file);
    }
  };

  const isDisabled = !!(maxCount && maxCount <= (fileList?.length || 0)) || disabled;

  const handlePreviewRemove = () => {
    onRemove(previewFile as UploadFile<RcFile>)
    setPreviewFile(null)
  }

  const handleClosePreview = () => {
    setPreviewFile(null);
  }

  return (
    <>
      <DirectUpload
        disabled={isDisabled}
        onRemove={onRemove}
        onPreview={handlePreview}
        customRequest={customRequest}
        beforeUpload={beforeUpload || checkImage}
        fileList={fileList}
        {...props}
      />
      <Modal
        open={!!previewFile}
        onCancel={() => setPreviewFile(null)}
        title={
          <Paragraph ellipsis className={styles["image-modal-title"]}>
            {previewFile?.name}
          </Paragraph>
        }
        footer={null}
        className={styles.preview__modal}
      >
        <Image src={previewFile?.thumbUrl || previewFile?.url} alt="preview" className={styles["preview-image"]} />
        <div className={styles.preview__buttons}>
          <Button size="large" type="primary" onClick={handleClosePreview}>
            {t("location-settings.close")}
          </Button>
          <Button size="large" ghost danger onClick={handlePreviewRemove}>
            {t("location-settings.delete")}
          </Button>
        </div>
      </Modal>
    </>
  );
};

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";

import { Icon } from "@/elements";

import { handleScroll } from "@/helpers";

import { ROUTES } from "@/constants";
import { ScrollTypes } from "@/constants/global";

import styles from "./PriceSetupEditFooter.module.scss";

import { RootState } from "@/store";
import { setHandleSubmitSeasonData } from "@/store/footer/footer.slice";
import { setShowDeleteSeasonModal } from "@/store/price-setup/price-setup.slice";

const { PRICE_SETUP, SETTINGS } = ROUTES;

export const PriceSetupEditFooter = () => {
  const footerContent = useSelector((state: RootState) => state.footer.footerContent);
  const isDisabled = useSelector(
    (state: RootState) => state.priceSetup.isDisabledSeasonSubmitButton
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hotelId } = useParams();

  const handleSubmit = () => {
    dispatch(setHandleSubmitSeasonData(true));
  };

  const handleClose = () => {
    navigate(`/${hotelId}/${SETTINGS}/${PRICE_SETUP}`);
  };

  const handleDelete = () => {
    dispatch(setShowDeleteSeasonModal(true));
    handleScroll(ScrollTypes.ADD)
  };

  return (
    <div className={styles["footer__buttons-wrapper"]}>
      <div className={styles.footer__buttons}>
        <Button
          disabled={isDisabled}
          size="large"
          type="primary"
          onClick={handleSubmit}
          className={styles.footer__button}
        >
          {footerContent?.submitButtonText}
        </Button>
        <div className={styles["footer__button-container"]}>
          <Button onClick={handleClose} size="large" ghost className={styles.footer__button}>
            {footerContent?.cancelButtonText}
          </Button>
          <div className={styles["footer__delete-btn"]} onClick={handleDelete}>
            <Icon icon="outlineTrash" />
          </div>
        </div>
      </div>
    </div>
  );
};

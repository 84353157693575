import { Icon } from "@/elements";
import { IWeekdayType } from "@/elements/Weekdays/Weekdays.type";

import styles from "./SeasonSetupListItem.module.scss";

const MAX_WEEK_DAYS = 7;

export const getIconType = (listOfDays: IWeekdayType[]) => {
  if (listOfDays.length === MAX_WEEK_DAYS) {
    return <Icon icon="declineOutline" className={styles.decline} />;
  }
  if (listOfDays.length < MAX_WEEK_DAYS && listOfDays.length) {
    return <Icon icon="alertOutline" className={styles.alert} />;
  }

  return <Icon icon="checkOutline" className={styles.success} />;
};

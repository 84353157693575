import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useCustomMutation } from "@/hooks";

import { api } from "@/helpers";

import { ROUTES } from "@/constants";

import { TMutationsOptions } from "@/types";

import { setShowPaymentOptionDeleteModal } from "@/store/price-setup/price-setup.slice";

interface IPaymentOptionDeleteData {
  optionId: number;
}

const fetcher = async (deleteOption: IPaymentOptionDeleteData) => {
  return api.del(`payment-options/${deleteOption.optionId}`);
};

const { SETTINGS, PAYMENT_OPTIONS } = ROUTES;

export const useDeletePaymentOption = (
  options?: TMutationsOptions<IPaymentOptionDeleteData, IPaymentOptionDeleteData>
) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { hotelId } = useParams();

  const { mutate, ...rest } = useCustomMutation<IPaymentOptionDeleteData, IPaymentOptionDeleteData>(
    fetcher,
    {
      ...options,
      onSuccess: async (data, ...args) => {
        await queryClient.invalidateQueries(["get-payment-options"], { exact: false });
        navigate(`/${hotelId}/${SETTINGS}/${PAYMENT_OPTIONS}`);
        dispatch(setShowPaymentOptionDeleteModal(false));
        options?.onSuccess?.(data, ...args);
      }
    }
  );

  const handleMutate = (data: IPaymentOptionDeleteData) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

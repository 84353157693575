import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { FallingOut } from "@/elements";
import { AppDrawer } from "@/elements/Drawer/Drawer";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { handleScroll } from "@/helpers";

import { ScrollTypes } from "@/constants/global";

import { IBookingFilterParams } from "@/types";

import styles from "./BookingFilter.module.scss";

import { BookingFilterContent } from "./BookingFilterContent";

import { RootState } from "@/store";
import { setHandleBookingFilterSection } from "@/store/booking/booking.slice";

const initialData = {
  sortField: "checkinDate",
  sortOrder: "ASC",
  guestsCount: null,
  nightsCount: null,
  paymentStatus: null
};

export const BookingFilter = memo(function BookingFilter({
  handleFilterValues
}: {
  tabName: string;
  handleFilterValues: (values: IBookingFilterParams) => void;
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useGetScreenStatus();
  const filterValues = useSelector((state: RootState) => state.booking.filters);
  const isShowBookingFilter = useSelector((state: RootState) => state.booking.main.isShowFilter);

  const [bookingsRequestFilter, setBookingsRequestFilter] =
    useState<IBookingFilterParams>(filterValues);

  const handleChangeStateValue = (values: any) => {
    setBookingsRequestFilter((prevValue) => ({
      ...prevValue,
      ...values
    }));
  };

  const clearValues = () => {
    handleChangeStateValue(initialData);
  };

  const closeFallingOut = () => {
    dispatch(setHandleBookingFilterSection(false));
    handleScroll(ScrollTypes.REMOVE);
  };

  if (!isMobile) {
    return (
      <AppDrawer
        title={
          <div className={styles["filter__header-drawer"]}>
            <h3 className={styles["filter__header-title"]}>{t("booking.filter.title")}</h3>
            <p className={styles["filter__header-clear"]} onClick={clearValues}>
              {t("booking.filter.clear")}
            </p>
          </div>
        }
        open={isShowBookingFilter}
        onClose={closeFallingOut}
      >
        <BookingFilterContent
          handleFilterValues={handleFilterValues}
          bookingsRequestFilter={bookingsRequestFilter}
          setBookingsRequestFilter={setBookingsRequestFilter}
        />
      </AppDrawer>
    );
  }

  return (
    <FallingOut
      title={t("booking.filter.title")}
      handleClose={closeFallingOut}
      headerAction={
        <p className={styles["filter__header-clear"]} onClick={clearValues}>
          {t("booking.filter.clear")}
        </p>
      }
      content={
        <BookingFilterContent
          handleFilterValues={handleFilterValues}
          bookingsRequestFilter={bookingsRequestFilter}
          setBookingsRequestFilter={setBookingsRequestFilter}
        />
      }
    />
  );
});

import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Divider, Form } from "antd";

import { DirectHandleUpload } from "@/components";

import { Button, FormItem, Icon, Input, notification, Title } from "@/elements";

import { useAdditionalHotel } from "@/react-queries/hotel/useAdditionalHotel";
import { useDeleteLocation } from "@/react-queries/hotel/useDeleteLocation";
import { useGetAllLocations } from "@/react-queries/hotel/useGetAllLocation";

import { useYupSync } from "@/hooks";
import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { ROUTES } from "@/constants";

import { authBasicSettingsSchema } from "./AuthBasicSettingsForm.schema";

import { ILocationSettings } from "@/types";
import { IAuthBasicSettings, IAuthBasicSettingsFormProps } from "./AuthBasicSettingsForm.types";

import styles from "./AuthBasicSettingsForm.module.scss";

import { CancellationModal } from "../CancellationModal/CancellationModal";

import { RootState } from "@/store";

const { SETTINGS, DASHBOARD } = ROUTES;

enum Submit_Types {
  CREATE = "create",
  ADDITIONAL = "additional"
}

export const AuthBasicSettingsForm: FC<IAuthBasicSettingsFormProps> = ({
  form,
  submitForm,
  isEdit = false,
  isCreate = false,
  isAdditional = false,
  isDataLoading = false
}) => {
  const { t } = useTranslation();
  const yupSync = useYupSync(authBasicSettingsSchema);
  const [disabled, setDisabled] = useState<boolean>(true);
  const activeHotel = useSelector((state: RootState) => state.hotel.activeHotel);
  const isLocationUpdating = useSelector((state: RootState) => state.user.isLocationUpdating);
  const isMobile = useGetScreenStatus();
  const { mutate: handleAdditionalHotel, isLoading: isAdditionalHotelLoading } =
    useAdditionalHotel();
  const navigate = useNavigate();
  const { hotelId } = useParams();
  const [isDeleteLocation, setIsDeleteLocation] = useState<boolean>(false);
  const { mutate: handleDeleteLocation, isLoading: isDeleteLocationLoading } = useDeleteLocation();
  const isSingleLocation = useSelector((state: RootState) => state.user.main?.hotels?.length === 1);
  const { data: allLocations } = useGetAllLocations({
    enabled: isAdditional
  });

  const handleValueChange = (_: any, values: IAuthBasicSettings) => {
    if (isEdit && activeHotel) {
      for (const key of Object.keys(values)) {
        if (
          !values[key as keyof IAuthBasicSettings].toString().trim() &&
          (key as keyof IAuthBasicSettings) !== "googleMapAddress"
        ) {
          setDisabled(true);
          break;
        }

        if ((key as keyof IAuthBasicSettings) === "images") {
          if (!values.images[0]) {
            setDisabled(true);
            continue;
          }

          if (values.images[0]?.url !== activeHotel.locationImage) {
            setDisabled(false);
            break;
          }

          setDisabled(true);
          continue;
        }
        if ((key as keyof IAuthBasicSettings) === "maxCountOfGuests") {
          if (+values[key as keyof IAuthBasicSettings] !== activeHotel.maxGuests) {
            setDisabled(false);
            break;
          }

          return setDisabled(true);
        }
        if (
          values[key as keyof IAuthBasicSettings] !== activeHotel[key as keyof IAuthBasicSettings]
        ) {
          setDisabled(false);
          break;
        }
        setDisabled(true);
      }
    }
  };

  const handleAdditionalButton = () => {
    form
      .validateFields()
      .then((values: IAuthBasicSettings) => {
        if (!values.images[0]?.file) {
          return notification.error(t("location-settings.required-hotel-image"));
        }
        const locationInfo = {
          publicLabel: values.publicLabel,
          googleMapAddress: values.googleMapAddress,
          maxGuests: Number(values.maxCountOfGuests),
          file: values.images[0]?.file
        } as ILocationSettings;
        handleAdditionalHotel(locationInfo);
      })
      .then(() => form.resetFields())
      .catch((error) => {
        return error;
      });
  };

  const handleSubmit = (type: Submit_Types) => {
    return form
      .validateFields()
      .then(() => {
        return Submit_Types.CREATE === type ? submitForm() : handleAdditionalButton();
      })
      .catch((error) => {
        return error;
      });
  };

  const handleDelete = () => {
    handleDeleteLocation();
  };

  const handleToggleDelete = () => {
    if (isDataLoading) {
      return;
    }
    setIsDeleteLocation((prevValue) => !prevValue);
  };

  const handleCancelChanges = () => {
    navigate(`/${hotelId}/${SETTINGS}`);
  };

  return (
    <div
      className={
        isEdit || isCreate
          ? `${styles["location__settings-container"]} ${isEdit ? styles.edit : null}`
          : styles["basic-settings-container"]
      }
    >
      <h1 className={styles["basic-settings-container__title"]}>
        {isEdit
          ? isCreate
            ? t("locations.create.form-title")
            : t("settings.location.photo")
          : t("locations.create.form-title")}
      </h1>

      <Form<IAuthBasicSettings>
        className={styles["auth-basic-settings-form"]}
        name="basic-settings-location"
        form={form}
        layout="vertical"
        onValuesChange={handleValueChange}
      >
        <FormItem
          className={styles.location__image}
          rules={[yupSync]}
          name="images"
          valuePropName="fileList"
        >
          <DirectHandleUpload maxCount={1} getFileLists={() => form.getFieldValue("images")} />
        </FormItem>

        <div className={styles.location__info}>
          <Icon icon="infoOutline" />
          <p className={styles["location__info-text"]}>{t("location-settings.edit.info")}</p>
        </div>

        <div className={styles.location__fields}>
          <FormItem
            label={t("location-settings.hotel-name")}
            name="publicLabel"
            required
            rules={[yupSync]}
          >
            <Input placeholder={t("location-settings.hotel-name-placeholder")} />
          </FormItem>

          <FormItem
            label={t("location-settings.google-location")}
            name="googleMapAddress"
            rules={[yupSync]}
          >
            <Input placeholder={t("location-settings.google-placeholder")} />
          </FormItem>
        </div>
        {isAdditional || isCreate ? (
          <div>
            <Title className={styles["basic-settings__title"]} level={5}>
              {t("location-settings.conditions")}
            </Title>

            <FormItem
              name="maxCountOfGuests"
              required
              label={t("location-settings.label.number-of-guests")}
              rules={[yupSync]}
            >
              <Input
                type="number"
                min={1}
                placeholder={t("location-settings.placeholder.number-of-guests")}
                className={styles["guests-number"]}
              />
            </FormItem>
          </div>
        ) : null}

        {isEdit && !isMobile ? <Divider className={styles["location__settings-divider"]} /> : null}

        <div className={styles.location__buttons}>
          <Button
            size="large"
            className={styles["basic-settings-form-button"]}
            type="primary"
            onClick={() => handleSubmit(Submit_Types.CREATE)}
            loading={
              isLocationUpdating ||
              isAdditionalHotelLoading ||
              isDeleteLocationLoading ||
              isDataLoading
            }
            disabled={(disabled && isEdit) || isLocationUpdating}
          >
            {isEdit
              ? t("location-settings.edit.use-changes")
              : isCreate
              ? t("locations.create.form-create")
              : t("location-settings.continue")}
          </Button>
          {isAdditional && (
            <>
              <Button
                ghost
                className={styles["location__additional-button"]}
                loading={isAdditionalHotelLoading || isDeleteLocationLoading || isDataLoading}
                onClick={() => handleSubmit(Submit_Types.ADDITIONAL)}
              >
                {t("location-settings.additional-button")}
              </Button>
              {allLocations?.length ? (
                <a
                  href={`/${allLocations[0].id}/${DASHBOARD}`}
                  className={styles["location__additional-start"]}
                >
                  {t("location-settings.start-work", { locationName: allLocations[0].publicLabel })}
                </a>
              ) : null}
            </>
          )}
          {isEdit && (
            <div className={styles["location__buttons-container"]}>
              <Button
                onClick={handleCancelChanges}
                size="large"
                className={styles["basic-settings-form-button"]}
                loading={isDataLoading}
                ghost
              >
                {t("location-settings.edit.decline")}
              </Button>
              {isSingleLocation ? null : (
                <div className={styles["location__buttons-delete"]} onClick={handleToggleDelete}>
                  <Icon icon="outlineTrash" />
                </div>
              )}
            </div>
          )}
        </div>
      </Form>
      {isDeleteLocation ? (
        <CancellationModal
          titleHeader={t("locations.delete.header-title")}
          title={t("locations.delete.text")}
          approveButtonText={t("locations.delete.approve")}
          declineButtonText={t("locations.delete.decline")}
          handleApprove={handleDelete}
          handleClose={handleToggleDelete}
        />
      ) : null}
    </div>
  );
};

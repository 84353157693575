
import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { useCustomMutation } from "@/hooks";

import { api } from "@/helpers";

import { ROUTES } from "@/constants";

import { TMutationsOptions } from "@/types";
import { IUserUpdateInfo } from "@/types/userInfo/userInfo.type";

const fetcher = async (body: IUserUpdateInfo) => {
  return api.patch("host/user", body);
};

const { SETTINGS } = ROUTES

export const useUpdateCurrentUser = (options?: TMutationsOptions<IUserUpdateInfo, IUserUpdateInfo>) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { hotelId } = useParams();

  const { mutate, ...rest } = useCustomMutation<IUserUpdateInfo, IUserUpdateInfo>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      navigate(`/${hotelId}/${SETTINGS}`)
      await queryClient.invalidateQueries(["current-user"], { exact: false });
    }
  });

  const handleMutate = (data: IUserUpdateInfo) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Divider } from "antd";
import dayjs from "dayjs";

import { Icon } from "@/elements";
import { IWeekdayType } from "@/elements/Weekdays/Weekdays.type";

import { ROUTES } from "@/constants";
import { DATE_FORMAT, DATE_MONTH_DATE_FORMAT } from "@/constants/global";

import styles from "./SeasonSetupListItem.module.scss";

import { getWeekDays } from "../SeasonTariff/SeasonTariff.helper";
import { ISeasonTariffForm } from "../SeasonTariff/SeasonTariff.type";

import { getIconType } from "./SeasonSetupListItem.helper";
import { IPriceSetupListItem } from "./SeasonSetupListItem.type";

const { EDIT } = ROUTES;

export const SeasonSetupListItem = ({ season }: IPriceSetupListItem) => {
  const navigate = useNavigate();

  const notChooseDays = useMemo(() => {
    const weekDayList = getWeekDays();
    const getChoosenWeekdays = season?.tariffs?.map((tariff: ISeasonTariffForm) => tariff.daysOfWeek).flat();
    
    return weekDayList.filter(
      (weekday: IWeekdayType) => !getChoosenWeekdays?.includes(weekday.fullName)
    );
  }, [season]);

  const handleNavigateOpenSession = () => {
    navigate(`${season.id}/${EDIT}`);
  }

  return (
    <div className={styles["price-setup-item"]} onClick={handleNavigateOpenSession}>
      <div className={styles["price-setup-item__details"]}>
        <div className={styles["price-setup-item__title"]}>{season.name}</div>
        <div className={styles["price-setup-item__description"]}>
          <div className={styles["price-setup-item__description-date"]}>
            {dayjs(season.startDate, DATE_FORMAT).format(DATE_MONTH_DATE_FORMAT)}
          </div>
          <Divider className={styles["price-setup-item__description-divider"]} />
          <div className={styles["price-setup-item__description-date"]}>
          {dayjs(season.endDate, DATE_FORMAT).format(DATE_MONTH_DATE_FORMAT)}
          </div>
        </div>
      </div>
      {
        getIconType(notChooseDays)
      }
      <Icon icon="arrowRight" className={styles["price-setup-item__icon-next"]} />
    </div>
  );
};

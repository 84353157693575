import { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Popover } from "antd";

import { Icon } from "@/elements";

import { useCurrentUser } from "@/react-queries";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { allLocationsIdStorageService } from "@/helpers";

import { ROUTES } from "@/constants";
import { AllLocationType } from "@/constants/global";

import styles from "./ProfileDropDown.module.scss";

import { LocationChooser } from "../LocationChooser/LocationChooser";

import { RootState } from "@/store";
import { clearCalendarState } from "@/store/calendar/calendar.slice";
import { setHandleShowLocationModal } from "@/store/header/header.slice";
import { setUserInformation } from "@/store/user/user.slice";

const { AUTH, HOTEL_SETUP, LOCATIONS, ALL_LOCATIONS } = ROUTES;

const { getItem: getIsAllLocation } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

export const ProfileDropDown: FC = () => {
  const {
    t,
    i18n,
    i18n: { language }
  } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const activeHotel = useSelector((state: RootState) => state.hotel.activeHotel);
  const isShowLocationModal = useSelector((state: RootState) => state.header.isShowLocationModal);
  const isAllLocation = getIsAllLocation();
  const isMobile = useGetScreenStatus();
  const location = useLocation();

  const { data: userData, isLoading } = useCurrentUser();

  const handleNavigationToLocations = () => {
    dispatch(clearCalendarState());

    if (!isMobile) {
      return;
    }

    if (activeHotel) {
      return navigate(`/${activeHotel.id}/${LOCATIONS}`);
    }
    if (isAllLocation) {
      return navigate(`/${ALL_LOCATIONS}/${LOCATIONS}`);
    }
  };

  const handleOpenPopover = () => {
    const isLocationsPage = location.pathname.includes(`/${LOCATIONS}`);
    if (isMobile || isLocationsPage) {
      return;
    }

    dispatch(setHandleShowLocationModal(!isShowLocationModal));
  };

  useEffect(() => {
    if (userData) {
      dispatch(setUserInformation(userData));
      i18n.changeLanguage(userData.appLanguage);
    }
  }, [userData]);

  useEffect(() => {
    const handleScroll = () => {
      dispatch(setHandleShowLocationModal(false));
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (!userData?.hotels?.length && !isLoading) {
    return <Navigate to={`${AUTH}${HOTEL_SETUP}`} replace />;
  }

  if (isLoading) {
    return null;
  }

  const contentRender = useMemo(() => {
    if (isAllLocation) {
      return (
        <div onClick={handleNavigationToLocations} className={styles["header__all-locations"]}>
          <div className={styles["header__all-locations-icon"]}>
            <Icon icon="house" />
          </div>
          <span className={styles["header__all-locations-text"]}>
            {t("locations.header-title")}
          </span>
          <Icon icon="expandArrow" />
        </div>
      );
    }

    return (
      <div onClick={handleNavigationToLocations} className={styles["header__hotel-container"]}>
        <Avatar className={styles.avatar} src={activeHotel?.locationImage as string} size="small" />
        <span className={styles["header__hotel-name"]}>{activeHotel?.publicLabel}</span>
        <Icon icon="expandArrow" />
      </div>
    );
  }, [isAllLocation, isMobile, language, activeHotel]);

  if (!isMobile) {
    return (
      <Popover
        trigger={"click"}
        content={<LocationChooser isPopoverVersion />}
        open={isShowLocationModal}
        onOpenChange={handleOpenPopover}
      >
        {contentRender}
      </Popover>
    );
  }

  return contentRender;
};

import { object, string } from "yup";

import { checkPhoneValidation, ValidEmail } from "@/helpers";

export const userInfoUpdateSchema = object().shape({
  fullName: string().required(),
  email: string()
    .required()
    .test("email", (value) => ValidEmail(value)),
  phoneNumber: string()
    .required()
    .test("matches", (value) => {
      return checkPhoneValidation(value);
    })
});

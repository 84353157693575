import { UploadFile } from "antd";
import { RcFile } from "antd/es/upload";

export type THostyUploadFile = UploadFile<RcFile> & {
  file?: RcFile;
  signedId?: string;
};

export function isRcFile(file: string | RcFile | Blob): file is RcFile {
  if (typeof file === "string") {
    return false;
  }

  if ("uid" in file) {
    return true;
  }

  return false;
}

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { GuestForm } from "@/components";

import { ROUTES } from "@/constants";

import styles from "../Guests.module.scss";

import { setHeaderNavigation } from "@/store/header/header.slice";

const { GUESTS } = ROUTES;

export const CreateGuests = () => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { hotelId } = useParams();

  const { t } = useTranslation();

  useEffect(() => {
    dispatch(
      setHeaderNavigation({
        navigation: {
          title: t("navigation.guests"),
          link: `/${hotelId}/${GUESTS}`
        }
      })
    );
  }, [pathname]);

  return (
    <div className={styles.guests__container}>
      <GuestForm localizationName={"create-guests"} />
    </div>
  );
};

import { FC } from "react";
import { Route, Routes } from "react-router-dom";

import { MainLayout } from "@/layouts";

import { BookingBlockView } from "@/containers/BookingBlockView/BookingBlockView";

import { BookingPaymentHistory } from "@/components/Booking/BookingPaymentHistory/BookingPaymentHistory";
import { PaymentOptionForm } from "@/components/Forms/PaymentOptionForm/PaymentOptionForm";
import { PriceSetupForm } from "@/components/Forms/PriceSetupForm/PriceSetupForm";
import { LocationsCreate } from "@/components/LocationsCreate/LocationsCreate";
import { SeasonTariff } from "@/components/Season/SeasonTariff/SeasonTariff";
import { BookingHotelRulesUpdate } from "@/components/Settings/BookingHotelRulesUpdate/BookingHotelRulesUpdate";
import { SettingsApp } from "@/components/Settings/SettingsApp/SettingsApp";
import { SettingsLocation } from "@/components/Settings/SettingsLocation/SettingsLocation";
import { SettingsUpdatePassword } from "@/components/Settings/SettingsUpdatePassword/SettingsUpdatePassword";
import { SettingsUserProfile } from "@/components/Settings/SettingsUserProfile/SettingsUserProfile";
import { SettingsWidget } from "@/components/Settings/SettingsWidget/SettingsWidget";

import { ROUTES } from "@/constants";

import { Booking } from "../Booking/Booking";
import { BookingView } from "../Booking/View/BookingView";
import { Dashboard } from "../Dashboard/Dashboard";
import { CreateGuests } from "../Guests/Create/CreateGuests";
import { EditGuests } from "../Guests/Edit/EditGuests";
import { Guests } from "../Guests/Guests";
import { GuestView } from "../Guests/View/GuestView";
import { Locations } from "../Locations/Locations";
import { PaymentOptions } from "../PaymentOptions/PaymentOptions";
import { PrivacyPolicy } from "../PrivacyPolicy/PrivacyPolicy";
import { PriceSetup } from "../SeasonSetup/SeasonSetup";
import { Settings } from "../Settings/Settings";
import { TermsOfCondition } from "../TermsOfCondition/TermsOfCondition";

const {
  DASHBOARD,
  BOOKINGS,
  GUESTS,
  SETTINGS,
  PRICE_SETUP,
  SEASON_TARIFF,
  PAYMENT_OPTIONS,
  CREATE,
  EDIT,
  VIEW,
  BLOCKED,
  LOCATION,
  UPDATE_PASSWORD,
  PAYMENT_HISTORY,
  PRIVACY_POLICY,
  TERMS_OF_CONDITION,
  USER_PROFILE,
  BOOKING_RULES,
  WIDGET,
  APP,
  LOCATIONS
} = ROUTES;

export const MainRoutes: FC = () => {

  return (
    <MainLayout>
      <Routes>
        <Route path={DASHBOARD} element={<Dashboard />} />
        <Route path={SETTINGS} element={<Settings />}>
          <Route path={PRICE_SETUP} element={<PriceSetup />}>
            <Route path={CREATE} element={<PriceSetupForm />} />
            <Route path={`:priceSetupId/${EDIT}`} element={<PriceSetupForm />} />
            <Route path={`:priceSetupId/${SEASON_TARIFF}/${CREATE}`} element={<SeasonTariff />} />
            <Route
              path={`:priceSetupId/${SEASON_TARIFF}/:tariffId/${EDIT}`}
              element={<SeasonTariff />}
            />
          </Route>
          <Route path={PAYMENT_OPTIONS} element={<PaymentOptions />}>
            <Route path={CREATE} element={<PaymentOptionForm />} />
            <Route path={`:paymentOptionId/${EDIT}`} element={<PaymentOptionForm />} />
          </Route>
          <Route path={LOCATION} element={<SettingsLocation />} />
          <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={TERMS_OF_CONDITION} element={<TermsOfCondition />} />
          <Route path={UPDATE_PASSWORD} element={<SettingsUpdatePassword />} />
          <Route path={USER_PROFILE} element={<SettingsUserProfile />} />
          <Route path={BOOKING_RULES} element={<BookingHotelRulesUpdate />} />
          <Route path={WIDGET} element={<SettingsWidget />} />
          <Route path={APP} element={<SettingsApp />} />
        </Route>
        <Route path={BOOKINGS} element={<Booking />}>
          <Route path={`:bookingId/${PAYMENT_HISTORY}`} element={<BookingPaymentHistory />} />
          <Route path={`:bookingId/${VIEW}`} element={<BookingView />} />
          <Route path={`:bookingId/${BLOCKED}`} element={<BookingBlockView />} />
        </Route>
        <Route path={GUESTS} element={<Guests />}>
          <Route path={CREATE} element={<CreateGuests />} />
          <Route path={`:userId/${EDIT}`} element={<EditGuests />} />
          <Route path={`:userId/${VIEW}`} element={<GuestView />} />
        </Route>
        <Route path={LOCATIONS} element={<Locations />}>
          <Route path={CREATE} element={<LocationsCreate />} />
        </Route>
      </Routes>
    </MainLayout>
  );
};

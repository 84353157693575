import React, { useMemo } from "react"
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { Icon, LocationSelect } from "@/elements"

import { allLocationsIdStorageService } from "@/helpers";

import { AllLocationType } from "@/constants/global";

import styles from "./BookingAction.module.scss"

import { RootState } from "@/store";
import { setHandleAllLocationId, setHandleBookingActionButtonContent, setHandleBookingBlockedSection, setHandleBookingFormCreate, setHandleEditPriceModal } from "@/store/booking/booking.slice";

const { getItem: getAllLocationStatus } = allLocationsIdStorageService(
    AllLocationType.ALL_LOCATIONS_ID
  );

export const BookingActionContent = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isAllLocation = getAllLocationStatus();
    const chooseCalendarDay = useSelector((state: RootState) => state.booking.choosenCalendarBooking);
    const chosenLocation = useSelector(
      (state: RootState) => state.booking.allLocation.chosenLocationId
    );
  
    const isDisableButtons = useMemo(() => {
      if (!isAllLocation) {
        return false;
      }
  
      return !chooseCalendarDay?.hotelId && !chosenLocation && typeof chosenLocation !== "string";
    }, [chosenLocation, isAllLocation, chooseCalendarDay]);

    const handleOpenBookingCreate = () => {
        if (isDisableButtons) return;
        dispatch(setHandleBookingActionButtonContent(false));
        dispatch(setHandleBookingFormCreate(true));
      };
    
      const handleOpenBookingBlocked = () => {
        if (isDisableButtons) return;
        dispatch(setHandleBookingActionButtonContent(false));
        dispatch(setHandleBookingBlockedSection(true));
      };
    
      const handleOpenPriceEdit = () => {
        if (isDisableButtons) return;
        dispatch(setHandleBookingActionButtonContent(false));
        dispatch(setHandleEditPriceModal(true));
      };
    
      const handleChooseLocation = (value: string) => {
        dispatch(setHandleAllLocationId(value));
      };

  return (
    <div className={styles["booking-action__container"]}>
    {isAllLocation ? (
      <div className={styles["booking-action__content"]}>
        <div className={styles["booking-action__content-item"]}>
          <LocationSelect
            disabled={!!chooseCalendarDay?.hotelId}
            defaultValue={chooseCalendarDay?.hotelId || chosenLocation || null}
            placeholder={t("create-guests.location-placeholder")}
            onChange={handleChooseLocation}
          />
        </div>
      </div>
    ) : null}
    <div className={styles["booking-action__content"]}>
      <div
        className={`${styles["booking-action__button"]} ${
          isDisableButtons ? styles.disable : null
        }`}
        onClick={handleOpenBookingCreate}
      >
        <Icon icon="calendarPlus" />
        <div className={styles["booking-action__button-content"]}>
          {t("booking.action-btn.create")}
        </div>
      </div>
      <div
        className={`${styles["booking-action__button"]} ${
          isDisableButtons ? styles.disable : null
        }`}
        onClick={handleOpenBookingBlocked}
      >
        <Icon icon="lock" />
        <div className={styles["booking-action__button-content"]}>
          {t("booking.action-btn.block")}
        </div>
      </div>
    </div>
    {chooseCalendarDay.date && (
      <div className={styles["booking-action__content"]}>
        <div
          className={`${styles["booking-action__button"]} ${
            isDisableButtons ? styles.disable : null
          } ${styles["full-width"]}`}
          onClick={handleOpenPriceEdit}
        >
          <Icon icon="bankNote" />
          <div className={styles["booking-action__button-content"]}>
            {t("booking.action-btn.edit-price")}
          </div>
        </div>
      </div>
    )}
  </div>

  )
}

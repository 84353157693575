export const MAX_MOBILE_WIDTH = 768;
export const CELL_TABLE_WIDTH = 64;
export const BOOKING_COUNT_PER_PAGE = 10;
export const LIST_CALENDAR_HEIGHT_STICKY = 107.5;
export const DATE_FORMAT = "YYYY-MM-DD";
export const DATE_DAY_FORMAT = "DD";
export const DATE_DAY_OF_WEEK_FORMAT = "dd";
export const DATE_DAY_OF_WEEK_MEDIUM_FORMAT = "ddd";
export const DATE_DAY_OF_WEEK_FULL_FORMAT = "dddd";
export const DATE_DAY_NUMBER_FORMAT = "D";
export const DATE_MONTH_FORMAT = "MMM";
export const DATE_CALENDAR_EVENT_FORMAT = "DD.MM";
export const DATE_MONTH_DATE_FORMAT = "DD MMM";
export const DATE_SHORT_MONTH_DATE_FORMAT = "DD MMM YYYY";
export const DATE_MONTH_YEAR_FORMAT = "D MMMM YYYY";
export const DATE_YEAR_FORMAT = "YYYY";
export const FULL_DATE_FORMAT = "MMMM YYYY";
export const DATE_WITH_POINTS_FORMAT = "DD.MM.YYYY";
export const TIME_FORMAT = "HH:mm";
export const MONTH_FORMAT = "MMMM";
export const DATE_TIME_FORMAT = "DD MMM HH:mm";

export enum ScrollTypes {
  ADD = "add",
  REMOVE = "remove"
}

export enum LanguageType {
  LANG = "lang",
  EN = "en",
  UA = "uk"
}

export enum SettingDetailType {
  FOCUS = "focus",
  FREE = "free",
  SLOGAN = "slogan",
  CONTACT = "contact",
  SOCIALS = "socials",
}

export enum HotelType {
  HOTEL_ID = "activeHotelId"
}

export enum AllLocationType {
  ALL_LOCATIONS_ID = "isAllLocations"
}

export enum FooterType {
  DEFAULT = "default",
  PRICE_SETUP = "price-setup",
  PRICE_SETUP_EDIT = "price-setup-edit",
  PRICE_SETUP_TARIFF = "price-setup-tariff",
  PRICE_SETUP_EDIT_TARIFF = "price-setup-edit-tariff"
}

export const WeekendsType = ["сб", "нд", "Sat", "Sun"]
import { useQueryClient } from "react-query";
import i18next from "i18next";

import { notification } from "@/elements";

import { useCustomMutation } from "@/hooks";

import { api } from "@/helpers";

import { ILocationSettings, ILocationSettingsResponse, TMutationsOptions } from "@/types";

const fetcher = async (body: ILocationSettings): Promise<ILocationSettingsResponse> => {
  return api.post("/host/hotel", body, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const useAdditionalHotel = (
  options?: TMutationsOptions<ILocationSettingsResponse, ILocationSettings>
) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useCustomMutation<ILocationSettingsResponse, ILocationSettings>(
    fetcher,
    {
      ...options,
      onSuccess: async (data, ...args) => {
        options?.onSuccess?.(data, ...args);
        notification.success(i18next.t("locations.helper-text"));
        await queryClient.invalidateQueries(["get-all-locations"], { exact: false });
      }
    }
  );

  return { mutate, ...rest };
};

import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { Divider, Typography } from "antd";

import { queryClient } from "@/providers";

import { IntroduceIndividual } from "@/containers/Settings";
import { IntroduceMulti } from "@/containers/Settings/IntroduceMulti/IntroduceMulti";

import { SettingsAllLocaiton } from "@/components/Settings/SettingsAllLocaiton/SettingsAllLocaiton";

import { Icon, Loader, Title } from "@/elements";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import {
  allLocationsIdStorageService,
  checkSettingsPathname,
  removeAllLocationsId,
  removeHotelId,
  removeJWTToken,
  removeLangKey
} from "@/helpers";

import { ROUTES } from "@/constants";
import { AllLocationType } from "@/constants/global";

import styles from "./Settings.module.scss";

const { Link } = Typography;

const { getItem: getIsAllLocation } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

const {
  PRICE_SETUP,
  SETTINGS,
  PAYMENT_OPTIONS,
  LOCATION,
  USER_PROFILE,
  UPDATE_PASSWORD,
  BOOKING_RULES,
  PRIVACY_POLICY,
  TERMS_OF_CONDITION,
  WIDGET,
  APP,
  AUTH,
  SIGN_IN
} = ROUTES;

export const Settings = () => {
  const {
    t,
    i18n: { language }
  } = useTranslation();
  const location = useLocation();
  const isAllLocation = getIsAllLocation();
  const isMobile = useGetScreenStatus();
  const { hotelId } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 700);
    
    return () => clearTimeout(timer);
  }, []);

  const logout = () => {
    removeJWTToken();
    removeLangKey();
    removeHotelId();
    removeAllLocationsId();
    queryClient.clear();

    window.location.href = `${AUTH}${SIGN_IN}`;
  };

  const getIsActivePage = (type: string) => {
    return location.pathname.includes(type);
  };

  const contentSettingsRender = useMemo(() => {
    if (isAllLocation) {
      return <SettingsAllLocaiton />;
    }

    return (
      <div className={styles.settings__container}>
        {isMobile ? (
          <Title className={styles.settings__title} level={3}>
            {t("settings.title")}
          </Title>
        ) : null}
        <div className={styles.settings__content}>
          <div
            className={`${styles["settings__item-container"]} ${
              getIsActivePage(LOCATION) ? styles.active : null
            }`}
          >
            <Link className={styles.settings__item} href={`/${hotelId}/${SETTINGS}/${LOCATION}`}>
              <span className={styles["settings__item-text"]}>{t("settings.info-location")}</span>
              <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
            </Link>
            <Divider />
          </div>
          <div
            className={`${styles["settings__item-container"]} ${
              getIsActivePage(PRICE_SETUP) ? styles.active : null
            }`}
          >
            <Link className={styles.settings__item} href={`/${hotelId}/${SETTINGS}/${PRICE_SETUP}`}>
              <span className={styles["settings__item-text"]}>{t("settings.price-setup")}</span>
              <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
            </Link>
            <Divider />
          </div>
          <div
            className={`${styles["settings__item-container"]} ${
              getIsActivePage(BOOKING_RULES) ? styles.active : null
            }`}
          >
            <Link
              className={styles.settings__item}
              href={`/${hotelId}/${SETTINGS}/${BOOKING_RULES}`}
            >
              <span className={styles["settings__item-text"]}>{t("settings.booking-rules")}</span>
              <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
            </Link>
            <Divider />
          </div>
          <div
            className={`${styles["settings__item-container"]} ${
              getIsActivePage(WIDGET) ? styles.active : null
            }`}
          >
            <Link className={styles.settings__item} href={`/${hotelId}/${SETTINGS}/${WIDGET}`}>
              <span className={styles["settings__item-text"]}>{t("settings.widget.title")}</span>
              <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
            </Link>
          </div>
        </div>

        <div className={styles.settings__content}>
          <div
            className={`${styles["settings__item-container"]} ${
              getIsActivePage(USER_PROFILE) ? styles.active : null
            }`}
          >
            <Link
              className={styles.settings__item}
              href={`/${hotelId}/${SETTINGS}/${USER_PROFILE}`}
            >
              <span className={styles["settings__item-text"]}>{t("settings.user-profile")}</span>
              <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
            </Link>
            <Divider />
          </div>
          <div
            className={`${styles["settings__item-container"]} ${
              getIsActivePage(UPDATE_PASSWORD) ? styles.active : null
            }`}
          >
            <Link
              className={styles.settings__item}
              href={`/${hotelId}/${SETTINGS}/${UPDATE_PASSWORD}`}
            >
              <span className={styles["settings__item-text"]}>{t("settings.change-password")}</span>
              <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
            </Link>
            <Divider />
          </div>
          <div
            className={`${styles["settings__item-container"]} ${
              getIsActivePage(PAYMENT_OPTIONS) ? styles.active : null
            }`}
          >
            <Link
              className={styles.settings__item}
              href={`/${hotelId}/${SETTINGS}/${PAYMENT_OPTIONS}`}
            >
              <span className={styles["settings__item-text"]}>{t("settings.payment-options")}</span>
              <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
            </Link>
            <Divider />
          </div>
          <div
            className={`${styles["settings__item-container"]} ${
              getIsActivePage(TERMS_OF_CONDITION) ? styles.active : null
            }`}
          >
            <Link
              className={styles.settings__item}
              href={`/${hotelId}/${SETTINGS}/${TERMS_OF_CONDITION}`}
            >
              <span className={styles["settings__item-text"]}>
                {t("settings.terms-and-conditions")}
              </span>
              <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
            </Link>
            <Divider />
          </div>
          <div
            className={`${styles["settings__item-container"]} ${
              getIsActivePage(PRIVACY_POLICY) ? styles.active : null
            }`}
          >
            <Link
              className={styles.settings__item}
              href={`/${hotelId}/${SETTINGS}/${PRIVACY_POLICY}`}
            >
              <span className={styles["settings__item-text"]}>{t("settings.privacy-policy")}</span>
              <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
            </Link>
            <Divider />
          </div>
          <div
            className={`${styles["settings__item-container"]} ${
              getIsActivePage(APP) ? styles.active : null
            }`}
          >
            <Link className={styles.settings__item} href={`/${hotelId}/${SETTINGS}/${APP}`}>
              <span className={styles["settings__item-text"]}>{t("settings.app.title")}</span>
              <Icon className={styles["settings__item-icon"]} icon="arrowRight" />
            </Link>
          </div>
        </div>
        <div className={styles.settings__content}>
          <div className={styles["settings__item-container"]}>
            <Link className={styles.settings__item} onClick={logout}>
              <span className={styles["settings__item-text"]}> {t("settings.logout")} </span>
            </Link>
          </div>
        </div>
      </div>
    );
  }, [isAllLocation, location, isMobile, language]);

  if (isLoading) {
    return <Loader isFullScreen />;
  }

  if (checkSettingsPathname(location.pathname) && isMobile) {
    return <Outlet />;
  }

  return (
    <div className={styles.settings__wrapper}>
      {contentSettingsRender}
      {!isMobile ? (
        <>
          <Divider className={styles["settings__content-divider"]} type="vertical" />
          {!isMobile && checkSettingsPathname(location.pathname) ? (
            <div className={styles["settings__content-wrapper"]}>
              <Outlet />
            </div>
          ) : (
            <div className={styles.settings__additional}>
              {isAllLocation ? <IntroduceMulti /> : <IntroduceIndividual />}
            </div>
          )}
        </>
      ) : null}
    </div>
  );
};

import { useDispatch } from "react-redux";

import { useCustomMutation } from "@/hooks";

import { api, handleHotelIdInApi } from "@/helpers";

import { IBookingReservations, IBookingReservationsRequest, TMutationsOptions } from "@/types";

import { setHandleUpdateReservation } from "@/store/calendar/calendar.slice";

const fetcher = async (body: IBookingReservationsRequest) => {
  return api.get("host/bookings/reservations", handleHotelIdInApi(body));
};

export const userGetReservationBookings = (
  options?: TMutationsOptions<IBookingReservations[], IBookingReservationsRequest>
) => {
  const dispatch = useDispatch();

  const { mutate, ...rest } = useCustomMutation<
    IBookingReservations[],
    IBookingReservationsRequest
  >(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      dispatch(setHandleUpdateReservation(false))
      options?.onSuccess?.(data, ...args);
    }
  });

  const handleMutate = (data: IBookingReservationsRequest) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider } from "antd";

import { BookingStatus } from "@/constants/bookings/bookings.constant";

import { ReactComponent as Edit } from "@/assets/icons/edit.svg";

import styles from "./GuestViewCard.module.scss";

import { IGuestViewCard } from "./GuestViewCard.type";

export const GuestViewCard: FC<IGuestViewCard> = ({ bookingData, guestData, handleEdit }) => {
  const { t } = useTranslation();
  const isPending = bookingData?.status === BookingStatus.PENDING;

  return (
    <div className={styles["guests-view__card"]}>
      <div className={styles["guests-view__title-wrapper"]}>
        <h1 className={styles["guests-view__title"]}>{t("view-guests.title")}</h1>
        <div className={styles["guests-view__title-button"]}>
          <Button
            disabled={isPending}
            onClick={handleEdit}
            icon={<Edit />}
            className={styles["guests-view__btn"]}
          >
            {t("view-guests.edit")}
          </Button>
        </div>
      </div>
      <Divider className={styles["guests-view__divider"]} />
      <div className={styles["guests-view__name"]}>{guestData?.fullName}</div>
      <div className={styles["guests-view__list"]}>
        <div className={styles["guests-view__list-details"]}>
          <div className={styles["guests-view__item"]}>
            <p className={styles["guests-view__item-title"]}>{t("view-guests.phone")}</p>
            {guestData?.phoneNumber ? (
              <a
                href={`tel:${guestData?.phoneNumber}`}
                className={`${styles["guests-view__item-content"]} ${styles.link}`}
              >
                {guestData?.phoneNumber}
              </a>
            ) : (
              "–"
            )}
          </div>
          <div className={styles["guests-view__item"]}>
            <p className={styles["guests-view__item-title"]}>{t("view-guests.email")}</p>
            {guestData?.email ? (
              <a
                href={`mailto:${guestData?.email}`}
                className={`${styles["guests-view__item-content"]} ${styles.link}`}
              >
                {guestData?.email}
              </a>
            ) : (
              "–"
            )}
          </div>
        </div>
        <div className={styles["guests-view__item"]}>
          <p className={styles["guests-view__item-title"]}>{t("view-guests.comment")}</p>
          {guestData?.comment ? (
            <p className={styles["guests-view__item-content"]}>{guestData?.comment}</p>
          ) : (
            "–"
          )}
        </div>
      </div>
      <Button
        disabled={isPending}
        onClick={handleEdit}
        icon={<Edit />}
        className={styles["guests-view__btn"]}
      >
        {t("view-guests.edit")}
      </Button>
    </div>
  );
};

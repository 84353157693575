import { FC } from "react";
import { TimePicker as AntdTimePicker, TimePickerProps } from "antd";
import classNames from "classnames";

import { TIME_FORMAT } from "@/constants/global";

import styles from "./TimePicker.module.scss";

import { Icon } from "../Icon/Icon";

export const TimePicker: FC<TimePickerProps> = ({
  className,
  ...rest
}) => {
  return (
    <AntdTimePicker
      data-testid="timepicker"
      size="large"
      format={TIME_FORMAT}
      suffixIcon={<Icon icon="calendar" />}
      className={classNames(styles["host-time-picker"], className)}
      {...rest}
    />
  );
};

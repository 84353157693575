export const ROUTES = {
  INDEX: "/",
  AUTH: "/auth",
  SIGN_IN: "/sign-in",
  SIGN_UP: "/sign-up",
  RESET_PASSWORD: "/reset-password",
  SET_NEW_PASSWORD: "/:token/set-new-password",
  HOTEL_SETUP: "/hotel-setup",
  DASHBOARD: "dashboard",
  SETTINGS: "settings",
  BOOKINGS: "bookings",
  GUESTS: "guests",
  ALL_LOCATIONS: "all-locations",
  LOCATIONS: "locations",
  USER_PROFILE: "user-profile",
  LOCATION: "location",
  PRICE_SETUP: "price-setup",
  SEASON_TARIFF: "tariff",
  PAYMENT_OPTIONS: "payment-options",
  PAYMENT_HISTORY: "payment-history",
  TERMS_OF_CONDITION: "terms-of-condition",
  PRIVACY_POLICY: "privacy-policy",
  UPDATE_PASSWORD: "update-password",
  BOOKING_RULES: "booking-rules",
  WIDGET: "widget",
  CREATE: "create",
  EDIT: "edit",
  VIEW: "view",
  BLOCKED: "blocked",
  APP: "app",
} as const;

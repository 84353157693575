import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Form } from "antd";

import { FormItem, Icon, Input, Loader } from "@/elements";

import { useCurrentUser } from "@/react-queries";
import { useUpdateHotelBookingRules } from "@/react-queries/hotel/useUpdateHotelBookingRules";

import { useYupSync } from "@/hooks";

import { ROUTES } from "@/constants";

import { bookingRulesUpdate } from "./BookingHotelRulesUpdate.schema";

import { IHotelBookingRules } from "@/types";

import styles from "./BookingHotelRulesUpdate.module.scss";

import { RootState } from "@/store";
import { setHeaderNavigation } from "@/store/header/header.slice";

const { SETTINGS } = ROUTES;

export const BookingHotelRulesUpdate = () => {
  const [form] = Form.useForm<IHotelBookingRules>();
  const yupSync = useYupSync(bookingRulesUpdate);
  const activeHotel = useSelector((state: RootState) => state.hotel.activeHotel);
  const [disableButton, setDisableButton] = useState<boolean>(true);
  const dispatch = useDispatch();
  const { hotelId } = useParams();
  const { data: currentUser, isLoading: isCurrentUserLoading } = useCurrentUser();

  const { t } = useTranslation();

  const { mutate: handleUpdateBookingRules, isLoading: isUpdateHotelBookingRules } =
    useUpdateHotelBookingRules();

  const handleSubmit = (values: IHotelBookingRules) => {
    handleUpdateBookingRules({
      maxGuests: +values.maxGuests,
      minNights: values.minNights ? +values.minNights : null
    });
  };

  const handleValueUpdate = (_: IHotelBookingRules, values: IHotelBookingRules) => {
    if (activeHotel) {
      for (const key of Object.keys(values)) {
        const conditionCompare =
          activeHotel &&
          Number(values[key as keyof IHotelBookingRules]) !==
            activeHotel[key as keyof IHotelBookingRules];
        const conditionEmpty = activeHotel &&
        !values[key as keyof IHotelBookingRules] &&
          !activeHotel[key as keyof IHotelBookingRules]

        if (conditionEmpty) {
          setDisableButton(true);
          break;
        }
        if (conditionCompare) {
          setDisableButton(false);
          break;
        }

        setDisableButton(true);
      }
    }
  };

  useEffect(() => {
    if (activeHotel) {
      form.setFieldsValue({
        maxGuests: activeHotel.maxGuests,
        minNights: activeHotel.minNights ?? null
      });
    }
    if (currentUser) {
      dispatch(
        setHeaderNavigation({
          navigation: {
            title: t("settings.title"),
            link: `/${hotelId}/${SETTINGS}`
          }
        })
      );
    }
  }, [currentUser]);

  if (isCurrentUserLoading || isUpdateHotelBookingRules) {
    return <Loader isFullScreen />;
  }

  return (
    <div className={styles["booking-hotel-rules__container"]}>
      <h1 className={styles["booking-hotel-rules__title"]}>{t("booking.rules.title")}</h1>
      <Form
        onValuesChange={handleValueUpdate}
        onFinish={handleSubmit}
        form={form}
        className={styles["booking-hotel-rules__content"]}
        layout="vertical"
      >
        <div className={styles["booking-hotel-rules__wrapper"]}>
          <FormItem className={styles["booking-hotel-rules__content-item"]} name="maxGuests" required label={t("booking.rules.max-guests")} rules={[yupSync]}>
            <Input type="number" min={1} />
          </FormItem>
          <FormItem className={styles["booking-hotel-rules__content-item"]} name="minNights" label={t("booking.rules.min-nights")} rules={[yupSync]}>
            <Input type="number" min={1} />
          </FormItem>
          <div className={styles["booking-hotel-rules__item"]}>
            <Icon icon="infoOutline" className={styles["booking-hotel-rules__icon"]} />
            <span className={styles["booking-hotel-rules__text"]}>
              {t("booking.rules.describe-min-nights")}
            </span>
          </div>

          <Button
            disabled={disableButton}
            loading={isUpdateHotelBookingRules}
            type="primary"
            className={styles["booking-hotel-rules__button"]}
            onClick={form.submit}
          >
            {t("booking.rules.save")}
          </Button>
        </div>
      </Form>
    </div>
  );
};

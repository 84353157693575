import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Col, Divider, Form, Input as AntdInput, Row } from "antd";
import dayjs from "dayjs";

import { DatePicker, FormItem, Icon } from "@/elements";

import { useCheckBlockedDates } from "@/react-queries/booking/useCheckBlockedDates";
import { useGetBlockedBooking } from "@/react-queries/booking/useGetBlockedBooking";
import { useUpdateBlockedBooking } from "@/react-queries/booking/useUpdateBlockedData";

import { useYupSync } from "@/hooks";

import { handleDatePicker, handleScroll } from "@/helpers";

import { DATE_FORMAT, ScrollTypes } from "@/constants/global";

import { bookingBlockSchema } from "./BookingBlockEdit.schema";

import styles from "./BookingBlockEdit.module.scss";

import { IBookingBlock } from "./BookingBlockEdit.type";

import { setIsShowBlockedCancelModal, setIsShowBlockedModal } from "@/store/booking/booking.slice";

const { TextArea } = AntdInput;

export const BookingBlockEditContent = () => {
  const { bookingId } = useParams();
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const yupSync = useYupSync(bookingBlockSchema);
  const dispatch = useDispatch();
  const [checkinDate, setCheckinDate] = React.useState<string | null>("");
  const { error: validateError, mutate: handleCheckDates } = useCheckBlockedDates();

  const { mutate: handleBlockDate } = useUpdateBlockedBooking();

  const { data: blockedData } = useGetBlockedBooking(bookingId || "", {
    enabled: !!bookingId
  });

  const isValidDates = useMemo(() => {
    return !validateError;
  }, [validateError]);

  const handleValueChange = (currentValue: IBookingBlock, values: IBookingBlock) => {
    const isCheckInDateKey = Object.keys(currentValue).includes("checkinDate");
    const isCheckOutDateKey = Object.keys(currentValue).includes("checkoutDate");

    if (isCheckInDateKey) {
      setCheckinDate(dayjs(values.checkinDate).format(DATE_FORMAT));
    }

    if (values.checkinDate && values.checkoutDate) {
      if (isCheckOutDateKey || isCheckInDateKey) {
        handleCheckDates({
          id: blockedData?.id,
          checkinDate: dayjs(values.checkinDate).format(DATE_FORMAT),
          checkoutDate: dayjs(values.checkoutDate).format(DATE_FORMAT)
        });
      }
      if (!dayjs(values.checkinDate).isBefore(values.checkoutDate)) {
        form.setFieldsValue({
          checkoutDate: values.checkinDate,
          checkinDate: values.checkoutDate
        });
      }
      if (dayjs(values.checkinDate).isSame(values.checkoutDate, "day")) {
        form.setFieldsValue({
          checkoutDate: dayjs(values.checkoutDate).add(1, "day") as unknown as string
        });
      }
    }
  };

  const handleSubmit = (data: IBookingBlock) => {
    if (bookingId) {
      handleBlockDate({
        id: Number(bookingId),
        checkinDate: dayjs(data.checkinDate).format(DATE_FORMAT),
        checkoutDate: dayjs(data.checkoutDate).format(DATE_FORMAT),
        notes: data.notes
      });
    }
    dispatch(setIsShowBlockedModal(false));
    handleScroll(ScrollTypes.REMOVE);
  };

  const handleOpenCancellationModal = () => {
    dispatch(setIsShowBlockedCancelModal(true));
  };

  useEffect(() => {
    if (blockedData) {
      form.setFieldsValue({
        checkoutDate: dayjs(blockedData.checkoutDate, DATE_FORMAT),
        checkinDate: dayjs(blockedData.checkinDate, DATE_FORMAT),
        notes: blockedData.notes
      });
    }
  }, [blockedData]);

  return (
    <Form
      onValuesChange={handleValueChange}
      form={form}
      name="booking-blocked"
      onFinish={handleSubmit}
      layout="vertical"
      scrollToFirstError
      className={styles["booking-block__form"]}
    >
      <div className={styles["booking-block__container"]}>
        <Row className={styles["booking-block__item"]} gutter={24}>
          <Col xs={12}>
            <FormItem
              required
              name="checkinDate"
              label={t("booking.blocked.check-in")}
              rules={[yupSync]}
            >
              <DatePicker
                onChange={(date) => handleDatePicker(date, form)}
                inputReadOnly
                suffixIcon={<Icon icon="calendar" />}
              />
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem
              required
              name="checkoutDate"
              label={t("booking.blocked.check-out")}
              rules={[yupSync]}
            >
              <DatePicker
                allowClear={false}
                minDate={dayjs(checkinDate)}
                inputReadOnly
                suffixIcon={<Icon icon="calendar" />}
              />
            </FormItem>
          </Col>
        </Row>
        <Divider className={styles["booking-block__divider"]} />
        <h1 className={styles["booking-block__title"]}>{t("booking.blocked.block-desc-title")}</h1>
        <div className={styles["booking-block__textarea"]}>
          <FormItem name="notes" label={t("booking.blocked.comment")} rules={[yupSync]}>
            <TextArea className={styles["booking-block__textarea-field"]} maxLength={200} rows={6} autoSize />
          </FormItem>
        </div>
        <Divider className={styles["booking-block__divider"]} />
        <div className={styles["booking-block__btns"]}>
          <Button
            className={styles["booking-block__submit-btn"]}
            type="primary"
            htmlType="submit"
            disabled={!isValidDates}
          >
            {t("booking.blocked.edit-use-changes")}
          </Button>
          <Button
            className={styles["booking-block__btn"]}
            ghost
            danger
            type="primary"
            onClick={handleOpenCancellationModal}
          >
            {t("booking.blocked.edit-decline-changes")}
          </Button>
        </div>
      </div>
    </Form>
  );
};

import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { api } from "@/helpers";

import { IBookingTransactions, TQueryOptions } from "@/types";

const fetcher = async (bookingId: string) => {
  return api.get(`bookings/${bookingId}/transactions`);
};

export const useGetTransaction = <TQueryFnData = IBookingTransactions[], TData = IBookingTransactions[]>(
  bookingId: string,
  options?: TQueryOptions<TQueryFnData, TData>
) => {

  return useCustomQuery<TQueryFnData, TData>(["get-booking-transactions"], () => fetcher(bookingId), {
    ...options,
    staleTime: Infinity,
  });
};

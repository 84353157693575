export const SINGLE_GUEST = 1;

export enum CalendarTypes {
    "MONTHLY" = "monthly",
    "LIST" = "list",
    "ALL" = "all"
}

export enum CalendarListViewType {
    "COLLAPSE" = "collapse",
    "EXPAND" = "expand"
}

export enum CalendarDayPriceStage {
    CHANGES = "changes",
    CONFIRMED = "confirmed",
}

import { useSelector } from "react-redux";

import { IGuest } from "@/components/Guest/GuestList/GuestList.type";

import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { allLocationsIdStorageService, api, handleHotelIdInApi } from "@/helpers";

import { AllLocationType } from "@/constants/global";

import { TQueryOptions } from "@/types";

import { RootState } from "@/store";

const { getItem: getAllLocationStatus } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

const fetcher = async (
  data: { searchParams: string; hotelId?: number | string | null | undefined } | undefined
) => {
  const isAllLocation = getAllLocationStatus();
  const conditionData = isAllLocation ? data : handleHotelIdInApi(data);

  return api.get("guests", conditionData);
};

export const useGetGuests = <TQueryFnData = IGuest[], TData = IGuest[]>(
  params?: {
    searchParams: string;
  },
  options?: TQueryOptions<TQueryFnData, TData>
) => {
  const isAllLocation = getAllLocationStatus();
  const chosenFromCalendarLocationId = useSelector(
    (state: RootState) => state.booking.choosenCalendarBooking.hotelId
  );
  const chosenLocationId = useSelector(
    (state: RootState) => state.booking.allLocation.chosenLocationId
  );
  const conditionHotelId =
    chosenLocationId || chosenFromCalendarLocationId
      ? { hotelId: chosenLocationId || String(chosenFromCalendarLocationId) }
      : {};
  const conditionData = isAllLocation
    ? {
        ...params,
        ...conditionHotelId,
        searchParams: params?.searchParams || ""
      }
    : params;

  return useCustomQuery<TQueryFnData, TData>(["get-guests"], () => fetcher(conditionData), {
    ...options,
  });
};

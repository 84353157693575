import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IGuest } from "@/components/Guest/GuestList/GuestList.type";

import {
  BookingCreateSteps,
  FilterCounter,
  FilterPaymentStatus,
  FilterTabValues,
  SegmentTypes,
  SortFilterValues,
  SortOrderValues
} from "@/constants/bookings/bookings.constant";
import { BOOKING_COUNT_PER_PAGE } from "@/constants/global";

import { IBooking, IBookingFilterParams } from "@/types";

const searchParams = new URLSearchParams(location.search);

export interface BookingSlice {
  main: {
    isShowFilter: boolean;
    isShowActionButtonContent: boolean;
    isShowBookingForm: boolean;
    isShowBookingGuestList: boolean;
    isBookingCreated: boolean;
    isDiscardBookingCreation: boolean;
  };
  filters: {
    page?: number;
    perPage?: number;
    sortField: SortFilterValues | null;
    sortOrder: SortOrderValues | null;
    tab: FilterTabValues | null;
    guestsCount: FilterCounter | null;
    nightsCount: FilterCounter | null;
    paymentStatus: FilterPaymentStatus | null;
  };
  creation: {
    createdBookingId: string | null;
    handleDisabledSubmitButton: boolean;
    desktopStep: BookingCreateSteps;
    switchType: SegmentTypes;
  };
  blocked: {
    isShowBlocked: boolean;
    isShowBlockedEditModal: boolean;
    isShowBlockedCancelModal: boolean;
  };
  guest: IGuest | null;
  view: {
    isDeclineModal: boolean;
    isApproveModal: boolean;
    chosenBooking: null | IBooking;
  };
  edit: {
    isShowEditModal: boolean;
    isShowDateError: boolean;
  };
  payment: {
    isShowPaymentModal: boolean;
  };
  choosenCalendarBooking: {
    date: string | null;
    totalPrice: number | null;
    hotelId?: number | null;
  };
  isShowEditPriceModal: boolean;
  allLocation: {
    chosenLocationId: number | string | null;
  };
}

const initialState: BookingSlice = {
  main: {
    isShowFilter: false,
    isShowActionButtonContent: false,
    isShowBookingForm: false,
    isShowBookingGuestList: false,
    isBookingCreated: false,
    isDiscardBookingCreation: false
  },
  filters: {
    page: Number(searchParams.get("page")) || 1,
    perPage: BOOKING_COUNT_PER_PAGE,
    sortField: "checkinDate" as SortFilterValues,
    sortOrder: "ASC" as SortOrderValues,
    tab: (searchParams.get("activeTab") as FilterTabValues) || FilterTabValues.NOT_APPROVED,
    guestsCount: null,
    nightsCount: null,
    paymentStatus: null
  },
  blocked: {
    isShowBlocked: false,
    isShowBlockedEditModal: false,
    isShowBlockedCancelModal: false
  },
  creation: {
    createdBookingId: null,
    handleDisabledSubmitButton: false,
    desktopStep: BookingCreateSteps.CREATE,
    switchType: SegmentTypes.CREATE
  },
  guest: null,
  view: {
    isDeclineModal: false,
    isApproveModal: false,
    chosenBooking: null
  },
  edit: {
    isShowEditModal: false,
    isShowDateError: false
  },
  payment: {
    isShowPaymentModal: false
  },
  choosenCalendarBooking: {
    date: null,
    totalPrice: null,
    hotelId: null
  },
  isShowEditPriceModal: false,
  allLocation: {
    chosenLocationId: null
  }
};

export const bookingSlice = createSlice({
  name: "booking",
  initialState,
  reducers: {
    setHandleBookingFilterSection: (state, action: PayloadAction<boolean>) => {
      state.main.isShowFilter = action.payload;
    },
    setHandleBookingActionButtonContent: (state, action: PayloadAction<boolean>) => {
      state.main.isShowActionButtonContent = action.payload;
    },
    setHandleBookingBlockedSection: (state, action: PayloadAction<boolean>) => {
      state.blocked.isShowBlocked = action.payload;
    },
    setHandleChooseBooking: (state, action: PayloadAction<IBooking | null>) => {
      state.view.chosenBooking = action.payload;
    },
    setHandleBookingCreation: (state, action: PayloadAction<boolean>) => {
      state.main.isBookingCreated = action.payload;
    },
    setHandleDiscardBookingCreation: (state, action: PayloadAction<boolean>) => {
      state.main.isDiscardBookingCreation = action.payload;
    },
    setBookingCreatedData: (state, action: PayloadAction<string>) => {
      state.creation.createdBookingId = action.payload;
    },
    setHandleBookingCreationStep: (state, action: PayloadAction<BookingCreateSteps>) => {
      state.creation.desktopStep = action.payload;
    },
    setHandleAllLocationId: (
      state,
      action: PayloadAction<BookingSlice["allLocation"]["chosenLocationId"]>
    ) => {
      state.allLocation.chosenLocationId = action.payload;
    },
    setIsShowBlockedCancelModal: (state, action: PayloadAction<boolean>) => {
      state.blocked.isShowBlockedCancelModal = action.payload;
    },
    setIsShowPaymentModal: (state, action: PayloadAction<boolean>) => {
      state.payment.isShowPaymentModal = action.payload;
    },
    setFilterValues: (state, action: PayloadAction<IBookingFilterParams>) => {
      state.filters = action.payload;
    },
    setHandleBookingFormCreate: (state, action: PayloadAction<boolean>) => {
      state.main.isShowBookingForm = action.payload;
    },
    setDisabledSubmitButton: (state, action: PayloadAction<boolean>) => {
      state.creation.handleDisabledSubmitButton = action.payload;
    },
    setHandleBookingGuestList: (state, action: PayloadAction<boolean>) => {
      state.main.isShowBookingGuestList = action.payload;
    },
    setHandleSwitchType: (state, action: PayloadAction<SegmentTypes>) => {
      state.creation.switchType = action.payload;
    },
    setDeclineModal: (state, action: PayloadAction<boolean>) => {
      state.view.isDeclineModal = action.payload;
    },
    setIsShowErrorDate: (state, action: PayloadAction<boolean>) => {
      state.edit.isShowDateError = action.payload;
    },
    setApproveModal: (state, action: PayloadAction<boolean>) => {
      state.view.isApproveModal = action.payload;
    },
    setEditModalStatus: (state, action: PayloadAction<boolean>) => {
      state.edit.isShowEditModal = action.payload;
    },
    setGuest: (state, action: PayloadAction<IGuest>) => {
      state.guest = action.payload;
    },
    setIsShowBlockedModal: (state, action: PayloadAction<boolean>) => {
      state.blocked.isShowBlockedEditModal = action.payload;
    },
    setChoosenCalendarBooking: (
      state,
      action: PayloadAction<BookingSlice["choosenCalendarBooking"]>
    ) => {
      state.choosenCalendarBooking = action.payload;
    },
    clearChoosenCalendarBooking: (state) => {
      state.choosenCalendarBooking = {
        date: null,
        totalPrice: null,
        hotelId: null
      };
    },
    setHandleEditPriceModal: (state, action: PayloadAction<boolean>) => {
      state.isShowEditPriceModal = action.payload;
    },
    clearFilterValues: (state) => {
      state.filters = {
        sortField: null,
        sortOrder: null,
        tab: FilterTabValues.NOT_APPROVED,
        guestsCount: null,
        nightsCount: null,
        paymentStatus: null
      };
    },
    clearBookingStates: (state) => {
      state.main = {
        isShowFilter: false,
        isShowActionButtonContent: false,
        isShowBookingForm: false,
        isShowBookingGuestList: false,
        isBookingCreated: false,
        isDiscardBookingCreation: false
      };
      state.creation = {
        createdBookingId: null,
        handleDisabledSubmitButton: false,
        desktopStep: BookingCreateSteps.CREATE,
        switchType: SegmentTypes.CREATE
      };
    },
    clearGuest: (state) => {
      state.guest = null;
    }
  }
});

export const {
  setHandleBookingFilterSection,
  setHandleBookingActionButtonContent,
  setHandleBookingFormCreate,
  setHandleBookingGuestList,
  setHandleBookingCreation,
  setHandleDiscardBookingCreation,
  setHandleBookingBlockedSection,
  setBookingCreatedData,
  setDisabledSubmitButton,
  setHandleChooseBooking,
  setIsShowErrorDate,
  setIsShowBlockedModal,
  setIsShowBlockedCancelModal,
  setIsShowPaymentModal,
  setFilterValues,
  setDeclineModal,
  setApproveModal,
  setGuest,
  clearGuest,
  clearBookingStates,
  clearFilterValues,
  setEditModalStatus,
  setChoosenCalendarBooking,
  clearChoosenCalendarBooking,
  setHandleEditPriceModal,
  setHandleAllLocationId,
  setHandleBookingCreationStep,
  setHandleSwitchType
} = bookingSlice.actions;

export default bookingSlice.reducer;

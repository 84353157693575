import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useParams } from "react-router-dom";

import { BookingList } from "@/components";

import { Icon, Title } from "@/elements";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { handleScroll } from "@/helpers";

import { ScrollTypes } from "@/constants/global";

import styles from "./Booking.module.scss";

import { RootState } from "@/store";
import { setHandleBookingFilterSection } from "@/store/booking/booking.slice";

export const Booking = () => {
  const { bookingId } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useGetScreenStatus();
  const bookingFilters = useSelector((state: RootState) => state.booking.filters);

  const isFiltersChosen = useMemo(() => {
    const { nightsCount, guestsCount, paymentStatus, sortField, sortOrder } = bookingFilters;

    return (
      !!nightsCount ||
      !!guestsCount ||
      !!paymentStatus ||
      sortField !== "checkinDate" ||
      (sortOrder !== "ASC" && sortField === "checkinDate")
    );
  }, [bookingFilters]);

  const isEdit = !!bookingId;

  const handleFilterSection = () => {
    dispatch(setHandleBookingFilterSection(true));
    if (isMobile) {
      handleScroll(ScrollTypes.ADD);
    }
  };

  if (isEdit) {
    return <Outlet />;
  }

  return (
    <div className={styles.booking__wrapper}>
      <div className={styles.booking__container}>
        <div className={styles.booking__header}>
          <Title level={3} className={styles.booking__title}>
            {t("booking.title")}
          </Title>
          {isMobile ? (
            <div className={styles.booking__icon} onClick={handleFilterSection}>
              <Icon icon="slider" />
            </div>
          ) : (
            <div
              className={`${styles.booking__icon} ${isFiltersChosen ? styles.active : null}`}
              onClick={handleFilterSection}
            >
              <Icon icon="slider" />
              <span className={styles["booking__icon-text"]}>{t("booking.button-filter")}</span>
            </div>
          )}
        </div>
        <div className={styles.booking__content}>
          <BookingList />
        </div>
      </div>
    </div>
  );
};

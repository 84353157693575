import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import FullCalendar from "@fullcalendar/react";
import { Popover } from "antd";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import weekOfYear from "dayjs/plugin/weekOfYear";

import { BookingCreated } from "@/components";
import { AllLocationCalendar } from "@/components/AllLocationCalendar/AllLocationCalendar";
import { BookingEditPriceModal } from "@/components/Booking/BookingEditPriceModal/BookingEditPriceModal";
import { BookingAction } from "../../components/Booking/BookingAction/BookingAction";
import { CalendarListView } from "../../components/Calendar/CalendarListView/CalendarListView";
import { CalendarMonthly } from "../../components/Calendar/CalendarMonthly/CalendarMonthly";
import { CalendarSelectionModal } from "../../components/Calendar/CalendarSelectionModal/CalendarSelectionModal";
import { CalendarSwitch } from "../../components/Calendar/CalendarSwitch/CalendarSwitch";
import { BookingBlock } from "../../components/Forms/BookingBlock/BookingBlock";
import { BookingCreate } from "../../components/Forms/BookingCreate/BookingCreate";

import { Icon, Loader } from "@/elements";
import { CalendarMonthChooser } from "@/elements/CalendarMonthChooser/CalendarMonthChooser";

import { useGetAllLocations } from "@/react-queries/hotel/useGetAllLocation";

import { useGetDaysInMonth } from "@/hooks/booking/useGetDaysInMonth";
import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { allLocationsIdStorageService, handleScroll, langStorageService } from "@/helpers";

import { CalendarTypes } from "@/constants/calendar/calendar.constants";
import {
  AllLocationType,
  DATE_FORMAT,
  FULL_DATE_FORMAT,
  LanguageType,
  ScrollTypes
} from "@/constants/global";

import styles from "./Calendar.module.scss";

import { RootState } from "@/store";
import {
  setCalendarDate,
  setCalendarType,
  setHandleMonthSwitcher,
  setHandleSelectingCalendarModal,
  setHandleShowModalSwitchType
} from "@/store/calendar/calendar.slice";

dayjs.extend(weekOfYear);
dayjs.extend(isBetween);

const { getItem: getAllLocation } = allLocationsIdStorageService(AllLocationType.ALL_LOCATIONS_ID);
const { getItem: getUserLang } = langStorageService<string>(LanguageType.LANG);

export const Calendar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isCalendarActive, setIsCalendarActive] = useState(false);
  const calendarRef = React.useRef<FullCalendar | null>(null);
  const calendarDate = useSelector((state: RootState) => state.calendar.calendarDate);
  const isShowCalendarMonthSwitcher = useSelector((state: RootState) => state.calendar.isShowMonthSwitcher);
  const [userActiveLang, setUserActiveLang] = useState(getUserLang());
  const isBookingCreated = useSelector((state: RootState) => state.booking.main.isBookingCreated);
  const isMobile = useGetScreenStatus();
  const isAllLocation = getAllLocation();
  const { data: allLocations, isLoading: isAllLocationsLoading } = useGetAllLocations({
    enabled: !!isAllLocation
  });
  const [isSticky, setSticky] = useState(false);
  const { t } = useTranslation();

  const { dates, handleDates } = useGetDaysInMonth();

  const isShowModalSwitchType = useSelector(
    (state: RootState) => state.calendar.isShowSwitchTypeModal
  );
  const isShowBookingCreate = useSelector(
    (state: RootState) => state.booking.main.isShowBookingForm
  );
  const isShowBlockBooking = useSelector((state: RootState) => state.booking.blocked.isShowBlocked);
  const isShowBookingAction = useSelector(
    (state: RootState) => state.booking.main.isShowActionButtonContent
  );
  const isShowEditPriceModal = useSelector(
    (state: RootState) => state.booking.isShowEditPriceModal
  );
  const calendarViewType = useSelector((state: RootState) => state.calendar.calendarType);
  const isShowSelectionModal = useSelector(
    (state: RootState) => state.calendar.isShowSelectionCalendarModal
  );
  const conditionShowFooter =
    isCalendarActive || (!isAllLocation && calendarViewType !== CalendarTypes.LIST);

  const handleOpenSwitchTypeModal = () => {
    dispatch(setHandleShowModalSwitchType(true));
    handleScroll(ScrollTypes.ADD);
  };

  const handleOpenSelectionModal = () => {
    dispatch(setHandleSelectingCalendarModal(true));
    handleScroll(ScrollTypes.ADD);
  };

  const handleListNextMonth = () => {
    const nextMonth = dayjs(calendarDate, FULL_DATE_FORMAT)
      .add(1, "month")
      .format(FULL_DATE_FORMAT);
    dispatch(setCalendarDate(nextMonth));
  };

  const handleListPrevMonth = () => {
    const prevMonth = dayjs(calendarDate, FULL_DATE_FORMAT)
      .subtract(1, "month")
      .format(FULL_DATE_FORMAT);
    dispatch(setCalendarDate(prevMonth));
  };

  const handleMonthlyNextMonth = () => {
    calendarRef.current?.getApi().next();
    handleListNextMonth();
  };

  const handleMonthlyPrevMonth = () => {
    calendarRef.current?.getApi().prev();
    handleListPrevMonth();
  };

  const conditionForNextMonth = () => {
    if (calendarViewType === CalendarTypes.LIST) {
      return handleListNextMonth();
    }

    return handleMonthlyNextMonth();
  };

  const conditionForPrevMonth = () => {
    if (calendarViewType === CalendarTypes.LIST) {
      return handleListPrevMonth();
    }

    return handleMonthlyPrevMonth();
  };

  const conditionForCurrentMonthShow = () => {
    return dispatch(setCalendarDate(dayjs().format(FULL_DATE_FORMAT)));
  };

  useEffect(() => {
    if (!calendarDate) {
      dispatch(
        setCalendarDate(
          dayjs()
            .locale(userActiveLang as string)
            .format(FULL_DATE_FORMAT)
        )
      );
    } else {
      calendarRef.current
        ?.getApi()
        .gotoDate(dayjs(calendarDate, FULL_DATE_FORMAT).format(DATE_FORMAT));
      handleDates();
    }
  }, [calendarDate, calendarViewType]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const typeParam = searchParams.get("type");

    const navigateWithType = (type: CalendarTypes) => {
      dispatch(setCalendarType(type));
      navigate({ search: `?type=${type}` });
    };

    if (!typeParam) {
      navigateWithType(isAllLocation ? CalendarTypes.ALL : CalendarTypes.MONTHLY);
    } else {
      switch (calendarViewType) {
        case CalendarTypes.MONTHLY:
          navigateWithType(isAllLocation ? CalendarTypes.ALL : CalendarTypes.MONTHLY);
          break;
        case CalendarTypes.LIST:
          if (!isAllLocation) {
            navigateWithType(CalendarTypes.LIST);
          }
          break;
        default:
          return;
      }
    }

    setUserActiveLang(getUserLang());
  }, [location.pathname]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const typeParam = searchParams.get("type");

    if (!isMobile && CalendarTypes.LIST === typeParam) {
      dispatch(setCalendarType(CalendarTypes.MONTHLY));
    }
    if (isMobile && CalendarTypes.LIST === typeParam) {
      dispatch(setCalendarType(CalendarTypes.LIST));
    }
  }, [isMobile]);

  const renderContent = () => {
    if (calendarViewType === CalendarTypes.LIST) {
      return (
        <CalendarListView
          setSticky={setSticky}
          calendarDate={calendarDate || ""}
          handleNextMonth={handleMonthlyNextMonth}
          handlePrevMonth={handleMonthlyPrevMonth}
        />
      );
    }

    if (isAllLocation) {
      return (
        <AllLocationCalendar
          checkIsContentActive={setIsCalendarActive}
          calendarDate={calendarDate || ""}
          handleNextMonth={handleMonthlyNextMonth}
          handlePrevMonth={handleMonthlyPrevMonth}
        />
      );
    }

    return (
      <CalendarMonthly
        dates={dates}
        calendarRef={calendarRef}
        handleNextMonth={handleMonthlyNextMonth}
        handlePrevMonth={handleMonthlyPrevMonth}
      />
    );
  };

  if (isAllLocationsLoading) {
    return <Loader isFullScreen />;
  }

  return (
    <div className={styles.calendar__wrapper}>
      {isAllLocation ? (
        <div className={styles.calendar__sidebar}>
          <div className={styles["calendar__sidebar-header"]}>
            <div className={styles["calendar__sidebar-title"]}>
              {t("locations.dashboard.title")}
            </div>
          </div>
          <div className={styles["calendar__sidebar-content"]}>
            {allLocations?.map((location) => (
              <div key={location.id} className={styles["calendar__sidebar-item"]}>
                <img
                  src={location.locationImage}
                  className={styles["calendar__sidebar-item-icon"]}
                />
                <div className={styles["calendar__sidebar-item-name"]}>{location.publicLabel}</div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <div
        className={`${styles.calendar__content} ${isAllLocation ? styles["all-locations"] : null}`}
      >
        <div className={`${styles["calendar__header-wrapper"]}`}>
          <div className={`${styles.calendar__header} ${isSticky ? styles.sticky : ""}`}>
            {isMobile ? (
              <div className={styles["calendar__header-date"]} onClick={handleOpenSelectionModal}>
                <div className={`${styles["calendar__header-button"]} ${styles.month}`}>
                  <Icon icon="calendar" />
                </div>
                {isAllLocation ? null : <p>{calendarDate}</p>}
              </div>
            ) : (
              <Popover open={isShowCalendarMonthSwitcher} onOpenChange={(value: boolean) => dispatch(setHandleMonthSwitcher(value))} content={<CalendarMonthChooser />} trigger="click">
                <div className={styles["calendar__header-date"]}>
                  <div className={`${styles["calendar__header-button"]} ${styles.month}`}>
                    <Icon icon="calendar" />
                  </div>
                  {isAllLocation ? null : <p>{calendarDate}</p>}
                </div>
              </Popover>
            )}
            <div className={styles["calendar__header-buttons"]}>
              {!isAllLocation ? (
                <div
                  className={`${styles["calendar__header-button"]} ${styles.calendar}`}
                  onClick={handleOpenSwitchTypeModal}
                >
                  <Icon icon="dashboard" />
                </div>
              ) : null}
              <div className={styles["calendar__header-months"]}>
                {!isMobile ? (
                  <div
                    onClick={conditionForCurrentMonthShow}
                    className={styles["calendar__header-today-button"]}
                  >
                    {t("calendar.today-button")}
                  </div>
                ) : null}

                <div onClick={conditionForPrevMonth} className={styles["calendar__header-button"]}>
                  <Icon icon="arrowLeft" />
                </div>
                <div onClick={conditionForNextMonth} className={styles["calendar__header-button"]}>
                  <Icon icon="arrowLeft" className={styles["arrow-right"]} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {renderContent()}
        {conditionShowFooter ? (
          <div className={styles.calendar__footer}>
            <div className={styles["calendar__footer-item"]}>
              <span className={`${styles["calendar__footer-icon"]} ${styles.booked}`}></span>
              <div className={styles["calendar__footer-description"]}>
                {t("locations.calendar.booked")}
              </div>
            </div>
            <div className={styles["calendar__footer-cell"]}>
              <div className={styles["calendar__footer-item"]}>
                <span className={`${styles["calendar__footer-icon"]} ${styles.request}`}></span>
                <div className={styles["calendar__footer-description"]}>
                  {t("locations.calendar.request")}
                </div>
              </div>
              <div className={styles["calendar__footer-item"]}>
                <span className={`${styles["calendar__footer-icon"]} ${styles.last}`}></span>
                <div className={styles["calendar__footer-description"]}>
                  {t("locations.calendar.last")}
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
      {isShowSelectionModal && <CalendarSelectionModal />}
      {isShowModalSwitchType && <CalendarSwitch />}
      {isShowBookingAction && <BookingAction />}
      {isShowBookingCreate && <BookingCreate />}
      {isBookingCreated && <BookingCreated />}
      {isShowBlockBooking && <BookingBlock />}
      {isShowEditPriceModal && <BookingEditPriceModal />}
    </div>
  );
};

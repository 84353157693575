import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Typography } from "antd";

import { Icon } from "@/elements";

import { ROUTES } from "@/constants";
import { FooterType } from "@/constants/global";

import styles from "./MainLayoutFooter.module.scss";

import { PriceSetupEditFooter } from "./PriceSetupEditFooter/PriceSetupEditFooter";
import { PriceSetupEditTariffFooter } from "./PriceSetupEditTariffFooter/PriceSetupEditTariffFooter";
import { PriceSetupFooter } from "./PriceSetupFooter/PriceSetupFooter";
import { PriceSetupTariffFooter } from "./PriceSetupTariffFooter/PriceSetupTariffFooter";

import { RootState } from "@/store";

const { Link } = Typography;

const { DASHBOARD, BOOKINGS, GUESTS, SETTINGS } = ROUTES

export const MainLayoutFooter = () => {
  const { pathname } = useLocation();
  const footerContent = useSelector((state: RootState) => state.footer.footerContent);
  const { hotelId } = useParams();

  const { t } = useTranslation();

  const handleIsActiveTab = (tabPathname: string) => {
    const currentPagePathName = pathname.split("/").filter((item) => item)[1];
    const isActive = currentPagePathName.includes(tabPathname);

    if (currentPagePathName === "guests") {
      return `${styles["navigation-item"]} ${isActive ? styles["active-guests"] : ""}`;
    }

    return `${styles["navigation-item"]} ${isActive ? styles.active : ""}`;
  };

  if (footerContent) {
    if (footerContent.type === FooterType.PRICE_SETUP) {
      return <PriceSetupFooter />
    }
    if (footerContent.type === FooterType.PRICE_SETUP_EDIT) {
      return <PriceSetupEditFooter />
    }
    if (footerContent.type === FooterType.PRICE_SETUP_TARIFF) {
      return <PriceSetupTariffFooter />
    }
    if (footerContent.type === FooterType.PRICE_SETUP_EDIT_TARIFF) {
      return <PriceSetupEditTariffFooter />
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Link href={`/${hotelId}/${DASHBOARD}`} className={handleIsActiveTab(DASHBOARD)}>
          <Icon icon="calendar" className={styles["navigation-item__icon"]} />
          <span className={styles["navigation-item__text"]}>{t("navigation.calendar")}</span>
        </Link>
        <Link href={`/${hotelId}/${BOOKINGS}`} className={handleIsActiveTab(BOOKINGS)}>
          <Icon icon="receiptCheck" className={styles["navigation-item__icon"]} />
          <span className={styles["navigation-item__text"]}> {t("navigation.bookings")} </span>
        </Link>
        <Link href={`/${hotelId}/${GUESTS}`} className={handleIsActiveTab(GUESTS)}>
          <Icon icon="outlineUser" className={styles["navigation-item__icon"]} />
          <span className={styles["navigation-item__text"]}> {t("navigation.guests")} </span>
        </Link>
        <Link href={`/${hotelId}/${SETTINGS}`} className={handleIsActiveTab(SETTINGS)}>
          <Icon icon="settingsOutline" className={styles["navigation-item__icon"]} />
          <span className={styles["navigation-item__text"]}> {t("navigation.settings")} </span>
        </Link>
      </div>
    </div>
  );
};

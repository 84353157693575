import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { useCustomMutation } from "@/hooks";

import { api, hotelIdStorageService } from "@/helpers";

import { ROUTES } from "@/constants";
import { HotelType } from "@/constants/global";

import {
  IHotelBookingRules,
  TMutationsOptions
} from "@/types";

const { getItem } = hotelIdStorageService(HotelType.HOTEL_ID);

const fetcher = async (body: IHotelBookingRules): Promise<void> => {
  const activeHotelId = getItem();

  return api.patch(`/host/hotel/${activeHotelId}/rules`, body);
};

const { SETTINGS } = ROUTES;

export const useUpdateHotelBookingRules = (
  options?: TMutationsOptions<void, IHotelBookingRules>
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { hotelId } = useParams();

  const { mutate, ...rest } = useCustomMutation<void, IHotelBookingRules>(
    fetcher,
    {
      ...options,
      onSuccess: async (data, ...args) => {
        options?.onSuccess?.(data, ...args);

        await queryClient.invalidateQueries(["current-user"], { exact: false });

        navigate(`/${hotelId}/${SETTINGS}`);
      }
    }
  );

  return { mutate, ...rest };
};

import { ISeasonTariffForm } from "@/components/Season/SeasonTariff/SeasonTariff.type";

import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { api } from "@/helpers";

import { TQueryOptions } from "@/types";

const fetcher = async (seasonId: string) => {
  return api.get(`seasons/${seasonId}/tariffs`);
};

export const useGetTariffs = <TQueryFnData = ISeasonTariffForm[], TData = ISeasonTariffForm[]>(
  seasonId: string,
  options?: TQueryOptions<TQueryFnData, TData>
) => {
  return useCustomQuery<TQueryFnData, TData>(["get-season-tariffs"], () => fetcher(seasonId), {
    ...options,
    staleTime: Infinity,
  });
};

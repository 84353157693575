import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { api } from "@/helpers";

import { IBooking, TQueryOptions } from "@/types";

const fetcher = async (bookingId: string) => {
  return api.get(`host/bookings/${bookingId}`);
};

export const useGetBooking = <TQueryFnData = IBooking, TData = IBooking>(
  bookingId: string,
  options?: TQueryOptions<TQueryFnData, TData>
) => {

  return useCustomQuery<TQueryFnData, TData>(["get-booking"], () => fetcher(bookingId), {
    ...options,
    staleTime: Infinity,
  });
};

import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useCustomMutation } from "@/hooks";

import { allLocationsIdStorageService, api } from "@/helpers";

import { ROUTES } from "@/constants";
import { AllLocationType } from "@/constants/global";

import { ILocationSettings, ILocationSettingsResponse, TMutationsOptions } from "@/types";

import { setActiveHotel } from "@/store/hotel/hotel.slice";

const { getItem: getAllLocationStatus } = allLocationsIdStorageService(AllLocationType.ALL_LOCATIONS_ID);

const { DASHBOARD, ALL_LOCATIONS, LOCATIONS } = ROUTES;

const fetcher = async (body: ILocationSettings): Promise<ILocationSettingsResponse> => {
  return api.post("/host/hotel", body, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
};

export const useHotelSetup = (
  options?: TMutationsOptions<ILocationSettingsResponse, ILocationSettings>
) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const isAllLocation = getAllLocationStatus();

  const { mutate, ...rest } = useCustomMutation<ILocationSettingsResponse, ILocationSettings>(
    fetcher,
    {
      ...options,
      onSuccess: async (data, ...args) => {
        options?.onSuccess?.(data, ...args);

        await queryClient.invalidateQueries(["current-user"], { exact: false });

        dispatch(setActiveHotel(data));
        localStorage.setItem("activeHotelId", data.id.toString());
        if (isAllLocation) {
          return navigate(`/${ALL_LOCATIONS}/${LOCATIONS}`);
        }
        navigate(`/${data.id}/${DASHBOARD}`);
      }
    }
  );

  return { mutate, ...rest };
};

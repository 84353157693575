import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";

import { Icon } from "@/elements";

import { useGetDaysInMonth } from "@/hooks/booking/useGetDaysInMonth";

import { getCountOfDays, handleScroll } from "@/helpers";

import { ROUTES } from "@/constants";
import { BookingStatus } from "@/constants/bookings/bookings.constant";
import { CalendarListViewType } from "@/constants/calendar/calendar.constants";
import { DATE_FORMAT, ScrollTypes } from "@/constants/global";

import styles from "./CalendarListViewItem.module.scss";

import { getClassNameForEvents } from "../../../containers/Calendar/Calendar.helper";

import { conditionGuests, conditionNights, getEventDays } from "./CalendarListViewItem.helper";
import { ICalendarListViewItem, ICountNights } from "./CalendarListViewItem.type";

import { setChoosenCalendarBooking, setHandleBookingActionButtonContent } from "@/store/booking/booking.slice";

const EVENT_BLOCK_DEFAULT_HEIGHT = 97;

const { BOOKINGS, VIEW, BLOCKED } = ROUTES;

export const CalendarListViewItem: FC<ICalendarListViewItem> = ({
  day,
  reservations,
  handleExpandDates,
  dates,
  dayTotalPrice
}) => {
  const { t } = useTranslation();
  const isActiveDay = dayjs().format(DATE_FORMAT) === day.date;
  const { handleRangeDaysInMonth } = useGetDaysInMonth();
  const navigate = useNavigate();
  const { hotelId } = useParams();
  const dispatch = useDispatch();

  const approvedDays = useMemo(() => {
    return getEventDays({
      reservations,
      handleRangeDaysInMonth,
      withoutStatus: BookingStatus.BLOCKED
    });
  }, [reservations]);

  const blockedDays = useMemo(() => {
    return getEventDays({
      reservations,
      handleRangeDaysInMonth,
      exactStatus: BookingStatus.BLOCKED
    });
  }, [reservations]);

  const conditionSwitch = !day.isExpanded
    ? CalendarListViewType.EXPAND
    : CalendarListViewType.COLLAPSE;
  const reservationEvents = useMemo(() => {
    if (reservations) {
      const getBookingDayInActiveMonth = ({
        reservationCheckinDate,
        resertvationCheckoutDate
      }: {
        reservationCheckinDate: string;
        resertvationCheckoutDate: string;
      }) => {
        const activeMonth = dayjs(dates.fromDate).month();
        if (dayjs(reservationCheckinDate).month() !== activeMonth) {
          const getBookingDays = handleRangeDaysInMonth(
            reservationCheckinDate,
            resertvationCheckoutDate,
            dates
          );

          return {
            bookingDay: getBookingDays[0].date
          };
        }

        return {
          bookingDay: reservationCheckinDate
        };
      };

      return reservations.reduce((acc: ICountNights, reservation) => {
        const { bookingDay } = getBookingDayInActiveMonth({
          reservationCheckinDate: reservation.checkinDate,
          resertvationCheckoutDate: reservation.checkoutDate
        });
        const countOfTotalNights = getCountOfDays({
          fromDate: reservation.checkinDate,
          toDate: reservation.checkoutDate
        });

        const countOfShowDays = getCountOfDays({
          fromDate: bookingDay,
          toDate: reservation.checkoutDate
        });

        acc[bookingDay] = {
          ...reservation,
          nights: countOfTotalNights,
          countOfShowDays
        };

        return acc;
      }, {});
    }

    return {};
  }, [reservations]);

  const handleNavigateToBooking = () => {
    if (reservationEvents[day.date as string].status === BookingStatus.BLOCKED) {
      return navigate(`/${hotelId}/${BOOKINGS}/${reservationEvents[day.date as string].id}/${BLOCKED}`);
    }

    return navigate(`/${hotelId}/${BOOKINGS}/${reservationEvents[day.date as string].id}/${VIEW}`);
  };

  const handleDayClick = ({ date, totalPrice }: { date: string; totalPrice: number | null }) => {

    if (approvedDays.bookingDates.includes(date)) {
      const findDay = approvedDays.data.find((day) => day.bookedDays.includes(date));

      return navigate(`/${hotelId}/${BOOKINGS}/${findDay?.bookingId}/${VIEW}`);
    }

    if (blockedDays.bookingDates.includes(date)) {
      const findDay = blockedDays.data.find((day) => day.bookedDays.includes(date));

      return navigate(`/${hotelId}/${BOOKINGS}/${findDay?.bookingId}/${BLOCKED}`);
    }

    dispatch(
      setChoosenCalendarBooking({
        date,
        totalPrice
      })
    );
    dispatch(setHandleBookingActionButtonContent(true));
    handleScroll(ScrollTypes.ADD);
  };


  if (!day.isDisplay) {
    return null;
  }

  const renderEvents = () => {
    if (reservations && reservationEvents[day.date as string]) {
      const { nights, guestName, status, guestsCount, notes, totalPrice, countOfShowDays } =
        reservationEvents[day.date as string];

      const eventClassName = getClassNameForEvents(reservationEvents[day.date as string]);
      const isBlockedEvent = status === BookingStatus.BLOCKED;
      const conditionEventHeight = !day.isExpanded
        ? EVENT_BLOCK_DEFAULT_HEIGHT
        : EVENT_BLOCK_DEFAULT_HEIGHT * countOfShowDays;

      return (
        <div
          onClick={handleNavigateToBooking}
          style={{ height: `${conditionEventHeight}px` }}
          className={`${styles["list-item__event-content"]} ${styles[eventClassName]}`}
        >
          {isBlockedEvent ? (
            <div className={styles["list-item__event-blocked"]}>
              <div className={styles["list-item__block-description"]}>{notes}</div>
              <div className={styles["list-item__block-icon"]}>
                <Icon icon="lock" />
              </div>
            </div>
          ) : (
            <>
              <div className={styles["list-item__event-title"]}>{guestName}</div>
              <div className={styles["list-item__event-details"]}>
                <div className={styles["list-item__event-guests"]}>
                  {t(conditionGuests(guestsCount || 1) as string, { count: guestsCount })}
                </div>
                <span className={styles["list-item__event-divider"]}></span>
                <div className={styles["list-item__event-nights"]}>
                  {t(conditionNights(nights) as string, { count: nights })}
                </div>
              </div>
              <div className={styles["list-item__event-total-amount"]}>
                {t("calendar.list.event.amount", { count: totalPrice || 0 })}
              </div>
            </>
          )}
        </div>
      );
    }

    return null;
  };

  return (
    <div className={`${styles["list-item__container"]} ${isActiveDay ? styles.active : null}`}>
      <div
        className={styles["list-item__date"]}
        onClick={() =>
          handleDayClick({
            date: dayjs(day.date).format(DATE_FORMAT),
            totalPrice: Number(dayTotalPrice)
          })
        }
      >
        <div className={styles["list-item__date-number"]}>{day.day}</div>
        <div className={styles["list-item__date-day"]}>{day.dayOfWeek}</div>
        <div className={styles["list-item__date-total-price"]}>{dayTotalPrice}</div>
        {day.isShowExpand && (
          <div
            className={styles["list-item__date-expand"]}
            onClick={(e) => handleExpandDates(e, conditionSwitch, day.date)}
          >
            {day.isExpanded ? <Icon icon="collapse" /> : <Icon icon="expand" />}
          </div>
        )}
      </div>
      <div className={styles["list-item__event-container"]}>{renderEvents()}</div>
    </div>
  );
};

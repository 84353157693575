import { Dictionary } from "@fullcalendar/core/internal";
import dayjs from "dayjs";

import { ICountNights } from "../../components/Calendar/CalendarListViewItem/CalendarListViewItem.type";

import { Icon } from "@/elements";

import { BookingStatus } from "@/constants/bookings/bookings.constant";
import { DATE_FORMAT } from "@/constants/global";

export const getClassNameForEvents = (eventValue: Dictionary | ICountNights) => {
  if (dayjs(eventValue.lastDay || eventValue.checkoutDate, DATE_FORMAT).isBefore(dayjs(), "day")) {
    if (eventValue.status === BookingStatus.BLOCKED) {
      return "event-past-blocked";
    }

    return "event-grey";
  }

  if (eventValue.status === BookingStatus.PENDING) {
    return "event-pending";
  }

  if (eventValue.status === BookingStatus.APPROVED) {
    return "event-black";
  }

  if (eventValue.status === BookingStatus.REJECTED) {
    return "event-black";
  }

  if (eventValue.status === BookingStatus.CANCELED) {
    return "event-black";
  }

  if (eventValue.status === BookingStatus.BLOCKED) {
    return "event-blocked";
  }

  return "event-grey";
};

export const getIconForEvents = (eventValue: Dictionary | ICountNights) => {
  if (dayjs(eventValue.lastDay || eventValue.checkoutDate, DATE_FORMAT).isBefore(dayjs(), "day")) {
    if (eventValue.status === BookingStatus.BLOCKED) {
      return <Icon icon="lock" />;
    }

    return null;
  }

  if (eventValue.status === BookingStatus.PENDING) {
    return <Icon icon="pending" />;
  }

  if (eventValue.status === BookingStatus.APPROVED) {
    return <Icon icon="checkOutline" />;
  }

  if (eventValue.status === BookingStatus.BLOCKED) {
    return <Icon icon="lock" />;
  }

  return null;
};

import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { Timeline, Typography } from "antd";
import dayjs from "dayjs";

import { Icon, Loader } from "@/elements";

import { useGetTransaction } from "@/react-queries/transactions/useGetTransaction";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { ROUTES } from "@/constants";
import { SegmentBookingPaymentTypes } from "@/constants/bookings/bookings.constant";
import { DATE_TIME_FORMAT } from "@/constants/global";

import styles from "./BookingPaymentHistory.module.scss";

import { RootState } from "@/store";
import { setHeaderNavigation } from "@/store/header/header.slice";

const { BOOKINGS, VIEW } = ROUTES;

const { Link } = Typography;

export const BookingPaymentHistory = () => {
  const { bookingId, hotelId } = useParams();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const header = useSelector((state: RootState) => state.header);

  const isMobile = useGetScreenStatus();

  const { data: historyBookingPayment, isLoading } = useGetTransaction(bookingId || "", {
    enabled: !!bookingId
  });

  const timeLineData = useMemo(() => {
    return historyBookingPayment?.map((payment) => {
      const isShowAdditionalInfo = !!payment.notes && !!payment.transactionNumber;
      const isRefund = payment.transactionType === SegmentBookingPaymentTypes.REFUND;

      return {
        children: (
          <div className={styles["payment-history__timeline-content"]}>
            <div
              className={`${styles["payment-history__timeline-amount"]} ${
                isRefund ? styles.refund : null
              }`}
            >
              {isRefund ? `-${payment.amount}` : payment.amount} {t("booking.view.payment.fiat")}
            </div>
            <div className={styles["payment-history__timeline-date"]}>
              {dayjs(payment?.transactionTime || payment?.createdAt).format(DATE_TIME_FORMAT)}
              <span className={styles["payment-history__timeline-payment-type"]}>
                {payment?.paymentOption?.name}
              </span>
            </div>
            {isShowAdditionalInfo ? (
              <div className={styles["payment-history__timeline-details"]}>
                <p className={styles["payment-history__timeline-number"]}>
                  {payment.transactionNumber}
                </p>
                <p className={styles["payment-history__timeline-notes"]}>{payment.notes}</p>
              </div>
            ) : null}
          </div>
        )
      };
    });
  }, [historyBookingPayment]);

  useEffect(() => {
    dispatch(
      setHeaderNavigation({
        navigation: {
          title: t("booking.view.detail-title"),
          link: `/${hotelId}/${BOOKINGS}/${bookingId}/${VIEW}`
        }
      })
    );
  }, [pathname]);

  if (isLoading) {
    return <Loader isFullScreen />;
  }

  return (
    <div className={styles["payment-history__container"]}>
      {!isMobile ? (
        <div className={styles["payment-history__back"]}>
          <Link className={styles["payment-history__back-container"]} href={header.navigation.link}>
            <div className={styles["payment-history__back-icon"]}>
              <Icon icon="arrowLeft" className={styles["logo-header"]} />
            </div>
            <span className={styles["payment-history__back-title"]}>{header.navigation.title}</span>
          </Link>
        </div>
      ) : null}
      <h1 className={styles["payment-history__title"]}>
        {t("booking.view.payment.history-title")}
      </h1>
      <div className={styles["payment-history__content"]}>
        <Timeline items={timeLineData} />
      </div>
    </div>
  );
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form } from "antd";

import { Loader } from "@/elements";

import { useUpdateHotel } from "@/react-queries/hotel/useUpdateHotel";

import { ROUTES } from "@/constants";

import { IAuthBasicSettings } from "../../Forms/AuthBasicSettingsForm/AuthBasicSettingsForm.types";

import styles from "./SettingsLocation.module.scss";

import { setHandleCheckLocationUpdating } from "../../../store/user/user.slice";
import { AuthBasicSettingsForm } from "../../Forms/AuthBasicSettingsForm/AuthBasicSettingsForm";

import { RootState } from "@/store";
import { setHeaderNavigation } from "@/store/header/header.slice";

const { SETTINGS } = ROUTES;

export const SettingsLocation = () => {
  const { t } = useTranslation();
  const activeHotel = useSelector((state: RootState) => state.hotel.activeHotel);
  const userId = useSelector((state: RootState) => state.user.main?.id);
  const { mutate: updateHotel, isLoading } = useUpdateHotel();
  const dispatch = useDispatch();
  const { hotelId } = useParams();

  const [form] = Form.useForm<IAuthBasicSettings>();

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (!activeHotel || !userId || !activeHotel) {
          return;
        }
        const conditionUpdateFile = values.images[0]?.file ? { file: values.images[0]?.file } : {};
        updateHotel({
          hotelId: activeHotel.id,
          userId: userId,
          data: {
            publicLabel: values.publicLabel,
            googleMapAddress: values.googleMapAddress || "",
            ...conditionUpdateFile
          }
        });
        dispatch(setHandleCheckLocationUpdating(true));
      })
      .catch(console.error);
  };

  useEffect(() => {
    if (activeHotel) {
      form.setFieldsValue({
        publicLabel: activeHotel.publicLabel,
        googleMapAddress: activeHotel.googleMapAddress,
        maxCountOfGuests: activeHotel.maxGuests,
        images: [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: activeHotel.locationImage
          }
        ]
      });
    }
  }, [activeHotel]);

  useEffect(() => {
    if (activeHotel) {
      dispatch(
        setHeaderNavigation({
          navigation: {
            title: t("settings.title"),
            link: `/${hotelId}/${SETTINGS}`
          }
        })
      );
    }
  }, [activeHotel]);

  if (!activeHotel) {
    return <Loader isFullScreen />;
  }

  return (
    <div className={styles.location__container}>
      <h1 className={styles.location__title}>{t("settings.location.title")}</h1>
      <div className={styles.location__content}>
        <AuthBasicSettingsForm isDataLoading={isLoading} isEdit form={form} submitForm={handleSubmit} />
      </div>
    </div>
  );
};

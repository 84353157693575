import { BookingStatus } from "@/constants/bookings/bookings.constant";

export const GuestIconType = (type: string) => {
  switch (type) {
    case BookingStatus.PENDING: {
      return "pending";
    }
    case BookingStatus.REJECTED: {
      return "decline";
    }
    case BookingStatus.APPROVED: {
      return "success";
    }
    case BookingStatus.CANCELED: {
      return "decline";
    }
    default: {
      return "pending";
    }
  }
};

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";

import { CalendarTypes } from "@/constants/calendar/calendar.constants";
import { DATE_FORMAT } from "@/constants/global";

import { IBookingReservationsRequest } from "@/types";

export interface CalendarSlice {
  isShowSwitchTypeModal: boolean;
  isUpdateReservation: boolean;
  calendarType: CalendarTypes;
  isShowSelectionCalendarModal: boolean;
  activeDates: IBookingReservationsRequest;
  calendarDate: string | null;
  isShowMonthSwitcher: boolean;
}

const initialState: CalendarSlice = {
  isShowSwitchTypeModal: false,
  isUpdateReservation: false,
  calendarType: CalendarTypes.MONTHLY,
  isShowSelectionCalendarModal: false,
  activeDates: {
    fromDate: dayjs().month(dayjs().month()).startOf("month").format(DATE_FORMAT),
    toDate: dayjs().month(dayjs().month()).endOf("month").format(DATE_FORMAT)
  },
  calendarDate: null,
  isShowMonthSwitcher: false
};

export const calendarSlice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    setHandleShowModalSwitchType: (state, action: PayloadAction<boolean>) => {
      state.isShowSwitchTypeModal = action.payload;
    },
    setActiveMonthDays: (state, action: PayloadAction<IBookingReservationsRequest>) => {
      state.activeDates = action.payload;
    },
    setHandleUpdateReservation: (state, action: PayloadAction<boolean>) => {
      state.isUpdateReservation = action.payload;
    },
    setCalendarType: (state, action: PayloadAction<CalendarTypes>) => {
      state.calendarType = action.payload;
    },
    setHandleSelectingCalendarModal: (state, action: PayloadAction<boolean>) => {
      state.isShowSelectionCalendarModal = action.payload;
    },
    setCalendarDate: (state, action: PayloadAction<string>) => {
      state.calendarDate = action.payload;
    },
    setHandleMonthSwitcher: (state, action: PayloadAction<boolean>) => {
      state.isShowMonthSwitcher = action.payload;
    },
    clearCalendarState: (state) => {
      state.isShowSwitchTypeModal = false;
      state.isUpdateReservation = false;
      state.isShowSelectionCalendarModal = false;
      state.activeDates = {
        fromDate: dayjs().month(dayjs().month()).startOf("month").format(DATE_FORMAT),
        toDate: dayjs().month(dayjs().month()).endOf("month").format(DATE_FORMAT)
      };
      state.calendarDate = null;
    }
  }
});

export const {
  setHandleShowModalSwitchType,
  setActiveMonthDays,
  setHandleUpdateReservation,
  setCalendarType,
  setHandleSelectingCalendarModal,
  setCalendarDate,
  clearCalendarState,
  setHandleMonthSwitcher
} = calendarSlice.actions;

export default calendarSlice.reducer;

import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";

import { LocationChooser } from "@/components";

import { ROUTES } from "@/constants";

import styles from "./Locations.module.scss";

const { CREATE } = ROUTES;

export const Locations = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isCreate = pathname.includes(CREATE);


  if (isCreate) {
    return <Outlet />;
  }

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t("locations.title")}</h1>
      <LocationChooser />
    </div>
  );
};

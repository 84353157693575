import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { IBookingBlock } from "@/components/Forms/BookingBlock/BookingBlock.type";

import { useCustomMutation } from "@/hooks";

import { allLocationsIdStorageService, api, handleHotelIdInApi, handleScroll } from "@/helpers";

import { AllLocationType, ScrollTypes } from "@/constants/global";

import { TMutationsOptions } from "@/types";

import { setEditModalStatus } from "@/store/booking/booking.slice";

const { getItem: getAllLocationStatus } = allLocationsIdStorageService(AllLocationType.ALL_LOCATIONS_ID);

const fetcher = async (body: IBookingBlock) => {
  const { id, ...rest } = body;

  const isAllLocation = getAllLocationStatus();
  const conditionData = isAllLocation ? rest : handleHotelIdInApi(rest);

  return api.patch(`host/bookings/blocked-dates/${id}`, conditionData);
};

export const useUpdateBlockedBooking = (
  options?: TMutationsOptions<IBookingBlock, IBookingBlock>
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useCustomMutation<IBookingBlock, IBookingBlock>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      dispatch(setEditModalStatus(false));
      handleScroll(ScrollTypes.REMOVE);
      await queryClient.invalidateQueries(["get-blocked-booking"], { exact: false });
      await queryClient.invalidateQueries(["get-bookings"], { exact: false });
      await queryClient.invalidateQueries(["get-bookings-reservations"], { exact: false });
      await queryClient.invalidateQueries(["get-bookings-count"], { exact: false });
    }
  });

  const handleMutate = (data: IBookingBlock) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

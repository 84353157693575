import React, { useEffect, useMemo, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Button, Form, Switch } from "antd";

import { FormItem, Icon, Input, notification } from "@/elements";

import { useUpdateHotelBookingExpires } from "@/react-queries/hotel/useUpdateHotelBookingExpires";

import { useYupSync } from "@/hooks";
import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { ROUTES } from "@/constants";

import { bookingExpireSchema } from "./SettingsWidget.schema";

import { IBookingHotelWidgetExpire } from "@/types";

import styles from "./SettingsWidget.module.scss";

import { RootState } from "@/store";
import { setHeaderNavigation } from "@/store/header/header.slice";

const { SETTINGS } = ROUTES;

export const SettingsWidget = () => {
  const { t } = useTranslation();
  const { hotelId } = useParams();

  const [form] = Form.useForm<IBookingHotelWidgetExpire>();
  const yupSync = useYupSync(bookingExpireSchema);

  const dispatch = useDispatch();
  const [copied, setCopied] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const activeHotel = useSelector((state: RootState) => state.hotel.activeHotel);
  const isMobile = useGetScreenStatus();

  const widgetUrl = useMemo(() => {
    if (activeHotel) {
      return `${process.env.REACT_APP_WIDGET_URL}/${activeHotel.aliasId}`;
    }
  }, [activeHotel]);
  const [switchToggle, setSwitchToggle] = useState(false);

  const { mutate: updateBookingExpire, isLoading: isUpdating } = useUpdateHotelBookingExpires();

  const shareWidgetUrl = async () => {
    if (navigator.share) {
      await navigator.share({
        title: t("settings.widget.share-title"),
        text: t("settings.widget.share-title"),
        url: widgetUrl
      });
    }
  };

  const handleSubmit = (values: IBookingHotelWidgetExpire) => {
    updateBookingExpire({
      bookingExpiresIn: Number(values.bookingExpiresIn)
    });
  };

  const handleChange = (_: IBookingHotelWidgetExpire, values: IBookingHotelWidgetExpire) => {
    if (Number(values.bookingExpiresIn) !== activeHotel?.bookingExpiresIn) {
      return setDisableButton(false);
    }

    return setDisableButton(true);
  };

  useEffect(() => {
    dispatch(
      setHeaderNavigation({
        navigation: {
          title: t("settings.title"),
          link: `/${hotelId}/${SETTINGS}`
        }
      })
    );
  }, []);

  useEffect(() => {
    if (copied) {
      notification.success(t("settings.widget.copied"));
    }

    return () => setCopied(false);
  }, [copied]);

  useEffect(() => {
    if (
      form.getFieldValue("bookingExpiresIn") &&
      Number(form.getFieldValue("bookingExpiresIn")) === activeHotel?.bookingExpiresIn
    ) {
      setDisableButton(true);
    }
    if (activeHotel && activeHotel.bookingExpiresIn) {
      form.setFieldsValue({ bookingExpiresIn: activeHotel.bookingExpiresIn as number });
    }
  }, [activeHotel]);

  return (
    <div className={styles.widget__container}>
      <h1 className={styles.widget__title}>{t("settings.widget.title")}</h1>
      <div className={styles["widget__content-wrapper"]}>
        <div className={styles.widget__content}>
          <h2 className={styles["widget__content-title"]}>{t("settings.widget.content-title")}</h2>
          <div className={styles.widget__helper}>
            <Icon icon="infoOutline" />
            <span className={styles["widget__helper-text"]}>
              {t("settings.widget.widget-helper")}
            </span>
          </div>
          <FormItem
            className={styles["widget__content-field"]}
            label={t("settings.widget.content-label")}
            required
          >
            <Input disabled value={widgetUrl} readOnly />
          </FormItem>

          <div className={styles.widget__buttons}>
            <CopyToClipboard text={widgetUrl || ""} onCopy={() => setCopied(true)}>
              <Button type="primary" className={styles.widget__button} icon={<Icon icon="link" />}>
                {t("settings.widget.copy")}
              </Button>
            </CopyToClipboard>
            {isMobile ? (
              <Button
                onClick={shareWidgetUrl}
                className={styles.widget__button}
                ghost
                icon={<Icon icon="share" />}
              >
                {t("settings.widget.share")}
              </Button>
            ) : null}
          </div>
        </div>
        <div className={styles.widget__content}>
          <h2 className={styles["widget__content-title"]}>{t("settings.widget.expire-title")}</h2>

          {!switchToggle ? (
            <div className={styles.widget__helper}>
              <Icon icon="infoOutline" />
              <span className={styles["widget__helper-text"]}>
                {t("settings.widget.expire-helper")}
              </span>
            </div>
          ) : null}

          <div className={styles.widget__switcher}>
            <div className={styles["widget__switcher-preview"]}>
              <span className={styles["widget__switcher-name"]}>
                {t("settings.widget.switcher-title")}
              </span>
              <Switch onChange={setSwitchToggle} value={switchToggle} />
            </div>

            {switchToggle ? (
              <Form
                onValuesChange={handleChange}
                onFinish={handleSubmit}
                form={form}
                className={styles["widget__switcher-content"]}
                layout="vertical"
              >
                <FormItem
                  name="bookingExpiresIn"
                  required
                  label={t("settings.widget.expire-label")}
                  rules={[yupSync]}
                >
                  <Input type="number" min={1} suffix={t("settings.widget.expire-suffix")} />
                </FormItem>

                <Button
                  disabled={disableButton}
                  className={styles["widget__switcher-button"]}
                  type="primary"
                  onClick={form.submit}
                  loading={isUpdating}
                >
                  {t("settings.widget.expire-save")}
                </Button>
              </Form>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

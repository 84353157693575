import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Divider, Timeline } from "antd";
import dayjs from "dayjs";

import {
  getBlockedHistoryDescription,
  getHistoryDescription,
  getHistoryIconColor
} from "@/helpers";

import { DATE_TIME_FORMAT } from "@/constants/global";

import { IBookingChangesHistory } from "@/types";

import styles from "./BookingHistory.module.scss";

import { IBookingHistory } from "./BookingHistory.type";

export const BookingHistory: FC<IBookingHistory> = ({ bookingData, isBlocked = false }) => {
  const { t } = useTranslation();
  const conditionBookingHisttory =
    bookingData?.bookingChangesHistory || bookingData?.changesHistory || [];

  const timeLineData = useMemo(() => {
    return conditionBookingHisttory.reverse().map((bookingHistory: IBookingChangesHistory) => ({
      color: getHistoryIconColor(bookingHistory.changeType),
      children: (
        <div className={styles["booking-history__timeline-content"]}>
          <div className={styles["booking-history__timeline-date"]}>
            {dayjs(bookingHistory.createdAt).format(DATE_TIME_FORMAT)}
          </div>
          <div className={styles["booking-history__timeline-description"]}>
            {isBlocked
              ? getBlockedHistoryDescription(bookingHistory.changeType)
              : getHistoryDescription(bookingHistory.changeType)}
          </div>
        </div>
      )
    }))
  }, [conditionBookingHisttory]);

  return (
    <div className={styles["booking-history__container"]}>
      <h1 className={styles["booking-history__title"]}>{t("booking.view.history")}</h1>
      <Divider className={styles["booking-history__divider"]} />
      <Timeline items={timeLineData} />
    </div>
  );
};

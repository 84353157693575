import { FC, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, Navigate, useLocation } from "react-router-dom";
import { Steps } from "antd";
import { StepProps } from "antd/lib";

import { GoogleAuth } from "@/components";

import { Title } from "@/elements";

import { getJWTToken } from "@/helpers";

import { ROUTES } from "@/constants";

import Logo from "@/assets/images/logo.svg";

import styles from "./AuthLayout.module.scss";

import { RootState } from "@/store";

const { INDEX, SIGN_UP, AUTH, SIGN_IN, HOTEL_SETUP } = ROUTES;

interface IAuthProps {
  children?: ReactNode;
  current?: number;
}

export const AuthLayout: FC<IAuthProps> = ({ children, current }) => {
  const jwtToken = getJWTToken();
  const auth = useSelector((state: RootState) => state.auth);

  const { pathname } = useLocation();

  const { t } = useTranslation();

  const linkHeader = pathname.includes(`${SIGN_IN}`) ? `${AUTH}${SIGN_UP}` : `${AUTH}${SIGN_IN}`;
  const isSignUp = pathname.includes(`${SIGN_UP}`);
  const isHotelSetup = pathname.includes(`${HOTEL_SETUP}`);

  const steps = [
    { title: t("steps.registration") },
    { title: t("steps.hotel-setup") }
  ] as StepProps[];

  const items = steps.map(({ title, status }) => ({ key: title, title: title, status: status }));

  if (jwtToken && !isHotelSetup) {
    return <Navigate to={INDEX} replace />;
  }

  return (
    <div className={styles["auth-wrapper"]}>
      <img className={styles["logo-img"]} src={Logo} alt="HOSTY" />

      <div className={styles["form-container"]}>
        {(isSignUp || isHotelSetup) && (
          <Steps
            labelPlacement="vertical"
            responsive={false}
            className={styles.steps}
            size="small"
            items={items}
            current={current}
          />
        )}

        <div className={styles["form-container-header"]}>
          <Title className={styles["title-form-header"]}>{auth.activePage.titleForm}</Title>

          {!isHotelSetup && (
            <Link to={linkHeader} className={styles["link-form"]}>
              {auth.activePage.linkForm}
            </Link>
          )}
        </div>

        {children}

        {!isHotelSetup && auth.activePage.googleTitle && (
          <div className={styles["form-container-auth-google"]}>
            <Title className={styles["form-auth-google-title"]} level={6}>
              {t("auth-form.or")}
            </Title>
            <GoogleAuth title={auth.activePage.googleTitle} />
          </div>
        )}
      </div>
    </div>
  );
};

import { IGuest } from "@/components/Guest/GuestList/GuestList.type";

import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { api, getJWTToken } from "@/helpers";

import { TQueryOptions } from "@/types";

const fetcher = async (userId: string) => {
  return api.get(`guests/${userId}`);
};

export const useGetGuest = <TQueryFnData = IGuest, TData = IGuest>(
  userId: string,
  options?: TQueryOptions<TQueryFnData, TData>
) => {
  const token = getJWTToken();

  return useCustomQuery<TQueryFnData, TData>(["get-guest"], () => fetcher(userId), {
    ...options,
    staleTime: Infinity,
    enabled: !!token
  });
};

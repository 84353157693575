import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { api, handleHotelIdInApi } from "@/helpers";

import {
  IGetBookingCalendarDayPrice,
  IGetBookingCalendarDayPriceResponse,
  TQueryOptions
} from "@/types";

const fetcher = async (dates: IGetBookingCalendarDayPrice) => {
  return api.get("host/bookings/prices/calendar", handleHotelIdInApi(dates));
};

export const useGetCalendarDayPrice = <
  TQueryFnData = IGetBookingCalendarDayPrice,
  TData = IGetBookingCalendarDayPriceResponse[]
>(
  dates: IGetBookingCalendarDayPrice,
  options?: TQueryOptions<TQueryFnData, TData>
) => {
  return useCustomQuery<TQueryFnData, TData>(["get-calendar-booking-prices"], () => fetcher(dates), {
    ...options,
    staleTime: Infinity
  });
};

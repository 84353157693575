import { BookingStatus, LESS_MULTIPE_NIGHTS, ONE_NIGHT } from "@/constants/bookings/bookings.constant";
import { SINGLE_GUEST } from "@/constants/calendar/calendar.constants";

import { IBookingReservations } from "@/types";

export const conditionGuests = (guests: number) => {
  if (guests === SINGLE_GUEST) {
    return "calendar.list.event.single-guest";
  } else if (guests > SINGLE_GUEST) {
    return "calendar.list.event.multiple-guests";
  }
};

export const conditionNights = (nights: number) => {
  if (nights === ONE_NIGHT) {
    return "calendar.list.event.single-night";
  } else if (nights < LESS_MULTIPE_NIGHTS && nights > ONE_NIGHT) {
    return "calendar.list.event.multiple-less-nights";
  } else if (nights >= LESS_MULTIPE_NIGHTS) {
    return "calendar.list.event.multiple-nights";
  }
};

export const getEventDays = ({
  reservations,
  handleRangeDaysInMonth,
  exactStatus,
  withoutStatus
}: {
  reservations: IBookingReservations[] | undefined;
  handleRangeDaysInMonth: (start: string, end: string) => { date: string }[];
  exactStatus?: BookingStatus;
  withoutStatus?: BookingStatus;
}) => {
  const bookings: IBookingReservations[] = [];
  if (exactStatus) {
    bookings.push(...(reservations || []).filter((event: IBookingReservations) => event.status === exactStatus));
  }
  if (withoutStatus) {
    bookings.push(...(reservations || []).filter((event: IBookingReservations) => event.status !== withoutStatus));
  }
  
  const bookingDays =
    bookings.length &&
    bookings
      .map((booking: IBookingReservations) => ({
        bookedDays: handleRangeDaysInMonth(booking.checkinDate, booking.checkoutDate)
          .filter((day) => day.date !== booking.checkoutDate)
          .map((day) => day.date),
        bookingId: booking.id
      }))
      .flat();

  return {
    bookingDates: bookingDays ? bookingDays.map((day) => day.bookedDays).flat() : [],
    data: bookingDays || []
  };
};

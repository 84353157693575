import { useQueryClient } from "react-query";

import { useCustomMutation } from "@/hooks";

import { api } from "@/helpers";

import { TMutationsOptions } from "@/types";

interface ILangUpdate {
  appLanguage: string;
}

const fetcher = async ({ appLanguage }: ILangUpdate) => {
  return api.patch("host/user/app-language", {
    appLanguage
  });
};

export const useUpdateUserLang = (
  options?: TMutationsOptions<ILangUpdate, ILangUpdate>
) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useCustomMutation<ILangUpdate, ILangUpdate>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      await queryClient.invalidateQueries(["current-user"], { exact: false });
    }
  });

  const handleMutate = (data: ILangUpdate) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

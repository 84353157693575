import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { ROUTES } from "@/constants";

import styles from "./PrivacyPolicy.module.scss";

import { RootState } from "@/store";
import { setHeaderNavigation } from "@/store/header/header.slice";

const { SETTINGS } = ROUTES;

export const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const { hotelId } = useParams();
  const user = useSelector((state: RootState) => state.user.main);
  const isUserLoggedIn = !!user;
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(
        setHeaderNavigation({
          navigation: {
            title: t("settings.title"),
            link: `/${hotelId}/${SETTINGS}`
          }
        })
      );
    }
  }, [isUserLoggedIn, pathname]);

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t("privacy-policy.title")}</h1>
      <div className={isUserLoggedIn ? styles.container : styles.auth__container}>
        <p className={styles.term__title}>{t("privacy-policy.last-update")}</p>
        <p>{t("privacy-policy.introduce")}</p>
        <h2 className={styles.text__title}>{t("privacy-policy.title-1")}</h2>
        <p>
          <Trans
            i18nKey="privacy-policy.desc-1"
            components={{
              list: <ul />,
              item: <li />
            }}
          />
        </p>
        <h2 className={styles.text__title}>{t("privacy-policy.title-2")}</h2>
        <p>
          <Trans
            i18nKey="privacy-policy.desc-2"
            components={{
              list: <ul />,
              item: <li />
            }}
          />
        </p>
        <h2 className={styles.text__title}>{t("privacy-policy.title-3")}</h2>
        <p>{t("privacy-policy.desc-3")}</p>
        <h2 className={styles.text__title}>{t("privacy-policy.title-4")}</h2>
        <p>
          <Trans
            i18nKey="privacy-policy.desc-4"
            components={{
              list: <ul />,
              item: <li />
            }}
          />
        </p>
        <h2 className={styles.text__title}>{t("privacy-policy.title-5")}</h2>
        <p>{t("privacy-policy.desc-5")}</p>
        <h2 className={styles.text__title}>{t("privacy-policy.title-6")}</h2>
        <p>{t("privacy-policy.desc-6")}</p>
        <h2 className={styles.text__title}>{t("privacy-policy.title-7")}</h2>
        <p>
          <Trans
            i18nKey="privacy-policy.desc-7"
            components={{
              list: <ul />,
              item: <li />,
              a: <a />
            }}
          />
        </p>
        <h2 className={styles.text__title}>{t("privacy-policy.title-8")}</h2>
        <p>{t("privacy-policy.desc-8")}</p>
        <h2 className={styles.text__title}>{t("privacy-policy.title-9")}</h2>
        <p>
          <Trans
            i18nKey="privacy-policy.desc-9"
            components={{
              a: <a />
            }}
          />
        </p>
        <p>{t("privacy-policy.desc-11")}</p>
        <p>{t("privacy-policy.desc-12")}</p>
        <p>{t("privacy-policy.desc-13")}</p>
      </div>
    </div>
  );
};

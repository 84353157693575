import { array, number, object, string } from "yup";

import { REGEXP } from "@/constants";

export const authBasicSettingsSchema = object().shape({
  publicLabel: string().required(),
  googleMapAddress: string().notRequired().optional().test("matches", (value) => {
    if (!value) {
      return true;
    }

    return REGEXP.GOOGLE_LOCATION.test(value);
  }),
  images: array().required(),
  maxCountOfGuests: number().required().min(1),
});

import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { GuestForm } from "@/components";

import { Loader } from "@/elements";

import { useGetGuest } from "@/react-queries/guests/useGetGuest";

import { ROUTES } from "@/constants";

import styles from "../Guests.module.scss";

import { setHeaderNavigation } from "@/store/header/header.slice";

const { GUESTS, VIEW, BOOKINGS } = ROUTES;

export const EditGuests = () => {
  const { userId, hotelId } = useParams<{ userId: string, hotelId: string }>();
  const location = useLocation();
  const { t } = useTranslation();

  const isBookingBack = useMemo(() => {
    const route = location.state?.from;
    if (route) {
      return route?.includes(BOOKINGS);
    }

    return false;
  }, [location]);

  const dispatch = useDispatch();

  const { data: guestData, isLoading } = useGetGuest(userId || "", {
    enabled: !userId
  });

  useEffect(() => {
    if (guestData) {
      const conditionTitle = isBookingBack ? t("booking.title") : guestData.fullName;
      const conditionHeaderRedirect = isBookingBack
        ? location.state?.from
        : `/${hotelId}/${GUESTS}/${userId}/${VIEW}`;
      dispatch(
        setHeaderNavigation({
          navigation: {
            title: conditionTitle,
            link: conditionHeaderRedirect
          }
        })
      );
    }
  }, [guestData, location]);

  if (isLoading) {
    return <Loader isFullScreen />;
  }

  return (
    <div className={styles.guests__container}>
      <GuestForm initialValues={guestData} localizationName="edit-guests" />
    </div>
  );
};

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Divider, Form, Input as AntdInput, Switch, Typography } from "antd";

import { FormItem, Icon, Input, Loader } from "@/elements";

import { useCreatePaymentOption } from "@/react-queries/paymentOptions/useCreatePaymentOption";
import { useDeletePaymentOption } from "@/react-queries/paymentOptions/useDeletePaymentOption";
import { useGetPaymentOption } from "@/react-queries/paymentOptions/useGetPaymentOption";
import { useUpdatePaymentOption } from "@/react-queries/paymentOptions/useUpdatePaymentOption";

import { useYupSync } from "@/hooks";
import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { handleScroll } from "@/helpers";

import { ROUTES } from "@/constants";
import { ScrollTypes } from "@/constants/global";

import { paymentOptionSchema } from "./PaymentOptionForm.schema";

import { IPaymentOption } from "@/types/paymentOption/paymentOption.type";

import styles from "./PaymentOptionForm.module.scss";

import { CancellationModal } from "../CancellationModal/CancellationModal";

import { RootState } from "@/store";
import { setHeaderNavigation } from "@/store/header/header.slice";
import { setShowPaymentOptionDeleteModal } from "@/store/price-setup/price-setup.slice";

const { TextArea } = AntdInput;

const { SETTINGS, PAYMENT_OPTIONS } = ROUTES;

const { Link } = Typography;

export const PaymentOptionForm = () => {
  const [form] = Form.useForm();
  const yupSync = useYupSync(paymentOptionSchema);
  const header = useSelector((state: RootState) => state.header);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { paymentOptionId, hotelId } = useParams();
  const isShowCancellationModal = useSelector(
    (state: RootState) => state.priceSetup.paymentOption.isShowDeletePaymentOptionModal
  );
  const [isChange, setIsChange] = useState(false);
  const isEdit = !!paymentOptionId;
  const isMobile = useGetScreenStatus();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [isActive, setIsActive] = useState(false);

  const {
    data: paymentOptionData,
    isLoading: isPaymentOptionDataLoading,
    refetch,
    isRefetching
  } = useGetPaymentOption(paymentOptionId || "", {
    enabled: isEdit
  });
  const { mutate: createPaymentOption } = useCreatePaymentOption();
  const { mutate: updatePaymentOption } = useUpdatePaymentOption();

  const { mutate: deletePaymentOption } = useDeletePaymentOption();

  const navigateBack = () => {
    return navigate(`/${hotelId}/${SETTINGS}/${PAYMENT_OPTIONS}`);
  };

  const handleSubmit = (values: IPaymentOption) => {
    if (paymentOptionId) {
      return updatePaymentOption({
        ...values,
        id: Number(paymentOptionId)
      });
    }

    return createPaymentOption({
      ...values,
      isTurnedOn: isActive
    });
  };

  const handleChangeFormData = (_: IPaymentOption, values: IPaymentOption) => {
    if (paymentOptionData && isEdit) {
      for (const key of Object.keys(values)) {
        if (
          values[key as keyof IPaymentOption] !== paymentOptionData[key as keyof IPaymentOption]
        ) {
          setIsChange(true);
          break;
        }
        setIsChange(false);
      }
    }
  };

  const handleDeleteTariff = () => {
    if (paymentOptionId) {
      deletePaymentOption({
        optionId: Number(paymentOptionId)
      });
    }
  };

  const handleToggleModal = () => {
    dispatch(setShowPaymentOptionDeleteModal(!isShowCancellationModal));
    handleScroll(isShowCancellationModal ? ScrollTypes.REMOVE : ScrollTypes.ADD);
  };

  useEffect(() => {
    form.setFieldsValue({ isTurnedOn: isActive });
  }, [isActive]);

  useEffect(() => {
    if (paymentOptionId) {
      refetch();
    } else {
      form.resetFields();
    }
  }, [paymentOptionId]);

  useEffect(() => {
    if (paymentOptionData && isEdit) {
      form.setFieldsValue(paymentOptionData);
      setIsActive(!!paymentOptionData.isTurnedOn);
    }
  }, [paymentOptionData, isEdit]);

  useEffect(() => {
    dispatch(
      setHeaderNavigation({
        navigation: {
          title: t("payment-options.title"),
          link: `/${hotelId}/${SETTINGS}/${PAYMENT_OPTIONS}`
        }
      })
    );
  }, [pathname]);

  if (isPaymentOptionDataLoading || isRefetching) {
    return <Loader isFullScreen />;
  }

  return (
    <div className={styles.form__wrapper}>
      {!isMobile ? (
        <div className={styles.form__header}>
          <div className={styles.form__back}>
            <Link className={styles["form__back-container"]} href={header.navigation.link}>
              <div className={styles["form__back-icon"]}>
                <Icon icon="arrowLeft" className={styles["logo-header"]} />
              </div>
              <span className={styles["form__back-title"]}>{header.navigation.title}</span>
            </Link>
          </div>
          {isEdit ? null : (
            <h1 className={styles.form__title}>{t("payment-options.create-title")}</h1>
          )}
        </div>
      ) : null}
      <Form
        onValuesChange={handleChangeFormData}
        onFinish={handleSubmit}
        form={form}
        className={styles.form__container}
        layout="vertical"
      >
        <div className={styles.form__toggle}>
          <div className={styles["form__toggle-title"]}>{t("payment-options.connect")}</div>
          <FormItem name="isTurnedOn" rules={[yupSync]}>
            <Switch className={styles.form__switch} value={isActive} onChange={setIsActive} />
          </FormItem>
        </div>
        <Divider className={styles.form__divider} />
        <FormItem
          required
          name="name"
          className={styles.form__field}
          label={t("payment-options.name")}
          rules={[yupSync]}
        >
          <Input />
        </FormItem>
        <FormItem
          className={styles.form__field}
          name="description"
          label={t("payment-options.comment")}
          rules={[yupSync]}
        >
          <TextArea maxLength={200} rows={6} />
        </FormItem>
        {isMobile ? (
          <div className={styles.form__btns}>
            <Button
              disabled={isEdit && !isChange}
              className={styles["form__submit-btn"]}
              type="primary"
              htmlType="submit"
            >
              {isEdit ? t("payment-options.save") : t("payment-options.create")}
            </Button>
            <Button className={styles.form__btn} ghost onClick={navigateBack}>
              {isEdit ? t("payment-options.decline-changes") : t("payment-options.decline")}
            </Button>
          </div>
        ) : null}
      </Form>
      {!isMobile ? (
        <div className={styles.form__btns}>
          <Button
            disabled={isEdit && !isChange}
            className={styles["form__submit-btn"]}
            htmlType="submit"
            type="primary"
            onClick={form.submit}
          >
            {isEdit ? t("payment-options.save") : t("payment-options.create")}
          </Button>
          <div className={styles["form__btns-cancellation"]}>
            <Button className={styles.form__btn} ghost onClick={navigateBack}>
              {isEdit ? t("payment-options.decline-changes") : t("payment-options.decline")}
            </Button>
            {isEdit ? (
              <div className={styles["form__delete-btn"]} onClick={handleToggleModal}>
                <Icon icon="outlineTrash" />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
      {isEdit && isMobile && (
        <div className={styles.form__remove} onClick={handleToggleModal}>
          {t("payment-options.delete")}
        </div>
      )}
      {isShowCancellationModal && (
        <CancellationModal
          title={t("payment-options.delete-text")}
          approveButtonText={t("payment-options.confirm-delete", { name: paymentOptionData?.name })}
          handleApprove={handleDeleteTariff}
          declineButtonText={t("payment-options.decline-delete")}
          handleClose={handleToggleModal}
        />
      )}
    </div>
  );
};

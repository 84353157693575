import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { api } from "@/helpers";

import { TQueryOptions } from "@/types";
import { IPaymentOption } from "@/types/paymentOption/paymentOption.type";

const fetcher = async (optionId: string) => {
  return api.get(`payment-options/${optionId}`);
};

export const useGetPaymentOption = <TQueryFnData = IPaymentOption, TData = IPaymentOption>(
  optionId: string,
  options?: TQueryOptions<TQueryFnData, TData>
) => {

  return useCustomQuery<TQueryFnData, TData>(["get-payment-option"], () => fetcher(optionId), {
    ...options,
    staleTime: Infinity,
  });
};

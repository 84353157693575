import { FormInstance } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { PhoneNumberUtil } from "google-libphonenumber";
import i18next from "i18next";

import { allLocationsIdStorageService, hotelIdStorageService } from "../storage/storage.helpers";

import { ROUTES } from "@/constants";
import { BookingStatus } from "@/constants/bookings/bookings.constant";
import { AllLocationType, HotelType, ScrollTypes } from "@/constants/global";

import styles from "./general.module.scss";

const { getItem: getHotelId } = hotelIdStorageService(HotelType.HOTEL_ID);
const { getItem: getIsAllLocation } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

const {
  CREATE,
  VIEW,
  EDIT,
  PAYMENT_HISTORY,
  PRICE_SETUP,
  PAYMENT_OPTIONS,
  LOCATION,
  PRIVACY_POLICY,
  TERMS_OF_CONDITION,
  USER_PROFILE,
  UPDATE_PASSWORD,
  BOOKING_RULES,
  WIDGET,
  APP
} = ROUTES;

type RouteNames =
  | typeof CREATE
  | typeof VIEW
  | typeof EDIT
  | typeof PAYMENT_HISTORY
  | typeof PAYMENT_OPTIONS;

export const handleScroll = (type: ScrollTypes) => {
  const mainBlock = document.querySelector("#main");
  if (mainBlock && type === ScrollTypes.ADD) {
    mainBlock.classList.add(styles["no-scroll"]);
  }
  if (mainBlock && type === ScrollTypes.REMOVE) {
    mainBlock.classList.remove(styles["no-scroll"]);
  }
};

export const getCountOfDays = ({ fromDate, toDate }: { fromDate: string; toDate: string }) => {
  const countOfHours = dayjs(toDate).diff(fromDate, "hours");
  const days = Math.ceil(countOfHours / 24);

  return days;
};

export const getHistoryIconColor = (changeType: string) => {
  switch (changeType) {
    case BookingStatus.CREATED:
      return "grey";
    default:
      return "blue";
  }
};

export const checkPhoneValidation = (value: string) => {
  if (value.length < 5) {
    return false;
  }

  const phoneUtil = PhoneNumberUtil.getInstance();

  return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(value));
};

export const getHistoryDescription = (changeType: string) => {
  switch (changeType) {
    case BookingStatus.CREATED:
      return i18next.t("booking.history.created");
    case BookingStatus.APPROVED:
      return i18next.t("booking.history.approved");
    case BookingStatus.REJECTED:
      return i18next.t("booking.history.rejected");
    case BookingStatus.CANCELED:
      return i18next.t("booking.history.canceled");
    case BookingStatus.UDPATED:
      return i18next.t("booking.history.updated");
    default:
      return i18next.t("booking.history.unknown");
  }
};

export const getBlockedHistoryDescription = (changeType: string) => {
  switch (changeType) {
    case BookingStatus.CREATED:
      return i18next.t("booking.blocked.history.created");
    case BookingStatus.CANCELED:
      return i18next.t("booking.blocked.history.canceled");
    case BookingStatus.UDPATED:
      return i18next.t("booking.blocked.history.updated");
    default:
      return i18next.t("booking.history.unknown");
  }
};

export const checkSettingsPathname = (pathname: string) => {
  const settingsPathnames = [
    PAYMENT_OPTIONS,
    LOCATION,
    PRICE_SETUP,
    CREATE,
    EDIT,
    VIEW,
    USER_PROFILE,
    UPDATE_PASSWORD,
    PRIVACY_POLICY,
    TERMS_OF_CONDITION,
    BOOKING_RULES,
    WIDGET,
    APP
  ];

  return settingsPathnames.includes(pathname.split("/").at(-1) as unknown as any);
};

export const checkIsClearHeader = (pathname: string) => {
  const clearHeaderStatesPathnames = [CREATE, VIEW, EDIT];

  return !clearHeaderStatesPathnames.includes(pathname.split("/").at(-1) as any);
};

export const checkIsNotClearHeaderStates = (pathname: string) => {
  const clearHeaderStatesPathnames = [
    CREATE,
    VIEW,
    EDIT,
    PAYMENT_HISTORY,
    PRICE_SETUP,
    PAYMENT_OPTIONS,
    PRIVACY_POLICY,
    TERMS_OF_CONDITION,
    UPDATE_PASSWORD,
    BOOKING_RULES,
    WIDGET,
    APP,
    USER_PROFILE,
    LOCATION
  ];

  return !clearHeaderStatesPathnames.includes(pathname.split("/").at(-1) as RouteNames);
};

export const checkClearFooterStates = (pathname: string) => {
  const clearFooterStatesPathnames = [CREATE, VIEW, EDIT, PAYMENT_HISTORY, PAYMENT_OPTIONS];

  return !clearFooterStatesPathnames.includes(pathname.split("/").at(-1) as RouteNames);
};

export const handleHotelIdInApi = <T>(data: T) => {
  const hotelId = getHotelId();
  const isAllLocattions = getIsAllLocation();

  const conditionOfHotelId = isAllLocattions ? {} : { hotelId };

  return { ...data, ...conditionOfHotelId };
};

export const handleDatePicker = (date: Dayjs, form: FormInstance) => {
  if (!date) {
    form.setFieldValue("checkoutDate", null);
    form.setFieldValue("endDate", null);
  }

  return date;
}

import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Form } from "antd";

import { AuthLayout } from "@/layouts";

import { AuthBasicSettingsForm } from "@/components";
import { IAuthBasicSettings } from "@/components/Forms/AuthBasicSettingsForm/AuthBasicSettingsForm.types";

import { notification } from "@/elements";

import { useHotelSetup } from "@/react-queries";

import { ILocationSettings } from "@/types";

import { setAuthActivePageInfo } from "@/store/auth/auth.slice";

export const LocationSettings = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const [form] = Form.useForm<IAuthBasicSettings>();

  const { mutate: handleMutateHotelSetup, isLoading } = useHotelSetup();

  const submitForm = async () => {
    form
      .validateFields()
      .then((values) => {
        if (!values.images[0]?.file) {
          return notification.error(t("location-settings.required-hotel-image"));
        }
        const locationInfo = {
          publicLabel: values.publicLabel,
          googleMapAddress: values.googleMapAddress,
          maxGuests: Number(values.maxCountOfGuests),
          file: values.images[0]?.file
        } as ILocationSettings;

        handleMutateHotelSetup(locationInfo);
      })
      .catch(console.error);
  };

  useEffect(() => {
    dispatch(
      setAuthActivePageInfo({
        titleForm: t("steps.hotel-setup"),
        linkForm: "",
        googleTitle: ""
      })
    );
  }, [pathname]);

  return (
    <AuthLayout current={1}>
      <AuthBasicSettingsForm isDataLoading={isLoading} isAdditional form={form} submitForm={submitForm} />
    </AuthLayout>
  );
};

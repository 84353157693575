
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useCustomMutation } from "@/hooks";

import { api, handleHotelIdInApi } from "@/helpers";

import { ROUTES } from "@/constants";

import { TMutationsOptions } from "@/types";
import { ISeason } from "@/types/season/season.type";

import { setHandleSubmitSeasonData } from "@/store/footer/footer.slice";

const fetcher = async (body: ISeason) => {
  return api.post("seasons", handleHotelIdInApi(body));
};

const { SETTINGS, PRICE_SETUP, EDIT } = ROUTES;

export const useCreateSeason = (options?: TMutationsOptions<ISeason, ISeason>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hotelId } = useParams();

  const { mutate, ...rest } = useCustomMutation<ISeason, ISeason>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      await queryClient.invalidateQueries(["get-seasons"], { exact: false });
      navigate(`/${hotelId}/${SETTINGS}/${PRICE_SETUP}/${data.id}/${EDIT}`, {
        state: {
          isReturn: true
        }
      });
    },
    onSettled: () => {
      dispatch(setHandleSubmitSeasonData(false));
    }
  });

  const handleMutate = (data: ISeason) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useCustomMutation } from "@/hooks";

import { api } from "@/helpers";

import { ROUTES } from "@/constants";

import { ILocationSettings, ILocationSettingsResponse, TMutationsOptions } from "@/types";

import { setHandleCheckLocationUpdating } from "@/store/user/user.slice";

interface ILocationSettingsUpdate {
  hotelId: number;
  userId: number;
  data: ILocationSettings;
}

const fetcher = async (body: ILocationSettingsUpdate): Promise<ILocationSettingsResponse> => {
  const { hotelId, userId, data } = body;
  
return api.patch(
    `/host/hotel/${hotelId}`,
    {
      ...data,
      userId
    },
    {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    }
  );
};

const { SETTINGS } = ROUTES;

export const useUpdateHotel = (
  options?: TMutationsOptions<ILocationSettingsResponse, ILocationSettingsUpdate>
) => {
  const navigate = useNavigate();
  const { hotelId } = useParams();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useCustomMutation<ILocationSettingsResponse, ILocationSettingsUpdate>(
    fetcher,
    {
      ...options,
      onSuccess: async (data, ...args) => {
        options?.onSuccess?.(data, ...args);

        await queryClient.invalidateQueries(["current-user"], { exact: false });

        navigate(`/${hotelId}/${SETTINGS}`);
      },
      onSettled: () => {
        dispatch(setHandleCheckLocationUpdating(false));
      }
    }
  );

  return { mutate, ...rest };
};

import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";

import { FallingOut, Icon } from "@/elements";

import styles from "../modal.module.scss";

import { IBookingModal } from "./ApproveModal.type";

export const ApproveModal: FC<IBookingModal> = ({
    handleApprove,
    handleSkipApprove,
}) => {
  const { t } = useTranslation();

  return (
    <FallingOut
      content={
        <div className={styles["booking-modal__wrapper"]}>
          <div className={styles["booking-modal__container"]}>
            <h1 className={styles["booking-modal__title"]}>{t("booking.approve.title")}</h1>
            <div className={styles["booking-modal__content"]}>
              <div className={styles["booking-modal__item"]}>
                <Icon icon="info" className={styles["booking-modal__icon"]} />
                <span className={styles["booking-modal__text"]}>
                  {t("booking.approve.status-message")}
                </span>
              </div>
            </div>
          </div>
          <div className={styles["booking-modal__btns"]}>
            <Button
              onClick={handleApprove}
              size="large"
              type="primary"
              className={styles["booking-modal__btn"]}
            >
              {t("booking.approve.approve")}
            </Button>
            <Button
              onClick={handleSkipApprove}
              size="large"
              ghost
              className={styles["booking-modal__btn"]}
            >
              {t("booking.approve.decline")}
            </Button>
          </div>
        </div>
      }
    />
  );
};

import i18next from "i18next";
import { object, string } from "yup";

import { allLocationsIdStorageService, checkPhoneValidation, ValidEmail } from "@/helpers";

import { AllLocationType } from "@/constants/global";

const { getItem: getLocationStatus } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

export const guestSchema = object().shape({
  hotelId: string().test("required", (value) => {
    const isAllLocation = getLocationStatus();
    if (isAllLocation) {
      return !!value;
    }
    
return true;
  }),
  fullName: string().required(),
  email: string()
    .nullable()
    .test("email", i18next.t("error.email.email"), (value) => {
      return !(value && !ValidEmail(value));
    }),
  phoneNumber: string()
    .notRequired()
    .test("matches", (value) => {
      if (!value) {
        return true;
      }

      return checkPhoneValidation(value);
    })
});

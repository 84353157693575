import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { useUpdatePassword } from "@/react-queries/user/useUpdatePassword";

import { ROUTES } from "@/constants";

import { ISetNewPassword } from "@/types";

import styles from "./SettingsUpdatePassword.module.scss";

import { AuthSetNewPasswordForm } from "../../Forms/AuthSetNewPasswordForm/AuthSetNewPasswordForm";

import { setHeaderNavigation } from "@/store/header/header.slice";

const { SETTINGS } = ROUTES;

export const SettingsUpdatePassword = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hotelId } = useParams();

  const { mutate: updatePassword } = useUpdatePassword();

  const handleSubmit = (values: ISetNewPassword) => {
    updatePassword({
      newPassword: values.password
    });
  };

  const handleNavigate = () => {
    navigate(`/${hotelId}/${SETTINGS}`)
  }

  useEffect(() => {
    dispatch(
      setHeaderNavigation({
        navigation: {
          title: t("settings.title"),
          link: `/${hotelId}/${SETTINGS}`
        }
      })
    );
  }, [pathname]);

  return (
    <div className={styles.wrapper}>
      <h1 className={styles.title}>{t("settings.update-password.title")}</h1>
      <div className={styles.container}>
        <AuthSetNewPasswordForm handleSubmit={handleSubmit} navigateBackButton={handleNavigate} />
      </div>
    </div>
  );
};

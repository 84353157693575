import React, { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Divider, Tag } from "antd";
import dayjs from "dayjs";

import { Icon, notification } from "@/elements";
import { LocationTag } from "@/elements/LocationTag/LocationTag";

import { useChangeBookingStatus } from "@/react-queries/booking/useChangeBookingStatus";

import { allLocationsIdStorageService } from "@/helpers";

import { BookingStatus } from "@/constants/bookings/bookings.constant";
import { AllLocationType, DATE_DAY_FORMAT, DATE_MONTH_FORMAT } from "@/constants/global";

import { ReactComponent as Edit } from "@/assets/icons/edit.svg";

import styles from "./BookingViewCard.module.scss";

import { ApproveModal } from "./Modals/Approve/ApproveModal";
import { DeclineModal } from "./Modals/Decline/DeclineModal";
import {
  conditionCardStyle,
  conditionGuests,
  conditionNights,
  conditionStatus
} from "./BookingViewCard.helper";
import { IBookingViewCardProps } from "./BookingViewCard.type";

import { RootState } from "@/store";
import {
  setApproveModal,
  setDeclineModal,
  setEditModalStatus
} from "@/store/booking/booking.slice";

const { getItem: getAllLocation } = allLocationsIdStorageService(AllLocationType.ALL_LOCATIONS_ID);

export const BookingViewCard: FC<IBookingViewCardProps> = ({ bookingData, handleEdit, type }) => {
  const { t } = useTranslation();
  const { mutate: handleStatusBooking } = useChangeBookingStatus();
  const isShowDeclineModal = useSelector((state: RootState) => state.booking.view.isDeclineModal);
  const isShowApproveModal = useSelector((state: RootState) => state.booking.view.isApproveModal);
  const countNights = useMemo(() => {
    const checkinDate = dayjs(bookingData?.checkinDate);
    const checkoutDate = dayjs(bookingData?.checkoutDate);
    const hours = checkoutDate.diff(checkinDate, "hours");
    const days = Math.ceil(hours / 24);

    return days;
  }, [bookingData]);

  const dispatch = useDispatch();
  const isAllLocation = getAllLocation();

  const isPending = bookingData?.status === BookingStatus.PENDING;
  const isRejected = bookingData?.status === BookingStatus.REJECTED;
  const isCanceled = bookingData?.status === BookingStatus.CANCELED;
  const isBlocked = type === BookingStatus.BLOCKED;

  const handleModalOpenDecline = () => {
    dispatch(setDeclineModal(true));
  };

  const handleModalCloseDecline = () => {
    dispatch(setDeclineModal(false));
  };

  const handleDecline = () => {
    if (isPending) {
      handleStatusBooking({
        id: String(bookingData?.id) as string,
        status: BookingStatus.REJECTED
      });
    } else {
      handleStatusBooking({
        id: String(bookingData?.id) as string,
        status: BookingStatus.CANCELED
      });
    }
    dispatch(setDeclineModal(false));
    dispatch(setEditModalStatus(false));
    notification.error(
      <span className={styles["booking-view__declined"]}>{
        t(isPending ? "booking.history.rejected" : "booking.history.canceled")
      }</span>
    );
  };

  const handleModalOpenApprove = () => {
    dispatch(setApproveModal(true));
  };

  const handleModalCloseApprove = () => {
    dispatch(setApproveModal(false));
  };

  const handleApprove = () => {
    handleStatusBooking({
      id: String(bookingData?.id) as string,
      status: BookingStatus.APPROVED
    });
    dispatch(setApproveModal(false));
  };

  return (
    <>
      <div
        className={`${styles["booking-view__card"]} ${conditionCardStyle(
          bookingData?.status || type || ""
        )}`}
      >
        <div className={styles["booking-view__card-header"]}>
          {conditionStatus(bookingData?.status || type || "")}
          <div className={styles["booking-view__card-id"]}>{bookingData?.id}</div>
        </div>
        <Divider className={styles["booking-view__card-divider"]} />
        {isAllLocation ? (
          <div className={styles["booking-view__location"]}>
            <LocationTag hotel={bookingData?.hotel} />
          </div>
        ) : null}
        <div className={styles["booking-view__card-dates"]}>
          <div className={styles["booking-view__card-dates-item"]}>
            <h1 className={styles["booking-view__card-dates-item-title"]}>
              {isBlocked ? t("booking.blocked.start-date") : t("booking.view.check-in")}
            </h1>
            <div className={styles["booking-view__card-dates-item-content"]}>
              <span className={styles["booking-view__card-dates-number"]}>
                {dayjs(bookingData?.checkinDate).format(DATE_DAY_FORMAT)}
              </span>
              <p className={styles["booking-view__card-dates-month"]}>
                {dayjs(bookingData?.checkinDate).format(DATE_MONTH_FORMAT)}
              </p>
            </div>
          </div>
          <Tag className={styles["booking-view__card-nights"]}>
            {t(conditionNights(countNights || 0) as string, { count: countNights })}
          </Tag>
          <div className={styles["booking-view__card-dates-item"]}>
            <h1 className={styles["booking-view__card-dates-item-title"]}>
              {isBlocked ? t("booking.blocked.end-date") : t("booking.view.check-out")}
            </h1>
            <div className={styles["booking-view__card-dates-item-content"]}>
              <span className={styles["booking-view__card-dates-number"]}>
                {dayjs(bookingData?.checkoutDate).format(DATE_DAY_FORMAT)}
              </span>
              <p className={styles["booking-view__card-dates-month"]}>
                {dayjs(bookingData?.checkoutDate).format(DATE_MONTH_FORMAT)}
              </p>
            </div>
          </div>
        </div>
        <Divider className={styles["booking-view__card-divider"]} />
        <div className={styles["booking-view__card-details"]}>
          {isBlocked ? (
            <div className={styles["booking-view__card-blocked-container"]}>
              <div className={styles["booking-view__card-blocked-label"]}>
                {t("booking.blocked.block-cause")}
              </div>
              <div className={styles["booking-view__card-blocked-text"]}>{bookingData?.notes}</div>
            </div>
          ) : (
            <>
              <div className={styles["booking-view__card-details-item"]}>
                <h1 className={styles["booking-view__card-details-item-title"]}>
                  {t("booking.view.amount-booking")}
                </h1>
                <div className={styles["booking-view__card-details-item-content"]}>
                  <Icon
                    className={styles["booking-view__card-details-item-icon"]}
                    icon="bankNote"
                  />
                  <p className={styles["booking-view__card-details-item-count"]}>
                    {bookingData?.totalPrice}
                  </p>
                  <p className={styles["booking-view__card-details-item-fiat"]}>
                    {t("booking.view.fiat")}
                  </p>
                </div>
              </div>
              <div className={styles["booking-view__card-details-item"]}>
                <h1 className={styles["booking-view__card-details-item-title"]}>
                  {t("booking.view.guests")}
                </h1>
                <div className={styles["booking-view__card-details-item-content"]}>
                  <Icon
                    className={styles["booking-view__card-details-item-icon"]}
                    icon="outlineUser"
                  />
                  <p className={styles["booking-view__card-details-item-count"]}>
                    {bookingData?.totalGuests}
                  </p>
                  <p className={styles["booking-view__card-details-item-fiat"]}>
                    {t(conditionGuests(bookingData?.totalGuests || 0) as string)}
                  </p>
                </div>
              </div>
            </>
          )}
        </div>
        <Divider className={styles["booking-view__card-divider"]} />
        {isPending && (
          <div className={styles["booking-view__card-buttons"]}>
            <Button
              className={styles["booking-view__card-btn"]}
              onClick={handleModalOpenDecline}
              danger
              ghost
            >
              {t("booking.view.decline")}
            </Button>
            <Button
              onClick={handleModalOpenApprove}
              className={styles["booking-view__card-btn"]}
              type="primary"
            >
              {t("booking.view.approve")}
            </Button>
          </div>
        )}
        {isBlocked && (
          <Button
            onClick={handleEdit}
            icon={<Edit />}
            className={styles["booking-view__card-btn-edit"]}
          >
            {t("booking.blocked.edit-booking")}
          </Button>
        )}
        {!isCanceled && !isRejected && !isBlocked ? (
          <Button
            onClick={handleEdit}
            icon={<Edit />}
            className={styles["booking-view__card-btn-edit"]}
          >
            {t("booking.view.edit")}
          </Button>
        ) : null}
      </div>
      {isShowDeclineModal && (
        <DeclineModal
          buttonTitle={
            isPending ? t("booking.decline-modal.approve") : t("booking.edit.reject-button")
          }
          title={
            isPending ? t("booking.decline-modal.title") : t("booking.edit.canceled-booking-title")
          }
          handleDecline={handleDecline}
          handleSkipDecline={handleModalCloseDecline}
        />
      )}
      {isShowApproveModal && (
        <ApproveModal handleApprove={handleApprove} handleSkipApprove={handleModalCloseApprove} />
      )}
    </>
  );
};

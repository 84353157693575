import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { AlphabeticList } from "../../components/AlphabeticList/AlphabeticList";
import { EmptyContent } from "../../components/EmptyContent/EmptyContent";
import { IGuest } from "../../components/Guest/GuestList/GuestList.type";

import { FallingOut, Icon, Loader, Search } from "@/elements";

import { useGetGuests } from "@/react-queries/guests/useGetGuests";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { handleScroll } from "@/helpers";

import { BookingCreateSteps, SegmentTypes } from "@/constants/bookings/bookings.constant";
import { ScrollTypes } from "@/constants/global";

import styles from "./BookingGuestList.module.scss";

import { RootState } from "@/store";
import {
  setGuest,
  setHandleBookingCreationStep,
  setHandleBookingGuestList,
  setHandleSwitchType
} from "@/store/booking/booking.slice";

export const BookingGuestList = () => {
  const [search, setSearch] = useState("");
  const isShowBookingGuestList = useSelector((state: RootState) => state.booking.main.isShowBookingGuestList);

  const {
    data: guests,
    isLoading,
    refetch: refetchGuestList
  } = useGetGuests({
    searchParams: search || ""
  });

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isMobile = useGetScreenStatus();

  const handleClose = () => {
    if (!isMobile) {
      dispatch(setHandleSwitchType(SegmentTypes.LIST));

      return dispatch(setHandleBookingCreationStep(BookingCreateSteps.CREATE));
    }

    dispatch(setHandleBookingGuestList(false));
  };

  const handleGuestData = (item: IGuest) => {
    dispatch(setGuest(item));
    if (!isMobile) {
      dispatch(setHandleSwitchType(SegmentTypes.LIST));

      return dispatch(setHandleBookingCreationStep(BookingCreateSteps.CREATE));
    }

    return dispatch(setHandleBookingGuestList(false));
  };

  const onSearch = (query: string) => {
    setSearch(query);
  };

  useEffect(() => {
    isShowBookingGuestList ? handleScroll(ScrollTypes.ADD) : handleScroll(ScrollTypes.REMOVE);
  }, [isShowBookingGuestList])

  const contentForRender = useMemo(() => {
    if (!guests?.length) {
      return (
        <EmptyContent
          className={styles["booking-guests-empty__container"]}
          content={t("guests.empty-content")}
        />
      );
    }

    return (
      <div className={styles["booking-guests__list"]}>
        <AlphabeticList handleAction={handleGuestData} data={guests} />
      </div>
    );
  }, [guests]);

  const conditionContentRender = useMemo(() => {
    return (
      <div className={styles["booking-guests__content"]}>
        {!isMobile ? (
          <div className={styles["booking-guests__header"]}>
            <div className={styles["booking-guests__header-back"]} onClick={handleClose}>
              <div className={styles["booking-guests__header-icon-container"]}>
                <Icon icon="arrowLeft" className={styles["logo-header"]} />
              </div>
              <span className={styles["booking-guests__header-icon-title"]}>
                {t("booking.create.back")}
              </span>
            </div>
            <h1 className={styles["booking-guests__header-title"]}>
              {t("booking.create.guest-list")}
            </h1>
          </div>
        ) : null}
        <div className={styles["booking-guests__search"]}>
          <Search value={search} isSmall active onSearch={onSearch} />
        </div>
        {contentForRender}
      </div>
    );
  }, [guests]);

  useEffect(() => {
    if (typeof search === "string") {
      refetchGuestList();
    }
  }, [search]);

  if (isLoading) {
    return <Loader isFullScreen />;
  }

  if (!guests?.length) {
    if (!isMobile) {
      return conditionContentRender;
    }

    return (
      <FallingOut
        title={t("booking.create.guest-list")}
        handleClose={handleClose}
        content={conditionContentRender}
      />
    );
  }

  if (!isMobile) {
    return conditionContentRender;
  }

  return (
    <FallingOut
      title={t("booking.create.guest-list")}
      handleClose={handleClose}
      content={conditionContentRender}
    />
  );
};

import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Form, Row } from "antd";
import dayjs from "dayjs";

import { FallingOut, FormItem, Icon, Input } from "@/elements";
import { AppDrawer } from "@/elements/Drawer/Drawer";

import { useUpdateDaySpecialPrice } from "@/react-queries/booking/useUpdateDaySpecialPrice";

import { useYupSync } from "@/hooks";
import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { handleScroll } from "@/helpers";

import { CalendarDayPriceStage } from "@/constants/calendar/calendar.constants";
import {
  DATE_MONTH_YEAR_FORMAT,
  DATE_SHORT_MONTH_DATE_FORMAT,
  ScrollTypes
} from "@/constants/global";

import { bookingEditPriceSchema } from "./BookingEditPriceModal.schema";

import { IChangePriceDayCalendar } from "@/types";

import styles from "./BookingEditPriceModal.module.scss";

import { RootState } from "@/store";
import {
  clearChoosenCalendarBooking,
  setHandleEditPriceModal
} from "@/store/booking/booking.slice";

export const BookingEditPriceModal = () => {
  const [form] = Form.useForm<IChangePriceDayCalendar>();
  const yupSync = useYupSync(bookingEditPriceSchema);
  const dispatch = useDispatch();
  const [isShowChanges, setIsShowChanges] = useState(false);
  const [changedValues, setChangedValues] = useState<number>(0);
  const [changePriceDayStage, setChangePriceDayStage] = useState<CalendarDayPriceStage>(
    CalendarDayPriceStage.CHANGES
  );
  const isMobile = useGetScreenStatus();
  const isShowEditPriceModal = useSelector(
    (state: RootState) => state.booking.isShowEditPriceModal
  );

  const { mutate: handleUpdatePrice, isLoading: isPriceUpdating } = useUpdateDaySpecialPrice();

  const { t } = useTranslation();

  const chooseCalendarBooking = useSelector(
    (state: RootState) => state.booking.choosenCalendarBooking
  );

  const conditionTitle =
    changePriceDayStage === CalendarDayPriceStage.CHANGES ? (
      <span className={styles["modal-title"]}>
        {dayjs(dayjs(chooseCalendarBooking?.date)).format(DATE_MONTH_YEAR_FORMAT)}
      </span>
    ) : (
      t("booking.edit.price.finish-header-title")
    );

  const handleClose = () => {
    dispatch(setHandleEditPriceModal(false));
    dispatch(clearChoosenCalendarBooking());
    handleScroll(ScrollTypes.REMOVE);
  };

  const nextStage = (values: IChangePriceDayCalendar) => {
    setChangedValues(values.totalPrice || 0);
    setChangePriceDayStage(CalendarDayPriceStage.CONFIRMED);
  };

  const handleChanges = (_: IChangePriceDayCalendar, values: IChangePriceDayCalendar) => {
    if (chooseCalendarBooking.date) {
      for (const key of Object.keys(values)) {
        if (
          Number(values[key as keyof IChangePriceDayCalendar]) !==
          chooseCalendarBooking[key as keyof IChangePriceDayCalendar]
        ) {
          setIsShowChanges(true);
          break;
        }
        setIsShowChanges(false);
      }
    }
  };

  const handleSubmit = () => {
    if (chooseCalendarBooking) {
      handleUpdatePrice({
        date: chooseCalendarBooking.date as string,
        price: Number(changedValues),
        hotelId: chooseCalendarBooking?.hotelId as number
      });
    }
  };

  useEffect(() => {
    if (chooseCalendarBooking) {
      form.setFieldsValue({
        totalPrice: chooseCalendarBooking.totalPrice
      });
    }
  }, [chooseCalendarBooking]);

  const renderContent = () => {
    if (changePriceDayStage === CalendarDayPriceStage.CHANGES) {
      return (
        <Form
          className={styles.container}
          onFinish={nextStage}
          onValuesChange={handleChanges}
          form={form}
        >
          <h1 className={styles.title}> {t("booking.edit.price.title")} </h1>
          <Row gutter={24}>
            <Col xs={24}>
              <FormItem rules={[yupSync]} required name="totalPrice">
                <Input
                  className={styles["booking-price-edit__input"]}
                  type="number"
                  min={1}
                  suffix={t("booking.edit.price.fiat")}
                />
              </FormItem>
            </Col>
          </Row>
          {isShowChanges && (
            <div className={styles["booking-price-edit__change"]}>
              <Icon icon="infoOutline" />
              <Trans
                i18nKey="booking.edit.price.changed-price"
                values={{
                  date: dayjs(dayjs(chooseCalendarBooking?.date)).format(
                    DATE_SHORT_MONTH_DATE_FORMAT
                  )
                }}
                components={{
                  date: <span className={styles["booking-price-edit__change-date"]} />
                }}
              />
            </div>
          )}
          <Button
            onClick={form.submit}
            disabled={!isShowChanges}
            type="primary"
            className={styles.button}
          >
            {t("booking.edit.price.save")}
          </Button>
        </Form>
      );
    }

    return (
      <>
        <h1 className={styles["booking-price-edit__confirm-title"]}>
          {t("booking.edit.price.finish-title")}
        </h1>
        <div className={styles["booking-price-edit__confirm-content"]}>
          <div className={styles["booking-price-edit__confirm-item"]}>
            <Icon icon="calendar" />
            <div className={`${styles["booking-price-edit__confirm-item-value"]} ${styles.date}`}>
              {dayjs(dayjs(chooseCalendarBooking?.date)).format(DATE_SHORT_MONTH_DATE_FORMAT)}
            </div>
          </div>
          <div className={styles["booking-price-edit__confirm-item"]}>
            <Icon icon="bankNote" />
            <div className={styles["booking-price-edit__confirm-item-value"]}>
              {changedValues} {t("booking.edit.price.fiat")}
            </div>
          </div>
        </div>
        <div className={styles["booking-price-edit__confirm-btns"]}>
          <Button
            loading={isPriceUpdating}
            onClick={handleSubmit}
            size="large"
            type="primary"
            className={styles["booking-price-edit__confirm-btn"]}
          >
            {t("booking.edit.price.button-approve")}
          </Button>
          <Button
            onClick={handleClose}
            size="large"
            ghost
            className={styles["booking-price-edit__confirm-btn"]}
          >
            {t("booking.edit.price.decline")}
          </Button>
        </div>
      </>
    );
  };

  if (!isMobile) {
    return (
      <AppDrawer title={conditionTitle} onClose={handleClose} open={isShowEditPriceModal}>
        {renderContent()}
      </AppDrawer>
    );
  }

  return (
    <FallingOut
      title={conditionTitle}
      handleClose={handleClose}
      content={<div className={styles.container}>{renderContent()}</div>}
    />
  );
};

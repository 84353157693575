import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import i18next from "i18next";

import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { allLocationsIdStorageService, api, getJWTToken, hotelIdStorageService, langStorageService } from "@/helpers";

import { ROUTES } from "@/constants";
import { AllLocationType, HotelType, LanguageType } from "@/constants/global";

import { IUserWithHotel, TQueryOptions } from "@/types";

import { setActiveHotel } from "@/store/hotel/hotel.slice";

const { setItem, getItem } = hotelIdStorageService(HotelType.HOTEL_ID);
const { setItem: setAppLang } = langStorageService<string>(LanguageType.LANG);

const { getItem: getIsAllLocation } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

const { AUTH, HOTEL_SETUP, DASHBOARD } = ROUTES;

const fetcher = async () => {
  return api.get("host/user/profile");
};

export const useCurrentUser = (options?: TQueryOptions<IUserWithHotel, IUserWithHotel>) => {
  const token = getJWTToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return useCustomQuery<IUserWithHotel, IUserWithHotel>(["current-user"], fetcher, {
    ...options,
    onSuccess: (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      const hotelId = getItem();
      const isAllLocation = getIsAllLocation();
      setAppLang(data.appLanguage);
      i18next.changeLanguage(data.appLanguage);

      if (isAllLocation) {
        return;
      }
      if (!hotelId) {
        const firstHotel = (data as IUserWithHotel)?.hotels?.[0];
        if (!firstHotel) {
          return navigate(`${AUTH}${HOTEL_SETUP}`);
        }
        dispatch(setActiveHotel(firstHotel));
        setItem(String(firstHotel.id));

        navigate(`/${firstHotel.id}/${DASHBOARD}`);
      } else {
        const userHotel = (data as IUserWithHotel)?.hotels?.find(
          (hotel) => hotel.id === Number(hotelId)
        );

        if (userHotel) {
          dispatch(setActiveHotel(userHotel));
          setItem(String(userHotel.id));
        } else {
          const firstHotel = (data as IUserWithHotel)?.hotels?.[0];
          if (!firstHotel) {
            return navigate(`${AUTH}${HOTEL_SETUP}`);
          }
          dispatch(setActiveHotel(firstHotel));
          setItem(String(firstHotel.id));
        }
      }
    },
    staleTime: Infinity,
    enabled: !!token
  });
};

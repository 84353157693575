import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IUserWithHotel } from "@/types";

export interface UserSlice {
  main: IUserWithHotel | null;
  isLocationUpdating: boolean;
}

const initialState: UserSlice = {
  main: null,
  isLocationUpdating: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserInformation: (state, action: PayloadAction<IUserWithHotel>) => {
      state.main = action.payload;
    },
    setHandleCheckLocationUpdating: (state, action: PayloadAction<boolean>) => {
      state.isLocationUpdating = action.payload;
    },
    clearUserInfo: (state) => {
      state.main = null;
    }
  }
});

export const { setUserInformation, clearUserInfo, setHandleCheckLocationUpdating } = userSlice.actions;

export default userSlice.reducer;

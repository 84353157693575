import { RcFile } from "antd/es/upload";
import CryptoJS from "crypto-js";

import { useCustomMutation } from "@/hooks";

import { api } from "@/helpers";

import { isRcFile } from "@/types";

export interface IDirectUploadBody extends Record<string, unknown> {
  file: {
    filename: string;
    byteSize: number;
    checksum: string;
    contentType: string;
    metadata?: {
      message: string;
    };
  };
}

const md5FromFile = (file: File): Promise<CryptoJS.lib.WordArray> => {
  return new Promise((resolve) => {
    const reader = new FileReader();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reader.onload = (fileEvent: any) => {
      if (fileEvent?.target?.result) {
        const binary = CryptoJS.lib.WordArray.create(fileEvent.target.result);
        const md5 = CryptoJS.MD5(binary);
        resolve(md5);
      }
    };

    reader.readAsArrayBuffer(file);
  });
};

export const fileChecksum = async (file: File) => {
  const md5 = await md5FromFile(file);

  return md5.toString(CryptoJS.enc.Base64);
};

const fetcher = async (body: IDirectUploadBody) => {
  const res = await api.post("/direct-upload", body);

  return res.directUpload;
};

export const useCreatePreSignedUrl = () => {
  const { mutateAsync, ...rest } = useCustomMutation(fetcher, {
    preventShowDefaultErrorToast: true
  });

  const handleMutate = async (file: string | RcFile | Blob) => {
    if (isRcFile(file)) {
      const checksum = await fileChecksum(file);

      if (checksum) {
        return mutateAsync({
          file: {
            checksum,
            filename: file.name,
            byteSize: file.size,
            contentType: file.type
          }
        });
      }
    }
  };

  return { mutateAsync: handleMutate, ...rest };
};

import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { api } from "@/helpers";

import { TQueryOptions } from "@/types";
import { ISeason } from "@/types/season/season.type";

const fetcher = async (userId: string) => {
  return api.get(`seasons/${userId}`);
};

export const useGetSeason = <TQueryFnData = ISeason, TData = ISeason>(
  userId: string,
  options?: TQueryOptions<TQueryFnData, TData>
) => {

  return useCustomQuery<TQueryFnData, TData>(["get-season"], () => fetcher(userId), {
    ...options,
    staleTime: Infinity,
  });
};

import React, { useEffect, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Form } from "antd";
import dayjs from "dayjs";

import { BookingGuestList } from "@/containers/BookingGuestList/BookingGuestList";

import { useGetDynamicPrice } from "@/react-queries/booking/useGetDynamicPrice";

import { handleScroll } from "@/helpers";

import { BookingCreateSteps } from "@/constants/bookings/bookings.constant";
import { DATE_FORMAT, ScrollTypes } from "@/constants/global";

import { IBookingForm } from "@/types";

import styles from "./BookingCreate.module.scss";

import { BookingForm } from "../BookingForm/BookingForm";
import { CancellationModal } from "../CancellationModal/CancellationModal";

import { RootState } from "@/store";
import {
  clearBookingStates,
  clearChoosenCalendarBooking,
  setHandleAllLocationId,
  setHandleDiscardBookingCreation
} from "@/store/booking/booking.slice";

export const BookingCreateContent = () => {
  const booking = useSelector((state: RootState) => state.booking.main);
  const [guestCountError, setGuestCountError] = React.useState(false);
  const bookingCreationStep = useSelector((state: RootState) => state.booking.creation.desktopStep);
  const activeHotel = useSelector((state: RootState) => state.hotel.activeHotel);
  const allLocationChosenHotelId = useSelector(
    (state: RootState) => state.booking.allLocation.chosenLocationId
  );
  const allLocationChosenHotelIdFromCalendar = useSelector(
    (state: RootState) => state.booking.choosenCalendarBooking.hotelId
  );
  const userHotel = useSelector((state: RootState) =>
    state.user.main?.hotels?.find(
      (hotel) =>
        hotel.id === Number(allLocationChosenHotelId || allLocationChosenHotelIdFromCalendar)
    )
  );
  const [guestCount, setGuestCount] = React.useState<number | null>(null);
  const [nightsCount, setNightsCount] = React.useState<number | null>(null);
  const [checkinDate, setCheckinDate] = React.useState<string | null>(null);
  const [amount, setAmount] = React.useState<number | null>(null);
  const chooseCalendarDay = useSelector((state: RootState) => state.booking.choosenCalendarBooking);
  const isDisabledSubmitButton = useSelector(
    (state: RootState) => state.booking.creation.handleDisabledSubmitButton
  );

  const conditionForShowBookingDetails = useMemo(() => {
    return amount || (guestCount && nightsCount);
  }, [nightsCount, guestCount, amount]);

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const { mutate: handleDynamicPrice, data: dynamicTotalPrice } = useGetDynamicPrice();

  const { t } = useTranslation();

  const handleValueChange = (currentValue: IBookingForm, values: IBookingForm) => {
    const isTotalGuestKey = Object.keys(currentValue).includes("totalGuests");
    const isTotalPriceKey = Object.keys(currentValue).includes("totalPrice");
    const isCheckInDateKey = Object.keys(currentValue).includes("checkinDate");
    const isCheckOutDateKey = Object.keys(currentValue).includes("checkoutDate");

    if (isCheckInDateKey) {
      setCheckinDate(currentValue.checkinDate);
    }

    if (isCheckOutDateKey || isCheckInDateKey || isTotalGuestKey) {
      if (values.checkinDate && values.checkoutDate && values.totalGuests) {
        handleDynamicPrice({
          checkinDate: dayjs(values.checkinDate).format(DATE_FORMAT),
          checkoutDate: dayjs(values.checkoutDate).format(DATE_FORMAT),
          totalGuests: +values.totalGuests
        });
      }
    }

    if (values.checkinDate && values.checkoutDate) {
      if (!dayjs(values.checkinDate).isBefore(values.checkoutDate)) {
        form.setFieldsValue({
          checkoutDate: values.checkinDate,
          checkinDate: values.checkoutDate
        });
      }
      if (dayjs(values.checkinDate).isSame(values.checkoutDate, "day")) {
        form.setFieldsValue({
          checkoutDate: dayjs(values.checkoutDate).add(1, "day") as unknown as string
        });
      }
      const countOfHours = dayjs(form.getFieldValue("checkoutDate")).diff(
        form.getFieldValue("checkinDate"),
        "hours"
      );
      const days = Math.ceil(countOfHours / 24);
      setNightsCount(days);
    }

    setGuestCountError(
      !!values.totalGuests &&
        values.totalGuests > (activeHotel?.maxGuests || userHotel?.maxGuests || 1)
    );

    if (isTotalGuestKey) {
      setGuestCount(currentValue.totalGuests);
    }

    if (isTotalPriceKey) {
      setAmount(currentValue.totalPrice);
    }
  };

  const handleDiscardBookingCreation = () => {
    dispatch(setHandleDiscardBookingCreation(true));
    handleScroll(ScrollTypes.ADD);
  };

  const handleCancellationClose = () => {
    dispatch(setHandleDiscardBookingCreation(false));
    dispatch(clearChoosenCalendarBooking());
    dispatch(setHandleAllLocationId(null));
  };

  const discardBookingCreation = () => {
    dispatch(clearBookingStates());
    dispatch(clearChoosenCalendarBooking());
    dispatch(setHandleAllLocationId(null));
  };

  useEffect(() => {
    if (chooseCalendarDay?.date) {
      form.setFieldsValue({
        checkinDate: dayjs(chooseCalendarDay.date, DATE_FORMAT) as unknown as string
      });
      setCheckinDate(chooseCalendarDay.date);
    }
  }, [chooseCalendarDay]);

  if (bookingCreationStep === BookingCreateSteps.GUESTS) {
    return <BookingGuestList />
  }

  return (
    <>
      <div className={styles["booking-create__wrapper"]}>
        <div className={styles["booking-create__content"]}>
          <BookingForm
            form={form}
            guestCountError={guestCountError}
            handleValueChange={handleValueChange}
            checkinDate={checkinDate}
            setAmount={setAmount}
            dynamicTotalPrice={dynamicTotalPrice}
          />
        </div>
        <div className={styles["booking-create__buttons"]}>
          {conditionForShowBookingDetails ? (
            <div className={styles["booking-create__details"]}>
              <h2 className={styles["booking-create__details-title"]}>
                {t("booking.create.detail-title")}
              </h2>
              <div className={styles["booking-create__details-items"]}>
                <span className={styles["booking-create__details-item"]}>
                  <Trans
                    i18nKey="booking.create.detail-nights"
                    values={{ count: nightsCount || 0 }}
                    components={{
                      span: <span className={styles["booking-create__details-item_bold"]} />
                    }}
                  />
                </span>
                <span className={styles["booking-create__details-item"]}>
                  <Trans
                    i18nKey="booking.create.detail-guests"
                    values={{ count: guestCount }}
                    components={{
                      span: <span className={styles["booking-create__details-item_bold"]} />
                    }}
                  />
                </span>
              </div>
              <p className={styles["booking-create__details-item"]}>
                <Trans
                  i18nKey="booking.create.detail-amount"
                  values={{ count: amount }}
                  components={{
                    span: <span className={styles["booking-create__details-item_bold"]} />
                  }}
                />
              </p>
            </div>
          ) : null}
          <div className={styles["booking-create__buttons-container"]}>
            <Button
              size="large"
              type="primary"
              onClick={form.submit}
              disabled={guestCountError || isDisabledSubmitButton}
              className={styles["booking-create__button"]}
            >
              {t("booking.create.continue")}
            </Button>
            <Button
              onClick={handleDiscardBookingCreation}
              size="large"
              ghost
              className={styles["booking-create__button"]}
            >
              {t("booking.create.cancel")}
            </Button>
          </div>
        </div>
      </div>
      {booking.isShowBookingGuestList && <BookingGuestList />}
      {booking.isDiscardBookingCreation && (
        <CancellationModal
          title={t("booking.create.cancellation-title")}
          handleApprove={discardBookingCreation}
          handleClose={handleCancellationClose}
          approveButtonText={t("booking.create.cancellation")}
          declineButtonText={t("booking.create.cancellation-continue")}
        />
      )}
    </>
  );
};

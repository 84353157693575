import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Divider } from "antd";
import dayjs from "dayjs";

import { LocationTag } from "@/elements/LocationTag/LocationTag";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { allLocationsIdStorageService } from "@/helpers";

import { ROUTES } from "@/constants";
import { BookingPaymentStatus, BookingStatus } from "@/constants/bookings/bookings.constant";
import {
  AllLocationType,
  DATE_DAY_FORMAT,
  DATE_DAY_OF_WEEK_FORMAT,
  DATE_MONTH_FORMAT
} from "@/constants/global";

import { IBooking } from "@/types";

import styles from "./BookingCard.module.scss";

import { getTypeOfPayment } from "../BookingPayment/BookingPayment.helper";

import { setApproveModal, setDeclineModal, setHandleChooseBooking } from "@/store/booking/booking.slice";

const { BOOKINGS, VIEW } = ROUTES;

const { getItem: getAllLocationStatus } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

export const BookingCard = ({ booking }: { booking?: IBooking }) => {
  const { t } = useTranslation();
  const { hotelId } = useParams();
  const navigate = useNavigate();
  const isAllLocations = getAllLocationStatus();
  const isMobile = useGetScreenStatus();
  const dispatch = useDispatch();
  const isPending = useMemo(() => {
    return booking?.status === BookingStatus.PENDING;
  }, [booking]);
  const isApproved = useMemo(() => {
    return booking?.status === BookingStatus.APPROVED;
  }, [booking]);

  const typeOfStatusOfPayment = getTypeOfPayment({
    amountValue: booking?.paidAmount || 0,
    totalValue: booking?.totalPrice || 0
  });

  const isPartPaymentStatus = typeOfStatusOfPayment === BookingPaymentStatus.PART_PAY;

  const handleViewBooking = () => {
    navigate(`/${hotelId}/${BOOKINGS}/${booking?.id}/${VIEW}`);
  };

  const handleModalOpenDecline = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (booking) {
      dispatch(setHandleChooseBooking(booking))
    }

    dispatch(setDeclineModal(true));
  };

  const handleModalOpenApprove = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    if (booking) {
      dispatch(setHandleChooseBooking(booking))
    }

    dispatch(setApproveModal(true));
  };

  return (
    <>
      <div className={styles["booking-card__container"]} onClick={handleViewBooking}>
        {isAllLocations ? (
          <div className={styles["booking-card__location"]}>
            <LocationTag hotel={booking?.hotel} />
          </div>
        ) : null}
        <div className={styles["booking-card__checks"]}>
          <div className={styles["booking-card__check-item"]}>
            <span className={styles["booking-card__check-day"]}>
              {dayjs(booking?.checkinDate).format(DATE_DAY_FORMAT)}
            </span>
            <div className={styles["booking-card__check-date"]}>
              <span>{dayjs(booking?.checkinDate).format(DATE_MONTH_FORMAT)}</span>,
              <span className={styles["booking-card__check-date-day"]}>
                {dayjs(booking?.checkinDate).format(DATE_DAY_OF_WEEK_FORMAT)}
              </span>
            </div>
          </div>
          <Divider className={styles["booking-card__check-divider"]} />
          <div className={styles["booking-card__check-item"]}>
            <span className={styles["booking-card__check-day"]}>
              {dayjs(booking?.checkoutDate).format(DATE_DAY_FORMAT)}
            </span>
            <div className={styles["booking-card__check-date"]}>
              <span>{dayjs(booking?.checkoutDate).format(DATE_MONTH_FORMAT)}</span>,
              <span className={styles["booking-card__check-date-day"]}>
                {dayjs(booking?.checkoutDate).format(DATE_DAY_OF_WEEK_FORMAT)}
              </span>
            </div>
          </div>
        </div>
        <div className={styles["booking-card__user-details"]}>
          <h2 className={styles["booking-card__user-name"]}>{booking?.guest.fullName}</h2>
          <p className={styles["booking-card__user-phone"]}>{booking?.guest.phoneNumber}</p>
        </div>
        <Divider className={styles["booking-card__divider"]} />
        <div className={styles["booking-card__info-wrapper"]}>
          <div className={styles["booking-card__info"]}>
            <span className={styles["booking-card__info-title"]}>
              {t(isApproved && !isMobile ? "booking.view.payment.label" : "booking.amount")}
            </span>
            <div className={styles["booking-card__info-amount"]}>
              <span className={styles["booking-card__info-amount-count"]}>
                {isApproved && !isMobile ? (
                  <>
                    <span
                      className={`${styles["booking-card__payment"]} ${
                        styles[
                          getTypeOfPayment({
                            amountValue: booking?.paidAmount || 0,
                            totalValue: booking?.totalPrice || 0
                          })
                        ]
                      }`}
                    >
                      {booking?.paidAmount}
                    </span>
                    / {booking?.totalPrice}
                  </>
                ) : (
                  booking?.totalPrice
                )}
              </span>
              <span className={styles["booking-card__info-amount-fiat"]}>
                {t("booking.payment.unit")}
              </span>
            </div>
          </div>
        </div>
        <div className={styles["booking-card__buttons"]}>
          {isMobile || !isPending ? null : (
            <>
              <Button
                ghost
                className={styles["booking-card__button-cancel"]}
                onClick={handleModalOpenDecline}
              >
                {t("booking.view.decline")}
              </Button>
              <Button
                onClick={handleModalOpenApprove}
                type="primary"
                className={styles["booking-card__button-approve"]}
              >
                {t("booking.view.approve")}
              </Button>
            </>
          )}
          {isPartPaymentStatus && isApproved && !isMobile ? (
            <div className={styles["booking-card__payment-part"]}>
              <p className={styles["booking-card__payment-part-title"]}>
                {t("booking.view.payment.to-be-paid")}
              </p>
              <p className={styles["booking-card__payment-part-amount"]}>
                {(booking?.totalPrice || 0) - (booking?.paidAmount || 0)}
              </p>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

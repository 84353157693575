
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { IGuestForm } from "@/components/Forms/GuestForm/GuestForm.type";

import { useCustomMutation } from "@/hooks";

import { api, handleHotelIdInApi } from "@/helpers";

import { TMutationsOptions } from "@/types";

import { setCreatedUserId, setCreateGuestStatus } from "@/store/guests/guests.slice";

const fetcher = async (body: IGuestForm) => {
  return api.post("guests", handleHotelIdInApi(body));
};

export const useCreateGuest = (options?: TMutationsOptions<IGuestForm, IGuestForm>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useCustomMutation<IGuestForm, IGuestForm>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      dispatch(setCreateGuestStatus(true));
      dispatch(setCreatedUserId(data.id || ""));
      await queryClient.invalidateQueries(["get-guests"], { exact: false });
    }
  });

  const handleMutate = (data: IGuestForm) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

import React, { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";

import { Icon } from "@/elements";
import { IWeekdayType } from "@/elements/Weekdays/Weekdays.type";

import { ROUTES } from "@/constants";

import styles from "./SeasonSetupListTariffs.module.scss";

import { PriceSetupTariffItem } from "../../PriceSetupTariffItem/PriceSetupTariffItem";
import { getWeekDays } from "../SeasonTariff/SeasonTariff.helper";
import { ISeasonTariffForm } from "../SeasonTariff/SeasonTariff.type";

const { SETTINGS, PRICE_SETUP, SEASON_TARIFF, CREATE } = ROUTES;
const MAX_WEEK_DAYS = 7;

export const SeasonSetupListTariffs = React.memo(function SeasonSetupListTariffs({
  tariffs,
}: {
  tariffs: ISeasonTariffForm[] | undefined;
}) {
  const { t } = useTranslation();
  const { priceSetupId, hotelId } = useParams();
  const navigate = useNavigate();
  const isCreate = !priceSetupId;

  const notChooseDays = useMemo(() => {
    const weekDayList = getWeekDays();
    const getChoosenWeekdays = tariffs?.map((tariff) => tariff.daysOfWeek as string[]).flat();

    return weekDayList.filter(
      (weekday: IWeekdayType) => !getChoosenWeekdays?.includes(weekday.fullName)
    );
  }, [tariffs]);

  const handleRedirectToTariff = () => {
    navigate(`/${hotelId}/${SETTINGS}/${PRICE_SETUP}/${priceSetupId}/${SEASON_TARIFF}/${CREATE}`);
  };

  const handleRenderContent = () => {
    if (!isCreate && tariffs && notChooseDays.length && notChooseDays.length < MAX_WEEK_DAYS) {
      return (
        <div className={styles.tariffs__error}>
          <Icon icon="infoOutline" />
          <div>
            <Trans
              i18nKey="price-setup.tariff.not-selected-days"
              values={{
                days: notChooseDays.map((weekday: IWeekdayType) => weekday.shortName).join(", ")
              }}
              components={{
                errorText: <p className={styles["tariffs__error-list"]} />
              }}
            />
          </div>
        </div>
      );
    }

    if (!isCreate && tariffs && !notChooseDays.length) {
      return null;
    }

    return (
      <div className={styles.tariffs__empty}>
        <Icon icon="infoOutline" />
        <div className={styles["tariffs__empty-text"]}>
          {isCreate ? t("price-setup.form.create-season") : t("price-setup.form.empty-tariffs")}
        </div>
      </div>
    );
  };

  return (
    <>
      {!isCreate && !notChooseDays.length ? (
        <div className={styles.tariffs__content}>
          <div className={styles.tariffs__success}>
            <Icon icon="checkOutline" />
            <div className={styles["tariffs__success-text"]}>
              {t("price-setup.form.full-days-check")}
            </div>
          </div>
        </div>
      ) : null}
      {!isCreate && tariffs?.length ? (
        <div className={styles.tariffs__list}>
          {tariffs?.map((tariff) => {
            return <PriceSetupTariffItem key={tariff.id} tariff={tariff} />;
          })}
        </div>
      ) : null}
      {notChooseDays.length || isCreate ? (
        <div className={styles.tariffs__content}>
          {handleRenderContent()}
          {isCreate ? null : (
            <Button
              onClick={handleRedirectToTariff}
              ghost
              type="primary"
              className={styles.tariffs__button}
            >
              <Icon icon="roundedPlus" />
              {t("price-setup.form.add-tariff")}
            </Button>
          )}
        </div>
      ) : null}
    </>
  );
});

import React, { FC, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Divider, Form, Row } from "antd";
import dayjs from "dayjs";

import { DatePicker, FormItem, Icon, Input } from "@/elements";

import { useCheckDates } from "@/react-queries/booking/useCheckBookingDates";
import { useGetDynamicPrice } from "@/react-queries/booking/useGetDynamicPrice";
import { useUpdateBooking } from "@/react-queries/booking/useUpdateBooking";

import { useYupSync } from "@/hooks";

import { handleDatePicker } from "@/helpers";

import { BookingStatus } from "@/constants/bookings/bookings.constant";
import { DATE_FORMAT } from "@/constants/global";

import { bookingEditSchema } from "./BookingEdit.schema";

import styles from "./BookingEdit.module.scss";

import { IBookingEdit, IBookingEditForm } from "./BookingEdit.type";

import { RootState } from "@/store";
import { setDeclineModal } from "@/store/booking/booking.slice";

export const BookingEditContent: FC<IBookingEdit> = ({ bookingData, closeModal }) => {
  const [checkinDate, setCheckinDate] = React.useState<string | null>(
    bookingData?.checkinDate || ""
  );
  const [guestCountError, setGuestCountError] = React.useState(false);

  const [form] = Form.useForm<IBookingEditForm>();
  const { t } = useTranslation();
  const yupSync = useYupSync(bookingEditSchema);
  const isApproved = bookingData?.status === BookingStatus.APPROVED;
  const dispatch = useDispatch();
  const userLocations = useSelector((state: RootState) => state.user.main?.hotels);

  const isShowDateError = useSelector((state: RootState) => state.booking.edit.isShowDateError);
  const activeLocation = useSelector((state: RootState) => state.hotel.activeHotel);
  const allLocationChosenLocation = useSelector(
    (state: RootState) => state.booking.allLocation.chosenLocationId
  );

  const activeHotel = useMemo(() => {
    if (activeLocation) {
      return activeLocation;
    }

    if (allLocationChosenLocation && userLocations) {
      return userLocations.find((hotel) => String(hotel.id) === allLocationChosenLocation);
    }

    return null;
  }, [activeLocation, allLocationChosenLocation]);

  const { mutate: updateBooking } = useUpdateBooking();
  const { mutate: handleCheckDates } = useCheckDates();
  const { mutate: handleDynamicPrice, data: dynamicTotalPrice } = useGetDynamicPrice();

  const compareData = (data: IBookingEditForm): Partial<IBookingEditForm> => {
    if (!bookingData) {
      return {};
    }
    const result: Partial<IBookingEditForm> = {};
    const bookingCheckinDate = dayjs(bookingData.checkinDate).format(DATE_FORMAT);
    const bookingCheckoutDate = dayjs(bookingData.checkoutDate).format(DATE_FORMAT);
    const dataBookingCheckInDate = dayjs(data.checkinDate).format(DATE_FORMAT);
    const dataBookingCheckOutDate = dayjs(data.checkoutDate).format(DATE_FORMAT);

    if (bookingCheckinDate !== dataBookingCheckInDate) {
      result.checkinDate = dataBookingCheckInDate;
    }

    if (bookingCheckoutDate !== dataBookingCheckOutDate) {
      result.checkoutDate = dataBookingCheckOutDate;
    }

    if (bookingData.totalPrice !== data.totalPrice) {
      result.totalPrice = Number(data.totalPrice);
    }

    if (bookingData.totalGuests !== data.totalGuests) {
      result.totalGuests = Number(data.totalGuests);
    }

    return result;
  };

  const handleSubmit = (data: IBookingEditForm) => {
    if (!guestCountError && bookingData) {
      updateBooking({
        id: bookingData.id,
        ...compareData(data)
      });
    }
  };

  const handleValueChange = (currentValue: IBookingEditForm, values: IBookingEditForm) => {
    const isCheckInDateKey = Object.keys(currentValue).includes("checkinDate");
    const isCheckOutDateKey = Object.keys(currentValue).includes("checkoutDate");
    const isTotalGuestKey = Object.keys(currentValue).includes("totalGuests");

    if (isCheckInDateKey) {
      setCheckinDate(dayjs(values.checkinDate).format(DATE_FORMAT));
    }

    if (isCheckOutDateKey || isCheckInDateKey || isTotalGuestKey) {
      if (values.checkinDate && values.checkoutDate && values.totalGuests) {
        handleDynamicPrice({
          checkinDate: dayjs(values.checkinDate).format(DATE_FORMAT),
          checkoutDate: dayjs(values.checkoutDate).format(DATE_FORMAT),
          totalGuests: +values.totalGuests
        });
      }
    }

    if (values.checkinDate && values.checkoutDate) {
      if (isCheckOutDateKey || isCheckInDateKey) {
        handleCheckDates({
          id: bookingData?.id,
          checkinDate: dayjs(values.checkinDate).format(DATE_FORMAT),
          checkoutDate: dayjs(values.checkoutDate).format(DATE_FORMAT)
        });
      }
      if (!dayjs(values.checkinDate).isBefore(values.checkoutDate)) {
        form.setFieldsValue({
          checkoutDate: values.checkinDate,
          checkinDate: values.checkoutDate
        });
      }
      if (dayjs(values.checkinDate).isSame(values.checkoutDate, "day")) {
        form.setFieldsValue({
          checkoutDate: dayjs(values.checkoutDate).add(1, "day") as unknown as string
        });
      }
    }

    const conditionForShowMaxGuestError =
      !!values.totalGuests && values.totalGuests > ((activeHotel?.maxGuests as number) || 1);

    setGuestCountError(conditionForShowMaxGuestError);
  };

  const handleModalOpenDecline = () => {
    dispatch(setDeclineModal(true));
  };

  useEffect(() => {
    if (bookingData) {
      form.setFieldsValue({
        checkinDate: dayjs(bookingData.checkinDate),
        checkoutDate: dayjs(bookingData.checkoutDate),
        totalPrice: bookingData.totalPrice,
        totalGuests: bookingData.totalGuests
      });
    }
  }, [bookingData]);

  useEffect(() => {
    form.setFieldValue("totalPrice", dynamicTotalPrice?.totalPrice ?? bookingData?.totalPrice);
  }, [dynamicTotalPrice]);

  return (
    <Form
      className={styles["booking-edit__form"]}
      form={form}
      onFinish={handleSubmit}
      onValuesChange={handleValueChange}
      layout="vertical"
    >
      <div className={styles["booking-edit__blocks"]}>
        <h1 className={styles["booking-edit__title"]}>{t("booking.edit.date-title")}</h1>
        <Row gutter={24}>
          <Col xs={12}>
            <FormItem
              required
              name="checkinDate"
              label={t("booking.check-in-date")}
              rules={[yupSync]}
            >
              <DatePicker
                onChange={(date) => handleDatePicker(date, form)}
                inputReadOnly
                suffixIcon={<Icon icon="calendar" />}
              />
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem
              required
              name="checkoutDate"
              label={t("booking.check-out-date")}
              rules={[yupSync]}
            >
              <DatePicker
                allowClear={false}
                minDate={dayjs(checkinDate)}
                inputReadOnly
                suffixIcon={<Icon icon="calendar" />}
              />
            </FormItem>
          </Col>
          {isShowDateError && (
            <Col xs={24}>
              <p className={styles["booking-edit__date-error"]}>{t("booking.edit.date-error")}</p>
            </Col>
          )}
        </Row>
      </div>
      <Divider className={styles["booking-edit__divider"]} />
      <div className={styles["booking-edit__blocks"]}>
        <h1 className={styles["booking-edit__title"]}>{t("booking.edit.guest-title")}</h1>
        <Row gutter={24}>
          <Col xs={24}>
            <FormItem
              required
              name="totalGuests"
              label={t("booking.guests-amount")}
              rules={[yupSync]}
            >
              <Input
                type="number"
                prefix={(<Icon icon="outlineUser" />) as unknown as string}
                suffix={t("booking.edit.max", { count: activeHotel?.maxGuests })}
                min={1}
              />
            </FormItem>
          </Col>
          <Col xs={24}>
            {guestCountError && (
              <p className={styles["booking-edit__error"]}>
                {t("booking.create.guest-count-error")}
              </p>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24}>
            <FormItem
              rules={[yupSync]}
              required
              name="totalPrice"
              label={t("booking.price-per-night")}
            >
              <Input
                prefix={(<Icon icon="bankNote" />) as unknown as string}
                type="number"
                min={1}
                suffix={t("booking.edit.fiat")}
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <div className={styles["booking-edit__buttons"]}>
        <Button
          size="large"
          type="primary"
          onClick={form.submit}
          className={styles["booking-edit__button"]}
          disabled={guestCountError || isShowDateError}
        >
          {t("booking.edit.create")}
        </Button>
        <Button onClick={closeModal} size="large" ghost className={styles["booking-edit__button"]}>
          {t("booking.edit.decline")}
        </Button>
        {isApproved && (
          <Button
            onClick={handleModalOpenDecline}
            size="large"
            danger
            ghost
            className={styles["booking-edit__button"]}
          >
            {t("booking.edit.reject-booking")}
          </Button>
        )}
      </div>
    </Form>
  );
};

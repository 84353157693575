import React from "react"
import { Drawer } from "antd"
import { DrawerProps } from "antd/lib"

export const AppDrawer = ({
    title,
    children,
    onClose,
    open,
    ...rest
}: DrawerProps & {
    children: React.ReactNode
    title: string | React.ReactNode,
    onClose: () => void,
    open: boolean
}) => {
  return (
    <Drawer width={"420px"} title={title} onClose={onClose} open={open} {...rest}>
        {children}
    </Drawer>
  )
}

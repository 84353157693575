import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ISeasonTariffForm } from "@/components/Season/SeasonTariff/SeasonTariff.type";

import { useCustomMutation } from "@/hooks";

import { api } from "@/helpers";

import { ROUTES } from "@/constants";

import { TMutationsOptions } from "@/types";

import { setCreateTariff, setHandleDisabledSeasonSubmitButton } from "@/store/price-setup/price-setup.slice";

const fetcher = async (body: ISeasonTariffForm) => {
  const { id } = body;

  return api.post(`seasons/${id}/tariffs`, body);
};

const { SETTINGS, PRICE_SETUP, EDIT } = ROUTES

export const useCreateTariff = (
  options?: TMutationsOptions<ISeasonTariffForm, ISeasonTariffForm>
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hotelId } = useParams();

  const { mutate, ...rest } = useCustomMutation<ISeasonTariffForm, ISeasonTariffForm>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      await queryClient.invalidateQueries(["get-seasons-tariff"], { exact: false });
      navigate(`/${hotelId}/${SETTINGS}/${PRICE_SETUP}/${args[0].id}/${EDIT}`);
    },
    onSettled: () => {
      dispatch(setCreateTariff(false));
      dispatch(setHandleDisabledSeasonSubmitButton(false));
    }
  });

  const handleMutate = (data: ISeasonTariffForm) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

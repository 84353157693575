import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PriceSetupSlice {
  isShowDeleteSeasonModal: boolean;
  isDisabledSeasonSubmitButton: boolean;
  tariff: {
    isCreateTariff: boolean;
    isDeleteTariffModal: boolean;
    isUpdateTariff: boolean;
    isUpdateDataTariff: boolean;
  };
  paymentOption: {
    isShowDeletePaymentOptionModal: boolean;
  };
}

const initialState: PriceSetupSlice = {
  isShowDeleteSeasonModal: false,
  isDisabledSeasonSubmitButton: true,
  tariff: {
    isCreateTariff: false,
    isDeleteTariffModal: false,
    isUpdateTariff: false,
    isUpdateDataTariff: false,
  },
  paymentOption: {
    isShowDeletePaymentOptionModal: false
  }
};

export const priceSetupSlice = createSlice({
  name: "price-setup",
  initialState,
  reducers: {
    setShowDeleteSeasonModal: (state, action: PayloadAction<boolean>) => {
      state.isShowDeleteSeasonModal = action.payload;
    },
    setCreateTariff: (state, action: PayloadAction<boolean>) => {
      state.tariff.isCreateTariff = action.payload;
    },
    setShowPaymentOptionDeleteModal: (state, action: PayloadAction<boolean>) => {
      state.paymentOption.isShowDeletePaymentOptionModal = action.payload;
    },
    setIsDeleteTariffModal: (state, action: PayloadAction<boolean>) => {
      state.tariff.isDeleteTariffModal = action.payload;
    },
    setIsUpdateTariff: (state, action: PayloadAction<boolean>) => {
      state.tariff.isUpdateTariff = action.payload;
    },
    setIsUpdatedTariffData: (state, action: PayloadAction<boolean>) => {
      state.tariff.isUpdateDataTariff = action.payload;
    },
    setHandleDisabledSeasonSubmitButton: (state, action: PayloadAction<boolean>) => {
      state.isDisabledSeasonSubmitButton = action.payload;
    }
  }
});

export const {
  setShowDeleteSeasonModal,
  setCreateTariff,
  setShowPaymentOptionDeleteModal,
  setIsDeleteTariffModal,
  setIsUpdateTariff,
  setHandleDisabledSeasonSubmitButton,
  setIsUpdatedTariffData,
} = priceSetupSlice.actions;

export default priceSetupSlice.reducer;

import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { ISeasonTariffForm } from "@/components/Season/SeasonTariff/SeasonTariff.type";

import { useCustomMutation } from "@/hooks";

import { api } from "@/helpers";

import { ROUTES } from "@/constants";

import { TMutationsOptions } from "@/types";

import { setHandleDisabledSeasonSubmitButton, setIsUpdateTariff } from "@/store/price-setup/price-setup.slice";

interface ISeasonUpdateData {
  seasonId: string;
  tariffId: string;
  body: ISeasonTariffForm;
}

const fetcher = async ({ seasonId, tariffId, body }: ISeasonUpdateData) => {
  return api.patch(`seasons/${seasonId}/tariffs/${tariffId}`, body);
};

const { SETTINGS, PRICE_SETUP, EDIT } = ROUTES

export const useUpdateTariff = (
  options?: TMutationsOptions<ISeasonUpdateData, ISeasonUpdateData>
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hotelId } = useParams();

  const { mutate, ...rest } = useCustomMutation<ISeasonUpdateData, ISeasonUpdateData>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      navigate(`/${hotelId}/${SETTINGS}/${PRICE_SETUP}/${args[0].seasonId}/${EDIT}`)
      await queryClient.invalidateQueries(["get-season-tariff"], { exact: false });
    },
    onSettled: () => {
      dispatch(setIsUpdateTariff(false));
      dispatch(setHandleDisabledSeasonSubmitButton(false));
    }
  });

  const handleMutate = (data: ISeasonUpdateData) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Collapse, Divider } from "antd";
import { CollapseProps } from "antd/lib";
import dayjs from "dayjs";

import { Icon, Loader } from "@/elements";

import { useGetGuestHistotyBookings } from "@/react-queries/guests/useGetGuestHistotyBookings";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { allLocationsIdStorageService } from "@/helpers";

import { ROUTES } from "@/constants";
import { AllLocationType, DATE_WITH_POINTS_FORMAT } from "@/constants/global";

import styles from "./GuestHistory.module.scss";

import { GuestIconType } from "./GuestHistory.helper";
import { IGuestHistoryBooking } from "./GuestHistory.type";

const { BOOKINGS, VIEW } = ROUTES;

const { getItem: getAllLocationStatus } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

export const GuestHistory = () => {
  const { t } = useTranslation();
  const { userId, hotelId } = useParams<{ userId: string; hotelId: string }>();
  const navigate = useNavigate();
  const isAllLocaiton = getAllLocationStatus();
  const isMobile = useGetScreenStatus();

  const {
    data: guestHistoryBookings,
    refetch,
    isLoading,
    isRefetching
  } = useGetGuestHistotyBookings(userId || "", {
    enabled: !!userId
  });

  const handleNavigateToBookingDetails = (id: number) => {
    navigate(`/${hotelId}/${BOOKINGS}/${id}/${VIEW}`);
  };

  useEffect(() => {
    refetch();
  }, [userId]);

  const bookingHistory: CollapseProps["items"] = useMemo(() => {
    if (guestHistoryBookings) {
      return guestHistoryBookings.map((historyBooking: IGuestHistoryBooking, index) => {
        return {
          key: index,
          label: (
            <div className={styles["guest-history-header__container"]}>
              <Icon
                className={`${styles["guest-history-header__icon"]} ${
                  styles[GuestIconType(historyBooking.status)]
                }`}
                icon={GuestIconType(historyBooking.status) as string}
              />
              <div className={styles["guest-history-header__content"]}>
                <p className={styles["guest-history-header__checkin"]}>
                  {dayjs(historyBooking.checkinDate).format(DATE_WITH_POINTS_FORMAT)}
                </p>
                <Divider type="horizontal" className={styles["guest-history-header__divider"]} />
                <p className={styles["guest-history-header__checkout"]}>
                  {dayjs(historyBooking.checkoutDate).format(DATE_WITH_POINTS_FORMAT)}
                </p>
              </div>
            </div>
          ),
          children: (
            <div className={styles["guest-history__container"]}>
              <div className={styles["guest-history__content"]}>
                <div className={styles["guest-history__content-single"]}>
                  <div className={styles["guest-history__content-item"]}>
                    <p className={styles["guest-history__label"]}>
                      {t("view-guests.history.guests-count")}
                    </p>
                    <p className={styles["guest-history__value"]}>{historyBooking.totalGuests}</p>
                  </div>
                  <div className={styles["guest-history__content-item"]}>
                    <p className={styles["guest-history__label"]}>
                      {t("view-guests.history.total-amount")}
                    </p>
                    <p className={styles["guest-history__value"]}>
                      {historyBooking.totalPrice}
                      <span className={styles["guest-history__currency"]}>
                        {t("view-guests.history.fiat")}
                      </span>
                    </p>
                  </div>
                </div>
                {isAllLocaiton ? (
                  <div className={`${styles["guest-history__content-item"]} ${styles["new-line"]}`}>
                    {!isMobile ? (
                      <img
                        className={styles["guest-history__image"]}
                        src={historyBooking.hotel.locationImage}
                        alt={historyBooking.hotel.publicLabel}
                      />
                    ) : null}
                    <div className={styles["guest-history__content-item-details"]}>
                      <p className={styles["guest-history__label"]}>
                        {t("view-guests.history.location")}
                      </p>
                      <p className={styles["guest-history__value"]}>
                        {historyBooking.hotel.publicLabel}
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
              <Button
                onClick={() => handleNavigateToBookingDetails(historyBooking.id)}
                className={styles["guest-history__button"]}
                type="primary"
              >
                {t("view-guests.history.details")}
              </Button>
            </div>
          )
        };
      });
    }

    return [];
  }, [guestHistoryBookings, isMobile]);

  if (guestHistoryBookings && !guestHistoryBookings.length) {
    return null;
  }

  if (isLoading || isRefetching) {
    return <Loader isFullScreen />;
  }

  return (
    <Collapse
      className={styles["guest-history__wrapper"]}
      expandIconPosition="end"
      items={bookingHistory}
    />
  );
};

import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { useCustomMutation } from "@/hooks";

import { allLocationsIdStorageService, api, handleHotelIdInApi, handleScroll } from "@/helpers";

import { AllLocationType, ScrollTypes } from "@/constants/global";

import { ICalendarRequestUpdateSpecialDay, TMutationsOptions } from "@/types";

import {
  clearChoosenCalendarBooking,
  setHandleEditPriceModal
} from "@/store/booking/booking.slice";

const { getItem: getAllLocationStatus } = allLocationsIdStorageService(AllLocationType.ALL_LOCATIONS_ID);

const fetcher = async (body: ICalendarRequestUpdateSpecialDay) => {
  const isAllLocation = getAllLocationStatus();
  const conditionData = isAllLocation ? body : handleHotelIdInApi(body);

  return api.post("special-prices", conditionData);
};

export const useUpdateDaySpecialPrice = (
  options?: TMutationsOptions<ICalendarRequestUpdateSpecialDay, ICalendarRequestUpdateSpecialDay>
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useCustomMutation<
    ICalendarRequestUpdateSpecialDay,
    ICalendarRequestUpdateSpecialDay
  >(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      await queryClient.invalidateQueries(["get-booking"], { exact: false });
      await queryClient.invalidateQueries(["get-bookings"], { exact: false });
      await queryClient.invalidateQueries(["get-calendar-booking-prices"], { exact: false });
      await queryClient.invalidateQueries(["get-all-locations-calendar-booking-prices"], { exact: false });
    },
    onSettled: () => {
      dispatch(setHandleEditPriceModal(false));
      dispatch(clearChoosenCalendarBooking());
      handleScroll(ScrollTypes.REMOVE);
    }
  });

  const handleMutate = (data: ICalendarRequestUpdateSpecialDay) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

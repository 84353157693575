import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { FallingOut } from "@/elements";
import { AppDrawer } from "@/elements/Drawer/Drawer";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { handleScroll } from "@/helpers";

import { ScrollTypes } from "@/constants/global";

import { IBookingPaymentForm } from "./BookingPaymentForm.type";
import { BookingPaymentFormContent } from "./BookingPaymentFormContent";

import { RootState } from "@/store";
import { setIsShowPaymentModal } from "@/store/booking/booking.slice";

export const BookingPaymentForm: FC<IBookingPaymentForm> = ({ booking }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isMobile = useGetScreenStatus();
  const isShowPaymentModal = useSelector(
    (state: RootState) => state.booking.payment.isShowPaymentModal
  );

  const handleClose = () => {
    dispatch(setIsShowPaymentModal(false));
    handleScroll(ScrollTypes.REMOVE);
  };

  if (!isMobile) {
    return (
      <AppDrawer title={t("booking.payment.title")} onClose={handleClose} open={isShowPaymentModal}>
        <BookingPaymentFormContent booking={booking} />
      </AppDrawer>
    );
  }

  return (
    <FallingOut
      title={t("booking.payment.title")}
      handleClose={handleClose}
      content={<BookingPaymentFormContent booking={booking} />}
    />
  );
};

import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { api, handleHotelIdInApi } from "@/helpers";

import { TQueryOptions } from "@/types";
import { ISeason } from "@/types/season/season.type";

const fetcher = async () => {
  return api.get("seasons", handleHotelIdInApi({}));
};

export const useGetSeasons = <TQueryFnData = ISeason, TData = ISeason[]>(
  options?: TQueryOptions<TQueryFnData, TData>
) => {

  return useCustomQuery<TQueryFnData, TData>(["get-seasons"], fetcher, {
    ...options,
    staleTime: Infinity,
  });
};

import { useDispatch } from "react-redux";

import { useCustomMutation } from "@/hooks";

import { api, handleHotelIdInApi } from "@/helpers";

import { IBookingAllReservations, IBookingReservationsRequest, TMutationsOptions } from "@/types";

import { setHandleUpdateReservation } from "@/store/calendar/calendar.slice";


const fetcher = async (body: IBookingReservationsRequest) => {
  return api.get("host/bookings/reservations/all", handleHotelIdInApi(body));
};

export const useGetAllReservationBookings = (
  options?: TMutationsOptions<IBookingAllReservations[], IBookingReservationsRequest>
) => {
  const dispatch = useDispatch();

  const { mutate, ...rest } = useCustomMutation<
    IBookingAllReservations[],
    IBookingReservationsRequest
  >(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      dispatch(setHandleUpdateReservation(false))
      options?.onSuccess?.(data, ...args);
    }
  });

  const handleMutate = (data: IBookingReservationsRequest) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

import { useDispatch, useSelector } from "react-redux";

import { useCustomMutation } from "@/hooks";

import { allLocationsIdStorageService, api, handleHotelIdInApi } from "@/helpers";

import { AllLocationType } from "@/constants/global";

import { ICheckDates, TMutationsOptions } from "@/types";

import { RootState } from "@/store";
import { setIsShowErrorDate } from "@/store/booking/booking.slice";

const { getItem: getAllLocationStatus } = allLocationsIdStorageService(AllLocationType.ALL_LOCATIONS_ID);

const fetcher = async (body: ICheckDates) => {
  const { id, ...restData } = body;

  const isAllLocation = getAllLocationStatus();
  const conditionData = isAllLocation ? restData : handleHotelIdInApi(restData);
  
  return api.patch(`host/bookings/${id}/validate`, conditionData);
};

export const useCheckDates = (options?: TMutationsOptions<ICheckDates, ICheckDates>) => {
  const dispatch = useDispatch();
  const isAllLocation = getAllLocationStatus();
  const chosenFromCalendarLocationId = useSelector((state: RootState) => state.booking.choosenCalendarBooking.hotelId);
  const chosenLocationId = useSelector((state: RootState) => state.booking.allLocation.chosenLocationId);

  const { mutate, ...rest } = useCustomMutation<ICheckDates, ICheckDates>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      dispatch(setIsShowErrorDate(false));
      options?.onSuccess?.(data, ...args);
    }
  });

  const handleMutate = (data: ICheckDates) => {
    if (isAllLocation) {
      data.hotelId = chosenLocationId || String(chosenFromCalendarLocationId);
    }

    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

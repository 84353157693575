import { ISeasonTariffForm } from "@/components/Season/SeasonTariff/SeasonTariff.type";

import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { api } from "@/helpers";

import { TQueryOptions } from "@/types";

interface ISeasonTariffRequest {
  seasonId: number;
  tariffId: number;
}

const fetcher = async (data: ISeasonTariffRequest) => {
  const { seasonId, tariffId } = data;
  
  return api.get(`seasons/${seasonId}/tariffs/${tariffId}`);
};

export const useGetTariff = <TQueryFnData = ISeasonTariffForm, TData = ISeasonTariffForm>(
  data: ISeasonTariffRequest,
  options?: TQueryOptions<TQueryFnData, TData>
) => {
  return useCustomQuery<TQueryFnData, TData>(["get-season-tariff"], () => fetcher(data), {
    ...options,
    staleTime: Infinity
  });
};

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "antd";
import dayjs from "dayjs";

import { yearMonths } from "@/components/Calendar/CalendarSelectionModal/CalendarSelectionModal.helper";

import { handleScroll } from "@/helpers";

import { DATE_YEAR_FORMAT, FULL_DATE_FORMAT, ScrollTypes } from "@/constants/global";

import styles from "./CalendarMonthChooser.module.scss";

import { Icon } from "../Icon/Icon";

import { RootState } from "@/store";
import { setCalendarDate, setHandleMonthSwitcher } from "@/store/calendar/calendar.slice";

export const CalendarMonthChooser = ({ handleClose }: { handleClose?: () => void }) => {
  const dispatch = useDispatch();

  const calendarDate = useSelector((state: RootState) => state.calendar.calendarDate);
  const [filterYear, setFilterYear] = React.useState<string>(
    dayjs(calendarDate, FULL_DATE_FORMAT).format(DATE_YEAR_FORMAT)
  );

  const handleNextYear = () => {
    setFilterYear(dayjs(filterYear, DATE_YEAR_FORMAT).add(1, "year").format(DATE_YEAR_FORMAT));
  };

  const handlePrevYear = () => {
    setFilterYear(dayjs(filterYear, DATE_YEAR_FORMAT).subtract(1, "year").format(DATE_YEAR_FORMAT));
  };

  const handleChooseMonth = (monthNumber: number) => {
    dispatch(
      setCalendarDate(
        dayjs(dayjs(filterYear, DATE_YEAR_FORMAT)).month(monthNumber).format(FULL_DATE_FORMAT)
      )
    );
    dispatch(setHandleMonthSwitcher(false))
    if (handleClose) {
      handleClose();
      handleScroll(ScrollTypes.REMOVE);
    }
  };

  useEffect(() => {
    setFilterYear(dayjs(calendarDate, FULL_DATE_FORMAT).format(DATE_YEAR_FORMAT))
  }, [calendarDate])

  return (
    <div className={styles.selection__container}>
      <div className={styles.selection__header}>
        <div className={styles["selection__header-button"]} onClick={handlePrevYear}>
          <Icon icon="arrowLeft" />
        </div>
        <div className={styles["selection__header-year"]}>{filterYear}</div>
        <div className={styles["selection__header-button"]} onClick={handleNextYear}>
          <Icon icon="arrowLeft" className={styles["arrow-right"]} />
        </div>
      </div>
      <Divider className={styles.selection__divider} />
      <div className={styles.selection__content}>
        {yearMonths &&
          yearMonths.map((month, index) => {
            const isActiveMonth =
              dayjs(calendarDate, FULL_DATE_FORMAT).month() === month.value &&
              dayjs(calendarDate, FULL_DATE_FORMAT).year() === Number(filterYear);

            return (
              <div
                onClick={() => handleChooseMonth(month.value)}
                key={index}
                className={`${styles["selection__content-item"]} ${
                  isActiveMonth ? styles["selection__content-item__active"] : ""
                }`}
              >
                {month.title}
              </div>
            );
          })}
      </div>
    </div>
  );
};

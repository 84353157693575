import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Button, Divider, Form, Typography } from "antd";
import dayjs from "dayjs";
import { isArray } from "lodash";

import { DatePicker, FormItem, Icon, Input, Loader } from "@/elements";

import { useCreateSeason } from "@/react-queries/sessions/useCreateSeason";
import { useDeleteSeason } from "@/react-queries/sessions/useDeleteSession";
import { useGetSeason } from "@/react-queries/sessions/useGetSeason";
import { useUpdateSeason } from "@/react-queries/sessions/useUpdateSeason";
import { useGetTariffs } from "@/react-queries/tariffs/useGetTariffs";

import { useYupSync } from "@/hooks";
import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { handleDatePicker, handleScroll } from "@/helpers";

import { ROUTES } from "@/constants";
import { DATE_FORMAT, FooterType, ScrollTypes } from "@/constants/global";

import { priceSetupSchema } from "./PriceSetupForm.schema";

import styles from "./PriceSetupForm.module.scss";

import { SeasonSetupListTariffs } from "../../Season/SeasonSetupListTariffs/SeasonSetupListTariffs";
import { CancellationModal } from "../CancellationModal/CancellationModal";

import { IPriceSetupForm } from "./PriceSetupForm.type";

import { RootState } from "@/store";
import { setFooterContent, setHandleSubmitSeasonData } from "@/store/footer/footer.slice";
import { setHeaderNavigation } from "@/store/header/header.slice";
import {
  setHandleDisabledSeasonSubmitButton,
  setShowDeleteSeasonModal
} from "@/store/price-setup/price-setup.slice";

const { SETTINGS, PRICE_SETUP } = ROUTES;

const { Link } = Typography;

export const PriceSetupForm = () => {
  const { priceSetupId } = useParams();
  const location = useLocation();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [checkinDate, setCheckinDate] = React.useState<string | null>(null);
  const isSubmitData = useSelector((state: RootState) => state.footer.priceSetup.isSubmitData);
  const header = useSelector((state: RootState) => state.header);
  const isShowSeasonDeleteModal = useSelector(
    (state: RootState) => state.priceSetup.isShowDeleteSeasonModal
  );
  const isDisabled = useSelector(
    (state: RootState) => state.priceSetup.isDisabledSeasonSubmitButton
  );
  const [form] = Form.useForm<IPriceSetupForm>();
  const yupSync = useYupSync(priceSetupSchema);
  const { hotelId } = useParams();
  const isEdit = !!priceSetupId;
  const isCreate = location.pathname.includes("create");
  const isMobile = useGetScreenStatus();
  const navigate = useNavigate();

  const { data: seasonData, isLoading: isSeasonDataLoading } = useGetSeason(priceSetupId || "", {
    enabled: isEdit,
    cacheTime: 0
  });

  const {
    data: tariffs,
    isLoading: isTariffsLoading,
    refetch: refetchTariffs,
    isRefetching
  } = useGetTariffs(priceSetupId || "", {
    enabled: isEdit
  });

  const { mutate: createSeason, isLoading: isCreateSeasonLoading } = useCreateSeason();
  const { mutate: updateSeason } = useUpdateSeason();
  const { mutate: handleDeletePriceSetup } = useDeleteSeason();

  const handleSubmit = (values: IPriceSetupForm) => {
    form
      .validateFields()
      .then(() => {
        const formatDates = {
          startDate: dayjs(values.startDate).format(DATE_FORMAT),
          endDate: dayjs(values.endDate).format(DATE_FORMAT)
        };
        if (isEdit) {
          updateSeason(priceSetupId, {
            ...values,
            ...formatDates
          });
        } else {
          createSeason({
            ...values,
            ...formatDates
          });
        }
      })
      .catch((error) => {
        return error;
      });
  };

  const handleValueChange = (currentValue: IPriceSetupForm, values: IPriceSetupForm) => {
    const isCheckInDateKey = Object.keys(currentValue).includes("startDate");
    if (seasonData && isEdit) {
      for (const key of Object.keys(values)) {
        if (key === "startDate" || key === "endDate") {
          if (
            dayjs(values[key as keyof IPriceSetupForm]).format(DATE_FORMAT) !==
            dayjs(seasonData[key as keyof IPriceSetupForm]).format(DATE_FORMAT)
          ) {
            dispatch(setHandleDisabledSeasonSubmitButton(false));
            break;
          }
          dispatch(setHandleDisabledSeasonSubmitButton(true));
          continue;
        }
        if (values[key as keyof IPriceSetupForm] !== seasonData[key as keyof IPriceSetupForm]) {
          dispatch(setHandleDisabledSeasonSubmitButton(false));
          break;
        }
        dispatch(setHandleDisabledSeasonSubmitButton(true));
      }
    }

    if (isCheckInDateKey) {
      setCheckinDate(dayjs(values.startDate).format(DATE_FORMAT));
    }

    if (values.startDate && values.endDate) {
      if (!dayjs(values.startDate).isBefore(values.endDate)) {
        form.setFieldsValue({
          endDate: values.startDate,
          startDate: values.endDate
        });
      }
      if (dayjs(values.startDate).isSame(values.endDate, "day")) {
        const endDate = form.getFieldValue("endDate");
        form.setFieldsValue({
          endDate: dayjs(endDate).add(1, "day") as unknown as string
        });
      }
    }
  };

  const handleModalClose = () => {
    dispatch(setShowDeleteSeasonModal(false));
    handleScroll(ScrollTypes.REMOVE);
  };

  const handleClose = () => {
    navigate(`/${hotelId}/${SETTINGS}/${PRICE_SETUP}`);
  };

  const handleModalRemove = () => {
    if (priceSetupId) {
      handleDeletePriceSetup(priceSetupId);
      handleModalClose();
    }
  };

  const handleDelete = () => {
    dispatch(setShowDeleteSeasonModal(true));
    handleScroll(ScrollTypes.ADD);
  };

  useEffect(() => {
    dispatch(
      setHeaderNavigation({
        navigation: {
          title: t("price-setup.title"),
          link: `/${hotelId}/${SETTINGS}/${PRICE_SETUP}`
        }
      })
    );
  }, [location.pathname]);

  useEffect(() => {
    if (priceSetupId) {
      refetchTariffs();
    }
  }, [priceSetupId]);

  useEffect(() => {
    const conditionType = isCreate ? FooterType.PRICE_SETUP : FooterType.PRICE_SETUP_EDIT;
    const conditionCancelButton = isCreate
      ? t("price-setup.form.cancel-changes")
      : t("price-setup.form.edit-cancel");

    const conditionSubmitButton = isCreate
      ? t("price-setup.create-season")
      : t("price-setup.form.use-changes");
    dispatch(
      setFooterContent({
        type: conditionType,
        submitButtonText: conditionSubmitButton,
        cancelButtonText: conditionCancelButton
      })
    );
  }, [location.pathname]);

  useEffect(() => {
    if (isSubmitData) {
      form.submit();
      dispatch(setHandleSubmitSeasonData(false));
    }
  }, [isSubmitData]);

  useEffect(() => {
    if (!isCreate && seasonData && !isArray(seasonData)) {
      form.setFieldsValue({
        startDate: dayjs(seasonData.startDate),
        endDate: dayjs(seasonData.endDate),
        name: seasonData.name
      });
    }
  }, [seasonData]);

  useEffect(() => {
    const isReturn = location.state && location.state.isReturn;

    const handleBackButton = () => {
      if (isReturn) {
        navigate(`/${hotelId}/${SETTINGS}/${PRICE_SETUP}`);
      }
    };

    window.addEventListener("popstate", handleBackButton);

    return () => {
      if (!isReturn) {
        window.removeEventListener("popstate", handleBackButton);
      }
    };
  }, [location.pathname, location.state]);

  if (isSeasonDataLoading || isTariffsLoading || isRefetching) {
    return <Loader isFullScreen />;
  }

  return (
    <>
      <div className={styles["price-setup-form"]}>
        <div className={styles["price-setup-form__wrapper"]}>
          <Form
            onValuesChange={handleValueChange}
            form={form}
            name="price-setup-form"
            onFinish={handleSubmit}
            layout="vertical"
            scrollToFirstError
            className={styles["price-setup-form__wrapper"]}
          >
            <div className={styles["price-setup-form__container"]}>
              {!isMobile ? (
                <div className={styles["price-setup-form__back"]}>
                  <Link
                    className={styles["price-setup-form__back-container"]}
                    href={header.navigation.link}
                  >
                    <div className={styles["price-setup-form__back-icon"]}>
                      <Icon icon="arrowLeft" className={styles["logo-header"]} />
                    </div>
                    <span className={styles["price-setup-form__back-title"]}>
                      {header.navigation.title}
                    </span>
                  </Link>
                </div>
              ) : null}
              <h1 className={styles["price-setup-form__title"]}>{t("price-setup.form.title")}</h1>
              <div className={styles["price-setup-form__content"]}>
                <div className={styles["price-setup-form__content-name"]}>
                  <FormItem
                    required
                    name="name"
                    label={t("price-setup.form.season-name")}
                    rules={[yupSync]}
                  >
                    <Input />
                  </FormItem>
                </div>
                <div className={styles["price-setup-form__dates"]}>
                  <FormItem
                    required
                    name="startDate"
                    label={t("price-setup.form.season-start")}
                    rules={[yupSync]}
                  >
                    <DatePicker
                      onChange={(date) => handleDatePicker(date, form)}
                      inputReadOnly
                      suffixIcon={<Icon icon="calendar" />}
                    />
                  </FormItem>
                  <FormItem
                    required
                    name="endDate"
                    label={t("price-setup.form.season-end")}
                    rules={[yupSync]}
                  >
                    <DatePicker
                      allowClear={false}
                      minDate={dayjs(checkinDate)}
                      inputReadOnly
                      suffixIcon={<Icon icon="calendar" />}
                    />
                  </FormItem>
                </div>
              </div>
            </div>
          </Form>
          <div className={styles["price-setup-form__container"]}>
            <h1 className={styles["price-setup-form__title"]}>{t("price-setup.form.tariffs")}</h1>
            <SeasonSetupListTariffs tariffs={tariffs} />
          </div>

          {!isMobile ? (
            <>
              <Divider className={styles["price-setup-form__divider"]} />
              <div className={styles["price-setup-form__buttons"]}>
                <div className={styles["price-setup-form__buttons-cancellation"]}>
                  <Button
                    loading={isCreateSeasonLoading}
                    ghost
                    className={styles["price-setup-form__button"]}
                    onClick={handleClose}
                  >
                    {t(isEdit ? "price-setup.form.edit-cancel" : "price-setup.form.cancel-changes")}
                  </Button>

                  {isEdit ? (
                    <div className={styles["price-setup-form__delete-btn"]} onClick={handleDelete}>
                      <Icon icon="outlineTrash" />
                    </div>
                  ) : null}
                </div>

                <Button
                  type="primary"
                  className={styles["price-setup-form__button"]}
                  onClick={form.submit}
                  loading={isCreateSeasonLoading}
                  disabled={isEdit && isDisabled}
                >
                  {t(isEdit ? "price-setup.form.use-changes" : "price-setup.create-season")}
                </Button>
              </div>
            </>
          ) : null}
        </div>
      </div>
      {isShowSeasonDeleteModal && (
        <CancellationModal
          title={t("price-setup.delete.title")}
          approveButtonText={t("price-setup.delete.confirm-btn")}
          declineButtonText={t("price-setup.delete.cancel-btn")}
          handleApprove={handleModalRemove}
          handleClose={handleModalClose}
        />
      )}
    </>
  );
};

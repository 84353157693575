import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { RcFile } from "antd/es/upload";
import classNames from "classnames";

import { Icon, notification, Paragraph, Upload } from "@/elements";

import { TDirectUploadProps } from "./DirectUpload.types";

import styles from "./DirectUpload.module.scss";

export const DirectUpload: FC<TDirectUploadProps> = ({
  disabled,
  className,
  beforeUpload,
  fileList,
  onRemove,
  ...rest
}) => {
  const { t } = useTranslation();

  const handleBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
    if (disabled) {
      notification.error(t("location-settings.limited-amount-upload"));

      return false;
    }

    beforeUpload && beforeUpload(file, fileList);
  };

  return (
    <>
      <Upload
        name="file"
        maxCount={1}
        accept=".jpg,.jpeg,.png"
        listType="picture-card"
        openFileDialogOnClick={!disabled}
        beforeUpload={handleBeforeUpload}
        className={classNames(styles.upload, { [styles.disabled]: !!disabled }, className)}
        fileList={fileList}
        {...rest}
      >
        {!fileList?.length ? (
          <>
            <Icon className={styles["icon-upload"]} icon="upload" />
            <Paragraph className={styles["title-upload"]}>
              {t("location-settings.upload-title")}
            </Paragraph>
            <Paragraph className={styles["subtitle-upload"]}>
              {t("location-settings.subtitle-upload")}
            </Paragraph>
          </>
        ) : null}
      </Upload>
      {fileList?.length
        ? onRemove && (
            <Button onClick={() => onRemove(fileList[0])} className={styles["delete-btn"]}>
              <Icon icon="outlineTrash" />
            </Button>
          )
        : null}
    </>
  );
};

import { t } from "i18next";
import { date, number, object, string } from "yup";

import { checkPhoneValidation, ValidEmail } from "@/helpers";

export const bookingSchema = object().shape({
  checkinDate: date().required(),
  checkoutDate: date().required(),
  totalPrice: number().required().min(1),
  totalGuests: number().required().min(1),
  guest: object().shape({
    email: string().test("email", t("error.email.email"), (value) => {
      return !(value && !ValidEmail(value));
    }),
    comment: string().optional(),
    fullName: string().required(),
    phoneNumber: string()
      .test("matches", (value) => {
        if (!value) {
          return true;
        }

        return checkPhoneValidation(value);
      })
  })
});

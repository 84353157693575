import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Layout } from "antd";

import { MainLayoutFooter, MainLayoutHeader } from "@/components";

import { Icon } from "@/elements";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { checkClearFooterStates, checkIsNotClearHeaderStates, handleScroll } from "@/helpers";

import { ROUTES } from "@/constants";
import { ScrollTypes } from "@/constants/global";

import styles from "./MainLayout.module.scss";

import { IActionButtonConfig } from "./MainLayout.type";

import { setHandleBookingActionButtonContent } from "@/store/booking/booking.slice";
import { setFooterContent } from "@/store/footer/footer.slice";
import { clearHeaderStates } from "@/store/header/header.slice";

const { Content } = Layout;

interface IMainMainLayoutProps {
  children?: ReactNode;
}

const {
  VIEW,
  EDIT,
  BLOCKED,
  CREATE,
  PAYMENT_HISTORY,
  BOOKINGS,
  GUESTS,
  DASHBOARD,
  LOCATIONS,
  ALL_LOCATIONS
} = ROUTES;

export const MainLayout: FC<IMainMainLayoutProps> = ({ children }) => {
  const [actionButton, setActionButton] = useState<IActionButtonConfig | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useGetScreenStatus();

  const { pathname } = useLocation();
  const isLocationsPage = pathname.includes(LOCATIONS) && !pathname.includes(ALL_LOCATIONS);
  const isDashboardPage = pathname.includes(DASHBOARD);

  const isShowActionButton = useMemo(() => {
    const actionButtonConfigurations = {
      guests: {
        show: [GUESTS],
        unshow: [VIEW, EDIT, CREATE],
        action: () => navigate(CREATE)
      },
      dashboard: {
        show: [DASHBOARD],
        unshow: [],
        action: () => {
          dispatch(setHandleBookingActionButtonContent(true));
          if (isMobile) {
            handleScroll(ScrollTypes.ADD);
          }
        }
      },
      bookings: {
        show: [BOOKINGS],
        unshow: [VIEW, EDIT, BLOCKED, CREATE, PAYMENT_HISTORY],
        action: () => {
          dispatch(setHandleBookingActionButtonContent(true));
          if (isMobile) {
            handleScroll(ScrollTypes.ADD);
          }
        }
      }
    };

    const pathnameKeys = pathname.split("/").filter((path) => path);
    const actionButtonConfig =
      actionButtonConfigurations[pathnameKeys[1] as keyof typeof actionButtonConfigurations];

    if (!actionButtonConfig) {
      return false;
    }

    const unshowKeys = actionButtonConfig.unshow;
    const showKeys = actionButtonConfig.show;

    const isUnshow = unshowKeys.some((key) => pathnameKeys.includes(key));

    const isShow = showKeys.some((key) => pathnameKeys.includes(key));

    if (isShow && !isUnshow) {
      setActionButton(actionButtonConfig);
    }

    return isShow && !isUnshow;
  }, [pathname]);

  useEffect(() => {
    if (checkIsNotClearHeaderStates(pathname)) {
      dispatch(clearHeaderStates());
    }
    if (checkClearFooterStates(pathname)) {
      dispatch(setFooterContent(null));
    }
  }, [pathname]);

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0
    });
  }, [pathname]);

  return (
    <div id="main" className={styles.wrapper}>
      <MainLayoutHeader />
      <Layout className={styles["main-wrapper"]}>
        <Content className={`${styles.content} ${isDashboardPage ? styles.dashboard : null}`}>
          {children}
        </Content>
        {isShowActionButton && (
          <div onClick={() => actionButton?.action()} className={styles["action-button"]}>
            <button>
              <Icon className={styles["action-button__icon"]} icon="roundedPlus" />
            </button>
          </div>
        )}
      </Layout>
      {!isLocationsPage ? <MainLayoutFooter /> : null}
    </div>
  );
};

import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { api, getJWTToken } from "@/helpers";

import { IBooking, TQueryOptions } from "@/types";

const fetcher = async (bookingId: string) => {
  return api.get(`host/bookings/blocked-dates/${bookingId}`);
};

export const useGetBlockedBooking = <TQueryFnData = IBooking, TData = IBooking>(
  bookingId: string,
  options?: TQueryOptions<TQueryFnData, TData>
) => {
  const token = getJWTToken();

  return useCustomQuery<TQueryFnData, TData>(["get-blocked-booking"], () => fetcher(bookingId), {
    ...options,
    staleTime: Infinity,
    enabled: !!token
  });
};

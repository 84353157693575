import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { api, handleHotelIdInApi } from "@/helpers";

import { TQueryOptions } from "@/types";
import { IPaymentOption } from "@/types/paymentOption/paymentOption.type";

interface IGetPaymentOptionsParams {
  isTurnedOn?: boolean | undefined;
}

const fetcher = async (data: IGetPaymentOptionsParams | undefined) => {
  return api.get("payment-options", handleHotelIdInApi(data));
};

export const useGetPaymentOptions = <TQueryFnData = IPaymentOption, TData = IPaymentOption[]>(
  data?: IGetPaymentOptionsParams,
  options?: TQueryOptions<TQueryFnData, TData>
) => {

  return useCustomQuery<TQueryFnData, TData>(["get-payment-options"], () => fetcher(data), {
    ...options,
    staleTime: Infinity,
  });
};

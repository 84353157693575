import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Typography } from "antd";

import { BookingViewCard } from "@/containers/BookingViewCard/BookingViewCard";

import { BookingHistory } from "@/components/Booking/BookingHistory/BookingHistory";
import { BookingBlockEdit } from "@/components/Forms/BookingBlockEdit/BookingBlockEdit";
import { CancellationModal } from "@/components/Forms/CancellationModal/CancellationModal";

import { Icon, Loader } from "@/elements";

import { useDeleteBlocking } from "@/react-queries/booking/useDeleteBlocking";
import { useGetBlockedBooking } from "@/react-queries/booking/useGetBlockedBooking";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { allLocationsIdStorageService, handleScroll } from "@/helpers";

import { ROUTES } from "@/constants";
import { BookingStatus } from "@/constants/bookings/bookings.constant";
import { AllLocationType, ScrollTypes } from "@/constants/global";

import styles from "./BookingBlockView.module.scss";

import { RootState } from "@/store";
import {
  setHandleAllLocationId,
  setIsShowBlockedCancelModal,
  setIsShowBlockedModal
} from "@/store/booking/booking.slice";
import { clearCalendarState } from "@/store/calendar/calendar.slice";
import { setHeaderNavigation } from "@/store/header/header.slice";

const { getItem: getIsAllLocation } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

const { BOOKINGS, DASHBOARD } = ROUTES;

const { Link } = Typography;

export const BookingBlockView = () => {
  const header = useSelector((state: RootState) => state.header);

  const { bookingId, hotelId } = useParams();
  const location = useLocation();
  const isMobile = useGetScreenStatus();
  const isAllLocation = getIsAllLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isShowBlockedEditModal = useSelector(
    (state: RootState) => state.booking.blocked.isShowBlockedEditModal
  );
  const isShowBlockedCancelModal = useSelector(
    (state: RootState) => state.booking.blocked.isShowBlockedCancelModal
  );
  const isDashboardBack = useMemo(() => {
    const route = location.state?.route;
    if (route) {
      return route?.includes(DASHBOARD);
    }

    return false;
  }, [location]);

  const {
    data: bookingData,
    isLoading,
    refetch,
    isRefetching
  } = useGetBlockedBooking(bookingId || "");
  const { mutate: deleteBooking } = useDeleteBlocking();

  useEffect(() => {
    if (bookingData) {
      const conditionTitle = isDashboardBack ? t("navigation.calendar") : t("booking.view.title");
      const conditionLink = isDashboardBack ? location.state?.route : `/${hotelId}/${BOOKINGS}`;
      dispatch(
        setHeaderNavigation({
          navigation: {
            title: conditionTitle,
            link: conditionLink
          }
        })
      );
    }
  }, [bookingData]);

  const handleEditBooking = () => {
    dispatch(setIsShowBlockedModal(true));
    handleScroll(ScrollTypes.ADD);
  };

  const handleCancellationClose = () => {
    dispatch(setIsShowBlockedCancelModal(false));
    dispatch(setIsShowBlockedModal(false));
    handleScroll(ScrollTypes.REMOVE);
  };

  const removeBooking = () => {
    deleteBooking({
      id: bookingId || ""
    });
    dispatch(clearCalendarState());
    navigate(`/${hotelId}/${DASHBOARD}`);
    handleScroll(ScrollTypes.REMOVE);
  };

  useEffect(() => {
    if (bookingId) {
      refetch();
    }
  }, [bookingId]);

  useEffect(() => {
    if (bookingData && isAllLocation) {
      dispatch(setHandleAllLocationId(bookingData?.hotel?.id));
    }
  }, [bookingData]);

  if (isLoading && isRefetching) {
    return <Loader isFullScreen />;
  }

  return (
    <div className={styles["booking-view__container"]}>
      {!isMobile ? (
        <div className={styles["booking-view__back"]}>
          <Link className={styles["booking-view__back-container"]} href={header.navigation.link}>
            <div className={styles["booking-view__back-icon"]}>
              <Icon icon="arrowLeft" className={styles["logo-header"]} />
            </div>
            <span className={styles["booking-view__back-title"]}>{header.navigation.title}</span>
          </Link>
        </div>
      ) : null}
      <h1 className={styles["booking-view__title"]}> {t("booking.blocked.block-title")} </h1>
      <div className={styles["booking-view__content"]}>
        <div className={styles["booking-view__details"]}>
          <BookingViewCard
            bookingData={bookingData}
            type={BookingStatus.BLOCKED}
            handleEdit={handleEditBooking}
          />
        </div>
        <BookingHistory isBlocked bookingData={bookingData} />
      </div>
      {isShowBlockedEditModal && <BookingBlockEdit />}
      {isShowBlockedCancelModal && (
        <CancellationModal
          title={t("booking.blocked.title-cancellation-block")}
          approveButtonText={t("booking.blocked.cancellation-block")}
          declineButtonText={t("booking.blocked.cancellation-continue")}
          handleClose={handleCancellationClose}
          handleApprove={removeBooking}
        />
      )}
    </div>
  );
};

import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { notification } from "@/elements";

import { useCustomMutation } from "@/hooks";

import { api, handleScroll } from "@/helpers";

import { BookingStatus } from "@/constants/bookings/bookings.constant";
import { ScrollTypes } from "@/constants/global";

import { IBookingStatus, TMutationsOptions } from "@/types";

import { setHandleUpdateReservation } from "@/store/calendar/calendar.slice";

const fetcher = async (body: IBookingStatus) => {
  const { id, status } = body;

  return api.patch(`host/bookings/${id}/status`, { status });
};

export const useChangeBookingStatus = (
  options?: TMutationsOptions<IBookingStatus, IBookingStatus, Promise<void>>
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { mutate, ...rest } = useCustomMutation<IBookingStatus, IBookingStatus, Promise<void>>(
    fetcher,
    {
      ...options,
      onSuccess: async (data, ...args) => {
        options?.onSuccess?.(data, ...args);
        dispatch(setHandleUpdateReservation(true));
        handleScroll(ScrollTypes.REMOVE);
        await queryClient.invalidateQueries(["get-booking"], { exact: false });
        await queryClient.invalidateQueries(["get-bookings"], { exact: false });
        await queryClient.invalidateQueries(["get-bookings-reservations"], { exact: false });
        await queryClient.invalidateQueries(["get-bookings-count"], { exact: false });

        if (args[0].status === BookingStatus.APPROVED) {
          notification.success(t("booking.history.approved"));
        }
      }
    }
  );

  const handleMutate = (data: IBookingStatus) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

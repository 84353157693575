import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { useCustomMutation } from "@/hooks";

import { api, handleHotelIdInApi } from "@/helpers";

import { ROUTES } from "@/constants";

import { TMutationsOptions } from "@/types";
import { ISeason } from "@/types/season/season.type";

import { setFooterContent, setHandleSubmitSeasonData } from "@/store/footer/footer.slice";
import { setHandleDisabledSeasonSubmitButton } from "@/store/price-setup/price-setup.slice";

interface ISeasonUpdateData {
  seasonId: string;
  body: ISeason;
}

const fetcher = async ({ seasonId, body }: ISeasonUpdateData) => {
  return api.patch(`seasons/${seasonId}`, handleHotelIdInApi(body));
};

const { SETTINGS, PRICE_SETUP } = ROUTES;

export const useUpdateSeason = (
  options?: TMutationsOptions<ISeasonUpdateData, ISeasonUpdateData>
) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hotelId } = useParams();

  const { mutate, ...rest } = useCustomMutation<ISeasonUpdateData, ISeasonUpdateData>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      await queryClient.invalidateQueries(["get-season"], { exact: false });
      await queryClient.invalidateQueries(["get-seasons"], { exact: false });
      dispatch(setHandleDisabledSeasonSubmitButton(true));
      dispatch(setFooterContent(null));
      navigate(`/${hotelId}/${SETTINGS}/${PRICE_SETUP}`);
    },
    onSettled: () => {
      dispatch(setHandleSubmitSeasonData(false));
    }
  });

  const handleMutate = (seasonId: string, data: ISeason) => {
    mutate({ seasonId, body: data } as ISeasonUpdateData);
  };

  return { mutate: handleMutate, ...rest };
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { FallingOut } from "@/elements";
import { AppDrawer } from "@/elements/Drawer/Drawer";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { allLocationsIdStorageService, handleScroll } from "@/helpers";

import { BookingCreateSteps, SegmentTypes } from "@/constants/bookings/bookings.constant";
import { AllLocationType, ScrollTypes } from "@/constants/global";

import { BookingCreateContent } from "./BookingCreateContent";

import { RootState } from "@/store";
import {
  clearChoosenCalendarBooking,
  setHandleAllLocationId,
  setHandleBookingCreationStep,
  setHandleBookingFormCreate,
  setHandleSwitchType
} from "@/store/booking/booking.slice";

const { getItem: getAllLocation } = allLocationsIdStorageService(AllLocationType.ALL_LOCATIONS_ID);

export const BookingCreate = () => {
  const isShowBookingForm = useSelector((state: RootState) => state.booking.main.isShowBookingForm);
  const isMobile = useGetScreenStatus();
  const isAllLocation = getAllLocation();
  const chosenLocationId = useSelector(
    (state: RootState) => state.booking.allLocation.chosenLocationId
  );
  const bookingLocationId = useSelector(
    (state: RootState) => state.booking.choosenCalendarBooking.hotelId
  );
  const chosenLocation = useSelector((state: RootState) =>
    state.user.main?.hotels?.find((hotel) => hotel.id === (chosenLocationId || bookingLocationId))
  );
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const conditionTitle = isAllLocation
    ? t("booking.create.title-with-hotel", { hotelName: chosenLocation?.publicLabel })
    : t("booking.create.title");

  const handleClose = () => {
    dispatch(setHandleBookingFormCreate(false));
    dispatch(clearChoosenCalendarBooking());
    dispatch(setHandleAllLocationId(null));
    handleScroll(ScrollTypes.REMOVE);
    dispatch(setHandleSwitchType(SegmentTypes.CREATE));
    dispatch(setHandleBookingCreationStep(BookingCreateSteps.CREATE));
  };

  if (!isMobile) {
    return (
      <AppDrawer
        title={conditionTitle}
        onClose={handleClose}
        open={isShowBookingForm}
        maskClosable={false}
      >
        <BookingCreateContent />
      </AppDrawer>
    );
  }

  return (
    <>
      <FallingOut
        title={conditionTitle}
        handleClose={handleClose}
        content={<BookingCreateContent />}
      />
    </>
  );
};

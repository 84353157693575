import { useQueryClient } from "react-query";

import { useCustomMutation } from "@/hooks";

import { api, hotelIdStorageService } from "@/helpers";

import { HotelType } from "@/constants/global";

import { IBookingHotelWidgetExpire, TMutationsOptions } from "@/types";

const { getItem } = hotelIdStorageService(HotelType.HOTEL_ID);

const fetcher = async (body: IBookingHotelWidgetExpire): Promise<void> => {
  const activeHotelId = getItem();
  
  return api.patch(`/host/hotel/${activeHotelId}/expiration-time`, body);
};

export const useUpdateHotelBookingExpires = (
  options?: TMutationsOptions<void, IBookingHotelWidgetExpire>
) => {
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useCustomMutation<void, IBookingHotelWidgetExpire>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);

      await queryClient.invalidateQueries(["current-user"], { exact: false });
    }
  });

  return { mutate, ...rest };
};

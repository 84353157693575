import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Divider, Form, Input as AntdInput, Row } from "antd";
import dayjs from "dayjs";

import { DatePicker, FormItem, Icon } from "@/elements";

import { useBlockedDates } from "@/react-queries/booking/useBlockedDates";

import { useYupSync } from "@/hooks";

import { handleDatePicker, handleScroll } from "@/helpers";

import { DATE_FORMAT, ScrollTypes } from "@/constants/global";

import { bookingBlockSchema } from "./BookingBlock.schema";

import styles from "./BookingBlock.module.scss";

import { IBookingBlock } from "./BookingBlock.type";

import { RootState } from "@/store";
import {
  clearChoosenCalendarBooking,
  setHandleAllLocationId,
  setHandleBookingBlockedSection
} from "@/store/booking/booking.slice";

const { TextArea } = AntdInput;

export const BookingBlockForm = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const yupSync = useYupSync(bookingBlockSchema);
  const dispatch = useDispatch();
  const [checkinDate, setCheckinDate] = React.useState<string | null>("");
  const chooseCalendarDay = useSelector((state: RootState) => state.booking.choosenCalendarBooking);

  const { mutate: handleBlockDate } = useBlockedDates();

  const handleValueChange = (currentValue: IBookingBlock, values: IBookingBlock) => {
    const isCheckInDateKey = Object.keys(currentValue).includes("checkinDate");

    if (isCheckInDateKey) {
      setCheckinDate(dayjs(values.checkinDate).format(DATE_FORMAT));
    }

    if (values.checkinDate && values.checkoutDate) {
      if (!dayjs(values.checkinDate).isBefore(values.checkoutDate)) {
        form.setFieldsValue({
          checkoutDate: values.checkinDate,
          checkinDate: values.checkoutDate
        });
      }
      if (dayjs(values.checkinDate).isSame(values.checkoutDate, "day")) {
        form.setFieldsValue({
          checkoutDate: dayjs(values.checkoutDate).add(1, "day") as unknown as string
        });
      }
    }
  };

  const handleSubmit = (data: IBookingBlock) => {
    handleBlockDate({
      checkinDate: dayjs(data.checkinDate).format(DATE_FORMAT),
      checkoutDate: dayjs(data.checkoutDate).format(DATE_FORMAT),
      notes: data.notes
    });
    dispatch(setHandleAllLocationId(null));
    handleScroll(ScrollTypes.REMOVE);
  };

  const handleCloseBookingBlock = () => {
    dispatch(setHandleBookingBlockedSection(false));
    dispatch(clearChoosenCalendarBooking());
    dispatch(setHandleAllLocationId(null));
    handleScroll(ScrollTypes.REMOVE);
  };

  useEffect(() => {
    if (chooseCalendarDay?.date) {
      form.setFieldsValue({
        checkinDate: dayjs(chooseCalendarDay.date, DATE_FORMAT) as unknown as string
      });
      setCheckinDate(chooseCalendarDay.date);
    }
  }, [chooseCalendarDay]);

  return (
    <Form
      onValuesChange={handleValueChange}
      form={form}
      name="booking-blocked"
      onFinish={handleSubmit}
      layout="vertical"
      scrollToFirstError
      className={styles["booking-block"]}
    >
      <div className={styles["booking-block__container"]}>
        <Row className={styles["booking-block__item"]} gutter={24}>
          <Col xs={12}>
            <FormItem
              required
              name="checkinDate"
              label={t("booking.blocked.check-in")}
              rules={[yupSync]}
            >
              <DatePicker
                defaultValue={
                  chooseCalendarDay?.date ? dayjs(chooseCalendarDay.date, DATE_FORMAT) : null
                }
                inputReadOnly
                onChange={(date) => handleDatePicker(date, form)}
                suffixIcon={<Icon icon="calendar" />}
              />
            </FormItem>
          </Col>
          <Col xs={12}>
            <FormItem
              required
              name="checkoutDate"
              label={t("booking.blocked.check-out")}
              rules={[yupSync]}
            >
              <DatePicker
                allowClear={false}
                minDate={dayjs(checkinDate)}
                inputReadOnly
                suffixIcon={<Icon icon="calendar" />}
              />
            </FormItem>
          </Col>
        </Row>
        <div className={styles["booking-block__textarea"]}>
          <FormItem name="notes" label={t("booking.blocked.comment")} rules={[yupSync]}>
            <TextArea maxLength={200} rows={6} />
          </FormItem>
        </div>
        <Divider className={styles["booking-block__divider"]} />
        <div className={styles["booking-block__btns"]}>
          <Button className={styles["booking-block__submit-btn"]} type="primary" htmlType="submit">
            {t("booking.blocked.button-title")}
          </Button>
          <Button
            className={styles["booking-block__btn"]}
            ghost
            type="primary"
            onClick={handleCloseBookingBlock}
          >
            {t("booking.blocked.decline")}
          </Button>
        </div>
      </div>
    </Form>
  );
};

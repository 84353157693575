import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Pagination, Tabs } from "antd";

import { ApproveModal } from "@/containers/BookingViewCard/Modals/Approve/ApproveModal";
import { DeclineModal } from "@/containers/BookingViewCard/Modals/Decline/DeclineModal";

import { Loader, notification } from "@/elements";

import { useChangeBookingStatus } from "@/react-queries/booking/useChangeBookingStatus";
import { useGetBookings } from "@/react-queries/booking/useGetBookings";
import { useGetBookingsCount } from "@/react-queries/booking/useGetCountOfBookings";

import { handleScroll } from "@/helpers";

import { BookingStatus, FilterTabValues } from "@/constants/bookings/bookings.constant";
import { BOOKING_COUNT_PER_PAGE, ScrollTypes } from "@/constants/global";

import { IBookingFilterParams } from "@/types";

import styles from "./BookingList.module.scss";

import { EmptyContent } from "../../EmptyContent/EmptyContent";
import { BookingBlock } from "../../Forms/BookingBlock/BookingBlock";
import { BookingCreate } from "../../Forms/BookingCreate/BookingCreate";
import { BookingAction } from "../BookingAction/BookingAction";
import { BookingCard } from "../BookingCard/BookingCard";
import { BookingCreated } from "../BookingCreated/BookingCreated";
import { BookingEditPriceModal } from "../BookingEditPriceModal/BookingEditPriceModal";
import { BookingFilter } from "../BookingFilter/BookingFilter";

import { RootState } from "@/store";
import {
  setApproveModal,
  setDeclineModal,
  setFilterValues,
  setHandleBookingFilterSection,
  setHandleChooseBooking
} from "@/store/booking/booking.slice";

export const BookingList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const filterValues = useSelector((state: RootState) => state.booking.filters);
  const isShowDeclineModal = useSelector((state: RootState) => state.booking.view.isDeclineModal);
  const isShowApproveModal = useSelector((state: RootState) => state.booking.view.isApproveModal);
  const [currentPage, setCurrentPage] = useState<number>(Number(searchParams.get("page")) || 1);
  const isShowBookingCreate = useSelector(
    (state: RootState) => state.booking.main.isShowBookingForm
  );
  const isShowBlockBooking = useSelector((state: RootState) => state.booking.blocked.isShowBlocked);
  const isShowBookingAction = useSelector(
    (state: RootState) => state.booking.main.isShowActionButtonContent
  );
  const isShowEditPriceModal = useSelector(
    (state: RootState) => state.booking.isShowEditPriceModal
  );
  const [activeTabKey, setActiveTabKey] = useState<string>(
    searchParams.get("activeTab") || FilterTabValues.NOT_APPROVED
  );
  const isShowBookingFilter = useSelector((state: RootState) => state.booking.main.isShowFilter);
  const isBookingCreated = useSelector((state: RootState) => state.booking.main.isBookingCreated);
  const { mutate: handleStatusBooking } = useChangeBookingStatus();
  const choosenBookingFromList = useSelector(
    (state: RootState) => state.booking.view.chosenBooking
  );

  const {
    data: bookings,
    isLoading: isBookingsLoading,
    refetch,
    isRefetching: isBookingRefetching
  } = useGetBookings(filterValues);

  const {
    data: bookingCounts,
    isLoading: isBookingCountLoading,
    refetch: refetchCounter
  } = useGetBookingsCount(filterValues);

  useEffect(() => {
    if (!location.search) {
      navigate({
        search: `?activeTab=${activeTabKey}&page=${currentPage}`
      });
    }
  }, [location]);

  useEffect(() => {
    dispatch(
      setFilterValues({
        ...filterValues,
        page: currentPage,
        tab: activeTabKey as FilterTabValues
      })
    );
  }, [currentPage, activeTabKey]);

  useEffect(() => {
    refetch();
    refetchCounter();
  }, [filterValues, activeTabKey, currentPage]);

  const handleFilter = (values: IBookingFilterParams) => {
    dispatch(
      setFilterValues({
        ...values,
        tab: activeTabKey as FilterTabValues
      })
    );
    dispatch(setHandleBookingFilterSection(false));
    handleScroll(ScrollTypes.REMOVE);
  };

  const handleModalCloseApprove = () => {
    dispatch(setApproveModal(false));
    dispatch(setHandleChooseBooking(null));
  };

  const handleApprove = () => {
    handleStatusBooking({
      id: String(choosenBookingFromList?.id) as string,
      status: BookingStatus.APPROVED
    });
    dispatch(setApproveModal(false));
    dispatch(setHandleChooseBooking(null));
  };

  const handleModalCloseDecline = () => {
    dispatch(setHandleChooseBooking(null));
    dispatch(setDeclineModal(false));
  };

  const handleDecline = () => {
    handleStatusBooking({
      id: String(choosenBookingFromList?.id) as string,
      status: BookingStatus.REJECTED
    });
    dispatch(setDeclineModal(false));
    dispatch(setHandleChooseBooking(null));
    notification.error(
      <span className={styles["booking-view__declined"]}>{t("booking.history.rejected")}</span>
    );
  };

  const handleTabs = (value: string) => {
    setCurrentPage(1);
    setActiveTabKey(value);
    dispatch(
      setFilterValues({
        ...filterValues,
        tab: value as FilterTabValues,
        page: 1
      })
    );
    navigate({
      search: `?activeTab=${value}&page=1`
    });
  };

  const handlePages = (value: number) => {
    setCurrentPage(value);
    dispatch(
      setFilterValues({
        ...filterValues,
        page: value
      })
    );
    navigate({
      search: `?activeTab=${activeTabKey}&page=${value}`
    });
  };

  const conditionRenderBookingTabContent = () => {
    if (!bookings?.total) {
      return (
        <EmptyContent
          className={styles["booking-empty__container"]}
          content={t("booking.empty-content-tab")}
        />
      );
    }

    return bookings?.bookings?.map((booking, index) => (
      <BookingCard key={index} booking={booking} />
    ));
  };

  const BookingListTabs = [
    {
      key: FilterTabValues.NOT_APPROVED,
      label: t("booking.not-approved", { count: bookingCounts?.notApprovedCount }),
      children: conditionRenderBookingTabContent()
    },
    {
      key: FilterTabValues.FUTURE,
      label: t("booking.future", { count: bookingCounts?.futureCount }),
      children: conditionRenderBookingTabContent()
    },
    {
      key: FilterTabValues.ARCHIEVED,
      label: t("booking.archive", { count: bookingCounts?.archivedCount }),
      children: conditionRenderBookingTabContent()
    }
  ];

  if (isBookingsLoading || isBookingCountLoading || isBookingRefetching) {
    return <Loader isFullScreen />;
  }

  if (!bookings) {
    return (
      <div className={styles["booking-list__container"]}>
        <EmptyContent
          className={styles["booking-empty__container"]}
          content={t("booking.empty-content")}
        />
        {isShowBookingFilter && (
          <BookingFilter
            tabName={activeTabKey as FilterTabValues}
            handleFilterValues={handleFilter}
          />
        )}
      </div>
    );
  }

  return (
    <div className={styles["booking-list__container"]}>
      <Tabs
        onChange={handleTabs}
        activeKey={activeTabKey as FilterTabValues}
        defaultActiveKey={FilterTabValues.NOT_APPROVED}
        items={BookingListTabs}
      />
      {bookings.total ? (
        <div className={styles["booking-list__pagination"]}>
          <Pagination
            responsive
            defaultCurrent={currentPage}
            current={currentPage}
            total={bookings.total}
            onChange={handlePages}
            pageSize={BOOKING_COUNT_PER_PAGE}
            showSizeChanger={false}
          />
        </div>
      ) : null}
      {isShowBookingFilter && (
        <BookingFilter
          tabName={activeTabKey as FilterTabValues}
          handleFilterValues={handleFilter}
        />
      )}
      {isShowBookingAction && <BookingAction />}
      {isShowBookingCreate && <BookingCreate />}
      {isBookingCreated && <BookingCreated />}
      {isShowBlockBooking && <BookingBlock />}
      {isShowEditPriceModal && <BookingEditPriceModal />}
      {isShowDeclineModal && (
        <DeclineModal
          buttonTitle={t("booking.decline-modal.approve")}
          title={t("booking.decline-modal.title")}
          handleDecline={handleDecline}
          handleSkipDecline={handleModalCloseDecline}
        />
      )}
      {isShowApproveModal && (
        <ApproveModal handleApprove={handleApprove} handleSkipApprove={handleModalCloseApprove} />
      )}
    </div>
  );
};

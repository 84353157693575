import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Table } from "antd";

import { Loader } from "@/elements";

import { useGetGuests } from "@/react-queries/guests/useGetGuests";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { ROUTES } from "@/constants";

import styles from "./GuestList.module.scss";

import { AlphabeticList } from "../../AlphabeticList/AlphabeticList";
import { EmptyContent } from "../../EmptyContent/EmptyContent";

import { IGuest } from "./GuestList.type";

import { clearHeaderStates } from "@/store/header/header.slice";

const { GUESTS, VIEW } = ROUTES;

export const GuestList = ({ search }: { search?: string }) => {
  const {
    data: guests,
    isLoading,
    refetch: refetchGuestList
  } = useGetGuests({
    searchParams: search || ""
  });

  const { t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hotelId } = useParams();
  const isMobile = useGetScreenStatus();

  const handleGuestViewNavigation = (item: IGuest) => {
    navigate(`/${hotelId}/${GUESTS}/${item.id}/${VIEW}`);
  };

  useEffect(() => {
    dispatch(clearHeaderStates());
  }, [pathname]);

  useEffect(() => {
    if (typeof search === "string") {
      refetchGuestList();
    }
  }, [search]);

  const renderColumns = useMemo(() => {
    return [
      {
        title: t("guests.table-name"),
        key: "fullName",
        ellipsis: true,
        render: (record: IGuest) => {
          return <div className={styles["guests__table-name"]}>{record.fullName}</div>;
        }
      },
      {
        title: t("guests.table-phone"),
        key: "phoneNumber",
        render: (record: IGuest) => {
          return (
            <div className={styles["guests__table-item"]}>
              {record.phoneNumber}
            </div>
          );
        }
      },
      {
        title: t("guests.table-email"),
        key: "email",
        render: (record: IGuest) => {
          return (
            <div className={styles["guests__table-item"]}>
              {record.email}
            </div>
          );
        }
      },
      {
        title: t("guests.table-comment"),
        key: "comment",
        ellipsis: true,
        render: (record: IGuest) => {
          return (
            <div className={styles["guests__table-item"]}>
              {record.comment}
            </div>
          );
        }
      }
    ];
  }, [isMobile, guests]);

  if (isLoading) {
    return (
      <div className={styles.guests__container}>
        <Loader isFullScreen />
      </div>
    );
  }

  if (!guests?.length) {
    return (
      <div className={styles.guests__container}>
        <EmptyContent
          className={styles["guests-empty__container"]}
          content={t("guests.empty-content")}
        />
      </div>
    );
  }

  if (!isMobile) {
    return (
      <Table
        onRow={(record) => ({
          onClick: () => handleGuestViewNavigation(record)
        })}
        className={styles.guests__table}
        pagination={false}
        dataSource={guests}
        columns={renderColumns}
      />
    );
  }

  return (
    <div className={styles.guests__container}>
      <AlphabeticList handleAction={handleGuestViewNavigation} data={guests} />
    </div>
  );
};

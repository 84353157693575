import React, { FC, SVGProps } from "react";

import { ReactComponent as AlertOutline } from "@/assets/icons/alert-outline.svg";
import { ReactComponent as ArrowLeft } from "@/assets/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "@/assets/icons/arrow-right.svg";
import { ReactComponent as BankNote } from "@/assets/icons/bank-note.svg";
import { ReactComponent as Calendar } from "@/assets/icons/calendar.svg";
import { ReactComponent as CalendarPlus } from "@/assets/icons/calendar-plus.svg";
import { ReactComponent as CheckWithoutOutline } from "@/assets/icons/check.svg";
import { ReactComponent as Check } from "@/assets/icons/check-circle.svg";
import { ReactComponent as CheckOutline } from "@/assets/icons/check-outline.svg";
import { ReactComponent as CollapseIcon } from "@/assets/icons/collapse-icon.svg";
import { ReactComponent as Cross } from "@/assets/icons/cross.svg";
import { ReactComponent as DeclineOutline } from "@/assets/icons/cross-outline.svg";
import { ReactComponent as Dashboard } from "@/assets/icons/dashboard.svg";
import { ReactComponent as Decline } from "@/assets/icons/decline-icon.svg";
import { ReactComponent as Delete } from "@/assets/icons/delete.svg";
import { ReactComponent as DoubleArrowLeft } from "@/assets/icons/double-arrow-left.svg";
import { ReactComponent as DoubleArrowRight } from "@/assets/icons/double-arrow-right.svg";
import { ReactComponent as Dropdown } from "@/assets/icons/dropdown.svg";
import { ReactComponent as Edit } from "@/assets/icons/edit.svg";
import { ReactComponent as EmptySearch } from "@/assets/icons/empty-search.svg";
import { ReactComponent as EnIcon } from "@/assets/icons/EN-lang.svg";
import { ReactComponent as Exit } from "@/assets/icons/exit.svg";
import { ReactComponent as ExpandArrowIcon } from "@/assets/icons/expand-arrow-icon.svg";
import { ReactComponent as ExpandIcon } from "@/assets/icons/expand-icon.svg";
import { ReactComponent as Facebook } from "@/assets/icons/facebook.svg";
import { ReactComponent as FullLock } from "@/assets/icons/fullLock.svg";
import { ReactComponent as Google } from "@/assets/icons/google.svg";
import { ReactComponent as House } from "@/assets/icons/house.svg";
import { ReactComponent as Info } from "@/assets/icons/info.svg";
import { ReactComponent as InfoOutline } from "@/assets/icons/info-circle.svg";
import { ReactComponent as Inst } from "@/assets/icons/instagram.svg";
import { ReactComponent as Link } from "@/assets/icons/link.svg";
import { ReactComponent as Linkedin } from "@/assets/icons/linkedin.svg";
import { ReactComponent as Location } from "@/assets/icons/location.svg";
import { ReactComponent as Lock } from "@/assets/icons/lock.svg";
import { ReactComponent as OutlineTrash } from "@/assets/icons/outline-trash.svg";
import { ReactComponent as OutlineUsers } from "@/assets/icons/outline-users.svg";
import { ReactComponent as Pending } from "@/assets/icons/pending.svg";
import { ReactComponent as Plus } from "@/assets/icons/plus.svg";
import { ReactComponent as ReceiptCheck } from "@/assets/icons/receipt-check.svg";
import { ReactComponent as RoundedPlus } from "@/assets/icons/rounded-plus.svg";
import { ReactComponent as Search } from "@/assets/icons/search.svg";
import { ReactComponent as Settings } from "@/assets/icons/settings.svg";
import { ReactComponent as SettingsOutline } from "@/assets/icons/settings-outline.svg";
import { ReactComponent as Share } from "@/assets/icons/share.svg";
import { ReactComponent as Slider } from "@/assets/icons/slider.svg";
import { ReactComponent as UaIcon } from "@/assets/icons/UA-lang.svg";
import { ReactComponent as Upload } from "@/assets/icons/upload.svg";
import { ReactComponent as Users } from "@/assets/icons/users.svg";
import { ReactComponent as Logo } from "@/assets/images/logo.svg";

const ICONS_HASH_MAP: Record<string, React.ComponentType<SVGProps<SVGSVGElement>>> = {
  google: Google,
  info: Info,
  infoOutline: InfoOutline,
  location: Location,
  dashboard: Dashboard,
  upload: Upload,
  delete: Delete,
  declineOutline: DeclineOutline,
  alertOutline: AlertOutline,
  plus: Plus,
  search: Search,
  exit: Exit,
  expandArrow: ExpandArrowIcon,
  settings: Settings,
  inst: Inst,
  facebook: Facebook,
  linkedin: Linkedin,
  dropdown: Dropdown,
  logo: Logo,
  edit: Edit,
  house: House,
  arrowLeft: ArrowLeft,
  doubleArrowLeft: DoubleArrowLeft,
  arrowRight: ArrowRight,
  doubleArrowRight: DoubleArrowRight,
  success: Check,
  checkOutline: CheckOutline,
  decline: Decline,
  calendar: Calendar,
  receiptCheck: ReceiptCheck,
  users: Users,
  outlineUser: OutlineUsers,
  settingsOutline: SettingsOutline,
  emptySearch: EmptySearch,
  roundedPlus: RoundedPlus,
  outlineTrash: OutlineTrash,
  slider: Slider,
  cross: Cross,
  calendarPlus: CalendarPlus,
  lock: Lock,
  fullLock: FullLock,
  bankNote: BankNote,
  collapse: CollapseIcon,
  expand: ExpandIcon,
  pending: Pending,
  link: Link,
  share: Share,
  en: EnIcon,
  ua: UaIcon,
  check: CheckWithoutOutline,
};

interface IIconProps extends SVGProps<SVGSVGElement> {
  icon: keyof typeof ICONS_HASH_MAP;
}

export const Icon: FC<IIconProps> = ({ icon, ...rest }) => {
  const IconComponent = ICONS_HASH_MAP[icon];

  return <IconComponent {...rest} />;
};

import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Col, Divider, Form, Input as AntdInput, Row, Segmented } from "antd";
import dayjs from "dayjs";

import { DatePicker, FormItem, Icon, Input } from "@/elements";

import { useCreateBooking } from "@/react-queries/booking/useCreateBooking";
import { useCreateBookingWithExistingGuest } from "@/react-queries/booking/useCreateBookingWithExistingGuest";

import { useYupSync } from "@/hooks";
import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { handleDatePicker } from "@/helpers";

import { BookingCreateSteps, SegmentTypes } from "@/constants/bookings/bookings.constant";
import { DATE_FORMAT } from "@/constants/global";

import { bookingSchema } from "./BookingForm.schema";

import { IBookingForm } from "@/types";
import { IBookingFormProps } from "./BookingForm.types";

import styles from "./BookingForm.module.scss";

import { RootState } from "@/store";
import {
  clearGuest,
  setDisabledSubmitButton,
  setHandleAllLocationId,
  setHandleBookingCreationStep,
  setHandleBookingGuestList,
  setHandleSwitchType
} from "@/store/booking/booking.slice";

const { TextArea } = AntdInput;

export const BookingForm: FC<IBookingFormProps> = ({
  form,
  handleValueChange,
  guestCountError,
  checkinDate,
  dynamicTotalPrice,
  setAmount
}) => {
  const [isShowEmail, setIsShowEmail] = useState(false);
  const [isShowNote, setIsShowNote] = useState(false);
  const switchType = useSelector((state: RootState) => state.booking.creation.switchType);
  const guestListData = useSelector((state: RootState) => state.booking.guest);
  const choosenGuest = useSelector((state: RootState) => state.booking.guest);
  const chooseCalendarDay = useSelector((state: RootState) => state.booking.choosenCalendarBooking);
  const activeHotel = useSelector((state: RootState) => state.hotel.activeHotel);
  const yupSync = useYupSync(bookingSchema);
  const dispatch = useDispatch();
  const guestField = useRef(null);
  const isMobile = useGetScreenStatus();

  const { t } = useTranslation();

  const { mutate: createBooking } = useCreateBooking();
  const { mutate: createBookingWithExistingGuest } = useCreateBookingWithExistingGuest();

  const handleShowEmail = () => {
    if (!isShowEmail) {
      form.setFieldsValue({
        guest: {
          email: ""
        }
      });
    }
    setIsShowEmail(!isShowEmail);
  };

  const handleShowNote = () => {
    if (!isShowNote) {
      form.setFieldsValue({
        guest: {
          comment: ""
        }
      });
    }
    setIsShowNote(!isShowNote);
  };

  const handleGuestList = () => {
    if (!isMobile) {
      return dispatch(setHandleBookingCreationStep(BookingCreateSteps.GUESTS));
    }

    return dispatch(setHandleBookingGuestList(true));
  };

  const handleRemoveGuest = () => {
    dispatch(clearGuest());
  };

  const handleOnBlur = () => {
    if (!form.getFieldValue("totalPrice")) {
      return form.setFieldValue("totalPrice", dynamicTotalPrice?.totalPrice ?? 0);
    }
    if (
      setAmount &&
      form.getFieldValue("totalGuests") &&
      form.getFieldValue("checkinDate") &&
      form.getFieldValue("checkoutDate")
    ) {
      return setAmount(form.getFieldValue("totalPrice"));
    }
  };

  const contactContentShow = (type: SegmentTypes) => {
    switch (type) {
      case SegmentTypes.CREATE:
        return (
          <div className={styles["booking-form__blocks"]}>
            <Row gutter={24}>
              <Col xs={24}>
                <FormItem
                  required
                  name={["guest", "fullName"]}
                  label={t("booking.create.form-name")}
                  rules={[yupSync]}
                >
                  <Input />
                </FormItem>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col xs={24}>
                <FormItem
                  name={["guest", "phoneNumber"]}
                  label={t("booking.create.form-phone")}
                  rules={[yupSync]}
                >
                  <Input type="phone" />
                </FormItem>
              </Col>
            </Row>
            {isShowEmail ? (
              <Row gutter={24}>
                <Col xs={24}>
                  <FormItem name={["guest", "email"]} label="Email" rules={[yupSync]}>
                    <Input />
                  </FormItem>
                  <div onClick={handleShowEmail} className={styles["booking-form__remove-link"]}>
                    <Icon icon="cross" />
                    <span>{t("booking.create.form-remove-fields")}</span>
                  </div>
                </Col>
              </Row>
            ) : (
              <div onClick={handleShowEmail} className={styles["booking-form__add-link"]}>
                <Icon icon="roundedPlus" />
                <span>{t("booking.create.form-add-email")}</span>
              </div>
            )}
            {isShowNote ? (
              <Row gutter={24}>
                <Col xs={24}>
                  <FormItem
                    name={["guest", "comment"]}
                    label={t("booking.create.form-note")}
                    rules={[yupSync]}
                  >
                    <TextArea maxLength={200} rows={6} />
                  </FormItem>
                  <div onClick={handleShowNote} className={styles["booking-form__remove-link"]}>
                    <Icon icon="cross" />
                    <span>{t("booking.create.form-remove-fields")}</span>
                  </div>
                </Col>
              </Row>
            ) : (
              <div onClick={handleShowNote} className={styles["booking-form__add-link"]}>
                <Icon icon="roundedPlus" />
                <span>{t("booking.create.form-add-note")}</span>
              </div>
            )}
          </div>
        );
      case SegmentTypes.LIST:
        return choosenGuest ? (
          <div className={styles["booking-form__guest-detail-container"]}>
            <div className={styles["booking-form__guest-detail-content"]}>
              <p className={styles["booking-form__guest-detail-name"]}>{choosenGuest.fullName}</p>
              <p className={styles["booking-form__guest-detail-email"]}>{choosenGuest.email}</p>
              <p className={styles["booking-form__guest-detail-phone"]}>
                {choosenGuest.phoneNumber}
              </p>
            </div>
            <Icon
              icon="cross"
              className={styles["booking-form__guest-detail-close"]}
              onClick={handleRemoveGuest}
            />
          </div>
        ) : (
          <div className={styles["booking-form__guest"]} onClick={handleGuestList}>
            <p className={styles["booking-form__guest-text"]}>{t("booking.create.add-guest")}</p>
            <Icon icon="roundedPlus" />
          </div>
        );
      default:
        return null;
    }
  };

  const handleSubmit = (data: IBookingForm) => {
    if (!guestCountError) {
      if (guestListData) {
        createBookingWithExistingGuest({
          checkinDate: dayjs(data.checkinDate).format(DATE_FORMAT),
          checkoutDate: dayjs(data.checkoutDate).format(DATE_FORMAT),
          totalPrice: Number(data.totalPrice),
          totalGuests: Number(data.totalGuests),
          guest: {
            id: guestListData?.id,
            email: guestListData?.email,
            comment: guestListData?.comment,
            fullName: guestListData?.fullName,
            phoneNumber: guestListData?.phoneNumber
          }
        });
      } else {
        createBooking({
          checkinDate: dayjs(data.checkinDate).format(DATE_FORMAT),
          checkoutDate: dayjs(data.checkoutDate).format(DATE_FORMAT),
          totalPrice: Number(data.totalPrice),
          totalGuests: Number(data.totalGuests),
          guest: {
            email: data.guest?.email,
            comment: data.guest?.comment,
            fullName: data.guest?.fullName,
            phoneNumber: data.guest?.phoneNumber
          }
        });
      }
      dispatch(setHandleAllLocationId(null));
    }
  };

  useEffect(() => {
    if (switchType === "create") {
      dispatch(clearGuest());
    }
  }, [switchType]);

  useEffect(() => {
    if (switchType === SegmentTypes.LIST && !choosenGuest) {
      dispatch(setDisabledSubmitButton(true));
    } else {
      dispatch(setDisabledSubmitButton(false));
    }
  }, [switchType, choosenGuest]);

  useEffect(() => {
    const handleScroll = () => {
      if (guestField.current) {
        (guestField.current as any).blur();
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (setAmount && dynamicTotalPrice && dynamicTotalPrice.totalPrice) {
      form.setFieldsValue({
        totalPrice: dynamicTotalPrice?.totalPrice
      });
      setAmount(dynamicTotalPrice?.totalPrice);
    }
  }, [dynamicTotalPrice]);

  return (
    <Form
      onValuesChange={handleValueChange}
      form={form}
      name="booking-form"
      onFinish={handleSubmit}
      layout="vertical"
      scrollToFirstError
      className={styles["booking-form"]}
    >
      <Row gutter={24}>
        <Col xs={12}>
          <FormItem
            required
            name="checkinDate"
            label={t("booking.check-in-date")}
            rules={[yupSync]}
          >
            <DatePicker
              defaultValue={
                chooseCalendarDay?.date ? dayjs(chooseCalendarDay.date, DATE_FORMAT) : null
              }
              onChange={(date) => handleDatePicker(date, form)}
              onBlur={handleOnBlur}
              inputReadOnly
              suffixIcon={<Icon icon="calendar" />}
            />
          </FormItem>
        </Col>
        <Col xs={12}>
          <FormItem
            required
            name="checkoutDate"
            label={t("booking.check-out-date")}
            rules={[yupSync]}
          >
            <DatePicker
              allowClear={false}
              minDate={dayjs(checkinDate)}
              inputReadOnly
              suffixIcon={<Icon icon="calendar" />}
              onBlur={handleOnBlur}
            />
          </FormItem>
        </Col>
      </Row>
      <Divider />
      <div className={styles["booking-form__blocks"]}>
        <h1 className={styles["booking-form__title"]}>{t("booking.create.guest-info-title")}</h1>
        <Row gutter={24}>
          <Col xs={24}>
            <FormItem
              required
              name="totalGuests"
              label={t("booking.guests-amount")}
              rules={[yupSync]}
            >
              <Input
                ref={guestField}
                type="number"
                prefix={(<Icon icon="outlineUser" />) as unknown as string}
                suffix={t("booking.create.max", { count: activeHotel?.maxGuests })}
                min={1}
                onBlur={handleOnBlur}
              />
            </FormItem>
          </Col>
          <Col xs={24}>
            {guestCountError && (
              <p className={styles["booking-form__error"]}>
                {t("booking.create.guest-count-error")}
              </p>
            )}
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={24}>
            <FormItem
              rules={[yupSync]}
              required
              name="totalPrice"
              label={t("booking.price-per-night")}
            >
              <Input
                prefix={(<Icon icon="bankNote" />) as unknown as string}
                type="number"
                min={1}
                suffix={t("booking.create.fiat")}
                onBlur={handleOnBlur}
              />
            </FormItem>
          </Col>
        </Row>
      </div>
      <Divider />
      <h1 className={styles["booking-form__title"]}>
        {t("booking.create.guest-contact-detail-title")}
      </h1>
      <Segmented
        defaultValue="create"
        className={styles["booking-form__switch"]}
        value={switchType}
        onChange={(value) => dispatch(setHandleSwitchType(value as SegmentTypes))}
        options={[
          {
            label: t("booking.create.switch-create"),
            value: SegmentTypes.CREATE
          },
          {
            label: t("booking.create.switch-list"),
            value: SegmentTypes.LIST
          }
        ]}
      />
      {contactContentShow(switchType)}
    </Form>
  );
};

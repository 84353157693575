import { UploadFileStatus } from "antd/es/upload/interface";
import { RcFile } from "antd/lib/upload";
import { UploadRequestOption } from "rc-upload/lib/interface";

import { useCreatePreSignedUrl, useDeleteUploadedFile, useDirectUpload } from "@/react-queries";

import { getBase64 } from "@/helpers";

import { isRcFile, THostyUploadFile } from "@/types";

type TUploadControls = {
  onChange?: (fileList: THostyUploadFile[]) => void;
  getFileLists: () => THostyUploadFile[];
};

export const useUploadControls = ({ onChange, getFileLists }: TUploadControls) => {
  const createPreSignedUrl = useCreatePreSignedUrl();
  const directUpload = useDirectUpload();
  const deleteUploadedAssets = useDeleteUploadedFile();

  const startUploadingFile = (file: THostyUploadFile) => {
    if (onChange) {
      const currentFileList = getFileLists() || [];

      onChange([...currentFileList, { ...file, status: "uploading" }]);
    }
  };

  const updateFileList = async (file: RcFile, status: UploadFileStatus, signedId?: string) => {
    if (onChange) {
      const currentFileList = getFileLists();

      let base64 = "";

      if (status === "done" || status === ("success" as UploadFileStatus)) {
        base64 = await getBase64(file);
      }

      onChange(
        currentFileList?.map((el) => {
          if (el.uid !== file.uid) return el;

          return {
            file,
            name: file.name,
            signedId,
            uid: signedId || "",
            ...(base64 && { thumbUrl: base64 }),
            status
          };
        })
      );
    }
  };

  const customRequest = async ({ file }: UploadRequestOption) => {
    if (isRcFile(file)) {
      try {
        startUploadingFile(file);

        const { url, headers, signedId } = await createPreSignedUrl.mutateAsync(file);

        await directUpload.mutateAsync({ url, data: file, headers });

        await updateFileList(file, "done", signedId);
      } catch (error) {
        await updateFileList(file, "error");
      }
    }
  };

  const onRemove = (e: THostyUploadFile) => {
    if (onChange) {
      const currentFileList = getFileLists();

      onChange(currentFileList.filter((el) => el.uid !== e.uid));
      e.signedId && deleteUploadedAssets.mutate(e.signedId);
    }
  };

  return { onRemove, customRequest };
};

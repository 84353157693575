
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { useCustomMutation } from "@/hooks";

import { api, hotelIdStorageService } from "@/helpers";

import { ROUTES } from "@/constants";
import { HotelType } from "@/constants/global";

import { TMutationsOptions } from "@/types";

const { getItem: getHotelId } = hotelIdStorageService(HotelType.HOTEL_ID);

const { LOCATIONS } = ROUTES;

const fetcher = async () => {
    const hotelId = getHotelId();

  return api.del(`host/hotel/${hotelId}`);
};

export const useDeleteLocation = (options?: TMutationsOptions<void, void>) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, ...rest } = useCustomMutation<void, void>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      await queryClient.invalidateQueries(["current-user"], { exact: false });
      const hotelId = getHotelId();

      navigate(`/${hotelId}/${LOCATIONS}`)
    }
  });

  const handleMutate = () => {
    mutate();
  };

  return { mutate: handleMutate, ...rest };
};

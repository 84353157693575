import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ILocationSettingsResponse } from "@/types";

export interface HotelSlice {
  activeHotel: ILocationSettingsResponse | null;
}

const initialState: HotelSlice = {
  activeHotel: null
};

export const hotelSlice = createSlice({
  name: "hotel",
  initialState,
  reducers: {
    setActiveHotel: (state, action: PayloadAction<HotelSlice["activeHotel"]>) => {
      state.activeHotel = action.payload;
    },
    clearActiveHotel: (state) => {
      state.activeHotel = null;
    }
  }
});

export const { setActiveHotel, clearActiveHotel } = hotelSlice.actions;

export default hotelSlice.reducer;

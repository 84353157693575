
import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { useCustomMutation } from "@/hooks";

import { api } from "@/helpers";

import { TMutationsOptions } from "@/types";

import { setHandleSubmitSeasonData } from "@/store/footer/footer.slice";
import { setHandleDisabledSeasonSubmitButton } from "@/store/price-setup/price-setup.slice";

interface IDeleteTariff {
    seasonId: number;
    tariffId: number;
}

const fetcher = async ({ seasonId, tariffId }: IDeleteTariff) => {
  return api.del(`seasons/${seasonId}/tariffs/${tariffId}`);
};

export const useDeleteTariff = (options?: TMutationsOptions<IDeleteTariff, IDeleteTariff>) => {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { mutate, ...rest } = useCustomMutation<IDeleteTariff, IDeleteTariff>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      await queryClient.invalidateQueries(["get-seasons"], { exact: false });
      await queryClient.invalidateQueries(["get-season-tariffs"], { exact: false });
      dispatch(setHandleDisabledSeasonSubmitButton(false));
    },
    onSettled: () => {
      dispatch(setHandleSubmitSeasonData(false))
    }
  });

  const handleMutate = (data: IDeleteTariff) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

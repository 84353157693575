import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { api, handleHotelIdInApi } from "@/helpers";

import {
  IBookingAllLocationsPriceCalendar,
  IGetBookingCalendarDayPrice,
  TQueryOptions
} from "@/types";

const fetcher = async (dates: IGetBookingCalendarDayPrice) => {
  return api.get("host/bookings/prices/calendar/all", handleHotelIdInApi(dates));
};

export const useGetAllLocationsPriceCalendar = <
  TQueryFnData = IGetBookingCalendarDayPrice,
  TData = IBookingAllLocationsPriceCalendar[]
>(
  dates: IGetBookingCalendarDayPrice,
  options?: TQueryOptions<TQueryFnData, TData>
) => {
  return useCustomQuery<TQueryFnData, TData>(
    ["get-all-locations-calendar-booking-prices"],
    () => fetcher(dates),
    {
      ...options,
      staleTime: Infinity
    }
  );
};

import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Divider } from "antd";

import { Icon } from "@/elements";

import { useUpdateUserLang } from "@/react-queries/user/useUpdateUserLang";

import { ROUTES } from "@/constants";
import { LanguageType } from "../../../constants/global";

import styles from "./SettingsApp.module.scss";

import { getActiveLanguage } from "./SettingsApp.helper";

import { setHeaderNavigation } from "@/store/header/header.slice";

const { SETTINGS } = ROUTES;

export const SettingsApp = () => {
  const { t, i18n: { language } } = useTranslation();
  const dispatch = useDispatch();
  const { hotelId } = useParams();
  const { mutate: handleLangUpdate } = useUpdateUserLang();

  const handleChangeLanguage = (language: LanguageType) => {
    handleLangUpdate({
      appLanguage: language
    });
  };

  useEffect(() => {
    dispatch(
      setHeaderNavigation({
        navigation: {
          title: t("settings.title"),
          link: `/${hotelId}/${SETTINGS}`
        }
      })
    );
  }, [language]);

  return (
    <div className={styles["settings-app__container"]}>
      <h1 className={styles["settings-app__title"]}>{t("settings.app.language-title")}</h1>
      <div className={styles["settings-app__content"]}>
        <div
          onClick={() => handleChangeLanguage(LanguageType.EN)}
          className={styles["settings-app__language-item"]}
        >
          <div className={styles["settings-app__language-item-content"]}>
            <Icon icon="en" />
            <span>{t("settings.app.language-en")}</span>
          </div>
          {getActiveLanguage(LanguageType.EN) ? (
            <Icon className={styles["settings-app__language-check"]} icon="check" />
          ) : null}
        </div>
        <Divider className={styles["settings-app__divider"]} />
        <div
          onClick={() => handleChangeLanguage(LanguageType.UA)}
          className={styles["settings-app__language-item"]}
        >
          <div className={styles["settings-app__language-item-content"]}>
            <Icon icon="ua" />
            <span>{t("settings.app.language-ua")}</span>
          </div>
          {getActiveLanguage(LanguageType.UA) ? (
            <Icon className={styles["settings-app__language-check"]} icon="check" />
          ) : null}
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { FallingOut } from "@/elements";
import { AppDrawer } from "@/elements/Drawer/Drawer";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { handleScroll } from "@/helpers";

import { ScrollTypes } from "@/constants/global";

import { BookingBlockEditContent } from "./BookingBlockEditContent";

import { RootState } from "@/store";
import { setIsShowBlockedModal } from "@/store/booking/booking.slice";

export const BookingBlockEdit = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isMobile = useGetScreenStatus();
  const isShowBlockedEditModal = useSelector(
    (state: RootState) => state.booking.blocked.isShowBlockedEditModal
  );

  const handleCloseBookingBlock = () => {
    dispatch(setIsShowBlockedModal(false));
    handleScroll(ScrollTypes.REMOVE);
  };

  if (!isMobile) {
    return (
      <AppDrawer
        title={t("booking.blocked.edit-block-booking")}
        onClose={handleCloseBookingBlock}
        open={isShowBlockedEditModal}
      >
        <BookingBlockEditContent />
      </AppDrawer>
    );
  }

  return (
    <FallingOut
      title={t("booking.blocked.edit-block-booking")}
      handleClose={handleCloseBookingBlock}
      content={<BookingBlockEditContent />}
    />
  );
};

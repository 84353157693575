import { useQueryClient } from "react-query";
import { useDispatch } from "react-redux";

import { IGuestForm } from "@/components/Forms/GuestForm/GuestForm.type";

import { useCustomMutation } from "@/hooks";

import { api } from "@/helpers";

import { TMutationsOptions } from "@/types";

import { setCreateGuestStatus } from "@/store/guests/guests.slice";

interface IGuestUpdateData {
  userId: string;
  body: IGuestForm;
}

const fetcher = async ({ userId, body }: IGuestUpdateData) => {
  return api.patch(`guests/${userId}`, body);
};

export const useUpdateGuest = (options?: TMutationsOptions<IGuestUpdateData, IGuestUpdateData>) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, ...rest } = useCustomMutation<IGuestUpdateData, IGuestUpdateData>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      dispatch(setCreateGuestStatus(true));
      await queryClient.invalidateQueries(["get-guests"], { exact: false });
      await queryClient.invalidateQueries(["get-guest"], { exact: false });
    }
  });

  const handleMutate = (userId: string, data: IGuestForm) => {
    mutate({ userId, body: data } as IGuestUpdateData);
  };

  return { mutate: handleMutate, ...rest };
};

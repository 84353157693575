import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";

import { ICountNights } from "@/components/Calendar/CalendarListViewItem/CalendarListViewItem.type";

import { allLocationsIdStorageService, getCountOfDays, langStorageService } from "@/helpers";

import { CalendarListViewType } from "@/constants/calendar/calendar.constants";
import {
  AllLocationType,
  DATE_DAY_NUMBER_FORMAT,
  DATE_DAY_OF_WEEK_FORMAT,
  DATE_DAY_OF_WEEK_MEDIUM_FORMAT,
  DATE_FORMAT,
  FULL_DATE_FORMAT,
  LanguageType
} from "@/constants/global";

import { IBookingReservations, IMonthDays } from "@/types";

import useGetScreenStatus from "../general/useGetScreenStatus";

import { IGetDaysInMonth, IMonthStartAndEnd } from "./useGetDaysInMonth.type";

import { RootState } from "@/store";
import { setActiveMonthDays } from "@/store/calendar/calendar.slice";

const { getItem } = langStorageService(LanguageType.LANG);
const { getItem: getIsAllLocation } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

export const useGetDaysInMonth = () => {
  const dates = useSelector((state: RootState) => state.calendar.activeDates);
  const isAllLocations = getIsAllLocation();
  const isMobile = useGetScreenStatus();
  const calendarDate = useSelector((state: RootState) => state.calendar.calendarDate);
  const dispatch = useDispatch();

  const handleDates = () => {
    const conditionOfAllLocationAndMobile =
      isAllLocations && !isMobile
        ? dayjs(dayjs(calendarDate, FULL_DATE_FORMAT))
            .add(2, "month")
            .endOf("month")
            .format(DATE_FORMAT)
        : dayjs(dayjs(calendarDate, FULL_DATE_FORMAT))
            .month(dayjs(calendarDate, FULL_DATE_FORMAT).month())
            .endOf("month")
            .format(DATE_FORMAT);

    dispatch(
      setActiveMonthDays({
        fromDate: dayjs(dayjs(calendarDate, FULL_DATE_FORMAT))
          .month(dayjs(calendarDate, FULL_DATE_FORMAT).month())
          .startOf("month")
          .format(DATE_FORMAT),
        toDate: conditionOfAllLocationAndMobile
      })
    );
  };

  const handleRangeDaysInMonth = (
    reservationFromDate: string,
    reservationToDate: string,
    monthDays?: IMonthStartAndEnd
  ) => {
    const activeMonth = dayjs(monthDays?.fromDate).month();
    const startDate = dayjs(reservationFromDate);
    const endDate = dayjs(reservationToDate);
    const isEnglishLang = getItem() === LanguageType.EN;
    const conditionDayOfWeekFormat = isEnglishLang
      ? DATE_DAY_OF_WEEK_MEDIUM_FORMAT
      : DATE_DAY_OF_WEEK_FORMAT;

    const days = [];

    for (
      let date = startDate;
      date.isBefore(endDate) || date.isSame(endDate);
      date = date.add(1, "day")
    ) {
      days.push({
        date: date.format(DATE_FORMAT),
        day: date.format(DATE_DAY_NUMBER_FORMAT),
        dayOfWeek: date.format(conditionDayOfWeekFormat),
        isDisplay: true,
        isExpanded: false
      });
    }

    if (monthDays && !isAllLocations && isMobile) {
      return days.filter((day) => dayjs(day.date).month() === activeMonth);
    }

    return days;
  };

  const getBookingCoupleNights = ({
    reservationDates,
    expandedDays,
    monthDays
  }: {
    reservationDates: IBookingReservations[];
    expandedDays?: string[];
    monthDays?: IMonthStartAndEnd;
  }) => {
    const activeMonth = dayjs(monthDays?.fromDate).month();

    const getBookingDate = ({
      reservationCheckinDate,
      rangeBookingsDates
    }: {
      reservationCheckinDate: string;
      rangeBookingsDates: IMonthDays[];
    }) => {
      if (dayjs(reservationCheckinDate).month() !== activeMonth) {
        return {
          bookingDay: rangeBookingsDates[0]?.date,
          updatedReservationBookingsDates: rangeBookingsDates.filter(
            (date) => date.date !== rangeBookingsDates[0]?.date
          )
        };
      }

      return {
        bookingDay: reservationCheckinDate,
        updatedReservationBookingsDates: rangeBookingsDates
      };
    };

    return reservationDates.reduce((acc: ICountNights, reservation) => {
      const days = getCountOfDays({
        fromDate: reservation.checkinDate,
        toDate: reservation.checkoutDate
      });
      let rangeBookingsDates = [];
      if (days > 1) {
        rangeBookingsDates = handleRangeDaysInMonth(
          reservation.checkinDate,
          reservation.checkoutDate,
          monthDays
        ).filter(
          (day) => day.date !== reservation.checkinDate && day.date !== reservation.checkoutDate
        );
        if (expandedDays) {
          expandedDays.push(
            ...rangeBookingsDates.map((bookingDate: IMonthDays) => bookingDate.date)
          );
        }

        const { bookingDay, updatedReservationBookingsDates } = getBookingDate({
          reservationCheckinDate: reservation.checkinDate,
          rangeBookingsDates
        });

        acc[bookingDay] = {
          ...reservation,
          rangeBookingsDates: updatedReservationBookingsDates,
          nights: days,
          countOfShowDays: days
        };
      }

      return acc;
    }, {});
  };

  const handleRangeDaysInMonthWithExpanded = ({ monthDays, reservationDates }: IGetDaysInMonth) => {
    const activeMonthKey = dayjs(dates.fromDate).month();
    const listOfMonthDays = handleRangeDaysInMonth(monthDays.fromDate, monthDays.toDate);
    const expandedDays: string[] = [];
    const coupleNights = getBookingCoupleNights({
      reservationDates,
      expandedDays,
      monthDays
    });
    const activeMonthExpandedDays = expandedDays.filter(
      (day) => dayjs(day).month() === activeMonthKey
    );

    const monthDaysWithExpanded = listOfMonthDays.map((day) => {
      if (coupleNights[day.date]) {
        return {
          ...day,
          rangeBookingsDates: coupleNights[day.date].rangeBookingsDates,
          isShowExpand: true
        };
      }

      if (activeMonthExpandedDays.includes(day.date) && monthDays.fromDate !== day.date) {
        return {
          ...day,
          isDisplay: false
        };
      }

      return {
        ...day,
        isDisplay: true
      };
    });

    return monthDaysWithExpanded;
  };

  const handleExpandCollapseBookingsDays = (
    showType: CalendarListViewType,
    date: string,
    monthDays: IMonthDays[],
    reservationDates: IBookingReservations[],
    dates: IMonthStartAndEnd
  ) => {
    const coupleNights = getBookingCoupleNights({
      reservationDates,
      monthDays: dates
    });
    const rangeBookingsDates = coupleNights[date].rangeBookingsDates || [];
    const expandedDays = rangeBookingsDates.map((bookingDate: IMonthDays) => bookingDate.date);

    switch (showType) {
      case CalendarListViewType.COLLAPSE:
        return monthDays.map((day) => {
          if (day.date === date && coupleNights[day.date]) {
            return {
              ...day,
              isExpanded: false
            };
          }

          if (expandedDays.includes(day.date)) {
            return {
              ...day,
              isDisplay: false
            };
          }

          return day;
        });
      case CalendarListViewType.EXPAND:
        return monthDays.map((day) => {
          if (day.date === date && coupleNights[day.date]) {
            return {
              ...day,
              isExpanded: true
            };
          }
          if (expandedDays.includes(day.date)) {
            return {
              ...day,
              isDisplay: true
            };
          }

          return day;
        });
      default:
        return monthDays;
    }
  };

  return {
    dates,
    handleDates,
    handleRangeDaysInMonth,
    handleRangeDaysInMonthWithExpanded,
    handleExpandCollapseBookingsDays
  };
};

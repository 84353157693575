import dayjs from "dayjs";

import { langStorageService } from "@/helpers/storage/storage.helpers";

import { DATE_DAY_OF_WEEK_FORMAT, DATE_DAY_OF_WEEK_FULL_FORMAT, DATE_DAY_OF_WEEK_MEDIUM_FORMAT, LanguageType } from "@/constants/global";

const { getItem } = langStorageService<string>(LanguageType.LANG);

export const getWeekDays = () => {
  const today = dayjs();
  const startOfWeek = today.startOf("week");
  const endOfWeek = today.endOf("week");
  const isEnglishLang = getItem() === LanguageType.EN;

  const conditionDayOfWeekFormat = isEnglishLang
  ? DATE_DAY_OF_WEEK_MEDIUM_FORMAT
  : DATE_DAY_OF_WEEK_FORMAT;

  const weekdays = [];

  for (let day = startOfWeek; day <= endOfWeek; day = day.add(1, "day")) {
    weekdays.push({
      shortName: day.format(conditionDayOfWeekFormat),
      fullName: day.locale("en").format(DATE_DAY_OF_WEEK_FULL_FORMAT),
    });
  }

  return weekdays;
};

import React from "react";
import { Avatar } from "antd";

import { ILocationSettingsResponse } from "@/types";

import styles from "./LocationTag.module.scss";

export const LocationTag = ({ hotel }: { hotel: ILocationSettingsResponse | undefined }) => {
  return (
    <div className={styles["location-tag__container"]}>
      <Avatar className={styles["location-tag__avatar"]} size={"small"} src={hotel?.locationImage} />
      <span className={styles["location-tag__name"]}>{hotel?.publicLabel}</span>
    </div>
  );
};

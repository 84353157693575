import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Divider, Tag } from "antd";

import { useGetTransaction } from "@/react-queries/transactions/useGetTransaction";

import { handleScroll } from "@/helpers";

import { ROUTES } from "@/constants";
import { BookingPaymentStatus } from "@/constants/bookings/bookings.constant";
import { ScrollTypes } from "@/constants/global";

import styles from "./BookingPayment.module.scss";

import { getTypeOfPayment } from "./BookingPayment.helper";
import { IBookingPayment } from "./BookingPayment.type";

import { setIsShowPaymentModal } from "@/store/booking/booking.slice";

const { BOOKINGS, PAYMENT_HISTORY } = ROUTES;

export const BookingPayment: FC<IBookingPayment> = ({ booking }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { bookingId, hotelId } = useParams();

  const { data: transactions, isLoading: isTransactionLoading } = useGetTransaction(
    bookingId || "",
    {
      enabled: !!bookingId
    }
  );

  const isTransactionExist = !transactions?.length;

  const typeOfStatusOfPayment = getTypeOfPayment({
    amountValue: booking?.paidAmount || 0,
    totalValue: booking?.totalPrice || 0
  });

  const isOverpay = typeOfStatusOfPayment && typeOfStatusOfPayment === BookingPaymentStatus.OVERPAY;
  const isPartPaymentStatus = typeOfStatusOfPayment === BookingPaymentStatus.PART_PAY;

  const handleOpenPaymentModal = () => {
    dispatch(setIsShowPaymentModal(true));
    handleScroll(ScrollTypes.ADD);
  };

  const handleToHistoryPayment = () => {
    navigate(`/${hotelId}/${BOOKINGS}/${booking?.id}/${PAYMENT_HISTORY}`);
  };

  return (
    <div className={styles.payment__container}>
      <div className={styles.payment__header}>
        <h1 className={styles["payment__header-title"]}>{t("booking.view.payment.title")}</h1>
        <Tag className={`${styles["payment__header-tag"]} ${styles[typeOfStatusOfPayment]}`}>
          {t(`booking.view.payment.tags.${typeOfStatusOfPayment}`)}
        </Tag>
      </div>
      <Divider className={styles.payment__divider} />
      <div className={styles.payment__details}>
        <div className={styles["payment__details-container"]}>
          <p className={styles["payment__details-label"]}>{t("booking.view.payment.label")}</p>
          <div className={styles.payment__amount}>
            <span
              className={`${styles["payment__amount-payed"]} ${
                styles[
                  getTypeOfPayment({
                    amountValue: booking?.paidAmount || 0,
                    totalValue: booking?.totalPrice || 0
                  })
                ]
              }`}
            >
              {booking?.paidAmount}
            </span>
            <span className={styles["payment__amount-divider"]}>/</span>
            <span className={styles["payment__amount-total"]}>{booking?.totalPrice}</span>
            <span className={styles["payment__amount-fiat"]}>{t("booking.view.payment.unit")}</span>
          </div>
        </div>
        {isPartPaymentStatus ? (
          <div className={styles["payment__details-payment-part"]}>
            <p className={styles["payment__details-payment-part-title"]}>
              {t("booking.view.payment.to-be-paid")}
            </p>
            <p className={styles["payment__details-payment-part-amount"]}>
              {(booking?.totalPrice || 0) - (booking?.paidAmount || 0)}
            </p>
          </div>
        ) : null}
        {isOverpay ? (
          <div
            className={`${styles["payment__details-payment-part"]} ${
              isOverpay ? styles[typeOfStatusOfPayment] : null
            }`}
          >
            <p className={styles["payment__details-payment-part-title"]}>
              {isOverpay ? t("booking.view.payment.overpay") : t("booking.view.payment.to-be-paid")}
            </p>
            <p className={styles["payment__details-payment-part-amount"]}>
              {(booking?.paidAmount || 0) - (booking?.totalPrice || 0)}
            </p>
          </div>
        ) : null}
      </div>
      <Divider className={styles.payment__divider} />
      <div className={styles.payment__buttons}>
        <Button onClick={handleOpenPaymentModal} type="primary" className={styles.payment__button}>
          {t("booking.view.payment.transaction")}
        </Button>
        <Button
          loading={isTransactionLoading}
          onClick={handleToHistoryPayment}
          disabled={!isTransactionLoading && isTransactionExist}
          className={styles.payment__button}
          ghost
        >
          {t("booking.view.payment.history")}
        </Button>
      </div>
    </div>
  );
};

import React, { FC, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, Divider, Form, Input as AntdInput, Segmented, Select, Switch } from "antd";
import dayjs from "dayjs";

import { DatePicker, FormItem, Icon, Input, notification } from "@/elements";
import { TimePicker } from "@/elements/TimePicker/TimePicker";

import { useGetPaymentOptions } from "@/react-queries/paymentOptions/useGetPaymentOptions";
import { useCreateTransaction } from "@/react-queries/transactions/useCreateTransaction";

import { useYupSync } from "@/hooks";

import { handleScroll } from "@/helpers";

import {
  BookingPaymentStatus,
  SegmentBookingPaymentTypes
} from "@/constants/bookings/bookings.constant";
import { DATE_FORMAT, ScrollTypes, TIME_FORMAT } from "@/constants/global";

import { bookingPaymentSchema } from "./BookingPaymentForm.schema";

import { IBookingTransactions, ISelect } from "@/types";

import styles from "./BookingPaymentForm.module.scss";

import { getTypeOfPayment } from "../../Booking/BookingPayment/BookingPayment.helper";

import { getPaymentStatus } from "./BookingPaymentForm.helper";
import { IBookingPaymentForm } from "./BookingPaymentForm.type";

import { setIsShowPaymentModal } from "@/store/booking/booking.slice";

const { TextArea } = AntdInput;

export const BookingPaymentFormContent: FC<IBookingPaymentForm> = ({ booking }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const yupSync = useYupSync(bookingPaymentSchema);
  const [isShowAdditional, setIsShowAdditional] = useState(false);
  const dispatch = useDispatch();
  const [switchType, setSwitchType] = useState<SegmentBookingPaymentTypes>(
    SegmentBookingPaymentTypes.PAYMENT
  );
  const typeOfStatusOfPayment = getTypeOfPayment({
    amountValue: booking?.paidAmount || 0,
    totalValue: booking?.totalPrice || 0
  });

  const typeOfStatusForTag = getPaymentStatus({
    amountValue: booking?.paidAmount || 0,
    totalValue: booking?.totalPrice || 0
  });

  const isOverpay = typeOfStatusForTag && typeOfStatusForTag === BookingPaymentStatus.OVERPAY;
  const isRefund = useMemo(() => switchType === SegmentBookingPaymentTypes.REFUND, [switchType]);

  const conditionalAmount = useMemo(() => {
    return isOverpay
      ? (booking?.paidAmount || 0) - (booking?.totalPrice || 0)
      : (booking?.totalPrice || 0) - (booking?.paidAmount || 0);
  }, [booking]);

  const { mutate: createTransaction } = useCreateTransaction();

  const { data: paymentOptions } = useGetPaymentOptions({
    isTurnedOn: true
  });

  const paymentOptionsList = paymentOptions?.map((option) => ({
    label: option.name || "",
    value: option.id || ""
  }));

  const handleClose = () => {
    dispatch(setIsShowPaymentModal(false));
    handleScroll(ScrollTypes.REMOVE);
  };

  const handleSubmit = (values: IBookingTransactions) => {
    if (typeof values.paymentOptionId === "undefined") {
      return notification.error(t("booking.payment.error.payment-method"));
    }
    if (isRefund && values.amount > (booking?.paidAmount || 0)) {
      return notification.error(t("booking.payment.error.refund"));
    }
    const transactionDateTime = dayjs(
      `${dayjs(values.transactionDate).format(DATE_FORMAT)}T${dayjs(values.transactionTime).format(
        TIME_FORMAT
      )}`
    ).format();
    createTransaction({
      bookingId: booking?.id,
      data: {
        ...values,
        paymentOptionId: Number(values.paymentOptionId),
        amount: Number(values.amount),
        transactionTime: transactionDateTime,
        transactionType: switchType
      }
    });
  };

  return (
    <div className={styles.payment__container}>
      <Form
        layout="vertical"
        onFinish={handleSubmit}
        form={form}
        className={styles.payment__wrapper}
      >
        <div className={styles.payment__payed}>
          <div className={styles["payment__payed-container"]}>
            <div className={styles["payment__payed-label"]}>{t("booking.payment.payed")}</div>
            <div className={styles["payment__payed-details"]}>
              <span
                className={`${styles["payment__payed-count"]} ${styles[typeOfStatusOfPayment]}`}
              >
                {booking?.paidAmount}
              </span>
              <span className={styles["payment__payed-divider"]}>/</span>
              <span className={styles["payment__payed-amount"]}>{booking?.totalPrice}</span>
              <span className={styles["payment__payed-fiat"]}>{t("booking.payment.unit")}</span>
            </div>
          </div>
          {typeOfStatusForTag ? (
            <div
              className={`${styles["payment__payed-payment-part"]} ${
                typeOfStatusForTag && styles[typeOfStatusForTag]
              }`}
            >
              <p className={styles["payment__payed-payment-part-title"]}>
                {isOverpay
                  ? t("booking.view.payment.overpay")
                  : t("booking.view.payment.to-be-paid")}
              </p>
              <p className={styles["payment__payed-payment-part-amount"]}>{conditionalAmount}</p>
            </div>
          ) : null}
        </div>

        <Segmented
          defaultValue={SegmentBookingPaymentTypes.PAYMENT}
          className={styles.payment__switch}
          value={switchType}
          onChange={(value) => setSwitchType(value as SegmentBookingPaymentTypes)}
          options={[
            {
              label: t("booking.payment.switch-payment"),
              value: SegmentBookingPaymentTypes.PAYMENT
            },
            {
              label: t("booking.payment.switch-refund"),
              value: SegmentBookingPaymentTypes.REFUND,
              disabled: !booking?.paidAmount
            }
          ]}
        />

        <FormItem
          name="paymentOptionId"
          label={t("booking.payment.form.payment-method")}
          required
          rules={[yupSync]}
          className={styles.payment__select}
        >
          <Select
            placeholder={t("booking.payment.form.payment-method-placeholder")}
            options={paymentOptionsList as ISelect[]}
            suffixIcon={<Icon icon="dropdown" />}
          />
        </FormItem>
        <FormItem
          name="amount"
          label={t("booking.payment.form.payment-amount")}
          required
          rules={[yupSync]}
        >
          <Input
            prefix={(<Icon icon="bankNote" />) as unknown as string}
            type="number"
            min={1}
            suffix={t("booking.edit.fiat")}
            max={isRefund ? Number(booking?.paidAmount || 0) : undefined}
          />
        </FormItem>
        <Divider className={styles.payment__divider} />
        <div className={styles.payment__additional}>
          <div className={styles["payment__additional-header"]}>
            <p className={styles["payment__additional-title"]}>
              {t("booking.payment.form.additional-details")}
            </p>
            <Switch value={isShowAdditional} onChange={setIsShowAdditional} />
          </div>
          {isShowAdditional ? (
            <div className={styles["payment__additional-content"]}>
              <FormItem
                name={"transactionDate"}
                label={t("booking.payment.form.payment-date")}
                rules={[yupSync]}
              >
                <DatePicker inputReadOnly />
              </FormItem>
              <FormItem
                name={"transactionTime"}
                label={t("booking.payment.form.payment-time")}
                rules={[yupSync]}
              >
                <TimePicker inputReadOnly />
              </FormItem>
              <FormItem
                name={"transactionNumber"}
                label={t("booking.payment.form.number-transactions")}
                rules={[yupSync]}
              >
                <Input placeholder={t("booking.payment.form.number-transactions-placeholder")} />
              </FormItem>
              <FormItem name={"notes"} label={t("booking.payment.form.comment")} rules={[yupSync]}>
                <TextArea maxLength={200} rows={6} />
              </FormItem>
            </div>
          ) : null}
        </div>
      </Form>
      <div className={styles.payment__buttons}>
        <Button
          size="large"
          type="primary"
          onClick={form.submit}
          className={styles.payment__button}
        >
          {t("booking.payment.form.save")}
        </Button>
        <Button danger onClick={handleClose} size="large" ghost className={styles.payment__button}>
          {t("booking.payment.form.decline")}
        </Button>
      </div>
    </div>
  );
};


import { useQueryClient } from "react-query";

import { useCustomQuery } from "@/hooks/reactQuery/useCustomQuery";

import { api, handleHotelIdInApi } from "@/helpers";

import { IBookingFilterParams, IBookingsResponse, TQueryOptions } from "@/types";

const fetcher = async (filters: IBookingFilterParams | NonNullable<unknown>) => {
  return api.get("host/bookings", handleHotelIdInApi(filters));
};

export const useGetBookings = <TQueryFnData = IBookingsResponse, TData = IBookingsResponse>(
  filters?: IBookingFilterParams | NonNullable<unknown>,
  options?: TQueryOptions<TQueryFnData, TData>
) => {
  const queryClient = useQueryClient();

  return useCustomQuery<TQueryFnData, TData>(["get-bookings"], () => fetcher(filters || {}), {
    ...options,
    staleTime: Infinity,
    onSuccess: async () => {
      await queryClient.invalidateQueries(["get-bookings-count"], { exact: false });
    }
  });
};


import { useQueryClient } from "react-query";
import { useNavigate, useParams } from "react-router-dom";

import { useCustomMutation } from "@/hooks";

import { api } from "@/helpers";

import { ROUTES } from "@/constants";

import { TMutationsOptions } from "@/types";
import { IPaymentOption } from "@/types/paymentOption/paymentOption.type";

const fetcher = async (body: IPaymentOption) => {
  return api.patch(`payment-options/${body.id}`, body);
};

const { SETTINGS, PAYMENT_OPTIONS } = ROUTES

export const useUpdatePaymentOption = (options?: TMutationsOptions<IPaymentOption, IPaymentOption>) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { hotelId } = useParams();

  const { mutate, ...rest } = useCustomMutation<IPaymentOption, IPaymentOption>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);
      navigate(`/${hotelId}/${SETTINGS}/${PAYMENT_OPTIONS}`);
      await queryClient.invalidateQueries(["get-payment-options"], { exact: false });
    },
  });

  const handleMutate = (data: IPaymentOption) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

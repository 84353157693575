import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { FallingOut } from "@/elements";

import { handleScroll } from "@/helpers";

import { CalendarTypes } from "@/constants/calendar/calendar.constants";
import { ScrollTypes } from "@/constants/global";

import styles from "./CalendarSwitch.module.scss";

import { RootState } from "@/store";
import { setCalendarType, setHandleShowModalSwitchType } from "@/store/calendar/calendar.slice";

export const CalendarSwitch = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const calendarActiveType = useSelector((state: RootState) => state.calendar.calendarType);

  const isActiveCalendarType = (type: CalendarTypes) => {
    return calendarActiveType === type;
  }

  const handleClose = () => {
    dispatch(setHandleShowModalSwitchType(false));
    handleScroll(ScrollTypes.REMOVE);

  };

  const handleCalendarType = (type: CalendarTypes) => {
    dispatch(setCalendarType(type));
    navigate({
      search: `?type=${type}`
    })
    dispatch(setHandleShowModalSwitchType(false));
    handleScroll(ScrollTypes.REMOVE);
  };

  return (
    <FallingOut
      title={t("calendar.modal-title")}
      handleClose={handleClose}
      content={
        <div className={styles["calendar-switch__container"]}>
          <div
            className={`${styles["calendar-switch__button"]} ${isActiveCalendarType(CalendarTypes.MONTHLY) ? styles["active-type"] : ""}`}
            onClick={() => handleCalendarType(CalendarTypes.MONTHLY)}
          >
            <p>{t("calendar.modal-month")}</p>
          </div>
          <div
            className={`${styles["calendar-switch__button"]} ${isActiveCalendarType(CalendarTypes.LIST) ? styles["active-type"] : ""}`}
            onClick={() => handleCalendarType(CalendarTypes.LIST)}
          >
            <p>{t("calendar.modal-list")}</p>
          </div>
        </div>
      }
    />
  );
};

import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";

import { useCustomMutation } from "@/hooks";

import { api, removeJWTToken, setJWTRefreshToken, setJWTToken } from "@/helpers";

import { IAuthResponse, ISignIn, TMutationsOptions } from "@/types";


const fetcher = async (body: ISignIn) => {
  removeJWTToken();

  const res: IAuthResponse = await api.post("/host/auth/login", body);

  const { jwtToken, refreshToken } = res;

  jwtToken && setJWTToken(jwtToken);
  refreshToken && setJWTRefreshToken(refreshToken);
  
  return res;
};

export const useSignIn = (options?: TMutationsOptions<IAuthResponse, ISignIn>) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate, ...rest } = useCustomMutation<IAuthResponse, ISignIn>(fetcher, {
    ...options,
    onSuccess: async (data, ...args) => {
      options?.onSuccess?.(data, ...args);

      await queryClient.invalidateQueries(["current-user"], { exact: false });
      
      navigate("/");
    }
  });

  const handleMutate = (data: ISignIn) => {
    mutate(data);
  };

  return { mutate: handleMutate, ...rest };
};

import React from "react";
import { useSelector } from "react-redux";

import { SettingDetailType } from "@/constants/global";

import styles from "./IntroduceIndividual.module.scss";

import { Details } from "../Details/Details";

import { RootState } from "@/store";

export const IntroduceIndividual = () => {
  const activeLocation = useSelector((state: RootState) => state.hotel.activeHotel);

  return (
    <div className={styles.introduce__wrapper}>
      <div className={styles.introduce__location}>
        <div className={styles["introduce__image-container"]}>
          <img className={styles.introduce__image} src={activeLocation?.locationImage} alt="" />
        </div>
        <div className={styles["introduce__location-name"]}>{activeLocation?.publicLabel}</div>
      </div>

      <div className={styles.introduce__details}>
        <div className={`${styles.introduce__column} ${styles.first}`}>
          <Details type={SettingDetailType.FOCUS} />
          <Details type={SettingDetailType.FREE} />
        </div>
        <div className={`${styles.introduce__column} ${styles.second}`}>
          <div className={styles["introduce__column-item"]}>
            <Details type={SettingDetailType.SLOGAN} />
          </div>
          <div className={styles["introduce__column-contacts"]}>
            <div className={styles["introduce__column-contacts-item"]}>
              <Details type={SettingDetailType.CONTACT} />
            </div>
            <div className={styles["introduce__column-contacts-item"]}>
              <Details type={SettingDetailType.SOCIALS} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Typography } from "antd";

import { BookingViewCard } from "@/containers/BookingViewCard/BookingViewCard";

import { BookingHistory } from "@/components/Booking/BookingHistory/BookingHistory";
import { BookingPayment } from "@/components/Booking/BookingPayment/BookingPayment";
import { BookingEdit } from "@/components/Forms/BookingEdit/BookingEdit";
import { BookingPaymentForm } from "@/components/Forms/BookingPaymentForm/BookingPaymentForm";
import { GuestViewCard } from "@/components/Guest/GuestViewCard/GuestViewCard";

import { Icon, Loader } from "@/elements";

import { useGetBooking } from "@/react-queries/booking/useGetBooking";

import useGetScreenStatus from "@/hooks/general/useGetScreenStatus";

import { allLocationsIdStorageService, handleScroll } from "@/helpers";

import { ROUTES } from "@/constants";
import { AllLocationType, ScrollTypes } from "@/constants/global";

import styles from "./BookingView.module.scss";

import { RootState } from "@/store";
import { setEditModalStatus, setHandleAllLocationId } from "@/store/booking/booking.slice";
import { setHeaderNavigation } from "@/store/header/header.slice";

const { BOOKINGS, EDIT, DASHBOARD, GUESTS } = ROUTES;

const { getItem: getIsAllLocation } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

const { Link } = Typography;

export const BookingView = () => {
  const header = useSelector((state: RootState) => state.header);

  const { bookingId, hotelId } = useParams();
  const location = useLocation();
  const isAllLocation = getIsAllLocation();
  const isMobile = useGetScreenStatus();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isShowEditModal = useSelector((state: RootState) => state.booking.edit.isShowEditModal);
  const isShowPaymentModal = useSelector(
    (state: RootState) => state.booking.payment.isShowPaymentModal
  );
  const isDashboardBack = useMemo(() => {
    const route = location.state?.route;
    if (route) {
      return route?.includes(DASHBOARD);
    }

    return false;
  }, [location]);

  const {
    data: bookingData,
    isLoading,
    refetch,
    isRefetching
  } = useGetBooking(bookingId || "", {
    enabled: !!bookingId
  });

  useEffect(() => {
    if (bookingData) {
      const conditionTitle = isDashboardBack ? t("navigation.calendar") : t("booking.view.title");
      const conditionLink = isDashboardBack ? location.state?.route : `/${hotelId}/${BOOKINGS}`;
      dispatch(
        setHeaderNavigation({
          navigation: {
            title: conditionTitle,
            link: conditionLink
          }
        })
      );
    }
  }, [bookingData]);

  const handleEditGuest = () => {
    navigate(`/${hotelId}/${GUESTS}/${bookingData?.guest.id}/${EDIT}`, {
      state: { from: location.pathname }
    });
  };

  const handleEditBooking = () => {
    dispatch(setEditModalStatus(true));
    handleScroll(ScrollTypes.ADD);
  };

  const handleCloseEditBooking = () => {
    dispatch(setEditModalStatus(false));
    handleScroll(ScrollTypes.REMOVE);
  };

  useEffect(() => {
    if (bookingId) {
      refetch();
    }
  }, [bookingId]);

  useEffect(() => {
    if (bookingData && isAllLocation) {
      dispatch(setHandleAllLocationId(String(bookingData?.hotel?.id)));
    }
  }, [bookingData]);

  if (isLoading || isRefetching) {
    return <Loader isFullScreen />;
  }

  return (
    <div className={styles["booking-view__container"]}>
      {!isMobile ? (<div className={styles["booking-view__back"]}>
        <Link className={styles["booking-view__back-container"]} href={header.navigation.link}>
          <div className={styles["booking-view__back-icon"]}>
            <Icon icon="arrowLeft" className={styles["logo-header"]} />
          </div>
          <span className={styles["booking-view__back-title"]}>{header.navigation.title}</span>
        </Link>
      </div>) : null}
      <h1 className={styles["booking-view__title"]}> {t("booking.view.detail-title")} </h1>
      <div className={styles["booking-view__content"]}>
        <div className={styles["booking-view__first-details"]}>
          <BookingViewCard bookingData={bookingData} handleEdit={handleEditBooking} />
          <BookingPayment booking={bookingData} />
        </div>
        <div className={styles["booking-view__second-details"]}>
          <GuestViewCard
            bookingData={bookingData}
            guestData={bookingData?.guest}
            handleEdit={handleEditGuest}
          />
          <BookingHistory bookingData={bookingData} />
        </div>
      </div>
      {isShowEditModal && (
        <BookingEdit closeModal={handleCloseEditBooking} bookingData={bookingData} />
      )}
      {isShowPaymentModal && <BookingPaymentForm booking={bookingData} />}
    </div>
  );
};

import { FC } from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import { LocationSettings } from "@/containers";

import { Loader } from "@/elements";

import { useCurrentUser } from "@/react-queries";

import { allLocationsIdStorageService, hotelIdStorageService } from "@/helpers";

import { ROUTES } from "@/constants";
import { AllLocationType, HotelType } from "@/constants/global";

import { AuthRoutes } from "./AuthRoutes";
import { MainRoutes } from "./MainRoutes";
import { PrivateRoutes } from "./PrivateRoutes";

import { RootState } from "@/store";

const { DASHBOARD, AUTH, HOTEL_SETUP, ALL_LOCATIONS } = ROUTES;

const { getItem } = hotelIdStorageService(HotelType.HOTEL_ID);
const { getItem: getIsAllLocation } = allLocationsIdStorageService(
  AllLocationType.ALL_LOCATIONS_ID
);

export const AppRouter: FC = () => {
  const { isLoading: isUserDataLoading } = useCurrentUser();
  const activeHotel = useSelector((state: RootState) => state.hotel.activeHotel);
  const isAllLocation = getIsAllLocation();
  const conditionLink = isAllLocation
    ? `${ALL_LOCATIONS}/${DASHBOARD}`
    : activeHotel?.id
    ? `${activeHotel.id}/${DASHBOARD}`
    : `${getItem()}/${DASHBOARD}`;


  if (isUserDataLoading) {
    return <Loader isFullScreen />;
  }

  return (
    <Routes>
      <Route index element={<Navigate to={conditionLink} />} />

      <Route element={<PrivateRoutes />}>
        <Route path="/:hotelId/*" element={<MainRoutes />} />

        <Route path={`${AUTH}${HOTEL_SETUP}`} element={<LocationSettings />} />
      </Route>
      <Route path={`${AUTH}/*`} element={<AuthRoutes />} />
    </Routes>
  );
};

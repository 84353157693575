import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Form } from "antd";

import { Loader } from "@/elements";

import { useHotelSetup } from "@/react-queries";

import { allLocationsIdStorageService } from "@/helpers";

import { ROUTES } from "@/constants";
import { AllLocationType } from "@/constants/global";

import { ILocationSettings } from "@/types";
import { IAuthBasicSettings } from "../Forms/AuthBasicSettingsForm/AuthBasicSettingsForm.types";

import styles from "./LocationsCreate.module.scss";

import { AuthBasicSettingsForm } from "../Forms/AuthBasicSettingsForm/AuthBasicSettingsForm";

import { RootState } from "@/store";
import { setHeaderNavigation } from "@/store/header/header.slice";

const { LOCATIONS } = ROUTES;

const { getItem: getIsAllLocation } = allLocationsIdStorageService(AllLocationType.ALL_LOCATIONS_ID);

export const LocationsCreate = () => {
  const { t } = useTranslation();
  const activeHotel = useSelector((state: RootState) => state.hotel.activeHotel);
  const dispatch = useDispatch();
  const isAllLocation = getIsAllLocation();
  const { hotelId } = useParams();
  const [form] = Form.useForm<IAuthBasicSettings>();

  const { mutate: handleCreateHotel, isLoading } = useHotelSetup();

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const locationInfo = {
          publicLabel: values.publicLabel,
          googleMapAddress: values.googleMapAddress,
          maxGuests: Number(values.maxCountOfGuests),
          file: values.images[0]?.file
        } as ILocationSettings;

        handleCreateHotel(locationInfo);
      })
      .catch((error) => {
        return error
      });
  };

  useEffect(() => {
    if (activeHotel) {
      dispatch(
        setHeaderNavigation({
          navigation: {
            title: t("locations.title"),
            link: `/${hotelId}/${LOCATIONS}`
          }
        })
      );
    }
  }, [activeHotel]);

  if (!activeHotel && !isAllLocation) {
    return <Loader isFullScreen />;
  }

  return (
    <div className={styles.location__container}>
      <h1 className={styles.location__title}>{t("locations.create.title")}</h1>
      <div className={styles.location__content}>
        <AuthBasicSettingsForm isDataLoading={isLoading} isCreate form={form} submitForm={handleSubmit} />
      </div>
    </div>
  );
};

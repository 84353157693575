import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Divider, Radio, RadioChangeEvent } from "antd";

import { FilterCounter, FilterPaymentStatus } from "@/constants/bookings/bookings.constant";

import { IBookingFilterParams } from "@/types";

import styles from "./BookingFilter.module.scss";

import {
  bookingFilterSort,
  filterSortCount,
  paymentFilter
} from "../BookingList/BookingList.helper";

export const BookingFilterContent = memo(function BookingFilterContent({
  bookingsRequestFilter,
  setBookingsRequestFilter,
  handleFilterValues,
}: {
  bookingsRequestFilter: IBookingFilterParams;
  setBookingsRequestFilter: React.Dispatch<React.SetStateAction<IBookingFilterParams>>
  handleFilterValues: (values: IBookingFilterParams) => void;
}) {
  const { t } = useTranslation();

  const handleChangeStateValue = (values: any) => {
    setBookingsRequestFilter((prevValue) => ({
      ...prevValue,
      ...values
    }));
  };

  const handleSortingFilter = (data: RadioChangeEvent) => {
    handleChangeStateValue(data.target.value);
  };

  const handleSortingNights = (data: RadioChangeEvent) => {
    handleChangeStateValue({
      nightsCount: data.target.value
    });
  };

  const handleSortingPayment = (data: RadioChangeEvent) => {
    handleChangeStateValue({
      paymentStatus: data.target.value
    });
  };

  const handleSortingGuests = (data: RadioChangeEvent) => {
    handleChangeStateValue({
      guestsCount: data.target.value
    });
  };

  const handleSortFieldValues = () => {
    const getField = bookingFilterSort.find(
      (bookingFilter) =>
        bookingFilter.value.sortField === bookingsRequestFilter.sortField &&
        bookingFilter.value.sortOrder === bookingsRequestFilter.sortOrder
    );

    return getField?.value || "";
  };

  return (
    <div className={styles.filter__wrapper}>
      <div className={styles.filter__content}>
        <div className={styles["filter__content-item"]}>
          <h2 className={styles["filter__content-item-title"]}>{t("booking.filter.sort")}</h2>
          <div className={styles["filter__content-item-content"]}>
            <Radio.Group
              value={handleSortFieldValues()}
              onChange={handleSortingFilter}
              buttonStyle="solid"
            >
              {bookingFilterSort.map((sort) => (
                <Radio.Button
                  className={styles["filter__content-item-text"]}
                  key={sort.label}
                  value={sort.value}
                >
                  {sort.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>
        </div>
        <Divider className={styles["filter__content-item-divider"]} />
        <div className={styles["filter__content-item"]}>
          <h2 className={styles["filter__content-item-title"]}>{t("booking.filter.payment")}</h2>
          <div className={styles["filter__content-item-content"]}>
            <Radio.Group
              onChange={handleSortingPayment}
              value={bookingsRequestFilter.paymentStatus || FilterPaymentStatus.ALL}
              buttonStyle="solid"
            >
              {paymentFilter.map((sort) => (
                <Radio.Button
                  className={styles["filter__content-item-text"]}
                  key={sort.label}
                  value={sort.value}
                >
                  {sort.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>
        </div>
        <Divider />
        <div className={styles["filter__content-item"]}>
          <h2 className={styles["filter__content-item-title"]}>{t("booking.filter.nights")}</h2>
          <div className={styles["filter__content-item-content"]}>
            <Radio.Group
              onChange={handleSortingNights}
              value={bookingsRequestFilter.nightsCount || FilterCounter.ALL}
              buttonStyle="solid"
            >
              {filterSortCount.map((sort) => (
                <Radio.Button
                  className={styles["filter__content-item-text"]}
                  key={sort.label}
                  value={sort.value}
                >
                  {sort.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>
        </div>
        <Divider />
        <div className={styles["filter__content-item"]}>
          <h2 className={styles["filter__content-item-title"]}>{t("booking.filter.guests")}</h2>
          <div className={styles["filter__content-item-content"]}>
            <Radio.Group
              onChange={handleSortingGuests}
              value={bookingsRequestFilter.guestsCount || FilterCounter.ALL}
              buttonStyle="solid"
            >
              {filterSortCount.map((sort) => (
                <Radio.Button
                  className={styles["filter__content-item-text"]}
                  key={sort.label}
                  value={sort.value}
                >
                  {sort.label}
                </Radio.Button>
              ))}
            </Radio.Group>
          </div>
        </div>
        <Divider />
      </div>
      <div className={styles["filter__content-button-wrapper"]}>
        <Button
          onClick={() => handleFilterValues(bookingsRequestFilter)}
          type="primary"
          className={styles["filter__content-button"]}
        >
          {t("booking.filter.apply")}
        </Button>
      </div>
    </div>
  );
});

import React, { useMemo } from "react";
import { Select, SelectProps } from "antd";

import { useGetAllLocations } from "@/react-queries/hotel/useGetAllLocation";

import styles from "./LocationSelect.module.scss";

export const LocationSelect = (props: SelectProps) => {
  const { data: userLocations, isLoading: isLocationLodaing } = useGetAllLocations();

  const optionValues = useMemo(() => {
    return userLocations?.map((location) => ({
      label: location.publicLabel,
      value: location.id
    }));
  }, [userLocations]);

  return (
    <div className={styles.select}>
        <Select
          options={optionValues}
          loading={isLocationLodaing}
          {...props}
        />
    </div>
  );
};

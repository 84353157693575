import { number, object } from "yup";

export const bookingRulesUpdate = object().shape({
  maxGuests: number().required().min(1),
  minNights: number()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    })
    .notRequired()
    .test("min", (values) => {
      if (!values && typeof values !== "number") {
        return true;
      }
      
      return values >= 1;
    })
});
